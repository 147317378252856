import { AppFilter, AppTable, AppTableColumn } from '../../../shared/_models/models';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

const today = dayjs.tz(new Date(), 'America/New_York');
const dateRange = [today.subtract(1, 'year').toDate(), today.toDate()];
export const dealExternalSyncFiltersConfigBase: AppFilter[] = [
  new AppFilter('dealExternalSyncRange', 'DATES_RANGE', 'createdAt', 'Date Range (EST)', '',
    'default', false, true, 'createdAt', [],
    [...dateRange], null, null, null, false, [...dateRange],
    'Date range is required', null, null, null, true, undefined,
    undefined, true, 'query'),
];

export const dealExternalSyncFiltersConfig: AppFilter[] = [
  ...dealExternalSyncFiltersConfigBase,
  new AppFilter('action', 'SELECT', 'action', 'Action Type', 'Select action type',
    'default', true, true, null,
    [{id: 1, displayName: 'Sync', value: 'SYNC'}, {id: 2, displayName: 'Retrieve', value: 'RETRIEVE'}],
    undefined, null, null, null, false, undefined),
];

export const dealExternalSyncTable: AppTable = new AppTable('dealExternalSyncTable', 0, 10, 'createdAt', 'desc', true, true);

export const dealExternalSyncTableColumns: AppTableColumn[] = [
  {id: 'action', prop: 'action', displayName: 'Action', type: 'titlecase-text', isSortEnabled: true},
  {id: 'synced', prop: 'synced', displayName: 'Status', type: 'status-success-failed', isSortEnabled: true},
  {
    id: 'syncResult',
    prop: 'syncResult',
    displayName: 'Message',
    type: 'custom-function',
    isSortEnabled: true,
    valueFormatter: (value: string) => {
      if (value && typeof value === 'string') {
        let newValue: string;
        newValue = value.replace(/_/, ' ').toLowerCase();
        newValue = newValue.replace('google', 'DV360');
        const firstLetter = value.toString().substr(0, 1).toUpperCase();
        newValue = firstLetter + newValue.substr(1);
        return newValue;
      }
    }
  },
  {id: 'dealOrderStatus', prop: 'dealStatus.orderStatus', displayName: 'Order Status', type: 'titlecase-text', isSortEnabled: true},
  {id: 'dealBuyerStatus', prop: 'dealStatus.buyerStatus', displayName: 'Deal Status', type: 'titlecase-text', isSortEnabled: true},
  {id: 'fullName', prop: 'fullName', displayName: 'User', type: 'text', isSortEnabled: true},
  {id: 'createdAt', prop: 'createdAt', displayName: 'Time', type: 'date-and-time', isSortEnabled: true}
];

export const dealExternalSyncMarketplaceTableColumns: AppTableColumn[] = [
  {id: 'action', prop: 'action', displayName: 'Action', type: 'titlecase-text', isSortEnabled: true},
  {id: 'synced', prop: 'synced', displayName: 'Status', type: 'status-success-failed', isSortEnabled: true},
  {
    id: 'syncResult',
    prop: 'syncResult',
    displayName: 'Message',
    type: 'custom-function',
    isSortEnabled: true,
    valueFormatter: (value: string) => {
      if (value && typeof value === 'string') {
        let newValue: string;
        newValue = value.replace(/_/, ' ').toLowerCase();
        newValue = newValue.replace('google', 'DV360');
        const firstLetter = value.toString().substr(0, 1).toUpperCase();
        newValue = firstLetter + newValue.substr(1);
        return newValue;
      }
    }
  },
  {id: 'dealStatus', prop: 'externalApiData.dealStatus', displayName: 'Deal Status', type: 'titlecase-text', isSortEnabled: true},
  {id: 'fullName', prop: 'fullName', displayName: 'User', type: 'text', isSortEnabled: true},
  {id: 'createdAt', prop: 'createdAt', displayName: 'Time', type: 'date-and-time', isSortEnabled: true}
];

