import { IconDefinition } from '@ant-design/icons-angular';
import * as i from '@ant-design/icons-angular/icons';

export const SYSTEM_ZORRO_ICONS: IconDefinition[] = [
  i.UserOutline,
  i.LockOutline,
  i.HomeOutline,
  i.TableOutline,
  i.ForkOutline,
  i.CopyrightOutline,
  i.ClusterOutline,
  i.FileDoneOutline,
  i.MailOutline,
  i.TeamOutline,
  i.UnorderedListOutline,
  i.LayoutOutline,
  i.PoweroffOutline,
  i.EyeOutline,
  i.EyeInvisibleOutline,
  i.UsergroupAddOutline,
  i.SearchOutline,
  i.UserAddOutline,
  i.PlusOutline,
  i.WindowsOutline,
  i.MinusCircleOutline,
  i.IdcardFill,
  i.FileTextFill,
  i.EditFill,
  i.CheckCircleFill,
  i.CloseCircleFill,
  i.EditOutline,
  i.ToolOutline,
  i.ShopOutline,
  i.LaptopOutline,
  i.MobileOutline,
  i.CheckCircleOutline,
  i.CloseCircleOutline,
  i.IdcardOutline,
  i.CheckOutline,
  i.BellOutline,
  i.ExclamationCircleOutline,
  i.MenuOutline,
  i.CloseOutline,
  i.LoadingOutline,
  i.UpOutline,
  i.DownOutline,
  i.CopyOutline,
  i.InboxOutline,
  i.DeleteOutline,
  i.DownloadOutline
];
