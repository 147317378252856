import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Store } from '@ngrx/store';
import * as TermsOfServiceActions from '../_store/termsOfService.actions';
import * as fromTermsOfService from '../_store/termsOfService.reducer';
import { FormHelper } from '../../../../_common';
import { ApiService } from '../../../../shared/_services/api.service';
import { SharedService } from '../../../../shared/_services/shared.service';
import {TermsOfService} from '../../../signup/register.model';
import {ResponseFromServer} from '../../../../shared/_models/models';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class SupplyTermsOfServiceService {
  unsubscribe$ = new Subject<void>();

  constructor(
    private http: HttpClient,
    private fh: FormHelper,
    private store: Store<fromTermsOfService.TermsOfServiceState>,
    private api: ApiService,
    private sharedService: SharedService
  ) {
  }

  fetchTermsOfService(docType: string): void {
    this.store.dispatch(new TermsOfServiceActions.FetchTermsOfServiceRequestAction());
    let termsOfServiceObservable: Observable<ResponseFromServer<TermsOfService[]>>;
    if (docType === 'terms-of-service') {
      termsOfServiceObservable = this.api.getTermsOfService();
    } else {
      termsOfServiceObservable = this.api.getDataProcessingAddendum();
    }
    termsOfServiceObservable.pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
      this.store.dispatch(new TermsOfServiceActions.FetchTermsOfServiceSuccessAction({termsOfService: res.data}));
    }, (err) => {
      this.store.dispatch(new TermsOfServiceActions.FetchTermsOfServiceFailureAction(err));
    });
  }

  fetchTermsOfServiceById(id: number | string, docType: string) {
    let termsOfServiceObservable: Observable<TermsOfService>;
    if (docType === '') {
      termsOfServiceObservable = this.api.getTermsOfServiceById(id);
    } else {
      termsOfServiceObservable = this.api.getDataProcessingAddendumById(id);
    }
    this.fetchOneTermsOfService(termsOfServiceObservable);
  }
  fetchLatestTermsOfService(docType: string) {
    let termsOfServiceObservable: Observable<TermsOfService>;
    if (docType === '') {
      termsOfServiceObservable = this.api.getLatestTermsOfService();
    } else {
      termsOfServiceObservable = this.api.getLatestDataProcessingAddendum();
    }
    this.fetchOneTermsOfService(termsOfServiceObservable);
  }

  createTermsOfService(termsOfService: any, docType: string) {
    this.store.dispatch(new TermsOfServiceActions.CreateTermsOfServiceRequestAction());
    let termsOfServiceObservable: Observable<TermsOfService>;
    if (docType === '') {
      termsOfServiceObservable = this.api.createTermsOfService(termsOfService);
    } else {
      termsOfServiceObservable = this.api.createDataProcessingAddendum(termsOfService);
    }
    termsOfServiceObservable.subscribe((res) => {
      this.store.dispatch(new TermsOfServiceActions.CreateTermsOfServiceSuccessAction({currentTermsOfService: res}));
      this.sharedService.onHttpCompletionResolve(true, 'Success',
        (docType === '' ? 'Terms of service' : 'Data processing addendum') + ' updated',
        '/supply/terms-of-service/' + docType, docType === '' ? 'terms-of-service-created' : docType + '-created');
    }, (err) => {
      this.store.dispatch(new TermsOfServiceActions.CreateTermsOfServiceFailureAction(err));
    });
  }
  private fetchOneTermsOfService(termsOfServiceObservable: Observable<TermsOfService>) {
    this.store.dispatch(new TermsOfServiceActions.FetchTermsOfServiceByIdRequestAction());
    termsOfServiceObservable.subscribe((res) => {
      this.store.dispatch(new TermsOfServiceActions.FetchTermsOfServiceByIdSuccessAction({currentTermsOfService: res}));
    }, (err) => {
      this.store.dispatch(new TermsOfServiceActions.FetchTermsOfServiceByIdFailureAction(err));
    });
  }
}
