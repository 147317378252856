import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { takeUntil} from 'rxjs/operators';

import _ from 'lodash';

import { Store } from '@ngrx/store';
import * as fromPlacements from '../_store/placements.reducer';
import * as PlacementsActions from '../_store/placements.actions';
import * as fromShared from '../../../shared/_store/shared.reducer';

import { FormHelper } from '../../../_common';
import { SharedService } from '../../../shared/_services/shared.service';
import {
  Commission,
  CommonDataModel,
  Floor,
  ContentGenre,
  Placement,
  IabBat,
  IabAttribute,
  DspAccount,
  CommissionToSubmit,
  ModalActionType, DomainBundlePayload, DomainFilter, PortalType, TrafficSource, ResponseFromServer, Country
} from '../../../shared/_models/models';
import { PlacementsService } from '../_services/placements.service';
import { AuthenticationService } from '../../../auth/_services/authentication.service';
import { SharedStoreService } from '../../../shared/_services/shared-store.service';
import {NzUploadFile} from 'ng-zorro-antd/upload';

@Component({
  selector: 'app-edit-placement',
  templateUrl: './edit-placement.component.html',
  styleUrls: ['./edit-placement.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlacementEditComponent implements OnInit, OnDestroy {
  publisherId: number;
  pubTsId: number;

  placementForm: UntypedFormGroup;

  editMode: 'CREATE' | 'DUPLICATE' | 'EDIT';

  currentPlacement$: Observable<{ placement: Placement; isLoading: boolean; isUpdateFinished: boolean; isCreateFinished: boolean }>;
  currentPlacement: Placement;
  isPlacementLoading = false;
  currentPlacementId: number;
  origPlacementId: number;

  iabCategories$: Observable<any>;
  iabBannerAdTypes$: Observable<IabBat[]>;
  iabCreativeAttributes$: Observable<IabAttribute[]>;
  dspAccounts$: Observable<DspAccount[]>;

  iabCategories: any = [];
  iabBannerAdTypes: any;
  iabCreativeAttributes: any;
  dspAccounts: DspAccount[] = [];
  uploadedFile: NzUploadFile[] = [];
  isDomainBundlesDuplicateFinished$: Observable<boolean>;
  isDomainBundlesDuplicateFinished: boolean;

  contentGenres$: Observable<ContentGenre[]>;
  pubTs$: Observable<CommonDataModel>;
  ts: TrafficSource;
  pubTsFloors: Floor[];
  pubTsCommission: Commission;
  countries$: Observable<ResponseFromServer<Country[]>>;
  countries: Country[];
  pubTsCot: string;

  isShowFloors: boolean;
  portalType: PortalType;
  floorsToSubmit: any;
  commissionsToSubmit: CommissionToSubmit;
  domainFilterToSubmit: DomainFilter;
  blockedListsData: any;
  badvData: { badvName: string }[] = [];
  badvDataSize: number;
  domainBundlePayload: DomainBundlePayload = {addedDomainBundles: [], removedDomainBundles: [], updatedDomainBundles: []} as DomainBundlePayload;
  ptsDemandProfile = 'not found';
  demandProfiles = [
    {displayName: 'Use Traffic Source Setting', value: 'use-traffic-source'},
    {displayName: 'All Demand', value: 'OPEN'},
    {displayName: 'Direct Demand Only', value: 'DIRECT'},
    {displayName: 'Nexxen PMPs Only', value: 'PMP'},
    {displayName: 'Direct Demand & Any Deals Only', value: 'DIRECT_PMP'},
    {displayName: 'Publisher Deals Only', value: 'PUBLISHER_PMP'},
    {displayName: 'Magnite Direct', value: 'MAGNITE_ONLY'},
    {displayName: 'Open Market Only', value: 'OPEN_MARKET_ONLY' },
  ];
  isRequiredFieldByPubTs = false;
  externalSourceIdTooltip: string;

  contentAttributes = [
    {displayName: 'All', value: 'all', isDisabledForRequest: false, isDisabledForReport: false},
    {displayName: 'ID', value: 'id', isDisabledForRequest: false, isDisabledForReport: false},
    {displayName: 'Title', value: 'title', isDisabledForRequest: false, isDisabledForReport: false},
    {displayName: 'Series', value: 'series', isDisabledForRequest: false, isDisabledForReport: false},
    {displayName: 'Season', value: 'season', isDisabledForRequest: false, isDisabledForReport: false},
    {displayName: 'Episode', value: 'episode', isDisabledForRequest: false, isDisabledForReport: false},
    {displayName: 'Category', value: 'category', isDisabledForRequest: false, isDisabledForReport: false},
    {displayName: 'Content Rating', value: 'contentrating', isDisabledForRequest: false, isDisabledForReport: false},
    {displayName: 'Live Stream', value: 'livestream', isDisabledForRequest: false, isDisabledForReport: false},
    {displayName: 'Language', value: 'language', isDisabledForRequest: false, isDisabledForReport: false},
    {displayName: 'Length', value: 'length', isDisabledForRequest: false, isDisabledForReport: false},
    {displayName: 'Network', value: 'network', isDisabledForRequest: false, isDisabledForReport: false},
    {displayName: 'Channel', value: 'channel', isDisabledForRequest: false, isDisabledForReport: false},
    {displayName: 'Key Words', value: 'keywords', isDisabledForRequest: false, isDisabledForReport: false},
    {displayName: 'Production Quality', value: 'productionquality', isDisabledForRequest: false, isDisabledForReport: false},
    {displayName: 'Producer', value: 'producer', isDisabledForRequest: false, isDisabledForReport: false}
  ];

  selectIcons: any = {};
  isModalVisible: boolean;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private formsHelper: FormHelper,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store<fromPlacements.PlacementsState>,
    private sharedStore: Store<fromShared.SharedState>,
    private placementsService: PlacementsService,
    private sharedService: SharedService,
    private sharedStoreService: SharedStoreService,
    private auth: AuthenticationService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  public ngOnInit(): void {
    this.store.dispatch(new PlacementsActions.ResetPlacementState());
    this.initRouteParams();
    this.initForm();
    this.initStoreSubscriptions();
    this.initFormListenChanges();
    this.initRequests();
  }

  initRouteParams() {
    this.portalType = this.activatedRoute.snapshot.data.portalType;
    this.publisherId = this.portalType === 'portal' ? this.auth.currentUserValue.publisherId : +this.activatedRoute.snapshot.params.id;
    this.isShowFloors = this.portalType !== 'supply' ?
      !!this.sharedService.getPortalPermissionsFromLocalStorage().find(permission => permission.name === 'publisher.floors.show') :
      true;
    if (this.activatedRoute.snapshot.params) {
      this.currentPlacementId = +this.activatedRoute.snapshot.params.placementId;
      this.pubTsId = this.portalType === 'portal' ? +this.activatedRoute.snapshot.params.id : +this.activatedRoute.snapshot.params.pubTsId;
      this.editMode = this.router.url.includes('duplicate') ? 'DUPLICATE' : (this.router.url.includes('create') ? 'CREATE' : 'EDIT');
      if (this.editMode !== 'CREATE') {
        this.placementsService.getPlacementById(this.currentPlacementId);
        this.placementsService.checkDomainBundleDuplicateStatus(this.currentPlacementId);
      }
      this.placementsService.getPublisherTrafficSourceById(this.pubTsId, this.publisherId);
    }
  }

  initRequests() {
    this.placementsService.getIabCategories();
    this.placementsService.getIabBannerAdTypes();
    this.placementsService.getIabCreativeAttributes();
    this.placementsService.getContentGenres();
    this.placementsService.getDspAccounts();
  }

  initStoreSubscriptions() {
    this.contentGenres$ = this.store.select(fromPlacements.getContentGenres);
    this.currentPlacement$ = this.store.select(fromPlacements.getCurrentPlacement);
    this.currentPlacement$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      if (res && !res.isLoading && res.placement) {
        this.currentPlacement = _.cloneDeep(res.placement);
        if (this.editMode === 'DUPLICATE') {
          this.currentPlacement.commissions?.forEach(commission => commission.id = undefined);
        }
        this.cdr.detectChanges();
        this.blockedListsData = this.getBlockedListsData(this.currentPlacement);
        this.badvDataSize = this.currentPlacement.badv ? this.currentPlacement.badv.length : 0;
        this.badvData = this.currentPlacement.badv ? this.currentPlacement.badv.map(badv => ({badvName: badv})) : [];

        if (!res.isCreateFinished && !res.isUpdateFinished) {
          this.patchFormValues();
        }
        if (this.ts) {
          this.setMandatoryFieldsByPubTs(this.ts.shortName);
        }
        // do stuff that depends on newly created placement id
        if (res.isCreateFinished) {
          this.origPlacementId = this.currentPlacementId;
          this.currentPlacementId = this.currentPlacement.placementId;
          this.isPlacementLoading = false;
          if (this.editMode === 'DUPLICATE') {
            this.placementsService.duplicateDomainBundlesByPlacementId(this.origPlacementId, this.currentPlacementId);
          }
          this.onBackToTableClick();
        }
      }
      this.isPlacementLoading = res ? res.isLoading : false;
    });

    // in order to render the blocked lists with all the data (like categories lists) required for its INIT process,
    // need to ensure it is pulled from server
    // the detect changes to ensure angular runs the check to load child component
    this.iabCategories$ = this.store.select(fromPlacements.getIabCategories);
    this.iabCategories$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      if (res) {
        this.iabCategories = _.cloneDeep(res);
        this.cdr.detectChanges();
      }
    });

    this.iabBannerAdTypes$ = this.store.select(fromPlacements.getIabBannerAdTypes);
    this.iabBannerAdTypes$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      if (res) {
        this.iabBannerAdTypes = _.cloneDeep(res);
        this.cdr.detectChanges();
      }
    });

    this.iabCreativeAttributes$ = this.store.select(fromPlacements.getIabCreativeAttributes);
    this.iabCreativeAttributes$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      if (res) {
        this.iabCreativeAttributes = _.cloneDeep(res);
        this.cdr.detectChanges();
      }
    });

    this.dspAccounts$ = this.store.select(fromPlacements.getDspAccounts);
    this.dspAccounts$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      if (res) {
        this.dspAccounts = _.cloneDeep(res);
        this.cdr.detectChanges();
      }
    });

    this.countries$ = this.sharedStoreService.getSharedAsset('countries');
    this.countries$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      if (res?.data) {
        this.countries = _.cloneDeep(res.data);
      }
    });

    this.pubTs$ = this.store.select(fromPlacements.getPubTs);
    this.pubTs$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      if (res?.data) {
        this.pubTsFloors = _.cloneDeep(res.data[0].floors);
        this.pubTsCommission = _.cloneDeep(res.data[0].commissions[0]);
        this.pubTsCot = res.data[0].cot;
        this.ptsDemandProfile = this.getDemandProfileDisplayName(res.data[0].demandProfile);
        this.ts = res.data[0].trafficSource;
        this.setMandatoryFieldsByPubTs(this.ts.shortName);
      }
    });

    this.isDomainBundlesDuplicateFinished$ = this.store.select(fromPlacements.getIsDomainBundlesDuplicateFinished);
    this.isDomainBundlesDuplicateFinished$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      if (res) {
        this.isDomainBundlesDuplicateFinished = res;
        this.cdr.detectChanges();
      }
    });
  }

  initForm(): void {
    this.placementForm = this.formBuilder.group({
      placementName: ['', Validators.required],
      status: [true],
      mediaType: [null, Validators.required],
      vpaidSupport: [false],
      coppa: [null],
      lda: [false],
      marginOpti: [true],
      notes: [''],
      cat: [],
      bcat: [],
      btype: [],
      battr: [],
      badv: [],
      dspAccountExcludeList: [],
      defaultGenre: [],
      externalSourceId: [],
      defaultDomainBundle: [''],
      defaultAppName: [''],
      defaultAppStoreUrl: [''],
      tldBadv: [],
      demandProfile: [],
      contentAttrRequestExclude: [[]],
      contentAttrReportExclude: [[]],
    });
  }

  initFormListenChanges(): void {
    this.placementForm.controls.mediaType.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        // different required fields for app or site mediaType
        if (this.placementForm.controls.mediaType.value === 'APP' && this.ts?.shortName === 'amazon') {
          this.placementForm.controls.defaultAppName.setValidators(Validators.required);
          this.placementForm.controls.defaultAppStoreUrl.setValidators(Validators.required);
        } else {
          this.placementForm.controls.defaultAppName.setValidators(null);
          this.placementForm.controls.defaultAppStoreUrl.setValidators(null);
        }
      });
  }

  patchFormValues(): void {
    if (this.currentPlacement) {
      // custom patches
      this.currentPlacement.contentAttrRequestExclude =
        this.sharedService.objectJsonDbFormatToArray(this.currentPlacement.contentAttrRequestExclude);
      this.currentPlacement.contentAttrReportExclude =
        this.sharedService.objectJsonDbFormatToArray(this.currentPlacement.contentAttrReportExclude);

      this.placementForm.patchValue(this.currentPlacement);
      this.placementForm.get('cat').setValue(this.currentPlacement.cat);
      this.placementForm.get('status').setValue(this.currentPlacement.status === 'ACTIVE');
      if (this.editMode === 'DUPLICATE') {
        this.placementForm.get('placementName').setValue(this.currentPlacement.placementName + ' - copy');
        this.placementForm.get('externalSourceId').setValue(null);
      }
      this.placementForm.get('demandProfile').setValue(this.setDemandProfile());
    }
  }

  isReadyToLoadChild(childType: string): boolean {
    let isReady = false;
    switch (childType) {
      case 'floors':
        isReady = this.countries && (this.editMode === 'CREATE' ||
          (!!this.currentPlacement?.commissions && !!this.currentPlacement?.floors));
        break;
      case 'commissions':
        isReady = this.portalType !== 'portal' && (this.editMode === 'CREATE' || !!this.currentPlacement);
        break;
      case 'blocked-lists':
        isReady = this.editMode === 'CREATE' ||
          (!!this.currentPlacement && !!this.blockedListsData && !!this.iabBannerAdTypes && !!this.iabCategories &&
            !!this.iabCreativeAttributes && !!this.dspAccounts);
        break;
      case 'domain-bundles':
        // in EDIT placement after it was previously duplicated we need to wait for all domains to finish copying in DB
        isReady = this.portalType !== 'portal' &&
          (!!this.currentPlacement &&
          ((this.editMode === 'DUPLICATE') || (this.editMode === 'EDIT' && this.isDomainBundlesDuplicateFinished))) ||
          this.editMode === 'CREATE';
        break;
    }
    return isReady;
  }

  public get f() {
    return this.placementForm;
  }

  public get fc() {
    return this.placementForm.controls;
  }

  getBlockedListsData(placement: Placement) {
    return {
      bcat: placement.bcat,
      btype: placement.btype?.map(x => Number(x)) || null,
      battr: placement.battr?.map(x => Number(x)) || null,
      tldBadv: placement.tldBadv,
      dspAccountExcludeList: placement.dspAccountExcludeList?.map(x => Number(x)) || null,
    };
  }

  prepareCurrentPlacementPayload(formValue: any, placementId: number | string, editMode: string): FormData {
    let payloadPlacement = _.cloneDeep(formValue);
    payloadPlacement = {...payloadPlacement, pubTs: {pubTsId: this.pubTsId, publisherId: this.publisherId}};

    payloadPlacement.status = payloadPlacement.status ? 'ACTIVE' : 'INACTIVE';
    payloadPlacement.placementId = editMode === 'EDIT' ? this.currentPlacementId : null;
    payloadPlacement.externalSourceId = payloadPlacement.externalSourceId ?
      payloadPlacement.externalSourceId.trim() : payloadPlacement.externalSourceId;

    payloadPlacement.filterDomainBundle = this.domainFilterToSubmit ?
      this.domainFilterToSubmit.filterDomainBundle : this.currentPlacement?.filterDomainBundle;

    let demandProfile;
    if (this.portalType === 'portal') {
      const resultIfNotChecked = (this.editMode !== 'CREATE' && this.currentPlacement.demandProfile !== 'PUBLISHER_PMP') ?
        this.currentPlacement.demandProfile : null;
      demandProfile = payloadPlacement.demandProfile ? 'PUBLISHER_PMP' : resultIfNotChecked;
      payloadPlacement.marginOpti = false;
      this.placementForm.get('marginOpti').setValue(false);
    } else {
      demandProfile = payloadPlacement.demandProfile === 'use-traffic-source' ? null : payloadPlacement.demandProfile;
    }
    payloadPlacement.demandProfile = demandProfile;

    payloadPlacement.contentAttrRequestExclude = this.sharedService.arrayToObjectJsonDbFormat(payloadPlacement.contentAttrRequestExclude);
    payloadPlacement.contentAttrReportExclude = this.sharedService.arrayToObjectJsonDbFormat(payloadPlacement.contentAttrReportExclude);

    payloadPlacement.cat = (payloadPlacement.cat && payloadPlacement.cat.length) ? payloadPlacement.cat : null;

    if (this.blockedListsData) {
      payloadPlacement.bcat = (this.blockedListsData.bcat && this.blockedListsData.bcat.length > 0) ?
        this.blockedListsData.bcat : null;
      payloadPlacement.btype = (this.blockedListsData.btype && this.blockedListsData.btype.length > 0) ?
        this.blockedListsData.btype : null;
      payloadPlacement.battr = (this.blockedListsData.battr && this.blockedListsData.battr.length > 0) ?
        this.blockedListsData.battr : null;
      payloadPlacement.dspAccountExcludeList =
        (this.blockedListsData.dspAccountExcludeList &&
          this.blockedListsData.dspAccountExcludeList.length > 0) ?
          this.blockedListsData.dspAccountExcludeList : null;
    }
    payloadPlacement.badv = this.badvData && this.badvData.length > 0 ? this.badvData.map(b => b.badvName) : null;

    if (this.portalType !== 'portal') {
      payloadPlacement = {...payloadPlacement, domainBundlePayload: this.domainBundlePayload ? this.domainBundlePayload : {}};
    }
    if (this.portalType === 'supply' && this.blockedListsData) {
      payloadPlacement.tldBadv = (this.blockedListsData.tldBadv && this.blockedListsData.tldBadv.length) ?
        this.blockedListsData.tldBadv : null;
    }
    if (this.portalType === 'portal') {
      payloadPlacement = {...payloadPlacement, publisherId: this.publisherId};
    }

    const floorsPayload =  this.floorsToSubmit?.floors.map(floorToSubmit => {
      const {
        supplyPlacementFloorId,
        country,
        deviceType,
        isGeoFloor,
        isHidden,
        ...filteredFloorToSubmit
      } = floorToSubmit;

      if (filteredFloorToSubmit.geoFloors) {
        filteredFloorToSubmit.geoFloors = filteredFloorToSubmit.geoFloors.map(geoFloor => {
          // tslint:disable-next-line:no-shadowed-variable
          const { placementId, impType, ...filteredGeoFloor } = geoFloor;
          return filteredGeoFloor;
        });
      }
      return filteredFloorToSubmit;
    });

    payloadPlacement = {...payloadPlacement, floors: this.floorsToSubmit ? floorsPayload : []};

    const commissionForPayload = this.commissionsToSubmit?.placementCommissions.map(commission => {
      const { overridePubTsCommission , ...filteredCommission } = commission;
      if (this.editMode === 'DUPLICATE') {
        const {id, ...duplicatedFilteredCommission} = filteredCommission;
        return  duplicatedFilteredCommission;
      } else {
        return filteredCommission;
      }
    });

    payloadPlacement = {...payloadPlacement, commissions: this.commissionsToSubmit ? commissionForPayload : []};

    const formData = new FormData();

    if (this.uploadedFile != null && this.uploadedFile.length > 0) {
      formData.append('file', this.uploadedFile[0] as any);
    } else {
      formData.append('file', new Blob());
    }

    formData.append('payload', JSON.stringify(payloadPlacement));

    return formData;
  }

  getDemandProfileDisplayName(trafficSourceDemandProfile: string = ''): string {
    const displayNameIndex = this.demandProfiles.findIndex(x => x.value === trafficSourceDemandProfile.toString().toUpperCase());
    return displayNameIndex > -1 ? this.demandProfiles[displayNameIndex].displayName : 'not found';
  }

  // for external portal - demand profile checkbox is true only for PUBLISHER_PMP
  private setDemandProfile = (): boolean | string =>  {
    let result;
    if (this.portalType === 'portal') {
      result = this.currentPlacement?.demandProfile === 'PUBLISHER_PMP' ? true : false;
    } else {
      result = this.currentPlacement?.demandProfile ? this.currentPlacement?.demandProfile : 'use-traffic-source';
    }
    return result;
  };

  onSubmitPlacement(actionType?: ModalActionType): void {
    this.formsHelper.markFormGroupDirtyAndUpdateValidity(this.placementForm);
    if (!this.placementForm.valid ||
      (this.floorsToSubmit && !this.floorsToSubmit.isFloorsFormValid) ||
      (this.commissionsToSubmit && !this.commissionsToSubmit.isCommissionFormValid) ||
      (this.domainFilterToSubmit && !this.domainFilterToSubmit.isDomainFilterValid)) {
      this.sharedService.showNotification('error', 'Invalid fields detected', 'Please validate marked fields before submit');
      return;
    }
    const placementCommission = this.commissionsToSubmit?.placementCommissions?.[0];
    if (this.portalType === 'supply') {
      if (placementCommission?.commission >= 20 && placementCommission.commissionType === 'FIXED') {
        if (actionType === 'APPROVE') {
          this.isModalVisible = false;
        } else {
          this.isModalVisible = actionType !== 'CANCEL';
          return;
        }
      }
    }
    this.isPlacementLoading = true;

    const formData = this.prepareCurrentPlacementPayload(this.placementForm.getRawValue(), this.currentPlacementId, this.editMode);

    this.submitPlacement(this.editMode, this.currentPlacementId, formData);
  }

  private setMandatoryFieldsByPubTs(tsName: string): void {
    this.externalSourceIdTooltip = '';
    if (tsName === 'amazon') {
      this.placementForm.controls.defaultDomainBundle.setValidators(Validators.required);
      this.isRequiredFieldByPubTs = true;
      this.externalSourceIdTooltip = 'Add the Site/App ID located in the Amazon Publisher Service UI';
    }
    if (tsName === 'google') {
      this.externalSourceIdTooltip = 'Add the publisher ID located in Google OB’s UI';
    }
    if (tsName === 'freewheel') {
      this.externalSourceIdTooltip = 'Add the Deal ID in the Freewheel platform';
    }
    if (tsName === 'springserve' || tsName === 'publica' || tsName === 'timehop' || tsName === 'spearad') {
      this.externalSourceIdTooltip = 'External source ID should be automatically set to placement ID';
      if (this.editMode === 'DUPLICATE') {
        this.placementForm.controls.externalSourceId.setValue(null);
      }
      this.placementForm.controls.externalSourceId.disable();
    }
  }

  onBlockedListsUpdate(blockedLists: any) {
    this.blockedListsData = {...blockedLists.blockedListsData};
  }

  onBadvUpdate(badv: { badvName: string }[]) {
    this.badvData = badv;
  }

  onDomainBundlePayloadUpdated(domainBundlePayLoad: DomainBundlePayload) {
    this.domainBundlePayload =  domainBundlePayLoad;
  }

  onDomainBundleFileUpdated(uploadedFile: NzUploadFile[]) {
    this.uploadedFile = uploadedFile;
  }

  onDomainBundleFileRemoved() {
    this.uploadedFile = null;
  }

  onFloorsUpdate(floors: any) {
    this.floorsToSubmit = {...floors};
  }

  onCommissionsUpdate(commissions: CommissionToSubmit) {
    this.commissionsToSubmit = _.cloneDeep(commissions);
  }

  onFilterDomainBundleUpdate(domainFilter: DomainFilter) {
    this.domainFilterToSubmit = domainFilter;
    if (this.currentPlacement) {
      this.currentPlacement.filterDomainBundle = domainFilter ? domainFilter.filterDomainBundle : 'DISABLED';
    }
  }

  submitPlacement(editMode: string, placementId: number, placement: FormData) {
    if (editMode === 'EDIT') {
      this.placementsService.updatePlacement(placement, placementId);
    } else {
      this.placementsService.createPlacement(placement);
    }
  }

  onChangeSelectIconState(isOpen, id) {
    this.selectIcons[id] = isOpen;
  }

  onBackToTableClick() {
    if (this.portalType === 'portal') {
      this.router.navigate([`/portal/integration-details/${this.pubTsId}`]);
    } else {
      this.router.navigate([`/supply/publishers/${this.publisherId}/update`],
        {queryParams: {tab: 'configurations', subTab: 'placements', pubTsId: this.pubTsId}});
    }
  }

  ngOnDestroy() {
    this.cdr.detach();
    this.store.dispatch(new PlacementsActions.ResetPlacementState());
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
