<ng-container *ngIf="blockedListsForm" [formGroup]="blockedListsForm" (keydown.enter)="$event.preventDefault()">
  <nz-row nzGutter="16">
    <nz-col nzMd="12" nzSm="24">
      <nz-form-item>
        <nz-form-label>Blocked Categories</nz-form-label>
        <nz-form-control>
          <nz-tree-select
            *ngIf="iabCategories"
            style="width: 100%"
            [nzNodes]="iabCategories"
            nzShowSearch
            nzCheckable
            nzPlaceHolder="Select IAB categories that will be blocked for the placement"
            formControlName="bcat"
            [nzDropdownStyle]="{ 'max-height': '300px' }"
          >
          </nz-tree-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>

    <nz-col nzMd="12" nzSm="24">
      <nz-form-item>
        <nz-form-label>Blocked Ad Types</nz-form-label>
        <nz-form-control>
          <nz-select nzMode="multiple" style="width:100%" nzShowSearch
                     formControlName="btype"
                     nzPlaceHolder="Select IAB ad types that will be blocked for this placement"
                     id="blocked-ad-types"
                     nzShowArrow="false"
                     [nzSuffixIcon]="selectIcons['blocked-ad-types'] ? arrowUp : arrowDown"
                     (nzOpenChange)="onChangeSelectIconState($event, 'blocked-ad-types')">
            <nz-option *ngFor="let option of iabBannerAdTypes" [nzLabel]="option.description"
                       [nzValue]="option.adTypeId"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>

    <nz-col nzMd="12" nzSm="24">
      <nz-form-item>
        <nz-form-label>Blocked Creative Attributes</nz-form-label>
        <nz-form-control>
          <nz-select nzMode="multiple" style="width:100%" nzShowSearch
                     nzPlaceHolder="Select IAB creative attributes that will be blocked for this placement"
                     formControlName="battr"
                     id="blocked-creative-attr"
                     nzShowArrow="false"
                     [nzSuffixIcon]="selectIcons['blocked-creative-attr'] ? arrowUp : arrowDown"
                     (nzOpenChange)="onChangeSelectIconState($event, 'blocked-creative-attr')">
            <nz-option *ngFor="let option of iabCreativeAttributes" [nzLabel]="option.description"
                       [nzValue]="option.attributeId"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>

    <ng-container *ngIf="portalType == 'supply'">
      <nz-col nzMd="12" nzSm="24">
        <nz-form-item>
          <nz-form-label>Blocked TLD</nz-form-label>
          <nz-form-control>
            <nz-select nzMode="tags" [nzTokenSeparators]="[',']" style="width: 100%;"
                       nzPlaceHolder="Use comma to separate items in list" formControlName="tldBadv"
                       id="tld-badv"
                       nzShowArrow="false"
                       [nzSuffixIcon]="selectIcons['blocked_tld'] ? arrowUp : arrowDown"
                       (nzOpenChange)="onChangeSelectIconState($event, 'blocked_tld')"></nz-select>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
    </ng-container>

    <nz-col nzMd="12" nzSm="24">
      <nz-form-item>
        <nz-form-label>Blocked DSPs</nz-form-label>
        <nz-form-control>
          <nz-select nzMode="multiple" [nzMaxTagCount]="5" style="width:100%" nzShowSearch nzAllowClear
                     nzPlaceHolder="Select DSP accounts that will be blocked for the placement"
                     formControlName="dspAccountExcludeList" id="blocked-dspAccountExcludeList">
            <nz-option *ngFor="let option of dspAccounts" [nzLabel]="option.externalName || option.nameDisplay"
              [nzValue]="option.id"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
</ng-container>

<ng-template #arrowUp>
  <fa-icon [icon]="'angle-down'" class="select-icon select-icon-open"></fa-icon>
</ng-template>
<ng-template #arrowDown>
  <fa-icon [icon]="'angle-down'" class="select-icon select-icon-close"></fa-icon>
</ng-template>
