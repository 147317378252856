import { Action } from '@ngrx/store';
import { AppFilter, AppTable, InventoryPackage } from '../../../shared/_models/models';

export const UPDATE_INVENTORY_PACKAGES_FILTERS = '[Inventory packages] Update filters';
export const UPDATE_INVENTORY_PACKAGES_TABLE = '[Inventory packages] Update table';

export const FETCH_INVENTORY_PACKAGES_REQUEST = '[Inventory packages] Get Inventory packages request';
export const FETCH_INVENTORY_PACKAGES_SUCCESS = '[Inventory packages] Get Inventory packages success';
export const FETCH_INVENTORY_PACKAGES_FAILURE = '[Inventory packages] Get Inventory packages failure';

export const FETCH_INVENTORY_PACKAGE_BY_ID_REQUEST = '[Inventory packages] Get Inventory package by id request';
export const FETCH_INVENTORY_PACKAGE_BY_ID_SUCCESS = '[Inventory packages] Get Inventory package by id success';
export const FETCH_INVENTORY_PACKAGE_BY_ID_FAILURE = '[Inventory packages] Get Inventory package by id failure';
export const REMOVE_INVENTORY_PACKAGE_BY_ID_FROM_STATE = '[Inventory packages] REMOVE Inventory package by id from state';

export const CREATE_INVENTORY_PACKAGE_REQUEST = '[Inventory packages] Create Inventory package request';
export const CREATE_INVENTORY_PACKAGE_SUCCESS = '[Inventory packages] Create Inventory package success';
export const CREATE_INVENTORY_PACKAGE_FAILURE = '[Inventory packages] Create Inventory package failure';

export const EDIT_INVENTORY_PACKAGE_REQUEST = '[Inventory packages] Update Inventory package request';
export const EDIT_INVENTORY_PACKAGE_SUCCESS = '[Inventory packages] Update Inventory package success';
export const EDIT_INVENTORY_PACKAGE_FAILURE = '[Inventory packages] Update Inventory package failure';

export const DELETE_INVENTORY_PACKAGE_REQUEST = '[Inventory packages] Delete Inventory package request';
export const DELETE_INVENTORY_PACKAGE_SUCCESS = '[Inventory packages] Delete Inventory package success';
export const DELETE_INVENTORY_PACKAGE_FAILURE = '[Inventory packages] Delete Inventory package failure';

export const FETCH_COUNTRIES_REQUEST = '[Inventory packages] Get countries request';
export const FETCH_COUNTRIES_SUCCESS = '[Inventory packages] Get countries success';
export const FETCH_COUNTRIES_FAILURE = '[Inventory packages] Get countries failure';

export const FETCH_REGIONS_REQUEST = '[Inventory packages] Get regions request';
export const FETCH_REGIONS_SUCCESS = '[Inventory packages] Get regions success';
export const FETCH_REGIONS_FAILURE = '[Inventory packages] Get regions failure';

export const FETCH_METROS_REQUEST = '[Inventory packages] Get metros request';
export const FETCH_METROS_SUCCESS = '[Inventory packages] Get metros success';
export const FETCH_METROS_FAILURE = '[Inventory packages] Get metros failure';

export const FETCH_PLACEMENTS_REQUEST = '[Inventory packages] Get placements request';
export const FETCH_PLACEMENTS_SUCCESS = '[Inventory packages] Get placements success';
export const FETCH_PLACEMENTS_FAILURE = '[Inventory packages] Get placements failure';

export const FETCH_BUNDLES_REQUEST = '[Inventory packages] Get bundles request';
export const FETCH_BUNDLES_SUCCESS = '[Inventory packages] Get bundles success';
export const FETCH_BUNDLES_FAILURE = '[Inventory packages] Get bundles failure';

export const FETCH_PUBLISHERS_REQUEST = '[Inventory packages] Get publishers request';
export const FETCH_PUBLISHERS_SUCCESS = '[Inventory packages] Get publishers success';
export const FETCH_PUBLISHERS_FAILURE = '[Inventory packages] Get publishers failure';

export const FETCH_DEVICE_TYPES_REQUEST = '[Inventory packages] Get device types request';
export const FETCH_DEVICE_TYPES_SUCCESS = '[Inventory packages] Get device types success';
export const FETCH_DEVICE_TYPES_FAILURE = '[Inventory packages] Get device types failure';

export const FETCH_CONTENT_ATTRIBUTES_REQUEST = '[Inventory packages] Get content attributes request';
export const FETCH_CONTENT_ATTRIBUTES_SUCCESS = '[Inventory packages] Get content attributes success';
export const FETCH_CONTENT_ATTRIBUTES_FAILURE = '[Inventory packages] Get content attributes failure';

export class UpdateInventoryPackagesFiltersAction implements Action {
  readonly type = UPDATE_INVENTORY_PACKAGES_FILTERS;
  constructor(public payload: { inventoryPackagesFilters: AppFilter[] }) {
  }
}

export class UpdateInventoryPackagesTableAction implements Action {
  readonly type = UPDATE_INVENTORY_PACKAGES_TABLE;
  constructor(public payload: { inventoryPackagesTable: AppTable }) {
  }
}

export class FetchInventoryPackagesRequestAction implements Action {
  readonly type = FETCH_INVENTORY_PACKAGES_REQUEST;
}

export class FetchInventoryPackagesSuccessAction implements Action {
  readonly type = FETCH_INVENTORY_PACKAGES_SUCCESS;

  constructor(public payload: { inventoryPackages: InventoryPackage[], totalPackages: number }) {
  }
}

export class FetchInventoryPackagesFailureAction implements Action {
  readonly type = FETCH_INVENTORY_PACKAGES_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class FetchInventoryPackageByIdRequestAction implements Action {
  readonly type = FETCH_INVENTORY_PACKAGE_BY_ID_REQUEST;
}

export class FetchInventoryPackageByIdSuccessAction implements Action {
  readonly type = FETCH_INVENTORY_PACKAGE_BY_ID_SUCCESS;

  constructor(public payload: { currentInventoryPackage: InventoryPackage }) {
  }
}

export class FetchInventoryPackageByIdFailureAction implements Action {
  readonly type = FETCH_INVENTORY_PACKAGE_BY_ID_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class RemoveInventoryPackageByIdFromState implements Action {
  readonly type = REMOVE_INVENTORY_PACKAGE_BY_ID_FROM_STATE;
}

export class CreateInventoryPackageRequestAction implements Action {
  readonly type = CREATE_INVENTORY_PACKAGE_REQUEST;
}

export class CreateInventoryPackageSuccessAction implements Action {
  readonly type = CREATE_INVENTORY_PACKAGE_SUCCESS;

  constructor(public payload: { currentInventoryPackage: InventoryPackage }) {
  }
}

export class CreateInventoryPackageFailureAction implements Action {
  readonly type = CREATE_INVENTORY_PACKAGE_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class EditInventoryPackageRequestAction implements Action {
  readonly type = EDIT_INVENTORY_PACKAGE_REQUEST;
}

export class EditInventoryPackageSuccessAction implements Action {
  readonly type = EDIT_INVENTORY_PACKAGE_SUCCESS;

  constructor(public payload: { currentInventoryPackage: InventoryPackage }) {
  }
}

export class EditInventoryPackageFailureAction implements Action {
  readonly type = EDIT_INVENTORY_PACKAGE_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class DeleteInventoryPackageRequestAction implements Action {
  readonly type = DELETE_INVENTORY_PACKAGE_REQUEST;
}

export class DeleteInventoryPackageSuccessAction implements Action {
  readonly type = DELETE_INVENTORY_PACKAGE_SUCCESS;

  constructor(public payload: { currentInventoryPackage: InventoryPackage }) {
  }
}

export class DeleteInventoryPackageFailureAction implements Action {
  readonly type = DELETE_INVENTORY_PACKAGE_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class FetchCountriesRequestAction implements Action {
  readonly type = FETCH_COUNTRIES_REQUEST;
}
export class FetchCountriesSuccessAction implements Action {
  readonly type = FETCH_COUNTRIES_SUCCESS;
  constructor(public payload: { countries: any }) {
  }
}
export class FetchCountriesFailureAction implements Action {
  readonly type = FETCH_COUNTRIES_FAILURE;
  constructor(public payload: { error: string }) {
  }
}


export class FetchRegionsRequestAction implements Action {
  readonly type = FETCH_REGIONS_REQUEST;
}
export class FetchRegionsSuccessAction implements Action {
  readonly type = FETCH_REGIONS_SUCCESS;
  constructor(public payload: { regions: any }) {
  }
}
export class FetchRegionsFailureAction implements Action {
  readonly type = FETCH_REGIONS_FAILURE;
  constructor(public payload: { error: string }) {
  }
}

export class FetchMetrosRequestAction implements Action {
  readonly type = FETCH_METROS_REQUEST;
}
export class FetchMetrosSuccessAction implements Action {
  readonly type = FETCH_METROS_SUCCESS;

  constructor(public payload: { metros: any }) {
  }
}
export class FetchMetrosFailureAction implements Action {
  readonly type = FETCH_METROS_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class FetchPlacementsRequestAction implements Action {
  readonly type = FETCH_PLACEMENTS_REQUEST;
}
export class FetchPlacementsSuccessAction implements Action {
  readonly type = FETCH_PLACEMENTS_SUCCESS;

  constructor(public payload: { placements: any }) {
  }
}
export class FetchPlacementsFailureAction implements Action {
  readonly type = FETCH_PLACEMENTS_FAILURE;

  constructor(public payload: { error: string }) {
  }
}


export class FetchBundlesRequestAction implements Action {
  readonly type = FETCH_BUNDLES_REQUEST;
}
export class FetchBundlesSuccessAction implements Action {
  readonly type = FETCH_BUNDLES_SUCCESS;

  constructor(public payload: { bundles: any }) {
  }
}
export class FetchBundlesFailureAction implements Action {
  readonly type = FETCH_BUNDLES_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class FetchPublishersRequestAction implements Action {
  readonly type = FETCH_PUBLISHERS_REQUEST;
}
export class FetchPublishersSuccessAction implements Action {
  readonly type = FETCH_PUBLISHERS_SUCCESS;

  constructor(public payload: { publishers: any }) {
  }
}
export class FetchPublishersFailureAction implements Action {
  readonly type = FETCH_PUBLISHERS_FAILURE;

  constructor(public payload: { error: string }) {
  }
}


export class FetchDeviceTypesRequestAction implements Action {
  readonly type = FETCH_DEVICE_TYPES_REQUEST;
}
export class FetchDeviceTypesSuccessAction implements Action {
  readonly type = FETCH_DEVICE_TYPES_SUCCESS;
  constructor(public payload: { deviceTypes: any }) {
  }
}
export class FetchDeviceTypesFailureAction implements Action {
  readonly type = FETCH_DEVICE_TYPES_FAILURE;
  constructor(public payload: { error: string }) {
  }
}


export class FetchContentAttributesRequestAction implements Action {
  readonly type = FETCH_CONTENT_ATTRIBUTES_REQUEST;
}
export class FetchContentAttributesSuccessAction implements Action {
  readonly type = FETCH_CONTENT_ATTRIBUTES_SUCCESS;
  constructor(public payload: { contentAttributes: any }) {
  }
}
export class FetchContentAttributesFailureAction implements Action {
  readonly type = FETCH_CONTENT_ATTRIBUTES_FAILURE;
  constructor(public payload: { error: string }) {
  }
}

export type InventoryPackagesActions =
  UpdateInventoryPackagesFiltersAction |
  UpdateInventoryPackagesTableAction |
  FetchInventoryPackagesRequestAction |
  FetchInventoryPackagesSuccessAction |
  FetchInventoryPackagesFailureAction |
  FetchInventoryPackageByIdRequestAction |
  FetchInventoryPackageByIdSuccessAction |
  FetchInventoryPackageByIdFailureAction |
  RemoveInventoryPackageByIdFromState |
  CreateInventoryPackageRequestAction |
  CreateInventoryPackageSuccessAction |
  CreateInventoryPackageFailureAction |
  EditInventoryPackageRequestAction |
  EditInventoryPackageSuccessAction |
  EditInventoryPackageFailureAction |
  DeleteInventoryPackageRequestAction |
  DeleteInventoryPackageSuccessAction |
  DeleteInventoryPackageFailureAction |
  FetchCountriesRequestAction |
  FetchCountriesSuccessAction |
  FetchCountriesFailureAction |
  FetchRegionsRequestAction |
  FetchRegionsSuccessAction |
  FetchRegionsFailureAction |
  FetchMetrosRequestAction |
  FetchMetrosSuccessAction |
  FetchMetrosFailureAction |
  FetchPlacementsRequestAction |
  FetchPlacementsSuccessAction |
  FetchPlacementsFailureAction |
  FetchBundlesRequestAction |
  FetchBundlesSuccessAction |
  FetchBundlesFailureAction |
  FetchPublishersRequestAction |
  FetchPublishersSuccessAction |
  FetchPublishersFailureAction |
  FetchDeviceTypesRequestAction |
  FetchDeviceTypesSuccessAction |
  FetchDeviceTypesFailureAction |
  FetchContentAttributesRequestAction |
  FetchContentAttributesSuccessAction |
  FetchContentAttributesFailureAction;
