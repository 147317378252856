import { Component, Input } from '@angular/core';
import { KpiWidget } from '../../_models/models';

@Component({
  selector: 'app-shared-kpi-widget',
  templateUrl: './kpi-widget.component.html',
  styleUrls: ['./kpi-widget.component.less'],
})
export class SharedKpiWidgetComponent {

  private _config: KpiWidget = null;
  @Input()
  set config(config: KpiWidget) {
    this._config = config;
  }
  get config() {
    return this._config;
  }

  @Input() isActive: boolean = false;

  constructor() {}


  getFormattedValue(value) {
    if (this.config.isLoading) {
      return '';
    }
    if (value === null || value === undefined) {
      return 'No Data';
    }
    if (Number.isNaN(value)) {
      value = 0;
    }
    const wo = this.config;
    let formatted;
    let abbrev = '';
    if (value >= 1000000000) {
      abbrev = 'B';
      formatted = value / 1000000000;
    } else if (value >= 1000000) {
      abbrev = 'M';
      formatted = value / 1000000;
    } else if (value >= 1000) {
      abbrev = 'K';
      formatted = value / 1000;
    } else {
      formatted = value;
    }
    return `${wo.unit?.left || ''}${(this.roundNumToDecimals(+formatted, wo.decimals)).toLocaleString()}${abbrev}${wo.unit?.right || ''}`;
  }

  getPercentageChange() {
    if (this.config?.isLoading) { return 0; }
    if (!this.config?.value?.current || !this.config?.value?.previous) { return 0; }
    const curr = +(this.config?.value?.current)?.toFixed(this.config?.decimals);
    const prev = +(this.config?.value?.previous)?.toFixed(this.config?.decimals);
    if (prev === 0) { return 0; }
    return +((curr - prev) / prev * 100).toFixed(this.config?.decimals);
  }

  getAbsPercentageChange() {
    // FUS-1006
    return this.roundNumToDecimals(this.getPercentageChange(), this.config.decimals);
  }

  isChangeNegative() {
    return this.getPercentageChange() < 0;
  }

  isChangePositive() {
    return this.getPercentageChange() > 0;
  }


  isNoChange() {
    return this.getPercentageChange() === 0;
  }

   roundNumToDecimals(value: number, decimals: number) {
    return Number(Math.round(+(value + 'e' + decimals)) + 'e-' + decimals).toFixed(decimals);
  }

}
