import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, NgZone, Output } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

type ElPosition = 'middle' | 'start' | 'end';

@Directive({
  selector: '[appOnScrollToElement]'
})
export class OnScrollToElementDirective implements AfterViewInit {

  @Output() appOnScrollToElement = new EventEmitter<void>();
  @Input() startInElPosition: ElPosition = 'middle';
  private unsubscribe$ = new Subject<void>();

  constructor(private el: ElementRef, private zone: NgZone) {
  }

  destroyEvent(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(): void {
    // this scroll event is runOutsideAngular to prevent the scroll event to execute the change detection in every minor scroll
    this.zone.runOutsideAngular(() => {
      fromEvent(window, 'scroll').pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => {
          const rect: DOMRect = this.el.nativeElement.getBoundingClientRect();
          const rectYTop = rect.top + window.scrollY;
          let condition: boolean;
          switch (this.startInElPosition) {
            case 'start':
              condition = (window.scrollY + window.innerHeight) > rectYTop;
              break;
            case 'middle':
              condition = (window.scrollY + window.innerHeight) > (rectYTop + (rect.height / 2));
              break;
            case 'end':
              const rectYBottom = rect.bottom + window.scrollY;
              condition = (window.scrollY + window.innerHeight) > rectYBottom;
              break;
          }
          if (condition) {
            this.appOnScrollToElement.emit();
            this.destroyEvent();
          }
        });
    });
  }

}
