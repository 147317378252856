import _, { cloneDeep } from 'lodash';
import {
  InventoryPackagesActions,
  CREATE_INVENTORY_PACKAGE_FAILURE,
  CREATE_INVENTORY_PACKAGE_REQUEST,
  CREATE_INVENTORY_PACKAGE_SUCCESS,
  DELETE_INVENTORY_PACKAGE_FAILURE,
  DELETE_INVENTORY_PACKAGE_REQUEST,
  DELETE_INVENTORY_PACKAGE_SUCCESS,
  FETCH_INVENTORY_PACKAGE_BY_ID_FAILURE,
  FETCH_INVENTORY_PACKAGE_BY_ID_REQUEST,
  FETCH_INVENTORY_PACKAGE_BY_ID_SUCCESS,
  FETCH_INVENTORY_PACKAGES_FAILURE,
  FETCH_INVENTORY_PACKAGES_REQUEST,
  FETCH_INVENTORY_PACKAGES_SUCCESS,
  REMOVE_INVENTORY_PACKAGE_BY_ID_FROM_STATE,
  EDIT_INVENTORY_PACKAGE_FAILURE,
  EDIT_INVENTORY_PACKAGE_REQUEST,
  EDIT_INVENTORY_PACKAGE_SUCCESS,
  FETCH_COUNTRIES_REQUEST,
  FETCH_COUNTRIES_SUCCESS,
  FETCH_COUNTRIES_FAILURE,
  FETCH_REGIONS_REQUEST,
  FETCH_REGIONS_SUCCESS,
  FETCH_REGIONS_FAILURE,
  FETCH_METROS_REQUEST,
  FETCH_METROS_SUCCESS,
  FETCH_METROS_FAILURE,
  FETCH_PLACEMENTS_REQUEST,
  FETCH_PLACEMENTS_SUCCESS,
  FETCH_PLACEMENTS_FAILURE,
  FETCH_BUNDLES_REQUEST,
  FETCH_BUNDLES_SUCCESS,
  FETCH_BUNDLES_FAILURE,
  FETCH_PUBLISHERS_FAILURE,
  FETCH_PUBLISHERS_SUCCESS,
  FETCH_PUBLISHERS_REQUEST,
  FETCH_DEVICE_TYPES_REQUEST,
  FETCH_DEVICE_TYPES_SUCCESS,
  FETCH_DEVICE_TYPES_FAILURE,
  FETCH_CONTENT_ATTRIBUTES_REQUEST,
  FETCH_CONTENT_ATTRIBUTES_SUCCESS,
  FETCH_CONTENT_ATTRIBUTES_FAILURE,
  UPDATE_INVENTORY_PACKAGES_TABLE,
  UPDATE_INVENTORY_PACKAGES_FILTERS,
} from './inventory-packages.actions';

import { AppFilter, AppTable, InventoryPackage } from '../../../shared/_models/models';
import { inventoryPackagesFiltersConfig, inventoryPackagesTableConfig } from '../_services/inventory-packages.config';


export interface InventoryPackagesState {
  inventoryPackagesTable: AppTable;
  inventoryPackagesFilters: AppFilter[];
  inventoryPackages: InventoryPackage[];
  totalPackages: number;
  isInventoryPackagesLoading: boolean;
  currentInventoryPackage: InventoryPackage;
  isCurrentInventoryPackageLoading: boolean;
  countries: any;
  isCountriesLoading: boolean;
  regions: any;
  isRegionsLoading: boolean;
  metros: any;
  isMetrosLoading: boolean;
  placements: any;
  isPlacementsLoading: boolean;
  bundles: any;
  isBundlesLoading: boolean;
  publishers: any;
  isPublishersLoading: boolean;
  deviceTypes: any;
  isDeviceTypesLoading: boolean;
  contentAttributes: any;
  isContentAttributesLoading: boolean;
  error?: any;
}

const initialState: InventoryPackagesState = {
  inventoryPackagesTable: cloneDeep(inventoryPackagesTableConfig),
  inventoryPackagesFilters: cloneDeep(inventoryPackagesFiltersConfig),
  inventoryPackages: null,
  totalPackages: null,
  isInventoryPackagesLoading: false,
  currentInventoryPackage: null,
  isCurrentInventoryPackageLoading: false,
  countries: null,
  isCountriesLoading: false,
  regions: null,
  isRegionsLoading: false,
  metros: null,
  isMetrosLoading: false,
  placements: null,
  isPlacementsLoading: false,
  bundles: null,
  isBundlesLoading: false,
  publishers: null,
  isPublishersLoading: false,
  deviceTypes: null,
  isDeviceTypesLoading: false,
  contentAttributes: null,
  isContentAttributesLoading: false,
  error: null
};

export function inventoryPackagesReducer(state = initialState, action: InventoryPackagesActions) {
  switch (action.type) {
    case UPDATE_INVENTORY_PACKAGES_TABLE:
      return {...state, inventoryPackagesTable: action.payload.inventoryPackagesTable};
    case UPDATE_INVENTORY_PACKAGES_FILTERS:
        return {...state, inventoryPackagesFilters: action.payload.inventoryPackagesFilters};
    case FETCH_INVENTORY_PACKAGES_REQUEST:
      return {...state, isInventoryPackagesLoading: true};
    case FETCH_INVENTORY_PACKAGES_SUCCESS:
      return {...state, inventoryPackages: action.payload.inventoryPackages,
        totalPackages: action.payload.totalPackages, isInventoryPackagesLoading: false};
    case FETCH_INVENTORY_PACKAGES_FAILURE:
      return {...state, isInventoryPackagesLoading: false, error: action.payload.error};

    case FETCH_INVENTORY_PACKAGE_BY_ID_REQUEST:
      return {...state, isCurrentInventoryPackageLoading: true};
    case FETCH_INVENTORY_PACKAGE_BY_ID_SUCCESS:
      return {...state, currentInventoryPackage: action.payload.currentInventoryPackage, isCurrentInventoryPackageLoading: false};
    case FETCH_INVENTORY_PACKAGE_BY_ID_FAILURE:
      return {...state, isCurrentInventoryPackageLoading: false, error: action.payload.error};
    case REMOVE_INVENTORY_PACKAGE_BY_ID_FROM_STATE:
      return {...state, currentInventoryPackage: null};
    case CREATE_INVENTORY_PACKAGE_REQUEST:
      return {...state, isCurrentInventoryPackageLoading: true};
    case CREATE_INVENTORY_PACKAGE_SUCCESS:
      if (!state.inventoryPackages) {
        state = {...state,  inventoryPackages: [action.payload.currentInventoryPackage]};
      } else {
        state = {...state, inventoryPackages: [...state.inventoryPackages, action.payload.currentInventoryPackage]};
      }
      return {
        ...state,
        inventoryPackages: [...state.inventoryPackages.slice()],
        currentInventoryPackage: action.payload.currentInventoryPackage,
        isCurrentInventoryPackageLoading: false
      };
    case CREATE_INVENTORY_PACKAGE_FAILURE:
      return {...state, isCurrentInventoryPackageLoading: false, error: action.payload.error};
    case EDIT_INVENTORY_PACKAGE_REQUEST:
      return {...state, isCurrentInventoryPackageLoading: true};
    case EDIT_INVENTORY_PACKAGE_SUCCESS:
      if (!state.inventoryPackages) {
        state = {...state,  inventoryPackages: [action.payload.currentInventoryPackage]};
        return {
          ...state,
          currentInventoryPackage: action.payload.currentInventoryPackage,
          inventoryPackages: [...state.inventoryPackages.slice()],
          isCurrentInventoryPackageLoading: false
        };
      } else {
        const inventoryPackagesIndexToUpdate = _.findIndex(state.inventoryPackages, {packageId: action.payload.currentInventoryPackage.packageId});
        return {
          ...state,
          currentInventoryPackage: action.payload.currentInventoryPackage,
          inventoryPackages: [...state.inventoryPackages.slice(0, inventoryPackagesIndexToUpdate),
            {
              ...action.payload.currentInventoryPackage
            },
            ...state.inventoryPackages.slice(inventoryPackagesIndexToUpdate + 1)
          ],
          isCurrentInventoryPackageLoading: false
        };
      }
    case EDIT_INVENTORY_PACKAGE_FAILURE:
      return {...state, isCurrentInventoryPackageLoading: false, error: action.payload.error};

    case DELETE_INVENTORY_PACKAGE_REQUEST:
      return {...state, isCurrentInventoryPackageLoading: true};
    case DELETE_INVENTORY_PACKAGE_SUCCESS:
      const inventoryPackagesToDeleteIndex = _.findIndex(state.inventoryPackages, {packageId: action.payload.currentInventoryPackage.packageId});
      return {
        ...state,
        inventoryPackages: [...state.inventoryPackages.slice(0, inventoryPackagesToDeleteIndex), ...state.inventoryPackages.slice(inventoryPackagesToDeleteIndex + 1)],
        isCurrentInventoryPackageLoading: false
      };
    case DELETE_INVENTORY_PACKAGE_FAILURE:
      return {...state, isCurrentInventoryPackageLoading: false, error: action.payload.error};

    case FETCH_COUNTRIES_REQUEST:
      return {...state, isCountriesLoading: true};
    case FETCH_COUNTRIES_SUCCESS:
      return {...state, countries: action.payload.countries, isCountriesLoading: false};
    case FETCH_COUNTRIES_FAILURE:
      return {...state, isCountriesLoading: false, error: action.payload.error};

    case FETCH_REGIONS_REQUEST:
      return {...state, isRegionsLoading: true};
    case FETCH_REGIONS_SUCCESS:
      return {...state, regions: action.payload.regions, isRegionsLoading: false};
    case FETCH_REGIONS_FAILURE:
      return {...state, isRegionsLoading: false, error: action.payload.error};

    case FETCH_METROS_REQUEST:
      return {...state, isMetrosLoading: true};
    case FETCH_METROS_SUCCESS:
      return {...state, metros: action.payload.metros, isMetrosLoading: false};
    case FETCH_METROS_FAILURE:
      return {...state, isMetrosLoading: false, error: action.payload.error};

    case FETCH_PLACEMENTS_REQUEST:
      return {...state, isPlacementsLoading: true};
    case FETCH_PLACEMENTS_SUCCESS:
      return {...state, placements: action.payload.placements, isPlacementsLoading: false};
    case FETCH_PLACEMENTS_FAILURE:
      return {...state, isPlacementsLoading: false, error: action.payload.error};

    case FETCH_BUNDLES_REQUEST:
      return {...state, isBundlesLoading: true};
    case FETCH_BUNDLES_SUCCESS:
      return {...state, bundles: action.payload.bundles, isBundlesLoading: false};
    case FETCH_BUNDLES_FAILURE:
      return {...state, isPlacementsLoading: false, error: action.payload.error};

    case FETCH_PUBLISHERS_REQUEST:
      return {...state, isPublishersLoading: true};
    case FETCH_PUBLISHERS_SUCCESS:
      return {...state, publishers: action.payload.publishers, isPublishersLoading: false};
    case FETCH_PUBLISHERS_FAILURE:
      return {...state, isPublishersLoading: false, error: action.payload.error};

    case FETCH_DEVICE_TYPES_REQUEST:
      return {...state, isDeviceTypesLoading: true};
    case FETCH_DEVICE_TYPES_SUCCESS:
      return {...state, deviceTypes: action.payload.deviceTypes, isDeviceTypesLoading: false};
    case FETCH_DEVICE_TYPES_FAILURE:
      return {...state, isDeviceTypesLoading: false, error: action.payload.error};

    case FETCH_CONTENT_ATTRIBUTES_REQUEST:
      return {...state, isContentAttributesLoading: true};
    case FETCH_CONTENT_ATTRIBUTES_SUCCESS:
      return {...state, contentAttributes: action.payload.contentAttributes, isContentAttributesLoading: false};
    case FETCH_CONTENT_ATTRIBUTES_FAILURE:
      return {...state, isContentAttributesLoading: false, error: action.payload.error};

    default:
      return state;
  }
}

export const getInventoryPackagesLoading = (state: InventoryPackagesState) => state.isInventoryPackagesLoading;
export const getInventoryPackagesTable = (state: InventoryPackagesState) => state.inventoryPackagesTable;
export const getInventoryPackagesFilters = (state: InventoryPackagesState) => state.inventoryPackagesFilters;
export const getInventoryPackages = (state: InventoryPackagesState) => state.inventoryPackages;
export const getInventoryPackagesTotal = (state: InventoryPackagesState) => state.totalPackages;
export const getInventoryPackageByIdLoading = (state: InventoryPackagesState) => state.isCurrentInventoryPackageLoading;
export const getInventoryPackageById = (state: InventoryPackagesState) => state.currentInventoryPackage;
export const getCountries = (state: InventoryPackagesState) => state.countries;
export const getCountriesLoading = (state: InventoryPackagesState) => state.isCountriesLoading;
export const getRegions = (state: InventoryPackagesState) => state.regions;
export const getMetros = (state: InventoryPackagesState) => state.metros;
export const getPlacements = (state: InventoryPackagesState) => state.placements;
export const getPlacementsLoading = (state: InventoryPackagesState) => state.isPlacementsLoading;
export const getPublishers = (state: InventoryPackagesState) => state.publishers;
export const getPublishersLoading = (state: InventoryPackagesState) => state.isPublishersLoading;
export const getDeviceTypes = (state: InventoryPackagesState) => state.deviceTypes;
export const getContentAttributes = (state: InventoryPackagesState) => state.contentAttributes;
