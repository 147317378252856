import {
  FETCH_GLOBAL_SEARCH_FAILURE,
  FETCH_GLOBAL_SEARCH_REQUEST,
  FETCH_GLOBAL_SEARCH_SUCCESS,
  REMOVE_GLOBAL_SEARCH_RESULTS_FROM_STORE,
  SharedActions,
} from './shared.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface SharedState {
  globalSearchOptions: any[];
  isLoadingGlobalSearch: boolean;
}

const initialState: SharedState = {
  globalSearchOptions: [],
  isLoadingGlobalSearch: false,
};

export function sharedReducer(state = initialState, action: SharedActions) {
  switch (action.type) {
    case FETCH_GLOBAL_SEARCH_REQUEST:
      return {...state, isLoadingGlobalSearch: true};
    case FETCH_GLOBAL_SEARCH_SUCCESS:
      return {
        ...state,
        globalSearchOptions: [...state.globalSearchOptions, ...action.payload.globalSearchOptions],
        isLoadingGlobalSearch: false
      };
    case FETCH_GLOBAL_SEARCH_FAILURE:
      return {...state, globalSearchOptions: [], isLoadingGlobalSearch: false, error: action.payload.error};
    case REMOVE_GLOBAL_SEARCH_RESULTS_FROM_STORE:
      return {...state, globalSearchOptions: []};

    default:
      return state;
  }
}

export const getSharedState = createFeatureSelector<SharedState>('shared');
export const getSharedGlobalSearchOptions = createSelector(getSharedState, (state: SharedState) => state.globalSearchOptions);
export const getSharedIsLoadingGlobalSearch = createSelector(getSharedState, (state: SharedState) => state.isLoadingGlobalSearch);
