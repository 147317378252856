import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import * as _ from 'lodash';

import { SharedService } from '../../_services/shared.service';
import { CountryService, SupplyPublisherService } from '../../../_services';

import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromShared from '../../_store/shared.reducer';

import {CommonDataModel, ContractFile, Country, ModeType, State, UserTypeLower} from '../../_models/models';
import { FormHelper } from '../../../_common';
import { SharedStoreService } from '../../_services/shared-store.service';

interface TaxFiles {
  taxFileId: number;
  taxFileName: string;
  taxFileCreatedAt: string;
  taxFileLoading: boolean;
}
@Component({
  selector: 'app-shared-tax-info',
  templateUrl: './tax-info.component.html',
  styleUrls: ['./tax-info.component.less']
})
export class SharedTaxInfoComponent implements OnChanges, OnInit, OnDestroy {

  @Input() userType: UserTypeLower = 'external';
  @Input() mode: ModeType = 'read';
  @Input() taxForm: UntypedFormGroup;
  @Input() contractHasBeenUploaded = false;
  @Input() taxHasBeenUploaded = false;
  @Input() isTaxFileRequired = false;

  countriesOptions: Country[] = [];
  countries$: Observable<CommonDataModel>;

  paymentTermsOptions$: Observable<{[key: string]: string}>;
  paymentTermsOptions: any[] = [];

  statesList: State[] = [];
  loadingStates = false;
  loadingTerms = false;
  firstCountryChange = true;
  country: string = null;
  taxFileName: string;
  taxFileLoading = false;
  uploadedTaxFiles: TaxFiles[] = [];
  isTaxFileListTouched = false;

  fileList: NzUploadFile[] = [];
  contractFile: NzUploadFile[] = [];
  uploadedContractFiles: ContractFile[] = [];

  selectIcons: any = {};

  @Output() taxFileChanged: EventEmitter<any> = new EventEmitter();
  @Output() contractFileChanged: EventEmitter<any> = new EventEmitter();

  private unsubscribe$ = new Subject<void>();

  isRequired = FormHelper.isRequired;

  beforeUpload = (file: NzUploadFile): boolean => {
    if (this.taxHasBeenUploaded) {
      this.fileList = [];
      this.taxHasBeenUploaded = false;
    }
    if (file.type !== 'application/pdf') {
      this.sharedService.showNotification('error', `Invalid file type`, 'Only PDF file can be uploaded');
    } else {
      this.fileList = this.fileList.concat(file);
      this.taxFileChanged.emit(this.fileList);
    }
    return false;
  }

  constructor(
    private countryService: CountryService,
    private sharedService: SharedService,
    private publisherService: SupplyPublisherService,
    private sharedStoreService: SharedStoreService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.taxHasBeenUploaded && changes.taxHasBeenUploaded.currentValue === true) {
      this.fileList = [];
      this.taxHasBeenUploaded = false;
    }
    this.onChanges();
  }

  ngOnInit() {
    this.initSubscriptions();
    this.calcTaxFileUrl();
    this.calcContractFileUrl();
  }

  initSubscriptions() {
      this.paymentTermsOptions$ = this.sharedStoreService.getSharedEnum('PaymentTerms');
      this.paymentTermsOptions$.pipe(
        map(res => res || []),
        takeUntil(this.unsubscribe$),
      ).subscribe(res => {
          this.paymentTermsOptions = _.sortBy(
            Object.keys(res).map(key => _.assign({
              value: key,
              text: res[key]
            })),
            ['text']
          );
        }
      );
      this.countries$ = this.sharedStoreService.getSharedAsset('countries');
      this.countries$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
        if (res.data?.length) {
          this.countriesOptions = res.data;
          this.populateState(this.taxForm.get('country').value);
        }
      });
  }

  get f() {
    return this.taxForm.controls;
  }

  hasSavedTaxFile() {
    return !!_.filter(this.f.financial_documents.value, ['docType', 'PAYMENT_TAX']).length;
  }

  isTaxFileError() {
    // any of the original mandatory field could work here..
    const anyMandatoryValue = this.taxForm.value.legalCompanyName;
    if (this.isTaxFileRequired) {
      if (!this.hasSavedTaxFile() && this.fileList.length === 0) {
        if (!this.taxForm.touched && anyMandatoryValue) {
          return true;
        }
        if (this.isTaxFileListTouched) {
          return true;
        }
        if (this.taxForm.dirty || this.taxForm.touched) {
          return true;
        }
      }
    }
    return false;
  }

  calcTaxFileUrl() {
    const financialDocs = this.f.financial_documents.value;
    this.uploadedTaxFiles = [];
    if (financialDocs) {
      financialDocs.forEach((doc) => {
       if (doc && doc.docType && doc.docType === 'PAYMENT_TAX') {
          if (!this.uploadedTaxFiles.find((item) => item.taxFileId === doc.id && item.taxFileName === doc.name)) {
            this.uploadedTaxFiles.push({
              taxFileId: doc.id,
              taxFileName: doc.name,
              taxFileCreatedAt: doc.createdAt,
              taxFileLoading: false
            });
          }
        }
      });
    }
  }

  calcContractFileUrl() {
    const financialDocs =  this.f.financial_documents.value;
    this.uploadedContractFiles = [];
    if (financialDocs) {
      financialDocs.forEach((doc) => {
        if (doc && doc.docType && doc.docType === 'PAYMENT_INFO') {
          if (!this.uploadedContractFiles.find((item) => item.contractFileId === doc.id && item.contractFileName === doc.name)) {
            this.uploadedContractFiles.push({
              contractFileId: doc.id,
              contractFileName: doc.name,
              contractFileCreatedAt: doc.createdAt,
              contractFileLoading: false
            });
          }
        }
      });
    }
  }

  downloadTaxFile(taxFileId) {
    this.taxFileLoading = true;
    const publisherId = this.f.publisher_id ? this.f.publisher_id.value : this.f.publisherId.value;
    const selectedTaxFile = this.uploadedTaxFiles.find((file) => file.taxFileId === taxFileId);
    if (taxFileId > -1 && taxFileId != null) {
      this.publisherService.getFinancialDocumentFile(publisherId, taxFileId).subscribe(
        data => this.startDownload(data, selectedTaxFile),
        error => {
          this.taxFileLoading = false;
        },
        () => {
          this.taxFileLoading = false;
        }
      );
    }
  }

  startDownload(data, filename) {
    const blob = new Blob([data], {type: 'application/pdf'});
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = filename.contractFileName ? filename.contractFileName : filename.taxFileName;
    // start download
    a.click();
    this.taxFileLoading = false;
  }

  onChanges(): void {
    this.taxForm.get('country').valueChanges.subscribe((countryName) => {
      if (!this.firstCountryChange && this.country !== countryName) {
        this.taxForm.get('state').setValue(null, {emitEvent: false});
      }
      if (this.firstCountryChange && countryName !== '') {
        this.firstCountryChange = false;
      }
      this.populateState(countryName);
      if (countryName === 'US') {
        this.taxForm.get('state').setValidators([Validators.required]);
        this.taxForm.get('state').updateValueAndValidity();
        this.taxForm.get('state').markAsTouched();
      } else {
        this.taxForm.get('state').setValidators(null);
        this.taxForm.get('state').updateValueAndValidity();
      }
      this.country = countryName;
    });

    this.taxForm.get('financial_documents').valueChanges.subscribe((docs) => {
      this.calcTaxFileUrl();
      this.calcContractFileUrl();
      this.contractFile = [];
    });

  }

  populateState(country2Char: string): void {
    const countryObj = this.countriesOptions.find(c => c.alpha2 === country2Char);
    this.statesList = [];
    if (countryObj) {
      this.loadingStates = true;
      this.countryService.getStates(countryObj.alpha3).subscribe((response) => {
        this.loadingStates = false;
        if (response.data) {
          this.statesList = response.data;
        }
      });
    }
  }

  onTaxFileChange(event: any) {
     this.fileList = [];
     this.isTaxFileListTouched = true;
    if (event.type === 'removed') {
      this.fileList = this.fileList.filter((file) => file.uid !== event.uid );
    } else {
      if (event.file.type !== 'application/pdf') {
        this.sharedService.showNotification('error', `Invalid file type`, 'Only PDF file can be uploaded');
      } else {
        this.fileList = this.fileList.concat(event.file);
      }
    }
    this.taxFileChanged.emit(this.fileList);
  }

  public getError(control_name: string, default_message: string = '') {
    const key = control_name.split('.');
    if (key.length === 1) {
      const element = this.taxForm.get(key[0]);
      return (element.errors && element.errors.message) ? element.errors.message : default_message;
    } else if (key.length === 2) {
      const element = this.taxForm.get(key[0]).get(key[1]);
      return (element.errors && element.errors.message) ? element.errors.message : default_message;
    } else if (key.length === 3) {
      const element = this.taxForm.get(key[0]).get(key[1]).get(key[2]);
      return (element.errors && element.errors.message) ? element.errors.message : default_message;
    } else if (key.length === 4) {
      const element = this.taxForm.get(key[0]).get(key[1]).get(key[2]).get(key[3]);
      return (element.errors && element.errors.message) ? element.errors.message : default_message;
    } else if (key.length === 5) {
      const parent = this.taxForm.get(key[0]).get(key[1]).get(key[2]).get(key[3]);
      const element = this.taxForm.get(key[0]).get(key[1]).get(key[2]).get(key[3]).get(key[4]);
      return ((element.errors && element.errors.message && (parent.get('enabled') && parent.get('enabled').value)) ?
        element.errors.message : default_message);
    }
  }

  onChangeSelectIconState(isOpen: boolean, id: string): void {
    this.selectIcons[id] = isOpen;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
