import { Component, Input } from '@angular/core';

@Component({
  selector: 'company-logo-svg',
  template: `
    <svg *ngIf="!minified" viewBox="0 0 160 29" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg">
      <path *ngFor="let val of logo_path_d_attributes" [ngStyle]="{fill}" [attr.d]="val.d"></path>
    </svg>
    <svg *ngIf="minified" viewBox="0 0 23.7 28.2" id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg">
      <path *ngFor="let val of logo_path_d_attributes" [ngStyle]="{fill}" [attr.d]="val.isForMinified ? val.d : ''"></path>
    </svg>
  `,
})
export class SvgUnrulyLogo {

  @Input() minified: boolean = false;
  @Input() fill: string = '#15003B';

  logo_path_d_attributes = [
    // N - 1st
    {
      isForMinified: true,
      d: 'M23.7372 12.1914C23.7372 4.75423 19.1199 0 11.8481 0C4.57625 0 -0.0410156 4.75562 -0.0410156 12.1914V28.1779H5.40205V11.9968C5.40205 7.8848 8.11382 4.87656 11.8481 4.87656C15.5824 4.87656 18.2941 7.8848 18.2941 11.9968V28.1779H23.7372V12.1914Z',
    },
    // E - 1st
    {
      isForMinified: false,
      d: 'M27.2148 14.0889C27.2148 21.8699 33.4084 28.1779 41.0485 28.1779C46.5962 28.1779 51.3794 24.8506 53.5849 20.0489H47.8558C46.3172 22.1502 43.9221 23.3347 41.0499 23.3347C36.8846 23.3347 33.46 20.4949 32.7639 16.4373H54.6896C54.8152 15.6725 54.8849 14.8897 54.8849 14.0889C54.8849 6.30792 48.6914 0 41.0513 0C33.4112 0 27.2176 6.30792 27.2176 14.0889H27.2148ZM32.6621 12.29C33.2494 7.78101 36.6642 4.84311 41.0485 4.84311C45.4328 4.84311 48.8476 7.73683 49.4349 12.29H32.6621Z',
    },
    // X - 1nd
    {
      isForMinified: false,
      d: 'M73.2003 28.1779L66.7919 18.8761L60.3877 28.1779H53.7715L63.4859 14.0742L53.791 0H60.4058L66.7919 9.27376L73.1821 0H79.7969L70.1007 14.0742L79.8151 28.1779H73.2003Z',
    },
    // X - 2st
    {
      isForMinified: false,
      d: 'M86.8004 28.1779L93.2087 18.8761L99.6129 28.1779H106.229L96.5148 14.0742L106.21 0H99.5948L93.2087 9.27376L86.8185 0H80.2037L89.8999 14.0742L80.1855 28.1779H86.8004Z',
    },
    // E - 2st
    {
      isForMinified: false,
      d: 'M118.951 0C111.311 0 105.117 6.30792 105.117 14.0889C105.117 14.8897 105.186 15.6738 105.312 16.4373H127.238C126.542 20.4949 123.118 23.3347 118.952 23.3347C116.08 23.3347 113.685 22.1502 112.146 20.0489H106.417C108.623 24.8506 113.405 28.1779 118.954 28.1779C126.594 28.1779 132.787 21.8699 132.787 14.0889C132.787 6.30792 126.594 0 118.954 0H118.951ZM110.564 12.29C111.152 7.73683 114.567 4.84311 118.951 4.84311C123.335 4.84311 126.75 7.78101 127.337 12.29H110.564Z',
    },
    // N - 2nd
    {
      isForMinified: false,
      d: 'M136.262 12.1914C136.262 4.75423 140.879 0 148.151 0C155.423 0 160.04 4.75562 160.04 12.1914V28.1779H154.597V11.9968C154.597 7.8848 151.885 4.87656 148.151 4.87656C144.417 4.87656 141.705 7.8848 141.705 11.9968V28.1779H136.262V12.1914Z',
    },
  ];

}
