<div class="globalSearchBackdrop"
  [class.open]="isOpen || alwaysOpen">

  <div class="globalSearchWrapper"
    appClickOutside (clickOutside)="onClickOutside($event)">
    <div class="inputCont" [class.invalidChars]="!isSearchValid">
      <fa-icon id="gs_icon" icon="search" class="font-icon-color-grey-cool"></fa-icon>
      <input [(ngModel)]="searchVal" #nonNgZorroSearchInput
            class="gs_select" placeholder="Search by ID/Name/Description"
            (ngModelChange)="onSearch($event)">
      <fa-icon *ngIf="searchVal" id="gs_clear" class="clearBtn font-icon-color-grey-iron font-icon-hover-color-grey-graphite" icon="times-circle" (click)="onClear(); $event.stopPropagation()"></fa-icon>
    </div>
    <div class="optionsCont" *ngIf="isOpen || alwaysOpen" @toggleDropdown (scroll)="onScroll($event)" [style.transform]="dropdownTransform">
      <ng-container *ngIf="isSearchValid && (!(isLoadingGlobalSearch$ | async)) || ((isLoadingGlobalSearch$ | async) && pageNumber)">
        <div class="gsOption" *ngFor="let option of globalSearchOptions$ | async" (click)="onSelectOption(option)" [id]="'gs_option_'+option.idstr">
          <div class="label">
            <div *ngIf="showPublisherData(option)" class="aboveMainText smallGrayText">Pub. ID: {{option.publisherId}}</div>
            <div class="nameAndIdCont">
              <span [title]="option.name" class="ellipsisText">{{option.name ? option.name : option.description}}</span>
              <span class="smallGrayText margin-left-small margin-right-small">
                ID: {{option.type === 'fusion_deal' ? option.externalId : (option.type === 'fusion_exchange_deal' ? option.externalDealId : option.idstr)}}
              </span>
            </div>
          </div>
          <div class="optionTag" [ngClass]="{'alignToBottom': showPublisherData(option) }" [style.background]="getTypeColor(option.type)">
            {{getTypeLabel(option.type)}}
          </div>
        </div>
      </ng-container>
      <div class="gsOption loadingOption" *ngIf="isLoadingGlobalSearch$ | async">
        <em nz-icon nzType="loading" class="loading-icon"></em> Loading Data...
      </div>
      <div class="gsOption errorMsg" *ngIf="!isSearchValid">
        <fa-icon icon="exclamation-circle"></fa-icon> Invalid characters {{ RESTRICTED_CHARACTERS.join(' ') }}
      </div>
    </div>
  </div>

</div>




