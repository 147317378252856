import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvConfigService {

  constructor() {
    this.setEnvVariables();
  }

  setEnvVariables(): void {
    // if running angular locally vs ondemand env, need to set user name ondemandUser
    if (environment.envName === 'ondemand') {
      const ondemandUser = location.hostname === 'localhost'
        ? (environment.ondemandUser ? environment.ondemandUser : 'NO-USER')
        : location.hostname.split('-')[1];
      environment.javaApiUrl = environment.javaApiUrl.replace('***USER***', ondemandUser);
      environment.javaInsightsUrl = environment.javaInsightsUrl.replace('***USER***', ondemandUser);
      environment.authServer = environment.authServer.replace('***USER***', ondemandUser);
    }
  }

}
