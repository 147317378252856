import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  AppFilter,
  AppTable,
  AppTableColumn,
  AuthPermissions,
  BillPayment, CommonDataModel, ModalButton, ModalActionType,
  Publisher
} from '../../_models/models';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { cloneDeep } from 'lodash';
import {
  authPermissions,
  billingHistoryConfig,
  billingHistoryFiltersConfig,
  billingHistoryTableButtons,
  billingHistoryTableColumns, GOOGLE_ACCOUNTID, initialDate, modalButtons, today
} from './billing-history.config';
import { AuthenticationService } from '../../../auth/_services/authentication.service';
import { takeUntil } from 'rxjs/operators';
import { SharedService } from '../../_services/shared.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from '../../_services/api.service';

@Component({
  selector: 'app-billing-history',
  templateUrl: './billing-history.component.html',
  styleUrls: ['./billing-history.component.less']
})
export class BillingHistoryComponent implements OnInit, OnChanges {
  private unsubscribe$ = new Subject<void>();

  @Input()
  accountingId: number;
  @Input()
  isExternal: boolean;
  billPaymentToShow: BillPayment;
  validHistoryView: boolean;

  billingHistory: BillPayment[];
  billingHistoryTable: AppTable = {...billingHistoryConfig};
  billingHistoryFilters: AppFilter[] = cloneDeep(billingHistoryFiltersConfig);
  billingHistoryTableColumns: AppTableColumn[] = cloneDeep(billingHistoryTableColumns);
  billingHistoryTableButtons = billingHistoryTableButtons;

  isLoading: boolean;
  publisherData: Publisher = null;
  authPermissions: AuthPermissions;

  isModalVisible: boolean;
  modalButtons: ModalButton[] = modalButtons;

  datePickerStartDate: Date = initialDate;
  datePickerEndDate: Date = today;

  constructor(private activatedRoute: ActivatedRoute,
              private apiService: ApiService,
              private auth: AuthenticationService,
              private sharedService: SharedService,
              private notificationService: NzNotificationService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.accountingId) {
      this.validHistoryView = this.accountingId !== null && !isNaN(this.accountingId) && Number(this.accountingId) !== GOOGLE_ACCOUNTID;
    }
  }

  ngOnInit() {
    this.isModalVisible = false;
    this.activatedRoute.data
      .subscribe((data: { publisher: Publisher }) => {
        this.publisherData = {...data.publisher};
      });
    this.authPermissions = authPermissions;
  }


  onFiltersChange($event: { filters: AppFilter[]; filterId: string }): void {
    this.datePickerStartDate = $event.filters[0].selectedValues[0];
    this.datePickerEndDate = $event.filters[0].selectedValues[1];
  }

  onRetrieveClick(): void {
    if (this.accountingId) {
      this.apiService.getPaymentHistory(
        this.accountingId,
        this.sharedService.convertDateToMMDDYYYY(this.datePickerStartDate),
        this.sharedService.convertDateToMMDDYYYY(this.datePickerEndDate)
      ).pipe(takeUntil(this.unsubscribe$)).subscribe(
        (commonData: CommonDataModel<BillPayment[]>) => {
          this.isLoading = commonData.isLoading;
          if (commonData.data && commonData.data?.length === 0) {
            this.notificationService.error('Retrieve History Failed', 'No payment history was found in this date range');
          }
          this.billingHistory = commonData?.data?.filter((billPayment: BillPayment ) => billPayment.voided === false);
        }
      );
    }
  }

  onRowAction($event: any): void {
    this.billPaymentToShow = $event.row;
    this.isModalVisible = true;
  }

  onModalEvent(_event: ModalActionType): void {
    this.isModalVisible = false;
  }

}
