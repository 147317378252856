<nz-alert [ngClass]="attrClass"  nzType="info" [nzMessage]=message >
  <ng-template #message>
    <div>
      <span class="bold-font font-icon-color-shade-slate-blue">{{boxTitle}} </span>
      <div class="small-font display-flex flex-column">
        <div class="whitespace-prewrap">{{boxContent}}
        </div>
        <ng-container [ngSwitch]="type">
          <ng-container *ngSwitchCase="'extended'">
            <ng-container *ngIf="extraData?.length">
              <span class="semibold-font margin-top-small">{{extraDataTitle}}</span>
              <span *ngFor="let item of extraData">- {{item}}</span>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-template>
</nz-alert>
