<div class="section-title">
  <fa-icon icon="history"></fa-icon>
  History
</div>
<app-filters (buttonClicked)="onButtonClick($event)"
             (filtersChanged)="onFiltersChange($event)"
             [buttons]="auditTableButtons"
             [filters]="auditFilters"
             [isDataLoading]="audit?.isLoading">
</app-filters>

<app-table
  (tablePageChanged)="onTablePageChange($event)"
  (tableSortChanged)="onTableSortChange($event)"
  [hasFooter]="true"
  [isPaginated]="true"
  [isTableLoading]="audit?.isLoading"
  [tableColumns]="auditTableColumns"
  [tableData]="audit?.data"
  [table]="auditTable"
  [totalRows]="audit?.totalElements">
</app-table>



