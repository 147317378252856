import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-password-validator',
  templateUrl: './password-validator.component.html',
  styleUrls: ['./password-validator.component.less']
})
export class PasswordValidatorComponent implements OnInit {

  @Input() type: 'Password' | 'PasswordConfirmation' | 'OldPassword';
  @Input() form: UntypedFormGroup;

  ngOnInit(): void {}

  get f() {
    return this.form;
  }

  get fc() {
    return this.form.controls;
  }
}
