import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { VersionService } from '../../_services';

@Injectable()
export class VersionInterceptor implements HttpInterceptor {
  constructor(private version: VersionService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        this.version.apiVersion = event.headers.get('Api-Version');
      }
      return event;
    }));
  }
}
