import { AppFilter, AppTable, AppTableColumn } from '../../../_models/models';
import { CreateQueryParams } from '@libs/crud-request-nestjs';

export const domainBundlesPermissionsConfig = {
  create: 'supply.domain-bundle.create',
  update: 'supply.domain-bundle.update',
  delete: 'supply.domain-bundle.delete',
};

export const domainBundlesFiltersConfig: AppFilter[] = [
  new AppFilter('domainBundlesSearchNameFilter', 'SEARCH', '', 'Search', 'Search site/app bundle name',
    'default', true, true,
    {nameProp: 'domainBundle'}, undefined, undefined,
    undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, undefined, undefined,
    undefined),
];

export const domainBundlesChangesFiltersConfig: AppFilter[] = [
  new AppFilter('domainBundlesChangesFilter', 'SEARCH', '', 'Search', 'Search for change to submit',
    'default', true, true,
    {nameProp: 'value'}, undefined, undefined,
    undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, undefined, undefined,
    undefined),
];

export const domainBundlesTableButtons = [
  {
    id: 'domainBundlesDownloadCsvButton', type: 'SECONDARY-BUTTON', title: 'Download CSV',
    hasSpinner: false, auth: 'canRead', action: 'download', isDisabled: false, isHidden: false,
    customClass: 'small-font small-height small-padding'
  },
  {
    id: 'domainBundlesDeleteAllButton', type: 'DELETE-ALL-BUTTON', title: 'Delete All Site/App Bundles', action: 'deleteAll',
    hasLoadingSpinner: false, auth: 'canDelete', desc: 'All site/app bundles including local changes will be deleted on submit?', isDisabled: false,
    customClass: 'small-font small-height small-padding'
  },
];

export const domainBundlesTableConfig: AppTable = new AppTable('domainBundlesTable', 1, 10, 'domainBundle',
  'ASC', true, true);

export const domainBundlesChangesTableConfig: AppTable = new AppTable('domainBundlesChangesTableConfig', 1, 10, 'domainBundle',
  'ASC', true, true);

export const domainBundlesTableColumns: AppTableColumn[] = [
  {id: 'domainBundlesName', prop: 'domainBundle', displayName: 'Site/App Bundle', type: 'text', isSortEnabled: true},
  {id: 'domainBundlesCreatedAt', prop: 'createdAt', displayName: 'Created at (EST)', type: 'date', isSortEnabled: true},
  {
    id: 'domainBundlesSubdomain', prop: 'includeSubDomains', displayName: 'Include Subdomain', type: 'toggle-domain',
    actionId: 'domainBundlesIncludeSubDomains', isSortEnabled: true
  },
  {
    id: 'domainBundlesActions', prop: 'actions', displayName: 'Actions', type: 'actions', isSortEnabled: false,
    actions: [
      {
        id: 'domainBundleDelete', name: 'delete', auth: 'canDelete', displayName: 'Delete',
        desc: 'Are you sure you wish to delete this site/app bundle?'
      },
    ]
  },
  {id: 'changes', prop: 'changes', displayName: 'Changes', type: 'text', isSortEnabled: false},
];

export const domainBundlesTableColumnsRx: AppTableColumn[] = [
  {id: 'domainBundlesName', prop: 'domainBundle', displayName: 'Site/App Bundle', type: 'text', isSortEnabled: true},
  {
    id: 'domainBundlesActions', prop: 'actions', displayName: 'Actions', type: 'actions', isSortEnabled: false,
    actions: [
      {
        id: 'domainBundleDelete', name: 'delete', auth: 'canDelete', displayName: 'Delete',
        desc: 'Are you sure you wish to delete this site/app bundle?'
      },
    ]
  },
  {id: 'changes', prop: 'changes', displayName: 'Changes', type: 'text', isSortEnabled: false},
];

export const domainBundlesChangesTableColumns: AppTableColumn[] = [
  {id: 'domain', prop: 'value', displayName: 'Value', type: 'text', isSortEnabled: true},
  {id: 'change', prop: 'change', displayName: 'Change', type: 'text', isSortEnabled: false},
  {
    id: 'domainBundlesActions', prop: 'actions', displayName: 'Actions', type: 'actions', isSortEnabled: false,
    actions: [
      {
        id: 'cancelAction', name: 'cancel', auth: 'canDelete', displayName: 'Undo Change',
        desc: 'Are you sure you wish to cancel this change?'
      },
    ]
  }
];

export const initialSegmentMapsFilterQueryConfig: CreateQueryParams = {
  search: {},
  page: 1,
  limit: 5
};

export const DOMAIN_BUNDLES_MOCK = [
  {
    createdAt: '2021-02-24T13:52:53.000-0500',
    domainBundle: 'test',
    id: 1810504,
    includeSubDomains: false,
    placementId: 123,
    pubTsId: 5756,
    updatedAt: '2021-02-24T13:52:53.000-0500'

  }
];

