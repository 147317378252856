<div class="widget" [ngClass]="{'active': isActive}" [style.min-width]="config.minWidth + 'px' || 'auto'">
  <div class="widget-grid" [ngClass]="{'previous-hidden': config.isPreviousHidden}">
    <div class="label">
      {{ config?.labels.title }}
    </div>
    <ng-container *ngIf="!config.isPreviousHidden">
      <div class="trend">
        <div class="value" [ngClass]="{
          'color-down': getPercentageChange() < 0,
          'color-grow': getPercentageChange() > 0
        }">
          <ng-container *ngIf="!config.isLoading; else skeleton">
            {{ config.isLoading ? '0.00' : getAbsPercentageChange() }}%
          </ng-container>
          <ng-container *ngIf="!isNoChange()">
            <fa-icon *ngIf="isChangePositive()" icon="caret-up"></fa-icon>
            <fa-icon *ngIf="isChangeNegative()" icon="caret-down"></fa-icon>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <div class="current-value">
      <ng-container *ngIf="!config.isLoading; else skeleton">
        {{ getFormattedValue(config.value?.current) }}
      </ng-container>
    </div>
    <ng-container *ngIf="!config.isPreviousHidden">
      <div class="previous-value">
        <ng-container *ngIf="!config.isLoading; else skeleton">
          <div>{{ config?.labels?.previous }} (EST)</div>
          <div>{{ getFormattedValue(config?.value?.previous) }}</div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>


<ng-template #skeleton>
  <nz-skeleton class=""
    [nzTitle]="false"
    [nzParagraph]="{rows: 1}"
    nzActive="true"></nz-skeleton>
</ng-template>

