import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModeType, UserTypeLower } from '../../_models/models';
import { SharedStoreService } from '../../_services/shared-store.service';

@Component({
  selector: 'app-shared-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.less']
})
export class SharedPaymentInfoComponent implements OnInit, OnChanges, OnDestroy {

  @Input() type: UserTypeLower = 'external';
  @Input() mode: ModeType = 'read';
  @Input() paymentForm: UntypedFormGroup;
  @Input() selectedMethodControlsParams: {};
  @Input() country: string = null;

  loadingMethods = false;

  paymentMethodOptions$: Observable<any>;
  paymentMethodOptions: any[] = [];

  selectIcons: any = {};

  private unsubscribe$ = new Subject<void>();

  constructor(
    private sharedStoreService: SharedStoreService,
  ) {
  }

  onChangeSelectIconState(isOpen, id) {
    this.selectIcons[id] = isOpen;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.country && changes.country.currentValue === 'US' && this.paymentForm.get('paymentMethodType').value === 'WIRE') {
      this.paymentForm.get('paymentMethodType').setValue(null);
      this.paymentForm.updateValueAndValidity();
    }
  }

  ngOnInit() {
    this.initSubscriptions();
    // THIS PART WILL NOT BE NEEDED AFTER WE REMOVE PAYPAL
    if (this.paymentForm.get('paymentMethodType').value === 'PAYPAL') {
      this.paymentForm.controls.selectedMethodForm.disable();
    }
  }

  initSubscriptions() {
    this.paymentMethodOptions$ = this.sharedStoreService.getSharedEnum('PaymentMethodType');
    this.paymentMethodOptions$.pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
      if (res) {
        this.paymentMethodOptions = _.sortBy(
          Object.keys(res).map(key => Object.assign({
            value: key,
            text: res[key]
          })),
          ['text']
        );
        if (this.type === 'external') {
          this.paymentMethodOptions = _.reject(this.paymentMethodOptions, {value: 'NONE'});
        }
        this.paymentMethodOptions = _.reject(this.paymentMethodOptions, {value: 'CHECK'});
      }
    });
  }

  isMode(modes) {
    return modes.includes(this.mode);
  }

  get selectedMethodForm() {
    return this.paymentForm.get('selectedMethodForm') as UntypedFormGroup;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
