import { AppFilter, AppTable, AppTableColumn, AssetsBlockedLists, Country, GroupAppFilter } from '../../_models/models';
import dayjs from 'dayjs';

const today: Date = new Date(new Date().toLocaleDateString('en-US', {timeZone: 'America/New_York'}));
const tomorrow: Date = dayjs(new Date(new Date().toLocaleDateString('en-US', {timeZone: 'America/New_York'})))
  .add(1, 'days').toDate();

export const auditEndpoints = {
  PUBLISHER: 'publisher/',
  DEAL: 'deal/'
};

export const auditTableButtons = [
  {id: 'auditResetFiltersButton', type: 'LINK', title: 'Reset Filters', hasLoadingSpinner: false},
];

export const publisherAuditTableConfig: AppTable = new AppTable('timestamp', 1, 10, 'timestamp',
  'desc', true, true);

export const publisherFieldsToUiMapping = {
  'bcat': {displayName: 'Blocked Categories', matchListName: 'iabCategoriesFlattened', matchProp: 'key', nameProp: 'title'},
  'btype': {displayName: 'Blocked Ad Types', matchListName: 'iabBannerAdTypes', matchProp: 'adTypeId', nameProp: 'description'},
  'battr': {displayName: 'Blocked Creative Attributes', matchListName: 'iabCreativeAttributes', matchProp: 'attributeId', nameProp: 'description'},
  'badv': {displayName: 'Blocked Advertisers'},
  'tldBadv': {displayName: 'Blocked Tld'},
  'bidfloorOpti': {displayName: 'Bid floor adjustment optimization'},
  'commissionOpti': {displayName: 'Commission optimization'},
  'dspAccountExcludeList': {displayName: 'Blocked Dsp', matchListName: 'dspAccounts', matchProp: 'id', nameProp: 'nameDisplay'},
  'cat': {displayName: 'Categories', matchListName: 'iabCategoriesFlattened', matchProp: 'key', nameProp: 'title'},
  ['publisher.floors.show']: {displayName: 'Show Floor Settings'},
  ['publisher.reporting.detailed']: {displayName: 'Detailed Reporting'},
  ['publisher.reporting.demand']: {displayName: 'Demand Reporting'},
  ['publisher.reporting.deals']: {displayName: 'Deal Reporting'},
  ['publisher.deal.show']: {displayName: 'Deals Section and Spearad'},
  'country': {displayName: 'Country', matchListName: 'countries', matchProp: 'alpha2', nameProp: 'name'},
};

const publisherSelectGroups: GroupAppFilter[] = [
  {
    groupId: 1, groupName: 'profileTab', groupDisplayName: 'Profile tab', possibleValues:
      [ {id: 1, displayName: 'Publisher Type', value: 'publisherAccountType'},
        {id: 2, displayName: 'Publisher Initiated Platform Fee', value: 'pubInitiatedDealPlatformfee'},
        {id: 3, displayName: 'Nexxen Initiated Platform Fee', value: 'unrulyInitiatedDealPlatformfee'},
        {id: 4, displayName: 'Accounting ID', value: 'accountingId'},
        {id: 5, displayName: 'Internal Notes', value: 'internalNotes'},
        {id: 6, displayName: 'Company Name', value: 'company'}]
  }, {
    groupId: 2, groupName: 'paymentTab', groupDisplayName: 'Payment tab', possibleValues:
      [ {id: 1, displayName: 'Beneficiary Name', value: 'legalCompanyName'},
        {id: 2, displayName: 'Payment Terms', value: 'paymentTerms'},
        {id: 3, displayName: 'Payment Method', value: 'paymentMethodType'},
        {id: 4, displayName: 'Tax ID', value: 'taxId'}]
  }, {
    groupId: 3, groupName: 'configurationsTab', groupDisplayName: 'Configurations tab', possibleValues:
      [ {id: 1, displayName: 'Demand Profile', value: 'demandProfile'},
        {id: 2, displayName: 'Bid Floor', value: 'bidFloor'},
        {id: 3, displayName: 'Floor Adjustment', value: 'floorAdjustment'},
        {id: 4, displayName: 'Commission Type', value: 'commissionType'},
        {id: 5, displayName: 'Commission', value: 'commission'},
        {id: 6, displayName: 'QPS Defined', value: 'qpsDefined'},
        {id: 7, displayName: 'QPS Limit', value: 'qpsLimit'}
      ]
        // {id: 6, displayName: 'Site/App Bundles', value: 'domainBundle'}]
  }, {
    groupId: 4, groupName: 'blockedListsTab', groupDisplayName: 'Blocked Lists tab', possibleValues:
      [ {id: 1, displayName: 'Blocked Categories', value: 'bcat'},
        {id: 2, displayName: 'Blocked Advertisers', value: 'badv'},
        {id: 3, displayName: 'Blocked DSPs', value: 'dspAccountExcludeList'}]
  },
];


// if all audits will be the same - remove publisher prefix from id
export const publisherAuditTableColumns: AppTableColumn[] = [
  {id: 'publisherAuditTimestamp', prop: 'timestamp', displayName: 'Timestamp', type: 'text', isSortEnabled: true},
  {id: 'publisherAuditUsername', prop: 'username', displayName: 'User Name', type: 'text', isSortEnabled: false},
  {id: 'publisherAuditField', prop: 'fieldName', displayName: 'Field Name', type: 'text', isSortEnabled: false},
  {id: 'publisherAuditParent', prop: 'parent', displayName: 'Parent', type: 'text', isSortEnabled: false},
  {id: 'publisherAuditActionType', prop: 'actionType', displayName: 'Action Type', type: 'text', isSortEnabled: false},
  {id: 'publisherAuditPreviousValue', prop: 'previousValue', displayName: 'Previous Value', type: 'text', isSortEnabled: false},
  {id: 'publisherAuditNewValue', prop: 'newValue', displayName: 'New Value', type: 'text', isSortEnabled: false},
];

export const auditFiltersConfig: AppFilter[] = [
  new AppFilter('auditDatesRange', 'DATES_RANGE', 'timestamp', 'Date Range (EST)', '',
    'default', true, false, null, [],
    [today, tomorrow], null, null, null, true,
    [today, tomorrow], null, null,
    null, null, true),
  new AppFilter('auditFieldName', 'SELECT_WITH_GROUPS', 'field', 'Field Name', 'Select field name',
    'multiple', true, false, null,
    [], [],
    null, null, null, false, undefined, undefined, undefined,
    undefined, undefined, undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, undefined, undefined, undefined, publisherSelectGroups),
  new AppFilter('auditPublisherUser', 'SELECT', 'userId', 'Publisher User', 'Select user',
    'default', true, false, null,
    [],
    [], null,
    null, null, null, []),
  new AppFilter('auditInternalUser', 'SELECT', 'userId', 'Internal User', 'Select user',
    'default', true, false, null,
    [],
    [], null,
    null, null, null, []),
  new AppFilter('auditActionType', 'SELECT', 'action', 'Action Type', 'Select action type',
    'default', true, false, null,
    [
      {id: 1, displayName: 'Create', value: 'INITIAL'},
      {id: 2, displayName: 'Update', value: 'UPDATE'}],
      // {id: 3, displayName: 'Delete', value: 'TERMINAL'}],
    [])
];

export const AUDIT_MOCK_NEW = {
  paginated: true,
  pageNumber: 1,
  pageSize: 10,
  totalElements: 1,
  data: [
    {
      timestamp: 234234234,
      username: 'Bob Sfog',
      field: 'publisherName',
      parent: 'Profile',
      previousValue: 'old name',
      newValue: 'new value',
      action: 'Update'
    }
  ],
};

export interface AuditTranslatableEntities extends AssetsBlockedLists {
  countries?: Country[];
  iabCategoriesFlattened?: Array<{ key: string; title: string }>;
}
