import { ElementRef, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';

const SPEARAD_CLIENT_URL = environment.spearadIframeUrl; // environment.spearadClientURL

/**
 * Service for controlling the Spearad client hosted in a given IFRAME.
 *
 * The following commands are supported
 *   * `navigate` (see {@link navigate()})
 *   * `search` (see {@link openSearch()})
 */
@Injectable({
  providedIn: 'root',
})
export class SpearadAppService {
  /**
   * The IFRAME element hosting the Spearad client application.
   */
  private iframe: ElementRef<HTMLIFrameElement>;

  /**
   * Generates a unique identifier used for mapping responses to their requests.
   *
   * @returns A unique UUID
   */
  private generateUUID(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c == 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  /**
   * Awaits the response for the request by specified UUID, using the given
   * timeout (in milliseconds; default is `3000`).
   *
   * If no response has been sent within the given amount of time, an error
   * is raised on the returned {@link Observable}.
   *
   * This method temporarily adds a message event handler to the underlying
   * window until a response has been received or the timeout is reached.
   *
   * @param uuid    The UUID of the request whose response is to be awaited
   * @param timeout The maximum time to wait for a response before failing
   *
   * @returns An {@link Observable} on the response
   */
  private awaitResponse(uuid: string, timeout = 3000): Observable<ISAEvent> {
    const result$ = new Subject<ISAEvent>();

    let handler: (event: MessageEvent) => void;

    const removeMessageListener = () => {
      window.removeEventListener('message', handler);
      handler = null;
    };

    // Handle the response from the Spearad client
    handler = (event: MessageEvent<ISAEvent>) => {
      if (event.origin === SPEARAD_CLIENT_URL && event.data.uuid === uuid) {
        const spearadEvent = <ISAEvent>event.data;
        if (!spearadEvent?.error) {
          result$.next(spearadEvent);
        } else {
          result$.error(spearadEvent?.error);
        }

        removeMessageListener();
        result$.complete();
      }
    };

    window.addEventListener('message', handler);

    // If the Spearad client does not respond within `timeout` milliseconds,
    // remove the message listener and emit an error on the resulting Observable
    window.setTimeout(() => {
      if (handler !== null) {
        const message =
          '' +
          'Spearad client command timeout! ' +
          'No response after ' +
          timeout / 1000 +
          ' seconds.';

        console.warn(message);
        removeMessageListener();
        result$.error(message);
        result$.complete();
      }
    }, timeout);

    return result$.asObservable();
  }

  /**
   * Sends the given command to the Spearad client and returns an
   * {@link Observable} on the response event.
   *
   * @param command The command to be sent
   *
   * @returns An Observable on the Spearad client response
   */
  private sendCommand(command: ISACommand): Observable<ISAEvent> {
    command.uuid = this.generateUUID();

    const win = this.iframe.nativeElement.contentWindow;
    win.postMessage(command, '*');

    return this.awaitResponse(command.uuid);
  }

  /**
   * Initializes this singleton service by loading the Spearad application
   * into the specified IFRAME using the provided JWT token.
   *
   * @param iframe The IFRAME element into which to load the Spearad app.
   * @param token  The signed JWT token used to login
   *
   * @returns An {@link Observable} on the initialization response providing
   *          the menu entries the user is allowed to see
   */
  init(iframe: ElementRef, token: string): Observable<ISAInitEvent> {
    this.iframe = iframe;
    this.iframe.nativeElement.setAttribute('src', `${SPEARAD_CLIENT_URL}/login?token=${token}`);

    return <Observable<ISAInitEvent>>(this.awaitResponse('initialization', 20000));
  }

  /**
   * Navigates the Spearad client to the specified path.
   *
   * @param path The path to navigate
   *
   * @returns An {@link Observable} on the `navigation` response event
   */
  navigate(path: string): Observable<ISANavigationEvent> {
    return <Observable<ISANavigationEvent>>this.sendCommand(<ISANavigateCmd>{
      type: 'navigate',
      path,
    });
  }

  refreshToken(): Observable<ISAKeptAliveEvent> {
    return <Observable<ISAKeptAliveEvent>>this.sendCommand(<ISAKeepAliveCmd>{
      type: 'keep-alive'
    });
  }

  /**
   * Opens the global Spearad search.
   *
   * @returns An {@link Observable} on the response event
   */
  openSearch(): Observable<void> {
    return <Observable<void>>(<unknown>this.sendCommand(<ISAOpenSearchCmd>{
      type: 'open-search',
    }));
  }

  logout(): Observable<void> {
    return <Observable<void>>(<unknown>this.sendCommand(<ISALogoutCmd>{
      type: 'logout',
    }));
  }
}


/**
 * Base interface for Spearad client commands.
 */
export interface ISACommand {
  uuid: string;
  type: 'navigate' | 'open-search' | 'keep-alive' | 'logout';
}

export interface ISAKeepAliveCmd extends ISACommand {
  type: 'keep-alive';
}

/**
 * Describes the `navigate` command.
 */
export interface ISANavigateCmd extends ISACommand {
  type: 'navigate';
  path: string;
}

/**
 * Describes the `open-search` command.
 */
export interface ISAOpenSearchCmd extends ISACommand {
  type: 'open-search';
}

/**
 * Describes the `logout` command.
 */
export interface ISALogoutCmd extends ISACommand {
  type: 'logout';
}

export interface ISAKeptAliveEvent extends ISAEvent {
  type: 'kept-alive';
}

/**
 * Base interface for events sent back by the Spearad client.
 */
export interface ISAEvent {
  uuid: string;
  type: 'initialization' | 'navigation' | 'search-opened' | 'logged-out' | 'kept-alive';
  error?: string;
}

/**
 * Describes the `initialization` event providing the Spearad menu entries.
 */
export interface ISAInitEvent extends ISAEvent {
  type: 'initialization';
  menu: ISAMenuEntry[];
}

/**
 * Describes `navigation` event.
 */
export interface ISANavigationEvent extends ISAEvent {
  type: 'navigation';
  status: 'succeeded' | 'failed';
}

/**
 * Describes a Spearad menu entry.
 */
export interface ISAMenuEntry {
  caption?: string;
  icon?: string;
  path: string;
  children?: ISAMenuEntry[];
  routerPath?: string[];
}
