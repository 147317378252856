<div class="card-wrapper" *ngIf="mainSyncDeal">
  <nz-card>
    <div class="margin-bottom-1 display-flex justify-content-space-between">
      <div>
        <span class="header margin-bottom-small" *ngIf="dealName; else skeleton">
          '{{dealName}}'
        </span>
        <span class="small-font">
          <ng-container *ngIf="mainSyncDeal.syncType === 'marketplace'; else buyerMode">
            <span class="margin-left-1"><strong>Deal:</strong> {{mainSyncDeal.externalApiData.id}}</span>
            <span class="margin-left-1">
              <strong>URI:</strong> {{mainSyncDeal.externalApiData.dv3MarketplaceUri}}
              <fa-icon icon="copy" nz-tooltip [nzTooltipTitle]="'Copy URI'"
                       (click)="copyToClipboard(mainSyncDeal.externalApiData.dv3MarketplaceUri)"></fa-icon>
            </span>
          </ng-container>
          <ng-template #buyerMode>
            <span class="margin-left-1"><strong>Order:</strong> {{mainSyncDeal.externalApiData.orderId}}</span>
            <span class="margin-left-1"><strong>Deal:</strong> {{mainSyncDeal.externalApiData.productId}}</span>
          </ng-template>
        </span>
      </div>
      <button *ngIf="mainSyncDeal.syncType !== 'marketplace'" nz-button nzType="primary" nzShape="round"
              class="primary-button" (click)="retrieveStatus()">
        Retrieve Status
      </button>
    </div>
    <app-filters
      [filters]="dealExtSyncsFilters"
      [isDataLoading]="isLoading"
      (filtersChanged)="onFiltersChange($event)"
    >
    </app-filters>
  </nz-card>
  <nz-card>
    <div *ngIf="(dealExtSyncsData$ | async) as dealExtSyncs">
      <app-table
        [isTableLoading]="dealExtSyncs.isLoading || isLoading"
        [table]="dealExtSyncsTable"
        [tableColumns]="dealExtSyncsTableColumns"
        [tableData]="dealExtSyncs.data"
        [tableType]="'serverSide'"
        [idProp]="'dealExtSyncId'"
        [totalRows]="dealExtSyncs.totalElements"
        [hasSizeChanger]="true"
        [isPaginated]="true"
        [hasFooter]="true"
        (tableSortChanged)="onTableSortChange($event)"
        (tablePageChanged)="onTablePageChange($event)"
      >
      </app-table>
    </div>
  </nz-card>
</div>

<ng-template #skeleton>
  <nz-skeleton
    [nzAvatar]="false"
    [nzTitle]="false"
    [nzParagraph]="{ rows: 1, width: '200px' }"
    nzActive="true"
  >
  </nz-skeleton>
</ng-template>
