<form (keydown.enter)="$event.preventDefault()" [formGroup]="parentFormGroup" nz-form nzLayout="vertical">
  <nz-row nzGutter="16">
    <nz-col nzLg="12" nzMd="12" nzSm="24">
      <nz-form-item>
        <nz-form-label>Exclude From Bid Request</nz-form-label>
        <nz-form-control>
          <nz-select (nzOpenChange)="onChangeSelectIconState($event, 'exclude-from-bid-request')"
                     id="exclude-from-bid-request"
                     nzMode="multiple"
                     formControlName="contentAttrRequestExclude"
                     nzPlaceHolder="Select attributes to exclude from bid request"
                     nzShowArrow="false"
                     nzMaxTagCount="4"
                     [nzSuffixIcon]="selectIcons['exclude-from-bid-request'] ? arrowUp : arrowDown"
                     style="width: 100%;">
            <nz-option *ngFor="let option of contentAttributes"
                       [nzDisabled]="option.isDisabledForRequest"
                       [nzLabel]="option.displayName"
                       [nzValue]="option.value">
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
    <nz-col nzLg="12" nzMd="12" nzSm="24">
      <nz-form-item>
        <nz-form-label>Exclude From Buyer Reporting</nz-form-label>
        <nz-form-control>
          <nz-select (nzOpenChange)="onChangeSelectIconState($event, 'exclude-from-buyer-reporting')"
                     id="exclude-from-buyer-reporting"
                     nzMode="multiple"
                     [nzSuffixIcon]="selectIcons['exclude-from-buyer-reporting'] ? arrowUp : arrowDown"
                     nzPlaceHolder="Select attributes to exclude from reporting"
                     nzShowArrow="false"
                     [nzTooltipTitle]="getTooltipText()"
                     nz-tooltip
                     formControlName="contentAttrReportExclude"
                     nzMaxTagCount="4"
                     style="width: 100%;">
            <nz-option *ngFor="let option of contentAttributes"
                       [nzDisabled]="option.isDisabledForReport"
                       [nzLabel]="option.displayName"
                       [nzValue]="option.value">
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </nz-row>
</form>

<ng-template #arrowUp>
  <fa-icon [icon]="'angle-down'" class="select-icon select-icon-open"></fa-icon>
</ng-template>
<ng-template #arrowDown>
  <fa-icon [icon]="'angle-down'" class="select-icon select-icon-close"></fa-icon>
</ng-template>

