<ng-container *ngIf="!hideTitle">
  <div class="sectionTitle">
    <fa-icon icon="cogs"></fa-icon> Sellers.json
    <ng-container *ngIf="form && !hideWarning">
     <span nz-tooltip *ngIf="!form.controls.sellerTypes.value || !form.controls.externalDomain.value || !form.controls.externalName.value"
           [nzTooltipTitle]="warningMsg">
            <fa-icon icon="exclamation-circle" class="fa-xs"></fa-icon>
          </span>
    </ng-container>
  </div>
</ng-container>
<nz-row nzGutter="16">
  <nz-col nzLg="6" nzMd="12" nzSm="24" nzXs="24">
    <nz-form-item>
      <nz-form-label nzRequired>Seller Type</nz-form-label>
      <nz-form-control [nzErrorTip]="'Please select seller type'">
        <nz-select nzMode="multiple"
                   formControlName="sellerTypes"
                   id="sellersSellerType"
                   nzPlaceHolder="Select a seller type"
                   nzShowArrow
                   (ngModelChange)="onSellerTypeListChange($event)">
          <ng-container *ngIf="(sellerTypes$ | async) as sellerTypes">
            <nz-option *ngFor="let sellerType of sellerTypes | sortBy:'key': 'desc'"
                       [nzLabel]="sellerType.value" [nzValue]="sellerType.key"></nz-option>
          </ng-container>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </nz-col>

  <nz-col nzLg="6" nzMd="12" nzSm="24" nzXs="24">
    <nz-form-item>
      <nz-form-label class="text-transform-none">Publisher Seller ID</nz-form-label>
      <nz-form-control>
        <input nz-input formControlName="publisherR1SellerId" placeholder="Not set" [value]="publisher?.publisherR1SellerId ? publisher?.publisherR1SellerId : 'Not set'" id="publisherR1SellerId">
      </nz-form-control>
    </nz-form-item>
  </nz-col>
  <nz-col nzLg="6" nzMd="12" nzSm="24" nzXs="24">
    <nz-form-item>
      <nz-form-label>Intermediary Seller ID</nz-form-label>
      <nz-form-control>
        <input nz-input formControlName="intermediaryR1SellerId" placeholder="Not set" [value]="publisher?.intermediaryR1SellerId ? publisher?.intermediaryR1SellerId : 'Not set'" id="intermediaryR1SellerId">
      </nz-form-control>
    </nz-form-item>
  </nz-col>
  <nz-col nzLg="6" nzMd="12" nzSm="24" nzXs="24">
    <nz-form-item>
      <nz-form-label>Both Seller ID</nz-form-label>
      <nz-form-control>
        <input nz-input formControlName="bothR1SellerId" placeholder="Not set" [value]="publisher?.bothR1SellerId ? publisher?.bothR1SellerId : 'Not set'" id="bothR1SellerId">
      </nz-form-control>
    </nz-form-item>
  </nz-col>

</nz-row>

<nz-row nzGutter="16">
  <nz-col nzLg="6" nzMd="12" nzSm="24" nzXs="24">
    <nz-form-item>
      <nz-form-label>External Name</nz-form-label>
      <nz-form-control>
        <input formControlName="externalName" id="sellersExternalName" nz-input placeholder="Enter an external name">
      </nz-form-control>
    </nz-form-item>
  </nz-col>
  <nz-col nzLg="12" nzMd="12" nzSm="24" nzXs="24">
    <nz-form-item>
      <nz-form-label>External Domain</nz-form-label>
      <nz-form-control>
        <input formControlName="externalDomain" id="sellersExternalDomain" nz-input placeholder="Enter an external domain">
      </nz-form-control>
    </nz-form-item>
  </nz-col>
</nz-row>

