import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class WindowService {

  constructor(@Inject(DOCUMENT) readonly document: Document) {}
  get window(): Window {
    return this.document.defaultView || window ;
  }

  openWindow(url: string, target= '_blank' ): void {
    this.window.open(url, target);
  }


}
