import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
 name: 'mapFromKeyValue'
})

export class MapFromKeyValuePipe implements PipeTransform {

transform(key: string): string {
    const mappingObject = {
        'ctv' : 'Connected TV',
        'video' : 'Video',
        'display' : 'Display',
        'native' : 'Native',
        'mobile' : 'Mobile',
        'unknown' : 'Unknown',
        'banner' : 'Banner',
        'app' : 'App',
        'site' : 'Site',
    };
    let value = '';
    value = mappingObject[key];

    return typeof value === 'undefined' ? key : value;
   }
}
