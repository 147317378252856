import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appGtmIdImplement]'
})
export class GtmIdImplementDirective implements AfterViewInit {

  @Input('appGtmIdImplement') appGtmId: string;
  @Input() gtmValue: string | number;

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  setDataAttribute(element: any): void {
    this.renderer.setAttribute(element, 'data-gtm-id', this.appGtmId);
    if (this.gtmValue) {
      if (typeof this.gtmValue === 'number') {
        this.gtmValue = this.gtmValue.toString();
      }
      this.renderer.setAttribute(element, 'data-gtm-value', this.gtmValue);
    }
  }

  ngAfterViewInit(): void {
    const element = this.el.nativeElement;
    this.setDataAttribute(element);
    if (element.children?.length > 0) {
      [...element.children].forEach(elChild => {
        this.setDataAttribute(elChild);
      });
    }
  }
}
