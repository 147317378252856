import { Component, EventEmitter, Input, Output } from '@angular/core';

import { DropdownButtonsConfig } from '../../_models/models';
import { RxFormDealSubmitActionId } from '../../../features/exchange-deals/_services/exchange-deals.config';

@Component({
  selector: 'app-dropdown-button',
  templateUrl: './app-dropdown-button.component.html',
  styleUrls: ['./app-dropdown-button.component.less'],
})
export class AppDropdownButtonComponent {

  @Input() buttons: DropdownButtonsConfig = null;
  @Input() isLoading = false;
  @Input() hideOptions: boolean;
  @Output() buttonClicked: EventEmitter<string | RxFormDealSubmitActionId> = new EventEmitter();

  isDropdownVisible = false;
  dropdownMinWidth = 150;

  onButtonClicked(id: string): void {
    this.buttonClicked.emit(id);
  }

  onDropdownToggle(isOpen, buttonGroupEl) {
    this.isDropdownVisible = isOpen;
    this.dropdownMinWidth = buttonGroupEl?.elementRef?.nativeElement?.getBoundingClientRect()?.width;
  }

}
