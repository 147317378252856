import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {first, map} from 'rxjs/operators';

import {Store} from '@ngrx/store';
import * as fromTrafficSources from '../_store/traffic-sources.reducer';
import * as TrafficSourcesActions from '../_store/traffic-sources.actions';
import {FormHelper} from '../../../../_common';
import {ApiService} from '../../../../shared/_services/api.service';
import {SharedService} from '../../../../shared/_services/shared.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import { Observable } from 'rxjs';
import { TrafficSource } from '../../../../shared/_models/models';

@Injectable({providedIn: 'root'})
export class SupplyTrafficSourceService {

  constructor(
    private http: HttpClient,
    private fh: FormHelper,
    private store: Store<fromTrafficSources.TrafficSourcesState>,
    private api: ApiService,
    private router: Router,
    private sharedService: SharedService,
    private notification: NzNotificationService
  ) {
  }

  fetchTrafficSources() {
    this.store.dispatch(new TrafficSourcesActions.FetchTrafficSourcesRequestAction());
    this.api.getTrafficSources()
      .pipe(
        map((res: any) => {
          return res.reduce((result, val) => {
            const newVal = val;
            newVal.tag = !val.deletedAt ? val.status : 'DELETED';
            result.push(newVal);
            return result;
          }, []);
        })
      )
      .subscribe((mutatedRes: any) => {
          this.store.dispatch(new TrafficSourcesActions.FetchTrafficSourcesSuccessAction({trafficSources: mutatedRes}));
        },
        (err: any) => {
          this.store.dispatch(new TrafficSourcesActions.FetchTrafficSourcesFailureAction(err));
        });
  }

  fetchTrafficSourceById(id: number | string) {
    this.store.dispatch(new TrafficSourcesActions.FetchTrafficSourceByIdRequestAction());
    this.api.getTrafficSourceById(id)
      .pipe(first())
      .subscribe((res: any) => {
        this.store.dispatch(new TrafficSourcesActions.FetchTrafficSourceByIdSuccessAction({editedTrafficSource: res}));
      }, (err: any) => {
        this.store.dispatch(new TrafficSourcesActions.FetchTrafficSourceByIdFailureAction(err));
      });
  }

  fetchSsps(search: any = {}) {
    this.store.dispatch(new TrafficSourcesActions.FetchSspsRequestAction());
    this.api.getSspBasic()
      .subscribe((res: any) => {
        this.store.dispatch(new TrafficSourcesActions.FetchSspsSuccessAction({ssps: res}));
      }, (err: any) => {
        this.store.dispatch(new TrafficSourcesActions.FetchSspsFailureAction(err));
      });
  }


  createTrafficSource(trafficSource): any {
    this.store.dispatch(new TrafficSourcesActions.CreateTrafficSourceRequestAction());
    this.api.createTrafficSource(trafficSource)
      .subscribe((res: any) => {
        res.tag = !res.deletedAt ? res.status : 'deleted';
        this.store.dispatch(new TrafficSourcesActions.CreateTrafficSourceSuccessAction({editedTrafficSource: res}));
        this.sharedService.onHttpCompletionResolve(true, 'Success',  'Traffic source created',
          '/supply/traffic-sources', 'traffic-source-created');
      }, (err: any) => {
        this.store.dispatch(new TrafficSourcesActions.CreateTrafficSourceFailureAction(err));
        this.notification.error('Error', 'Form validation error: mandatory fields are required; short name should be unique');
      });
  }

  editTrafficSource(trafficSource: any, id: number | string): any {
    this.store.dispatch(new TrafficSourcesActions.EditTrafficSourceRequestAction());
    this.api.editTrafficSource(trafficSource, id)
      .subscribe((res: any) => {
        res.tag = !res.deletedAt ? res.status : 'DELETED';
        this.store.dispatch(new TrafficSourcesActions.EditTrafficSourceSuccessAction({editedTrafficSource: res}));
        this.sharedService.onHttpCompletionResolve(true, 'Success',  'Traffic source updated',
          '/supply/traffic-sources', 'traffic-source-updated');
      }, (err: any) => {
        this.store.dispatch(new TrafficSourcesActions.EditTrafficSourceFailureAction(err));
        this.notification.error('Error', 'Form validation error: mandatory fields are required; short name should be unique');
      });
  }

  deleteTrafficSource(id: number | string): any {
    this.store.dispatch(new TrafficSourcesActions.DeleteTrafficSourceRequestAction());
    this.api.deleteTrafficSource(id)
      .subscribe(() => {
        this.store.dispatch(new TrafficSourcesActions.DeleteTrafficSourceSuccessAction({deleteTrafficSourceId: id}));
      }, (err: any) => {
        this.store.dispatch(new TrafficSourcesActions.DeleteTrafficSourceFailureAction(err));
      });
  }

  getAll(): Observable<TrafficSource[]> {
    return this.api.getTrafficSources();
  }
}
