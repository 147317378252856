import {
  AppFilter,
  AppTable,
  AppTableColumn,
  PublisherType,
  WarningBoxConfig
} from '../../../../shared/_models/models';
import { Validators } from '@angular/forms';
import { environment } from '../../../../../environments/environment';

export const publishersPermissionsConfig = {
  create: 'supply.publisher.create',
  update: 'supply.publisher.update',
  delete: 'supply.publisher.delete',
};

export const publisherBizdevOwnerPermissionsConfig = {
  update: 'supply.publisher.bizdev.update',
};

export const publishersFiltersConfig: AppFilter[] = [
  new AppFilter('publishersStatusFilter', 'SELECT', 'status', 'Publisher Status', 'Select status',
    'multiple', true, false, null,
    [{id: 1, displayName: 'Active', value: 'ACTIVE', isDisabled: false},
      {id: 2, displayName: 'Inactive', value: 'INACTIVE', isDisabled: false},
      {id: 3, displayName: 'Deleted', value: 'DELETED', isDisabled: true}],
    [{id: 1, displayName: 'Active', value: 'ACTIVE', isDisabled: false},
      {id: 2, displayName: 'Inactive', value: 'INACTIVE', isDisabled: false}], null,
    null, null, null,
    [{id: 1, displayName: 'Active', value: 'ACTIVE', isDisabled: false},
      {id: 2, displayName: 'Inactive', value: 'INACTIVE', isDisabled: false}]),
  new AppFilter('publishersSearchFilter', 'SEARCH', '', 'Publisher ID/Name', 'Search by publisher id/name',
    'default', true, true,  {nameProp: 'publisherName', idProp: 'publisherId'}),
  new AppFilter('publishersTypeFilter', 'SELECT', 'publisherInfo.publisherAccountType', 'Publisher Type', 'Select publisher type',
    'multiple', true, false, null,
    [{id: 1, displayName: 'Self serve', value: 'SELF_SERVE'}, {id: 2, displayName: 'Managed', value: 'MANAGED'}], []),
  new AppFilter('publishersTrafficSourcesFilter', 'SELECT', 'publisherTrafficSources', 'Integration', 'Select integration',
    'multiple', true, false, null,
    [], []),
  new AppFilter('publishersOpsOwnersFilter', 'SELECT', 'publisherInfo.operationsOwner.id', 'Operations Owner', 'Select operations owner',
    'multiple', true, false, null,
    [], []),
];

export const publishersTableButtons = [
  // {id: 'publishersResetFiltersButton', type: 'LINK', title: 'Reset Filters', hasLoadingSpinner: false},
  // {id: 'publishersApplyFiltersButton', type: 'SECONDARY-BUTTON', title: 'Apply Filters', hasLoadingSpinner: true},
  {id: 'publishersCreateButton', type: 'PRIMARY-BUTTON', title: 'Create Publisher', hasLoadingSpinner: false, auth: 'canCreate'}
];
export const publishersTableConfig: AppTable = new AppTable('publishersTable', 1, 10, 'publisherName', 'asc', true, true);
export const publishersTableColumns: AppTableColumn[] = [
  {id: 'publishersStatus', prop: 'status', displayName: 'Status', type: 'toggle', actionId: 'publisherStatusToggle', isSortEnabled: false},
  {id: 'publishersId', prop: 'publisherId', displayName: 'ID', type: 'text', isSortEnabled: true},
  {
    id: 'publishersName', prop: 'publisherName', displayName: 'Name', type: 'commentTag', isSortEnabled: true,
    commentTag: {checkProp: 'deletedAt', content: 'Deleted', tooltip: 'Publisher is deleted'}
  },
  {id: 'publishersType', prop: 'publisherAccountType', sortingProp: 'publisherInfo.publisherAccountType', displayName: 'Type', type: 'titlecase-text', isSortEnabled: true},
  {
    id: 'publishersIntegrations', prop: 'publisherTrafficSources', displayName: 'Integrations', type: 'moreTag', isSortEnabled: false,
    moreTag: {displayNameProp: 'trafficSource.displayName', popoverTitle: 'More integrations'}
  },
  {
    id: 'publishersOperationsOwner',
    prop: 'operationsOwner.name',
    sortingProp: 'publisherInfo.operationsOwner.name',
    displayName: 'Operations Owner',
    type: '',
    isSortEnabled: true
  },
  {
    id: 'publishersActions', prop: 'actions', displayName: 'Actions', type: 'actions', isSortEnabled: false,
    actions: [
      {id: 'publisherEdit', name: 'edit', auth: 'canUpdate', displayName: 'Edit', hrefLink: '/update', hrefIdProp: 'publisherId'},
      {
        id: 'publisherDelete',
        name: 'delete',
        auth: 'canDelete',
        displayName: 'Delete',
        desc: 'Are you sure you wish to delete this publisher?'
      },
    ]
  }
];

export const publisherCogsTypes: PublisherType[] = [
  {id: 'publisherInfoCogsTypeDirect', value: 'DIRECT', label: 'Direct'},
  {id: 'publisherInfoCogsTypeNetwork', value: 'NETWORK', label: 'Network'},
  {id: 'publisherInfoCogsTypeSsp', value: 'SSP', label: 'SSP'},
  {id: 'publisherInfoCogsTypeOffPlatform', value: 'OFF_PLATFORM', label: 'Off Platform'},
  {id: 'publisherInfoCogsTypeIntercompany', value: 'INTERCOMPANY', label: 'Intercompany'},
  {id: 'publisherInfoCogsTypeTest', value: 'TEST', label: 'Test'},
  {id: 'publisherInfoCogsTypeUnknown', value: 'UNKNOWN', label: 'Unknown'}
];

export const publisherIntegrationTypes: PublisherType[] = [
  {id: 'publisherInfoIntegrationTypeVideoTag', value: 'VIDEO_TAG', label: 'Video Tag'},
  {id: 'publisherInfoIntegrationTypePrebidHeaderBidder', value: 'PREBID_HEADER_BIDDER', label: 'Prebid Header Bidder'},
  {id: 'publisherInfoIntegrationTypeGoogleExchangeBidding', value: 'GOOGLE_EXCHANGE_BIDDING', label: 'Google Exchange Bidding'},
  {id: 'publisherInfoIntegrationTypeAmazonTam', value: 'AMAZON_TAM', label: 'Amazon TAM'},
  {id: 'publisherInfoIntegrationTypeUnruly', value: 'UNRULY', label: 'Unruly', disabled: true},
  {id: 'publisherInfoIntegrationTypeOther', value: 'OTHER', label: 'Other'},
];

export const publisherDataUsageRightTypes: PublisherType[] = [
  {id: 'publisherDataUsageRightTypeFullyResricted', value: 'FULLY_RESTRICTED', label: 'Fully Restricted'},
  {id: 'publisherDataUsageRightTypePartiallyRestricted', value: 'PARTIALLY_RESTRICTED', label: 'Partially Restricted'},
  {id: 'publisherDataUsageRightTypeNoRestrictions', value: 'NO_RESTRICTIONS', label: 'No Restrictions'},
  {id: 'publisherDataUsageRightTypeNotDefined', value: 'NOT_DEFINED', label: 'Not Defined'},
];
export const formGroupParamsByPaymentMethod = {
  ACH: {
    bankNameEnc: { title: 'Bank Name', required: true },
    bankAbaRoutingEnc: { title: 'Bank ABA Routing Number', required: true },
    bankAccountBeneficiaryEnc: { title: 'Bank Account Beneficiary', required: true },
    beneficiaryNameEnc: { title: 'Beneficiary Name', required: true },
  },
  CHECK: {
    checkNameEnc: { title: 'Check Name' },
  },
  PAYPAL: {
    paypalEmailEnc: { title: 'Email Address', required: true, extraValidations: [Validators.email] },
  },
  WIRE: {
    bankAccountBeneficiaryEnc: { title: 'Bank Account Beneficiary', required: true },
    swiftEnc: { title: 'Swift Code', required: true },
    ibanEnc: { title: 'IBAN', required: true },
    intermediateRoutingEnc: { title: 'Intermediate Routing Number', required: false },
    wireBankNameEnc: { title: 'Bank Name (to wire to)', required: true },
    wireBankAccountNumberEnc: { title: 'Bank Account Number (to wire to)', required: true },
  },
  PAYONEER: {
    payoneerEmailEnc: { title: 'Registered Email', required: true, extraValidations: [Validators.email] },
  },
  NONE: {},
};

export const UPLOAD_FILE_URL = () => `${environment.javaApiUrl}/publisher`;
export const paymentWarningBox: WarningBoxConfig =
  new WarningBoxConfig('basic',
    'Please Note',
    'Changing these fields will require the publisher to go through the publisher approval process again.');
export const updatePublisherWarningBox: WarningBoxConfig =
  new WarningBoxConfig('extended', 'Payment hold notes');
