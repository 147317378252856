import {Injectable} from '@angular/core';
import {CommonDataModel, JapiQuery, UserWithRoles} from '../../../../shared/_models/models';
import {Observable} from 'rxjs';
import {ApiService} from '../../../../shared/_services/api.service';

@Injectable({providedIn: 'root'})

export class UserService {

  constructor(
    private api: ApiService,
  ) { }

  getAll(query: JapiQuery): Observable<CommonDataModel<UserWithRoles[]>> {
    return this.api.getUsersWithRoles(query);
  }

  getById(id: number | string): Observable<CommonDataModel<UserWithRoles[]>> {
    const query: JapiQuery = {
      filter: {
        filters: [{
          fieldName: 'type', operation: 'EQUALS', value: 'INTERNAL',
        }, {
          fieldName: 'id', operation: 'EQUALS', value: id
        }],
        withTrashed: true
      }
    };
    return this.api.getUsersWithRoles(query);
  }

  create(user: UserWithRoles): any {
    user.user.type = 'INTERNAL';
    return this.api.createUserWithRoles(user);
  }

  update(user: UserWithRoles, id: number | string): any {
    return this.api.updateUserWithRoles(user, id);
  }

  delete(id: number): any {
    return this.api.deleteUserWithRoles(id);
  }

}
