import {
  CREATE_PLACEMENT_FAILURE,
  CREATE_PLACEMENT_REQUEST,
  CREATE_PLACEMENT_SUCCESS,
  EDIT_PLACEMENT_FAILURE,
  EDIT_PLACEMENT_REQUEST,
  EDIT_PLACEMENT_SUCCESS,
  FETCH_CONTENT_GENRE_FAILURE,
  FETCH_CONTENT_GENRE_REQUEST,
  FETCH_CONTENT_GENRE_SUCCESS,
  FETCH_IAB_BANNER_AD_TYPES_FAILURE,
  FETCH_IAB_BANNER_AD_TYPES_REQUEST,
  FETCH_IAB_BANNER_AD_TYPES_SUCCESS,
  FETCH_IAB_CATEGORIES_FAILURE,
  FETCH_IAB_CATEGORIES_REQUEST,
  FETCH_IAB_CATEGORIES_SUCCESS,
  FETCH_IAB_CREATIVE_ATTRIBUTES_FAILURE,
  FETCH_IAB_CREATIVE_ATTRIBUTES_REQUEST,
  FETCH_IAB_CREATIVE_ATTRIBUTES_SUCCESS,
  FETCH_DSP_ACCOUNTS_REQUEST,
  FETCH_DSP_ACCOUNTS_SUCCESS,
  FETCH_DSP_ACCOUNTS_FAILURE,
  FETCH_PLACEMENT_BY_ID_FAILURE,
  FETCH_PLACEMENT_BY_ID_REQUEST,
  FETCH_PLACEMENT_BY_ID_SUCCESS,
  FETCH_PLACEMENTS_FAILURE,
  FETCH_PLACEMENTS_REQUEST,
  FETCH_PLACEMENTS_SUCCESS,
  FETCH_PUB_TS_FAILURE,
  FETCH_PUB_TS_REQUEST,
  FETCH_PUB_TS_SUCCESS,
  PlacementsActions,
  RESET_PLACEMENT_STATE,
  SET_DOMAIN_BUNDLES_DUPLICATE_STATUS,
  UPDATE_PLACEMENTS_FILTERS,
  UPDATE_PLACEMENTS_TABLE
} from './placements.actions';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import _, { cloneDeep } from 'lodash';

import { AppFilter, AppTable, CommonDataModel, Floor, Placement } from '../../../shared/_models/models';
import { placementsFiltersConfig, placementsTableConfig } from '../_services/placements.config';

export interface PlacementsState {
  placementsFilters: AppFilter[];
  placementsTable: AppTable;
  contentGenres: CommonDataModel;
  placements: CommonDataModel;
  currentPlacement: { placement: Placement, isLoading: boolean, isUpdateFinished: boolean, isCreateFinished: boolean };
  iabCategories: CommonDataModel;
  iabBannerAdTypes: CommonDataModel;
  iabCreativeAttributes: CommonDataModel;
  dspAccounts: CommonDataModel;
  currentPlacementGeoFloors: CommonDataModel;
  pubTs: CommonDataModel;
  isDomainBundlesDuplicateFinished: boolean;
  createdPlacementFloor: Floor;
}

const initialState: PlacementsState = {
  placements: new CommonDataModel(),
  placementsTable: cloneDeep(placementsTableConfig),
  placementsFilters: cloneDeep(placementsFiltersConfig),
  currentPlacement: {placement: null, isLoading: false, isUpdateFinished: false, isCreateFinished: false},
  iabCategories: new CommonDataModel(),
  contentGenres: new CommonDataModel(),
  iabBannerAdTypes: new CommonDataModel(),
  iabCreativeAttributes: new CommonDataModel(),
  dspAccounts: new CommonDataModel(),
  currentPlacementGeoFloors: new CommonDataModel(),
  pubTs: new CommonDataModel(),
  isDomainBundlesDuplicateFinished: true,
  createdPlacementFloor: null,
};

export function placementsReducer(state = initialState, action: PlacementsActions) {
  switch (action.type) {
    case UPDATE_PLACEMENTS_FILTERS:
      return {...state, placementsFilters: action.payload.placementsFilters};
    case UPDATE_PLACEMENTS_TABLE:
      return {...state, placementsTable: action.payload.placementsTable};
    case FETCH_PLACEMENTS_REQUEST:
      return {...state, placements: {...state.placements, isLoading: true, httpErr: null}};
    case FETCH_PLACEMENTS_SUCCESS:
      return {
        ...state,
        placements: {
          ...state.placements,
          data: action.payload.placements.data,
          totalElements: action.payload.placements.totalElements,
          isLoading: false,
          httpErr: null
        }
      };
    case FETCH_PLACEMENTS_FAILURE:
      return {
        ...state,
        placements:
          {...state.placements, data: null, totalElements: 0, isLoading: false, httpErr: action.payload.error}
      };
    case FETCH_PLACEMENT_BY_ID_REQUEST:
      return {...state, currentPlacement: {...state.currentPlacement, isLoading: true}};
    case FETCH_PLACEMENT_BY_ID_SUCCESS:
      return {...state, currentPlacement: {...state.currentPlacement, placement: action.payload.currentPlacement, isLoading: false}};
    case FETCH_PLACEMENT_BY_ID_FAILURE:
      return {...state, currentPlacement: {...state.currentPlacement, isLoading: false}, error: action.payload.error};
    case CREATE_PLACEMENT_REQUEST:
      return {...state, currentPlacement: {...state.currentPlacement, isLoading: true}};
    case CREATE_PLACEMENT_SUCCESS:
      if (!state.placements.data) {
        state = {...state, placements: {...state.placements, data: [action.payload.currentPlacement]}};
      } else {
        state = {...state, placements: {...state.placements, data: [...state.placements.data, action.payload.currentPlacement]}};
      }
      return {
        ...state,
        placements: {...state.placements, data: [...state.placements.data.slice()]},
        currentPlacement: {placement: action.payload.currentPlacement, isLoading: false, isCreateFinished: true, isUpdateFinished: false},
      };
    case CREATE_PLACEMENT_FAILURE:
      return {...state, currentPlacement: {...state.currentPlacement, isLoading: false}, error: action.payload.error};
    case EDIT_PLACEMENT_REQUEST:
      return {...state, currentPlacement: {...state.currentPlacement, isLoading: true}};
    case EDIT_PLACEMENT_SUCCESS:
      if (!state.placements.data) {
        state = {...state, placements: {...state.placements, data: [action.payload.currentPlacement]}};
      }
      const placementsIndexToUpdate = _.findIndex(state.placements.data, {placementId: action.payload.currentPlacement.placementId});
      const updatedData = [...state.placements.data.slice(0, placementsIndexToUpdate),
        {...action.payload.currentPlacement},
        ...state.placements.data.slice(placementsIndexToUpdate + 1)];
      return {
        ...state,
        currentPlacement: {placement: action.payload.currentPlacement, isLoading: false, isCreateFinished: true, isUpdateFinished: true},
        placements: {...state.placements, data: updatedData},
      };
    case EDIT_PLACEMENT_FAILURE:
      return {
        ...state, currentPlacement: {...state.currentPlacement, isLoading: false},
        error: action.payload.error
      };
    case FETCH_IAB_CATEGORIES_REQUEST:
      return {...state, iabCategories: {...state.iabCategories, isLoading: true, httpErr: null}};
    case FETCH_IAB_CATEGORIES_SUCCESS:
      return {
        ...state,
        iabCategories: {
          ...state.iabCategories,
          data: action.payload.iabCategories,
          totalElements: action.payload.iabCategories.length, // totalElements,
          isLoading: false,
          httpErr: null
        },
      };
    case FETCH_IAB_CATEGORIES_FAILURE:
      return {
        ...state,
        iabCategories:
          {...state.iabCategories, data: null, totalElements: 0, isLoading: false, httpErr: action.payload.error}
      };
    case FETCH_IAB_BANNER_AD_TYPES_REQUEST:
      return {...state, iabBannerAdTypes: {...state.iabBannerAdTypes, isLoading: true, httpErr: null}};
    case FETCH_IAB_BANNER_AD_TYPES_SUCCESS:
      return {
        ...state,
        iabBannerAdTypes: {
          ...state.iabBannerAdTypes,
          data: action.payload.iabBannerAdTypes.data,
          totalElements: action.payload.iabBannerAdTypes.data.length, // totalElements,
          isLoading: false,
          httpErr: null
        },
      };
    case FETCH_IAB_BANNER_AD_TYPES_FAILURE:
      return {
        ...state,
        iabBannerAdTypes:
          {...state.iabBannerAdTypes, data: null, totalElements: 0, isLoading: false, httpErr: action.payload.error}
      };
    case FETCH_IAB_CREATIVE_ATTRIBUTES_REQUEST:
      return {...state, iabCreativeAttributes: {...state.iabCreativeAttributes, isLoading: true, httpErr: null}};
    case FETCH_IAB_CREATIVE_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        iabCreativeAttributes: {
          ...state.iabCreativeAttributes,
          data: action.payload.iabCreativeAttributes.data,
          totalElements: action.payload.iabCreativeAttributes.data.length, // totalElements,
          isLoading: false,
          httpErr: null
        },
      };
    case FETCH_IAB_CREATIVE_ATTRIBUTES_FAILURE:
      return {
        ...state,
        iabCreativeAttributes:
          {...state.iabCreativeAttributes, data: null, totalElements: 0, isLoading: false, httpErr: action.payload.error}
      };


    case FETCH_DSP_ACCOUNTS_REQUEST:
      return {...state, dspAccounts: {...state.dspAccounts, isLoading: true, httpErr: null}};
    case FETCH_DSP_ACCOUNTS_SUCCESS:
      return {
        ...state,
        dspAccounts: {
          ...state.dspAccounts,
          data: action.payload.dspAccounts.data,
          totalElements: action.payload.dspAccounts.data.length, // totalElements,
          isLoading: false,
          httpErr: null
        },
      };
    case FETCH_DSP_ACCOUNTS_FAILURE:
      return {
        ...state,
        dspAccounts:
          {...state.dspAccounts, data: null, totalElements: 0, isLoading: false, httpErr: action.payload.error}
      };

    case FETCH_PUB_TS_REQUEST:
      return {...state, pubTs: {...state.pubTs, isLoading: true, httpErr: null}};
    case FETCH_PUB_TS_SUCCESS:
      return {
        ...state,
        pubTs: {
          ...state.pubTs,
          data: action.payload.pubTs.data,
          totalElements: action.payload.pubTs.data.length,
          isLoading: false,
          httpErr: null
        },
      };
    case FETCH_PUB_TS_FAILURE:
      return {
        ...state,
        pubTs:
          {...state.pubTs, data: null, totalElements: 0, isLoading: false, httpErr: action.payload.error}
      };
    case RESET_PLACEMENT_STATE:
      return {
        ...state,
        currentPlacement: null,
        createdPlacementFloor: null,
        successRequests: 0,
        errorRequests: 0,
        currentPlacementGeoFloors: {data: null, totalElements: 0, isPaginated: true, isLoading: false, httpErr: null},
        pubTs: new CommonDataModel()
      };
    case FETCH_CONTENT_GENRE_SUCCESS:
      return {
        ...state,
        contentGenres: {
          ...state.contentGenres,
          data: action.payload.contentGenres,
          totalElements: action.payload.contentGenres.length,
          isLoading: false,
          httpErr: null
        },
      };
    case FETCH_CONTENT_GENRE_FAILURE:
      return {
        ...state,
        contentGenres:
          {...state.contentGenres, data: null, totalElements: 0, isLoading: false, httpErr: action.payload.error}
      };
    case FETCH_CONTENT_GENRE_REQUEST:
      return {...state, contentGenres: {...state.contentGenres, isLoading: true, httpErr: null}};
    default:
      return state;
  }
}

export const getPlacementsState = createFeatureSelector<PlacementsState>('placements');
export const getPlacements = createSelector(getPlacementsState, (state: PlacementsState) => state.placements);
export const getPlacementsTable = createSelector(getPlacementsState, (state: PlacementsState) => state.placementsTable);
export const getPlacementsFilters = createSelector(getPlacementsState, (state: PlacementsState) => state.placementsFilters);
export const getCurrentPlacement = createSelector(getPlacementsState, (state: PlacementsState) => state.currentPlacement);
export const getCreatedPlacementFloor = createSelector(getPlacementsState, (state: PlacementsState) => state.createdPlacementFloor);
export const getIabCategories = createSelector(getPlacementsState, (state: PlacementsState) => state.iabCategories.data);
export const getContentGenres = createSelector(getPlacementsState, (state: PlacementsState) => state.contentGenres.data);
export const getIabBannerAdTypes = createSelector(getPlacementsState, (state: PlacementsState) => state.iabBannerAdTypes.data);
export const getIabCreativeAttributes = createSelector(getPlacementsState, (state: PlacementsState) => state.iabCreativeAttributes.data);
export const getDspAccounts = createSelector(getPlacementsState, (state: PlacementsState) => state.dspAccounts.data);
export const getPubTs = createSelector(getPlacementsState, (state: PlacementsState) => state.pubTs);
export const getIsDomainBundlesDuplicateFinished =
  createSelector(getPlacementsState, (state: PlacementsState) => state.isDomainBundlesDuplicateFinished);
