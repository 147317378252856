import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AppTable, AppTableColumn, BillPayment } from '../../../_models/models';
import { billsTable, billsTableColumns } from './bill-payment-info.config';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-bill-payment-info',
  templateUrl: './bill-payment-info.component.html',
  styleUrls: ['./bill-payment-info.component.less']
})
export class BillPaymentInfoComponent implements  OnChanges {
  @Input()
  billPayment: BillPayment;

  billsTable: AppTable = {...billsTable};
  billsTableColumns: AppTableColumn[] = cloneDeep(billsTableColumns);

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.billPayment) {
      this.billsTableColumns.find(column => column.type === 'currency').currency = this.billPayment.currency;
      if (this.billPayment.currency === 'JPY') {
        const exchangeRateColumn: AppTableColumn = {
          id: 'billExchangeRate', prop: 'exchangeRate', displayName: 'Exchange Rate', type: 'text', isSortEnabled: false
        };
        this.billsTableColumns.push(exchangeRateColumn);
      }
    }
  }
}
