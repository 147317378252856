import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {catchError, filter, finalize, switchMap, take} from 'rxjs/operators';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { EventService, AuthenticationService, VersionCheckService } from '../../_services';
import { debounce } from 'lodash';
import { environment } from '../../../environments/environment';
import {KeycloakToken} from '../_models/models';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private debouncedFunction = {
    unknown: null,
    unauthenticated: null
  };

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private auth: AuthenticationService,
    private notification: NzNotificationService,
    private eventService: EventService,
    private vcs: VersionCheckService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(catchError(err => {
      if (request.url.startsWith(`${environment.authServer}`)) {
        return throwError(err);
      }
      if (
        (request.url.startsWith(`${environment.javaApiUrl}`) || request.url.startsWith(`${environment.javaInsightsUrl}`)
          || request.url.startsWith(`${environment.exchangeDealsUrl}`))
          && err.status === 401 && this.auth.currentUserValue
      ) {
        this.auth.doLogout(true);
        return of<HttpEvent<any>>();
      }
      if (request.headers.get('validate-only')) {
        return next.handle(request);
      }
      const response = err.error;

      if (err.status === 500) {
        const isHideMessage = !!request.headers.get('no-error-message');
        const isShowErrorNotification = !request.headers.get('no-error-notification');
        if (isHideMessage) {
          console.clear();
        }
        const errTitle = err.statusText ? (err.statusText === 'OK' ? 'Unexpected error' : err.statusText) : 'Unexpected error';
        const errDetails = response.data && response.data[0].error ? response.data[0].error : 'Server error occurred';
        if (isShowErrorNotification) {
          this.notification.error(errTitle, errDetails);
        }
        this.vcs.refreshPageIfNeeded();
      }

      if (err.status === 0) {
        const isHideMessage = !!request.headers.get('no-error-message');
        const isShowErrorNotification = !request.headers.get('no-error-notification');
        if (isHideMessage) {
          console.clear();
        }
        if (isShowErrorNotification) {
          this.debouncedError('unknown', 'Something went wrong', 'Connection error occurred.', 1000, isHideMessage);
        }
        throwError(response);

        if (request.headers.get('no-retry-request')) {
          return of<HttpEvent<any>>();
        } else if (request.headers.get('no-retry-request-with-error')) {
          return throwError(response);
        } else {
          return next.handle(request);
        }
      }

      // Error cases:
      if (response && response.data && response.data[0] && response.data[0].error) {

        if (response.data[0].error == 'Validation error') {
          const errorList = response.data[0].error_list;
          this.eventService.emitEvent('validation-failed', errorList);
          let html_errors_output = '';

          html_errors_output += '<ul>';
          for (const fieldError in errorList) {
            if (errorList[fieldError]) {
              html_errors_output += '<li>' + errorList[fieldError][0] + '</li>';
            }
          }
          html_errors_output += '</ul>';

          this.notification.error(response.data[0].error, html_errors_output);
        } else if (response.data[0].error == 'Error') {
          this.notification.error(response.data[0].error, response.data[0].message);
        } else if (response.data[0].message == 'Unauthenticated.') {
          this.debouncedError('unauthenticated', response.data[0].error, response.data[0].message, 1000);
        } else {
          this.notification.error(response.data[0].error, response.data[0].message);
        }

      }

      return next.handle(request);
    }));
  }

  debouncedError(id, title, message, timeout, isHideMessage = true) {
    if (this.debouncedFunction[id]) {
      this.debouncedFunction[id].cancel();
    }
    if (!isHideMessage) {
      this.debouncedFunction[id] = debounce(() => {
        this.notification.error(title, message);
      }, timeout);
      this.debouncedFunction[id]();
    }
  }

}
