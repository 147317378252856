<ng-template [ngIf]="type === 'Password'">

  <ng-container *ngIf="fc.password?.errors.required && fc.password?.touched">
    Please enter a password
  </ng-container>

  <ng-container *ngIf="fc.password?.errors && !fc.password?.errors.required && fc.password?.touched">
    The password should contain: <br/>
  </ng-container>

  <ng-container *ngIf="fc.password?.errors.shortPassword && fc.password?.touched">
    At least 8 characters <br/>
  </ng-container>

  <ng-container *ngIf="fc.password?.errors.longPassword && fc.password?.touched">
    No more than 20 characters <br/>
  </ng-container>

  <ng-container *ngIf="fc.password?.errors.upperCase && fc.password?.touched">
    One uppercase letter <br/>
  </ng-container>

  <ng-container *ngIf="fc.password?.errors.lowerCase && fc.password?.touched">
    One lowercase letter <br/>
  </ng-container>

  <ng-container *ngIf="fc.password?.errors.number && fc.password?.touched">
    One number <br/>
  </ng-container>

  <ng-container *ngIf="fc.password?.errors.specialCharacter && fc.password?.touched">
    One special character <br/>
  </ng-container>

</ng-template>


<ng-template [ngIf]="type === 'PasswordConfirmation'">
    <div *ngIf="form.get('passwordConfirmation').dirty && form.get('passwordConfirmation')?.hasError('required')" class="mimic-zorro-error-tip">
      Password confirmation is required
    </div>

    <div *ngIf="form.get('passwordConfirmation').dirty && !form.get('passwordConfirmation')?.hasError('required')
           && form?.hasError('notSamePassword')" class="mimic-zorro-error-tip">
      Passwords do not match
  </div>
</ng-template>

<ng-template [ngIf]="type === 'OldPassword'">
  <div *ngIf="form.get('oldPassword').dirty && form.get('oldPassword')?.hasError('required')" class="mimic-zorro-error-tip">
    Old password is required
  </div>

  <div *ngIf="form.get('oldPassword').dirty && !form.get('oldPassword')?.hasError('required')
           && form?.hasError('samePassword')" class="mimic-zorro-error-tip">
    Old password and new password are identical
  </div>
</ng-template>
