import { Pipe, PipeTransform } from '@angular/core';
import { AppFilter, UpdateAppFiltersRenderer } from '../_models/models';

@Pipe({
  name: 'excludeSelectOption'
})
export class ExcludeSelectOptionPipe implements PipeTransform {

  transform(appFilters: AppFilter[], ignored: UpdateAppFiltersRenderer[]): AppFilter[] {
    if (ignored?.length > 0 && appFilters?.length > 0) {
      ignored.forEach(ignore => {
        if (appFilters && ignore.filterId && ignore.selectOptionId) {
          const findAppFilter = appFilters.find(a => a.id === ignore.filterId);
          if (findAppFilter) {
            const findOption = findAppFilter.possibleValues.find(option => option.id === ignore.selectOptionId);
            findOption.hide = ignore.hideCondition;
          }
        }
      });
    }
    return appFilters;
  }
}
