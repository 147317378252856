import { Injectable } from '@angular/core';
import { CurrentUser } from '../_models/models';
import { environment } from '../../../environments/environment';

declare const pendo: any;

interface PendoOptions {
  visitor: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    type: string;
    roles: string[];
  };
  account?: {
    id: number;
    type: 'partner' | 'publisher';
  };
}

type PendoServiceAction = 'init' | 'setOptions';

@Injectable({
  providedIn: 'root'
})
export class PendoService {

  private PENDO_API_KEY = 'e0d14bb5-6269-4ed0-4aa8-22a152e6f55f';
  private usePendo = environment.pendo;

  onPendoAction(action: PendoServiceAction, payload?: CurrentUser | any): void {
    if (this.usePendo) {
      switch (action) {
        case 'init':
          this.initPendo(this.PENDO_API_KEY);
          break;
        case 'setOptions':
          this.setPendoOptions(payload);
          break;
        default:
          break;
      }
    }
  }

  private setAccount(currentUser?: CurrentUser): { id: number; type: 'partner' | 'publisher' } {
    if (currentUser?.partnerId) {
      return {id: currentUser.partnerId, type: 'partner'};
    } else if (currentUser?.publisherId) {
      return {id: currentUser.publisherId, type: 'publisher'};
    } else {
      return {id: null, type: null};
    }
  }

  private setPendoOptions(currentUser?: CurrentUser): void {
    const options: PendoOptions = {
      visitor: {
        id: currentUser?.email || 'ctrl-no-user',
        email: currentUser?.email || null,
        firstName: currentUser?.firstName || null,
        lastName: currentUser?.lastName || null,
        type: currentUser?.type || null,
        roles: currentUser?.roles || null,
      }
    };
    options.account = this.setAccount(currentUser);
    if (pendo.hasOwnProperty('isReady') && pendo.isReady()) {
      pendo.updateOptions(options);
    } else {
      pendo.initialize(options);
    }
  }

  private initPendo(apiKey: string): void {
    (function (p, e, n, d, o) {
      let v, w, x, y, z;
      o = p[d] = p[d] || {};
      o._q = o._q || [];
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
      for (w = 0, x = v.length; w < x; ++w) {
        (function (m) {
          o[m] = o[m] || function () {
            o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
          };
        })(v[w]);
      }
      y = e.createElement(n);
      y.async = !0;
      y.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })(window, document, 'script', 'pendo');
    this.onPendoAction('setOptions', {});
  }


}
