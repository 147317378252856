<form nz-form [formGroup]="addPublisherForm" [nzLayout]="'horizontal'">
  <nz-form-item>
    <nz-form-label>Publisher</nz-form-label>
    <nz-form-control [nzErrorTip]="addPublisherForm?.controls?.publisherId.errors?.required ? 'You must select a publisher' : 'Publisher already selected'">
      <nz-select
                 [nzPlaceHolder]="'Select a publisher'"
                 formControlName="publisherId"
                 nzAllowClear
                 nzShowSearch
                 nzServerSearch
                 nzShowArrow="false"
                 (nzOnSearch)="onSearch($event)"
                 (ngModelChange)="onPublisherSelect($event)"
      >
        <ng-container *ngFor="let option of publishers">
          <nz-option
            *ngIf="!isLoading"
            [nzLabel]="option.publisherName + ' (id: ' + option.publisherId + ')'"
            [nzValue]="option.publisherId"  >
          </nz-option>
        </ng-container>
        <nz-option *ngIf="isLoading" nzDisabled nzCustomContent>
          <em nz-icon nzType="loading" class="loading-icon"></em>
          Loading Publishers...
        </nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
</form>
