import {ActivatedRouteSnapshot, BaseRouteReuseStrategy} from '@angular/router';

export class CustomRouteReuseStrategy extends BaseRouteReuseStrategy {
  public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    // Add reloadOnSameUrlNavigation(=true) to the data object of a route config to force reload the route's components
    if (future.data?.reloadOnSameUrlNavigation) { // TODO: support reload when specifically requested in router.navigate
      return false;
    }
    return future.routeConfig === curr.routeConfig;
  }
}
