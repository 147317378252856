import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {first} from 'rxjs/operators';

import {Store} from '@ngrx/store';
import * as fromAdServerTech from '../_store/adServerTech.reducer';
import * as AdServerTechActions from '../_store/adServerTech.actions';

import {ApiService} from '../../../../shared/_services/api.service';
import {FormHelper} from '../../../../shared/_common/form.helper';
import {SharedService} from '../../../../shared/_services/shared.service';
import {NzNotificationService} from 'ng-zorro-antd/notification';

@Injectable({providedIn: 'root'})

export class SupplyAdServerTechService {

  constructor(
    private http: HttpClient,
    private fh: FormHelper,
    private store: Store<fromAdServerTech.AdServerTechState>,
    private api: ApiService,
    private sharedService: SharedService,
    private notification: NzNotificationService
  ) {
  }

  fetchAdServerTech() {
    this.store.dispatch(new AdServerTechActions.FetchAdServerTechRequestAction());
    this.api.getAdServerTechList()
      .subscribe((res: any) => {
          this.store.dispatch(new AdServerTechActions.FetchAdServerTechSuccessAction({adServerTech: res}));
        },
        (err: any) => {
          this.store.dispatch(new AdServerTechActions.FetchAdServerTechFailureAction(err));
        });
  }

  fetchTags() {
    this.store.dispatch(new AdServerTechActions.FetchTagParamsRequestAction());
    this.api.getTagParamsList().pipe(first()).subscribe((res: any) => {
        this.store.dispatch(new AdServerTechActions.FetchTagParamsSuccessAction({tagParams: res}));
      },
      (err: any) => {
        this.store.dispatch(new AdServerTechActions.FetchTagParamsFailureAction(err));
      });
  }

  fetchAdServerTechById(id: number | string) {
    this.store.dispatch(new AdServerTechActions.FetchAdServerTechByIdRequestAction());
    this.api.getAdServerTechById(id)
      .pipe(first())
      .subscribe((res: any) => {
        this.store.dispatch(new AdServerTechActions.FetchAdServerTechByIdSuccessAction({editedAdServerTech: res}));
      }, (err: any) => {
        this.store.dispatch(new AdServerTechActions.FetchAdServerTechByIdFailureAction(err));
      });
  }

  createAdServerTech(adServerTech: any) {
    this.store.dispatch(new AdServerTechActions.CreateAdServerTechRequestAction());
    this.api.createAdServerTech(adServerTech)
      .pipe(first())
      .subscribe((res: any) => {
        this.store.dispatch(new AdServerTechActions.CreateAdServerTechSuccessAction({editedAdServerTech: res}));
        this.sharedService.onHttpCompletionResolve(true, 'Success', 'Ad server tech created',
          'adServerTech/', 'ad-server-tech-created');
      }, (err: any) => {
        this.store.dispatch(new AdServerTechActions.CreateAdServerTechFailureAction(err));
        this.notification.error('Error', 'Form validation error: mandatory fields are required; names should be unique');
      });
  }

  editAdServerTech(adServerTech: any, id: number | string): any {
    this.store.dispatch(new AdServerTechActions.CreateAdServerTechRequestAction());
    this.api.updateAdServerTech(adServerTech, id)
      .subscribe((res: any) => {
        this.store.dispatch(new AdServerTechActions.EditAdServerTechSuccessAction({editedAdServerTech: res}));
        this.sharedService.onHttpCompletionResolve(true, 'Success', 'Ad server tech updated',
          'adServerTech', 'ad-server-tech-updated');
      }, (err: any) => {
        this.store.dispatch(new AdServerTechActions.EditAdServerTechFailureAction(err));
        this.notification.error('Error', 'Form validation error: mandatory fields are required; names should be unique');
      });
  }


  deleteAdServerTech(id: number | string): any {
    this.store.dispatch(new AdServerTechActions.DeleteAdServerTechRequestAction());
    this.api.deleteAdServerTechById(id)
      .subscribe(() => {
        this.store.dispatch(new AdServerTechActions.DeleteAdServerTechSuccessAction({deleteAdServerTechId: id}));
      }, (err: any) => {
        this.store.dispatch(new AdServerTechActions.DeleteAdServerTechFailureAction(err));
      });
  }
}
