<ng-container>
  <p class="modal-title">Please confirm</p>
  <p *ngIf="commissionsValueWithWarning; else trafficSource">Are you sure Fixed CPM commission value should be
    <strong>{{commissionsValueWithWarning}}</strong>?</p>
  <ng-template #trafficSource>
    <ng-container *ngIf="(trafficSourcesWithWarning | keyvalue) as tsWarning">
      <ng-container *ngIf="tsWarning?.length === 1">
        Are you sure Fixed CPM commission value for traffic source <strong>{{tsWarning[0].key}}</strong> should be
        <strong>{{tsWarning[0].value}}</strong>?
      </ng-container>
      <ng-container *ngIf="tsWarning?.length > 1">
        Please confirm Fixed CPM commission values for the following traffic sources:
        <ul>
          <li *ngFor="let ts of tsWarning">{{ ts.key }}: {{ts.value}}.</li>
        </ul>
      </ng-container>
    </ng-container>
  </ng-template>
</ng-container>
