import {Component, Input, OnInit} from '@angular/core';
import {CtrlUser} from '../../_models/models';
import {UntypedFormGroup} from '@angular/forms';
import {ClipboardService} from 'ngx-clipboard';
import {Common} from '../../_common/common.helper';

@Component({
  selector: 'app-user-form-password',
  templateUrl: './user-form-password.component.html',
  styleUrls: ['./user-form-password.component.less']
})
export class UserFormPasswordComponent implements OnInit {
  showChangePassword = false;
  isPasswordVisible = false;
  isConfPasswordVisible = false;
  isCopied_password = false;
  isCopied_passwordConfirmation = false;

  @Input() updateOtherUserMode = true;
  @Input() userInput: CtrlUser = null;
  @Input() isUpdating = false;
  @Input() form: UntypedFormGroup;

  constructor(
    private clipboardService: ClipboardService,
    private common: Common
  ) {
  }

  ngOnInit(): void {
    if (!this.isUpdating) {
      this.showChangePassword = true;
      if (this.userInput?.type === 'API') {
        this.generatePassword();
      }
    }
  }

  togglePasswordChange(changeTo?: boolean) {
    if (changeTo !== undefined) {
      this.showChangePassword = changeTo;
    } else {
      this.showChangePassword = !this.showChangePassword;
    }
    if (!this.showChangePassword) {
      this.isPasswordVisible = false;
      this.isConfPasswordVisible = false;
      this.form.get('password').patchValue(null);
      this.form.get('password').markAsPristine();
      this.form.get('passwordConfirmation').patchValue(null);
      this.form.get('passwordConfirmation').markAsPristine();
      this.form.updateValueAndValidity();
    }
  }

  generatePassword() {
    const newPass = this.common.generatePassword();
    this.isPasswordVisible = true;
    this.isConfPasswordVisible = true;
    this.form.get('password').patchValue(newPass);
    this.form.get('password').markAsDirty();
    this.form.get('passwordConfirmation').patchValue(newPass);
    this.form.get('passwordConfirmation').markAsDirty();
    this.form.updateValueAndValidity();
  }

  copyToClipboard(key) {
    this.clipboardService.copy(this.form.get(key).value);
    this[`isCopied_${key}`] = true;
    setTimeout(() => {
      this[`isCopied_${key}`] = false;
    }, 1500);
  }

}
