import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root',
})
export class VersionCheckService {
  // this will be replaced by actual hash post-build.js
  isRefreshNeeded: Observable<boolean>;

  private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
  private interval;
  private versionJsonUrl;
  private latestTimestamp = null;
  private isRefreshNeededSubject: BehaviorSubject<boolean>;

  constructor(
    private http: HttpClient,
    private notification: NzNotificationService,
  ) {
    this.isRefreshNeededSubject = new BehaviorSubject<boolean>(false);
    this.isRefreshNeeded = this.isRefreshNeededSubject.asObservable();
  }

  public get isRefreshNeededValue(): boolean {
    return this.isRefreshNeededSubject.value;
  }

  /**
   * Checks in every set frequency the version of frontend application
   * @param url
   * @param frequency - in milliseconds, defaults to 30 minutes
   */
  initVersionCheck(url, frequency = 1000 * 60 * 30) {
    this.versionJsonUrl = url;
    this.checkVersion();
    this.interval = setInterval(() => {
      this.checkVersion();
    }, frequency);
  }

  /**
   * Will do the call and check if the hash has changed or not
   * @param url
   */
  checkVersion(refreshIfHashChanged = false) {
    if (!this.versionJsonUrl) {
      return;
    }
    // timestamp these requests to invalidate caches
    this.http
      .get(this.versionJsonUrl + '?t=' + new Date().getTime())
      .pipe(first())
      .subscribe(
        (response: any) => {
          const hash = response.hash;
          // checking if this version.json is the new format which contains it creation timestamp (if not, we proceed as usual)
          const newTs = response.timestamp;
          if (newTs) {
            // if for some reason the version.json file was replaced mysteriously by Akamai to an old version.json (it happened),
            // we ignore it.
            if (this.latestTimestamp !== null && this.latestTimestamp > newTs) {
              return;
            }
            this.latestTimestamp = newTs;
          }
          const hashChanged = this.hasHashChanged(this.currentHash, hash);
          // If new version, do something
          if (hashChanged) {
            // ENTER YOUR CODE TO DO SOMETHING UPON VERSION CHANGE
            // for an example: location.reload();
            this.isRefreshNeededSubject.next(true);
            clearInterval(this.interval);
            if (refreshIfHashChanged) {
              this.refreshPageIfNeeded();
            } else {
              this.showUpdateNotification();
            }
          }
          // store the new hash, so we wouldn't trigger versionChange again
          // only necessary in case you did not force refresh
          this.currentHash = hash;
        },
        (err) => {
          console.error(err, 'Could not get version');
        }
      );
  }

  refreshPageIfNeeded() {
    if (this.isRefreshNeededValue) {
      location.reload();
    }
  }

  showUpdateNotification() {
    if (this.isRefreshNeededValue) {
      setTimeout(() => {
        this.notification.info('Update available', 'Please refresh this page', { nzDuration: 0 });
      }, 1000);
    }
  }

  /**
   * Checks if hash has changed.
   * This file has the JS hash, if it is a different one than in the version.json
   * we are dealing with version change
   * @param currentHash
   * @param newHash
   * @returns {boolean}
   */
  private hasHashChanged(currentHash, newHash) {
    if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
      return false;
    }
    return currentHash !== newHash;
  }
}
