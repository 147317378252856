import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, startWith } from 'rxjs/operators';

@Directive({
  selector: '[appNzMaxTagsByLength]'
})
export class NzMaxTagsByLengthDirective implements AfterViewInit {

  private replacements: Map<string, number> = new Map();
  @Input() appNzMaxTagsByLength: UntypedFormControl | AbstractControl;

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  cancelPreviousDisplayNone(item: HTMLElement): void {
    if (item.style.display === 'none') {
      this.renderer.setStyle(item, 'display', '');
    }
  }

  customizeValues(selectedValues: string[]): void {
    const nzSelectEl = this.el.nativeElement;
    const nzSelectTopControlEl = nzSelectEl.children[0];
    const itemsEl = nzSelectTopControlEl.children;
    let parentWidthGap = nzSelectTopControlEl.offsetWidth;
    let isShorten = false;
    selectedValues.forEach((v, i) => {
      let itemWidth = itemsEl[i].offsetWidth;
      if (this.replacements.has(v)) {
        itemWidth = this.replacements.get(v);
      }
      if (!isShorten) {
        parentWidthGap = parentWidthGap - itemWidth;
      }
      // 80 is the 80px width of the icon includes margin/padding and the new box with the count information of the rest selected item
      if (parentWidthGap < 80) {
        this.replacements.set(v, itemWidth);
        if (isShorten) {
          this.renderer.setStyle(itemsEl[i], 'display', 'none');
        } else {
          this.cancelPreviousDisplayNone(itemsEl[i]);
          this.renderer.setProperty(itemsEl[i], 'innerText', `+${selectedValues.length - i}...`);
          isShorten = true;
        }
      } else {
        this.cancelPreviousDisplayNone(itemsEl[i]);
        if (this.replacements.has(v)) {
          this.renderer.setProperty(itemsEl[i], 'innerText', v);
        }
      }
    });

  }

  ngAfterViewInit(): void {
    // If we are not using queueMicrotask the changes will not render
    // because ng zorro not created his HTML elements so queueMicrotask waits after its created and before it renders
    // settimeout renders the view before the changes and makes some lags
    queueMicrotask(() => {
      this.appNzMaxTagsByLength.valueChanges.pipe(
        debounceTime(100),
        distinctUntilChanged(),
        startWith(this.appNzMaxTagsByLength.value),
      ).subscribe((items: string[]) => {
        this.customizeValues(items);
      });
    });

  }
}
