import { Action } from '@ngrx/store';

export const FETCH_TERMS_OF_SERVICE_REQUEST = '[Terms of service] Fetch Terms of service request';
export const FETCH_TERMS_OF_SERVICE_SUCCESS = '[Terms of service] Fetch Terms of service success';
export const FETCH_TERMS_OF_SERVICE_FAILURE = '[Terms of service] Fetch Terms of service failure';

export const FETCH_TERMS_OF_SERVICE_BY_ID_REQUEST = '[Terms of service] Fetch Terms of service by id request';
export const FETCH_TERMS_OF_SERVICE_BY_ID_SUCCESS = '[Terms of service] Fetch Terms of service by id success';
export const FETCH_TERMS_OF_SERVICE_BY_ID_FAILURE = '[Terms of service] Fetch Terms of service by id failure';
export const REMOVE_TERMS_OF_SERVICE_BY_ID_FROM_STATE = '[Terms of service] Remove terms of service by id from state';

export const CREATE_TERMS_OF_SERVICE_REQUEST = '[Terms of service] Create Terms of service request';
export const CREATE_TERMS_OF_SERVICE_SUCCESS = '[Terms of service] Create Terms of service success';
export const CREATE_TERMS_OF_SERVICE_FAILURE = '[Terms of service] Create Terms of service failure';


export class FetchTermsOfServiceRequestAction implements Action {
  readonly type = FETCH_TERMS_OF_SERVICE_REQUEST;
}
export class FetchTermsOfServiceSuccessAction implements Action {
  readonly type = FETCH_TERMS_OF_SERVICE_SUCCESS;
  constructor(public payload: { termsOfService: any[] }) {
  }
}
export class FetchTermsOfServiceFailureAction implements Action {
  readonly type = FETCH_TERMS_OF_SERVICE_FAILURE;
  constructor(public payload: { error: string }) {
  }
}

export class FetchTermsOfServiceByIdRequestAction implements Action {
  readonly type = FETCH_TERMS_OF_SERVICE_BY_ID_REQUEST;
}
export class FetchTermsOfServiceByIdSuccessAction implements Action {
  readonly type = FETCH_TERMS_OF_SERVICE_BY_ID_SUCCESS;
  constructor(public payload: { currentTermsOfService: any }) {
  }
}
export class FetchTermsOfServiceByIdFailureAction implements Action {
  readonly type = FETCH_TERMS_OF_SERVICE_BY_ID_FAILURE;
  constructor(public payload: { error: string }) {
  }
}
export class RemoveTermsOfServiceByIdFromState implements Action {
  readonly type = REMOVE_TERMS_OF_SERVICE_BY_ID_FROM_STATE;
}


export class CreateTermsOfServiceRequestAction implements Action {
  readonly type = CREATE_TERMS_OF_SERVICE_REQUEST;
}
export class CreateTermsOfServiceSuccessAction implements Action {
  readonly type = CREATE_TERMS_OF_SERVICE_SUCCESS;
  constructor(public payload: { currentTermsOfService: any }) {
  }
}
export class CreateTermsOfServiceFailureAction implements Action {
  readonly type = CREATE_TERMS_OF_SERVICE_FAILURE;
  constructor(public payload: { error: string }) {
  }
}

export type TermsOfServiceActions =
  FetchTermsOfServiceRequestAction |
  FetchTermsOfServiceSuccessAction |
  FetchTermsOfServiceFailureAction |
  FetchTermsOfServiceByIdRequestAction |
  FetchTermsOfServiceByIdSuccessAction |
  FetchTermsOfServiceByIdFailureAction |
  RemoveTermsOfServiceByIdFromState |
  CreateTermsOfServiceRequestAction |
  CreateTermsOfServiceSuccessAction |
  CreateTermsOfServiceFailureAction;
