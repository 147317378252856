import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import _ from 'lodash';

import { FormHelper } from '../../../../shared/_common/form.helper';
import { Commission, CommissionToSubmit } from '../../../../shared/_models/models';

@Component({
  selector: 'app-placement-commissions',
  templateUrl: './placement-commissions.component.html',
  styleUrls: ['./placement-commissions.component.less']
})
export class PlacementCommissionsComponent implements OnInit, OnDestroy {

  @Input() editMode;
  @Input() placementId;
  @Input() placementCommission;
  @Input() pubTsCommission;
  @Output() updatedCommission: EventEmitter<CommissionToSubmit> = new EventEmitter();

  commissionForm: UntypedFormGroup;
  selectIcons: any = {};
  private unsubscribe$ = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private formsHelper: FormHelper
  ) {
  }

  public ngOnInit(): void {
    this.initForm();
    this.initFormListenChanges();
    // == in case commissions untouched, we still need to output data to parent to have it available on submit
    this.sendOutputsToParent();
  }

  initForm(): void {
    const isOverridden = this.placementCommission && this.placementCommission.commission > 0;
    // == init the form
    this.commissionForm = this.formBuilder.group({
      id: [null],
      overridePubTsCommission: [isOverridden],
      placementId: [this.placementId],
      commissionType: [{value: 'REVSHARE_DYNAMIC', disabled: !isOverridden}, Validators.required],
      commission: [{value: null, disabled: !isOverridden}, {validators: [Validators.required, Validators.min(0.001)], updateOn: 'change'}],
    });
    this.patchValuesToCommissions();
  }

  initFormListenChanges(): void {
    this.commissionForm.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((_val) => {
        this.sendOutputsToParent();
      });

    this.commissionForm.controls.overridePubTsCommission.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(val => {
        if (val) {
          this.commissionForm.controls.commissionType.enable();
          this.commissionForm.controls.commission.enable();
          this.commissionForm.controls.commission.markAsDirty();
          this.commissionForm.controls.commission.updateValueAndValidity({emitEvent: true});
        } else {
          this.commissionForm.controls.commissionType.disable();
          this.commissionForm.controls.commission.disable();
        }
      });
  }

  patchValuesToCommissions(): void {
    if (this.placementCommission) {
      this.commissionForm.patchValue(this.placementCommission);
      if (this.placementCommission.commission === 0) {
        this.commissionForm.controls.commissionType.disable();
        this.commissionForm.controls.commission.disable();
        this.commissionForm.controls.commissionType.disable();
        this.commissionForm.controls.overridePubTsCommission.setValue(false);
      }
      this.formsHelper.markFormGroupDirtyAndUpdateValidity(this.commissionForm);
    }
  }

  sendOutputsToParent(): void {
    const commission = _.cloneDeep(this.commissionForm.value);
    const commissionsPayload: Commission[] = [];
    if (commission.commission && commission.commission > 0 && commission.overridePubTsCommission) {
      commissionsPayload.push(commission);
    }
    const commissionToEmit: CommissionToSubmit = {
      isCommissionFormValid: this.commissionForm.valid,
      placementCommissions: commissionsPayload,
    };

    this.updatedCommission.emit(commissionToEmit);
  }

  getCommissionTooltip(field: string): string {
    const parentCommissionType = this.getCommissionTypeDisplayName(this.pubTsCommission?.commissionType);
    const parentCommissionSign = this.pubTsCommission ? (this.pubTsCommission.commissionType === 'FIXED' ? '$' : '%') : '';
    const parentCommissionValue = this.pubTsCommission && this.pubTsCommission.commission >= 0
      ? this.pubTsCommission.commission + parentCommissionSign
      : 'not set';
    const resMessage = 'Parent ' + field + ': ' + (field === 'type' ? parentCommissionType : parentCommissionValue);
    return resMessage;
  }

  getErrorCommission(control: any): string {
    const message = control.hasError('required') || control.hasError('min') ? 'Minimal value allowed is 0.001' : '';
    return message;
  }

  onChangeSelectIconState(isOpen, id): void {
    this.selectIcons[id] = isOpen;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getCommissionTypeDisplayName(commissionType: string): string {
    switch (commissionType) {
      case 'FIXED':
        return 'Fixed-CPM';
      case 'REVSHARE_DYNAMIC':
        return 'Dynamic Revshare';
      case 'REVSHARE_MINIMUM':
        return 'Revenue Share';
      case 'REVSHARE_STRICT':
        return 'Strict Revshare';
      default:
        return 'Not set';
    }
  }
}
