import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CommonDataModel, ResponseFromNestServer, ResponseFromServer } from '../_models/models';
import { catchError, map, startWith } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class HttpHelperService {

  constructor(private http: HttpClient) {
  }
  expandGet<T>(url: string): Observable<CommonDataModel<T>> {
    const commonDataModel = new CommonDataModel<T>();
    commonDataModel.isLoading = true;
    commonDataModel.status = 'Fetching';
    return this.http.get<ResponseFromServer<T>>(url).pipe(
      map(resFromServer => {
        commonDataModel.data = resFromServer.data;
        commonDataModel.totalElements = resFromServer.totalElements ? resFromServer.totalElements : 0;
        commonDataModel.isLoading = false;
        commonDataModel.status = 'Success';
        return commonDataModel;
      }),
      startWith(commonDataModel),
      catchError(error => {
        commonDataModel.isLoading = false;
        commonDataModel.status = 'Failed';
        commonDataModel.httpErr = error;
        return of(commonDataModel);
      })
    );
  }

  expandPost<T>(url: string, body: any, method: 'put' | 'post' = 'post'): Observable<CommonDataModel<T>> {
    const commonDataModel = new CommonDataModel<T>();
    commonDataModel.isLoading = true;
    commonDataModel.status = 'Fetching';
    return this.http[method === 'put' ? 'put' : 'post']<ResponseFromServer<T>>(url, body).pipe(
      map(resFromServer => {
        commonDataModel.data = resFromServer.data;
        commonDataModel.totalElements = resFromServer.totalElements ? resFromServer.totalElements : 0;
        commonDataModel.isLoading = false;
        commonDataModel.status = 'Success';
        return commonDataModel;
      }),
      startWith(commonDataModel),
      catchError(error => {
        commonDataModel.isLoading = false;
        commonDataModel.status = 'Failed';
        commonDataModel.httpErr = error;
        return of(commonDataModel);
      })
    );
  }

  expandNestPost<T>(url: string, body: any, method: 'put' | 'post' = 'post'): Observable<CommonDataModel<T>> {
    const commonDataModel = new CommonDataModel<T>();
    commonDataModel.isLoading = true;
    commonDataModel.status = 'Fetching';
    return this.http[method === 'put' ? 'put' : 'post']<ResponseFromNestServer<T>>(url, body).pipe(
      map(resFromNestServer => {
        commonDataModel.data = resFromNestServer.data.items;
        commonDataModel.totalElements = resFromNestServer.data.meta.totalItems ? resFromNestServer.data.meta.totalItems : 0;
        commonDataModel.isLoading = false;
        commonDataModel.status = 'Success';
        return commonDataModel;
      }),
      startWith(commonDataModel),
      catchError(error => {
        commonDataModel.isLoading = false;
        commonDataModel.status = 'Failed';
        commonDataModel.httpErr = error;
        return of(commonDataModel);
      })
    );
  }

  expandNestGet<T>(url: string): Observable<CommonDataModel<T>> {
    const commonDataModel = new CommonDataModel<T>();
    commonDataModel.isLoading = true;
    commonDataModel.status = 'Fetching';
    return this.http.get<ResponseFromNestServer<T>>(url).pipe(
      map(resFromNestServer => {
        commonDataModel.data = resFromNestServer.data.items;
        commonDataModel.totalElements = resFromNestServer.data.meta.totalItems ? resFromNestServer.data.meta.totalItems : 0;
        commonDataModel.isLoading = false;
        commonDataModel.status = 'Success';
        commonDataModel.rxMetaData = resFromNestServer.data.meta;
        return commonDataModel;
      }),
      startWith(commonDataModel),
      catchError(error => {
        commonDataModel.isLoading = false;
        commonDataModel.status = 'Failed';
        commonDataModel.httpErr = error;
        return of(commonDataModel);
      })
    );
  }




}
