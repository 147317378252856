import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { OptionsLists, ResponseFromServer, SharedStoreAssetConfig, SharedStoreAssetKey, TrafficSource } from '../_models/models';
import { Observable, of } from 'rxjs';
import { filter, finalize, first, shareReplay, switchMap } from 'rxjs/operators';
import { sharedAssetsConfigs, SharedStoreResponseType } from './shared-store.config';

@Injectable({providedIn: 'root'})

export class SharedStoreService {

  sharedAssetsConfigs = sharedAssetsConfigs;

  constructor(
    private api: ApiService,
  ) {}

  getGlobalAppAssets(currentUser): void {
    this.getSharedAsset('featureToggles').subscribe();
    this.getSharedAsset('optionsLists').subscribe();
    this.getSharedAsset('timezones').subscribe();
    this.getSharedAsset('currencies').subscribe();
    this.getSharedAsset('dspAccounts').subscribe();
    this.getSharedAsset('countries').subscribe();
    if (currentUser.userType === 'INTERNAL') {
      this.getSharedAsset('bizdevOwners').subscribe();
      this.getSharedAsset('opsOwners').subscribe();
    } else if (currentUser.publisherId) {
      this.getTrafficSourcesByPublisherId(currentUser.publisherId).subscribe();
    }
  }

  getSharedAsset(assetKey: SharedStoreAssetKey, configOverride?: SharedStoreAssetConfig<SharedStoreResponseType>): Observable<ResponseFromServer<any> | any> {
    const config: SharedStoreAssetConfig<SharedStoreResponseType> = configOverride || this.sharedAssetsConfigs[assetKey];
    // If we already have the response (the stored asset), we can just re-share it.
    if (config.response$) {
      return config.response$;
    }
    // If there's no stored asset and the asset is currently not loading, we need to call the asset's request.
    if (!config.isLoading) {
      config.isLoading = true;
      // Resetting the queue. In case any other source is trying to get this asset while it's loading from the DB,
      // we put it in a queue-subject, and once the asset has resolved correctly, all items in the queue
      // will get that same response.
      config.queueSubject.next(null);
      // Making the actual request in api-service
      return this.api[config.requestConfig.apiServiceMethod](...config.requestConfig.methodArguments || []).pipe(
        switchMap((res: ResponseFromServer<any>) => {
          // We need to make sure the response is correct, the config contains a small test function to validate that.
          if (config.responseIntegrityTest(res)) {
            // Handle any response modifications, if needed.
            if (config.requestConfig.responseHandler) {
              res = config.requestConfig.responseHandler(res, config);
            }
            // Once the test passed we wrap the response in an observable with the shareReplay operator (the caching pipe)
            // this is used in the future whenever any component/service needs this asset again (see the first "if" of this method).
            config.response$ = of(res).pipe(shareReplay(1));
            // Notifying any members in the queue that the response has arrived (with the actual response).
            config.queueSubject.next(config.response$);
            // Returning the response to original subscriber who requested this asset first.
            return config.response$;
          }
        }),
        finalize(() => {
          config.isLoading = false;
        }),
      );
    }
    // Returning the queue subject to anyone who's in dire need of this asset while being retrieved.
    // Once the asset has arrived, the response observable will pass the filter operator and will
    // then be switchMapped as the actual response.
    return config.queueSubject.pipe(
      filter((observable) => !!observable),
      first(),
      switchMap((res: Observable<ResponseFromServer<any>>) => res),
    );
  }

  setSharedAssets(assetKey: SharedStoreAssetKey, newRes: any): void {
    const config: SharedStoreAssetConfig<SharedStoreResponseType> = this.sharedAssetsConfigs[assetKey];
    config.response$ = of(newRes).pipe(shareReplay(1));
  }

  getSharedEnum(enumKey: string): Observable<{[key: string]: string}> {
    return this.getSharedAsset('optionsLists').pipe(
      first(),
      switchMap((optionsList: OptionsLists) => {
        return of(optionsList[enumKey]);
      })
    );
  }

  getTrafficSourcesByPublisherId(publisherId: number): Observable<ResponseFromServer<TrafficSource[]>> {
    const config = this.sharedAssetsConfigs.trafficSourcesByPublisherId;
    config.requestConfig.methodArguments = [publisherId];
    return this.getSharedAsset('trafficSourcesByPublisherId', config);
  }

  resetSharedStore(): void {
    Object.values(this.sharedAssetsConfigs).forEach(config => {
      config.isLoading = false;
      config.response$ = null;
    });
  }

}
