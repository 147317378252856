<ng-container>
  <form nzLayout="vertical" >
      <app-table
        [hasFooter] = "false"
        [table] = "alertsTable"
        [tableColumns]="alertsTableColumns"
        [tableData]="alertsTableData$ | async"
        [authPermissions]="authUser"
        [isTableLoading]="isLoading"
        (alertRowAction)="onAlertRowAction($event)">
      </app-table>
  </form>
</ng-container>
