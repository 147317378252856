
<nz-row nzGutter="16" [formGroup]="paymentForm">

  <nz-col nzLg="6" nzMd="12" nzSm="24">
    <nz-form-item [ngClass]="{'has-error': paymentForm.get('paymentMethodType')?.touched && paymentForm.get('paymentMethodType')?.errors}">
      <nz-form-label nzRequired>Select Payment Method</nz-form-label>
      <nz-form-control>
        <nz-select formControlName="paymentMethodType" id="payment_method_type" [nzLoading]="loadingMethods"
                   nzShowArrow="false"
                   [nzSuffixIcon]="selectIcons['paymentMethodType'] ? arrowUp : arrowDown"
                   (nzOpenChange)="onChangeSelectIconState($event, 'paymentMethodType')">
          <ng-container *ngFor="let method of paymentMethodOptions">
              <nz-option
                *ngIf="!(country === 'US' && method.value === 'WIRE')"
                [nzValue]="method.value"
                [nzLabel]="method.text"
                [nzDisabled]="method.value === 'PAYPAL'"></nz-option>
          </ng-container>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </nz-col>


  <ng-container formGroupName="selectedMethodForm">
    <ng-container *ngFor="let param of selectedMethodForm.controls | keyvalue">
      <nz-col nzLg="6" nzMd="12" nzSm="24">
        <nz-form-item [ngClass]="{'has-error': selectedMethodForm.get(param.key)?.touched && selectedMethodForm.get(param.key)?.errors}">
          <nz-form-label [nzRequired]="selectedMethodControlsParams[param.key].required">{{selectedMethodControlsParams[param.key].title}}</nz-form-label>
          <nz-form-control [nzErrorTip]="errorTipTpl">
            <input nz-input
              [formControlName]="param.key"
              [id]="'method_form' + param.key"
              [placeholder]="'Enter the ' + (selectedMethodControlsParams[param.key].title | lowercase)"
              autocomplete="off">
            <ng-template #errorTipTpl>
              <ng-container *ngIf="selectedMethodForm.get(param.key)?.hasError('required')">
                {{selectedMethodControlsParams[param.key].title}} required
              </ng-container>
              <ng-container *ngIf="selectedMethodForm.get(param.key)?.hasError('email')">
                Please enter a valid email address
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
    </ng-container>
  </ng-container>

</nz-row>

<ng-template #arrowUp>
  <fa-icon [icon]="'angle-down'" class="select-icon select-icon-open"></fa-icon>
</ng-template>
<ng-template #arrowDown>
  <fa-icon [icon]="'angle-down'" class="select-icon select-icon-close"></fa-icon>
</ng-template>
