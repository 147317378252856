import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { EnvConfigService } from './env-config.service';
import { GtmService } from './gtm.service';
import { PendoService } from './pendo.service';
import { DatadogService } from './datadog.service';
import { RequestPollingService } from './request-polling.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitializerService {

  constructor(
    private envConfigService: EnvConfigService,
    private gtmService: GtmService,
    private pendoService: PendoService,
    private datadogService: DatadogService,
    private requestPollingService: RequestPollingService,
  ) {
    this.envConfigService.setEnvVariables();
    this.gtmService.googleTagManagerInit(window, document, 'script', 'dataLayer', environment.googleTagContainerId);
    this.pendoService.onPendoAction('init');
    this.datadogService.onDatadogAction('init');
    this.requestPollingService.loadStoredPollings();
  }

}
