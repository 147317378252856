
<ng-container *ngIf="badvForm" [formGroup]="badvForm" (keydown.enter)="$event.preventDefault()">
  <nz-row nzGutter="32">
    <nz-col nzMd="6" nzSm="24">
      <ng-container >
        <nz-form-item>
          <nz-form-control>
            <nz-form-label>Add Blocked Advertisers manually</nz-form-label>
            <nz-select nzMode="tags" [nzTokenSeparators]="[',']" style="width: 100%;"
                       nzPlaceHolder="Use comma to separate items in list"
                       formControlName="badv"
                       id="badv"
                       nzShowArrow="false"
                       [nzSuffixIcon]="selectIcons['blocked-advertisers'] ? arrowUp : arrowDown">
            </nz-select>
          </nz-form-control>
        </nz-form-item>


        <button nzType="primary" type="button" nz-button nzBlock
                id="blockedAdvertisers"
                class="primary-button small-padding small-font badv-add-btn"
                (click)="onBadvAdd()"
                [disabled]="!badvForm.controls.badv.value"
        >Add Blocked Advertisers
        </button>

        <nz-divider></nz-divider>

        <nz-form-label>Add by file upload</nz-form-label>
                <nz-upload nzType="drag"
                           [nzMultiple]="false"
                           [nzLimit]="1"
                           [nzAction]="uploadBlockedAdvertisersFromFileUrl"
                           [nzFileType]="'text/plain,application/vnd.ms-excel,text/x-csv,application/csv,application/x-csv,text/csv,text/comma-separated-values,text/x-comma-separated-values,text/tab-separated-values'"
                           (nzChange)="onBlockedAdvertisersUploadFinished($event)">
                  <p class="ant-upload-drag-icon">
                    <em nz-icon nzType="inbox"></em>
                  </p>
                  <p class="ant-upload-text">Click or drag file to this area to upload</p>
                  <p class="ant-upload-hint">
                    CSV file with 1 record on each line.
                  </p>
                </nz-upload>

      </ng-container>

    </nz-col>

    <nz-col nzMd="18" nzSm="24" class="vertical-left-divider">
      <ng-container *ngIf="badvData">
        <app-filters [filters]="blockedAdvertisersFiltersConfig"
                     [buttons]="domainBundlesTableButtons"
                     (filtersChanged)="onFiltersChange($event)"
                     (buttonClicked)="onButtonClick($event)">
        </app-filters>
        <app-table [table]="blockedAdvertisersTable"
                   [authPermissions]="authPermissions"
                   [tableColumns]="blockedAdvertisersTableColumns"
                   [tableData]="filterBadvData"
                   [totalRows]= "filterBadvData.length"
                   [isPaginated]="true"
                   [hasSizeChanger]="true"
                   [hasFooter]="false"
                   [idProp]="'badvName'"
                   [tableType]="'clientSide'"
                   (badvAction)="onRowAction($event)">
        </app-table>
      </ng-container>
    </nz-col>
  </nz-row>
</ng-container>


<ng-template #arrowUp>
  <fa-icon [icon]="'angle-down'" class="select-icon select-icon-open"></fa-icon>
</ng-template>
<ng-template #arrowDown>
  <fa-icon [icon]="'angle-down'" class="select-icon select-icon-close"></fa-icon>
</ng-template>
