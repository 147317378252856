import { Action } from '@ngrx/store';
import { PublisherTrafficSource } from '../../../../../shared/_models/models';


export const FETCH_PUBLISHER_TRAFFIC_SOURCES_REQUEST = '[PublisherTrafficSources] Fetch publisher traffic sources request';
export const FETCH_PUBLISHER_TRAFFIC_SOURCES_SUCCESS = '[PublisherTrafficSources] Fetch publisher traffic sources success';
export const FETCH_PUBLISHER_TRAFFIC_SOURCES_FAILURE = '[PublisherTrafficSources] Fetch publisher traffic sources failure';

export const UPDATE_PUBLISHER_TRAFFIC_SOURCES_REQUEST = '[PublisherTrafficSources] Update publisher traffic sources request';
export const UPDATE_PUBLISHER_TRAFFIC_SOURCES_SUCCESS = '[PublisherTrafficSources] Update publisher traffic sources success';
export const UPDATE_PUBLISHER_TRAFFIC_SOURCES_FAILURE = '[PublisherTrafficSources] Update publisher traffic sources failure';

export const CLEAR_STORE_PUBLISHER_TRAFFIC_SOURCES = '[PublisherTrafficSources] Remove publisher traffic sources from store';


export class FetchPublisherTrafficSourcesRequestAction implements Action {
  readonly type = FETCH_PUBLISHER_TRAFFIC_SOURCES_REQUEST;
}

export class FetchPublisherTrafficSourcesSuccessAction implements Action {
  readonly type = FETCH_PUBLISHER_TRAFFIC_SOURCES_SUCCESS;

  constructor(public payload: { publisherTrafficSources: any[] }) {
  }
}

export class FetchPublisherTrafficSourcesFailureAction implements Action {
  readonly type = FETCH_PUBLISHER_TRAFFIC_SOURCES_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class UpdatePublisherTrafficSourcesRequestAction implements Action {
  readonly type = UPDATE_PUBLISHER_TRAFFIC_SOURCES_REQUEST;
}

export class UpdatePublisherTrafficSourcesSuccessAction implements Action {
  readonly type = UPDATE_PUBLISHER_TRAFFIC_SOURCES_SUCCESS;

  constructor(public payload: { publisherTrafficSources: PublisherTrafficSource[] }) {
  }
}

export class UpdatePublisherTrafficSourcesFailureAction implements Action {
  readonly type = UPDATE_PUBLISHER_TRAFFIC_SOURCES_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class ClearFromStorePublisherTrafficSourcesAction implements Action {
  readonly type = CLEAR_STORE_PUBLISHER_TRAFFIC_SOURCES;
}


export type PublisherTrafficSourcesActions =
  FetchPublisherTrafficSourcesRequestAction |
  FetchPublisherTrafficSourcesSuccessAction |
  FetchPublisherTrafficSourcesFailureAction |
  UpdatePublisherTrafficSourcesRequestAction |
  UpdatePublisherTrafficSourcesSuccessAction |
  UpdatePublisherTrafficSourcesFailureAction |
  ClearFromStorePublisherTrafficSourcesAction;

