import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/_services/api.service';
import { Store } from '@ngrx/store';
import * as fromAudienceTargeting from '../_store/audience-targeting.reducer';
import * as AudienceTargetingActions from '../_store/audience-targeting.actions';
import { SharedService } from '../../../shared/_services/shared.service';
import { audienceTargetingModelFiltersConfig } from './audience-targeting.config';
import { map } from 'rxjs/operators';
import { AudienceTargetingGroup, FilterSelectOption, JapiQuery } from '../../../shared/_models/models';
import { GtmService } from '../../../shared/_services/gtm.service';
import { SharedStoreService } from '../../../shared/_services/shared-store.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AudienceTargetingService {

  private getAudienceTargetingSegments$: Subscription;

  constructor(
    private api: ApiService,
    private gtmService: GtmService,
    private store: Store<fromAudienceTargeting.AudienceTargetingState>,
    private sharedService: SharedService,
    private sharedStoreService: SharedStoreService,
  ) {}

  getAudienceTargetingSegments(filters: JapiQuery): void {
    this.getAudienceTargetingSegments$?.unsubscribe();
    this.gtmService.customEvent('audienceTargetingFilters', filters as unknown as {[p: string]: string | number});
    // until there's a limit for the segment list, to prevent 9000+ values
    this.store.dispatch(new AudienceTargetingActions.FetchSegmentListRequestAction());
    this.getAudienceTargetingSegments$ = this.api.getAudienceTargetingSegments(filters)
      .subscribe(res => {
        this.store.dispatch(new AudienceTargetingActions.FetchSegmentListSuccessAction(
          {segments: res.data, totalElements: res.totalElements}
        ));
      },
      (err) => {
        this.store.dispatch(new AudienceTargetingActions.FetchSegmentListFailureAction(err));
        this.sharedService.onHttpCompletionResolve(false, 'Failure', 'Audience targeting segments fetch failed', '', '');
      });
  }

  getAudienceTargetingProviderList(): void {
    this.store.dispatch(new AudienceTargetingActions.FetchSegmentProviderListRequestAction());
    this.api.getAudienceTargetingProviders().pipe(
      map(res => this.sharedService.stringArrayToFilterValue(res))
    ).subscribe((res: FilterSelectOption[]) => {
      audienceTargetingModelFiltersConfig.find(a => a.id === 'audienceTargetingModelProvider').possibleValues = res;
      this.store.dispatch(new AudienceTargetingActions.FetchSegmentProviderListSuccessAction(
        {
          segmentProviders: res,
          updateAppFilters: this.sharedService.updateFilterPossibleValues
        }
      ));
    },
    (err) => {
      this.store.dispatch(new AudienceTargetingActions.FetchSegmentProviderListFailureAction(err));
      this.sharedService.onHttpCompletionResolve(false, 'Failure', 'Audience targeting provider list fetch failed', '', '');
    });
  }

  getAudienceTargetingSortByList(): void {
    this.store.dispatch(new AudienceTargetingActions.FetchSegmentSortByListRequestAction());
    this.sharedStoreService.getSharedEnum('SegmentSortedFields').pipe(
      map(res => this.sharedService.keyValueToFilterValue(res)),
      map(res => res.map(s => {
        s.displayName = 'Sort by ' + s.displayName;
        return s;
      }))
    )
      .subscribe((res: FilterSelectOption[]) => {
        const findSortByFilter = audienceTargetingModelFiltersConfig.find(a => a.id === 'audienceTargetingModelSortBy');
        findSortByFilter.possibleValues = res;
        this.store.dispatch(new AudienceTargetingActions.FetchSegmentSortByListSuccessAction(
          {
            segmentSortByList: res,
            updateAppFilters: this.sharedService.updateFilterPossibleValues
          }
        ));
      },
      (err) => {
        this.store.dispatch(new AudienceTargetingActions.FetchSegmentSortByListFailureAction(err));
        this.sharedService.onHttpCompletionResolve(false, 'Failure', 'Audience targeting sort by list fetch failed', '', '');
      });
  }

  getTotalMaxPrice(groups: AudienceTargetingGroup[]): void {
    this.store.dispatch(new AudienceTargetingActions.FetchTotalMaxPriceRequestAction());
    if (groups.length === 0) {
      this.store.dispatch(new AudienceTargetingActions.FetchTotalMaxPriceSuccessAction({totalMaxPrice: 0}));
    } else {
      this.api.getTotalMaxPrice(groups).subscribe(res => {
        this.store.dispatch(new AudienceTargetingActions.FetchTotalMaxPriceSuccessAction({totalMaxPrice: res}));
      },
      (err) => {
        this.store.dispatch(new AudienceTargetingActions.FetchTotalMaxPriceFailureAction(err));
        this.sharedService.onHttpCompletionResolve(false, 'Failure', 'Audience targeting total max price fetch failed', '', '');
      });
    }
  }

  gtmCloseEvent(closeMethod: string): void {
    this.gtmService.customEvent('audienceTargetingCloseEvent', {closeMethod});
  }
}
