import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
 name: 'SimplePercentPipe'
})

export class SimplePercentPipe implements PipeTransform {

  transform(value: any, digitsInfo?: any): string|null {
    return _.round(value, digitsInfo).toFixed(2) + '%';
  }

}
