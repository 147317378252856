<ng-container *ngIf="!updateOtherUserMode; else updateUserForm">
  <nz-spin [nzSpinning]="isJavaUserLoading$ | async" style="min-height: 300px;">
    <ng-container *ngIf="user && user.type === 'EXTERNAL' && portalType !== 'partner'">
      <ng-container *ngTemplateOutlet="updateUserForm"></ng-container>
    </ng-container>
    <ng-container *ngIf="user && (user.type === 'INTERNAL' || portalType === 'partner')">
      <div class="card-wrapper">
        <nz-card>
          <ng-container *ngTemplateOutlet="updateUserForm"></ng-container>
        </nz-card>
      </div>
    </ng-container>
  </nz-spin>
</ng-container>


<ng-template #updateUserForm>
  <form nz-form nzLayout="vertical" [formGroup]="form">
    <div class="sectionTitle">
      <fa-icon icon="user-circle"></fa-icon>
      User Details
    </div>
    <div nz-row nzGutter="16">
      <nz-col nzLg="8" nzMd="12" nzSm="24">
        <nz-form-item>
          <nz-form-label nzRequired>First name</nz-form-label>
          <nz-form-control [nzValidateStatus]="(form.get('firstName').invalid && form.get('firstName').touched) ? 'error' : null" [nzErrorTip]="firstNameErrorTipTpl">
            <input formControlName="firstName" nz-input placeholder="Enter a first name" type="text"
                   name="firstName" id="update_user_firstName">
            <ng-template #firstNameErrorTipTpl>
              <ng-container *ngIf="form.get('firstName')?.hasError('required')">
                First name is required
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      <nz-col nzLg="8" nzMd="12" nzSm="24">
        <nz-form-item>
          <nz-form-label nzRequired>Last name</nz-form-label>
          <nz-form-control [nzValidateStatus]="(form.get('lastName').invalid && form.get('lastName').touched) ? 'error' : null" [nzErrorTip]="lastNameErrorTipTpl">
            <input formControlName="lastName" name="lastName" nz-input placeholder="Enter a last name"
                   id="update_user_lastName">
            <ng-template #lastNameErrorTipTpl>
              <ng-container *ngIf="form.get('lastName')?.hasError('required')">
                Last name is required
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      <nz-col nzLg="8" nzMd="12" nzSm="24">
        <nz-form-item>
          <nz-form-label nzRequired>Email / Username</nz-form-label>
          <nz-form-control [nzValidateStatus]="(form.get('email')?.invalid && form.get('email')?.touched) ? 'error' : null" [nzErrorTip]="emailErrorTipTpl">
            <input autocomplete="off" formControlName="email" id="update_user_email" name="email" nz-input placeholder="Enter a valid email">
            <ng-template #emailErrorTipTpl>
              <ng-container *ngIf="form.get('email')?.hasError('email')">
                Invalid Email address
              </ng-container>
              <ng-container *ngIf="form.get('email')?.hasError('required')">
                Email is required
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="!userInput?.id && portalType === 'supply'">
          <nz-form-control>
            <label nz-checkbox formControlName="verified" class="full-width"
                   id="verified">User will
              not require email verification</label>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      <div style="width: 100%">
        <app-user-form-password
          [form]="form"
          [userInput]="userInput"
          [updateOtherUserMode]="updateOtherUserMode"
          [isUpdating]="!(updateOtherUserMode && (userInput && !userInput.id))"
        ></app-user-form-password>
      </div>
    </div>

    <div *ngIf="userInput && userInput.type === 'API'" class="sectionTitle">
      <fa-icon icon="cogs"></fa-icon>
      API Key
    </div>
    <div *ngIf="userInput && userInput.type === 'API'" nz-row nzGutter="16">
      <nz-col nzLg="8" nzMd="12" nzSm="24">
        <ng-container>
            <nz-form-item>
              <nz-row>
                <nz-form-label nzRequired>API key</nz-form-label>
                <span [nzTooltipTitle]="'Applicable to API user only, will be generated automatically on publisher save'" nz-tooltip>
                    <fa-icon class="fa-md font-icon-color-grey-cool font-icon-hover-color-primary-main" icon="question-circle"></fa-icon>
                </span>
              </nz-row>
              <nz-form-control>
                <input formControlName="gatewayApiKey" id="api-key-generate" name="lastName" nz-input
                       placeholder="API key">
              </nz-form-control>
            </nz-form-item>
        </ng-container>
      </nz-col>
      <nz-col nzLg="8" nzMd="12" nzSm="24">
        <button (click)="generateApiKey(); $event.preventDefault()"
          *ngIf="userInput?.id && !form.get('gatewayApiKey').value"
                [nzLoading]="isGeneratingGatewayKey"
                class="small-font secondary-button match-form-item-height small-height small-padding"
                id="generate-api-key-btn"
                nz-button
                style="transition: none;">
          Generate API Key
        </button>
      </nz-col>
    </div>
  <ng-container *ngIf="(!updateOtherUserMode || (userInput && userInput.type !== 'API'))">
    <div class="sectionTitle">
      <fa-icon icon="cogs"></fa-icon>
      Account Settings
    </div>
    <div nz-row nzGutter="16">
      <nz-col nzLg="8" nzMd="12" nzSm="24" *ngIf="!isExchangeUser">
        <ng-container formGroupName="timezone">
          <nz-form-item>
            <nz-row>
              <nz-form-label nzRequired>Default Time Zone</nz-form-label>
              <span nz-tooltip [nzTooltipTitle]="'Applicable to Nexxen SSP only'">
                <fa-icon icon="question-circle" class="fa-md font-icon-color-grey-cool font-icon-hover-color-primary-main"></fa-icon>
              </span>
            </nz-row>
            <nz-form-control>
              <nz-select formControlName="id" id="update_user_timezone_id" nzPlaceHolder="Select a default time zone"
                         [nzSuffixIcon]="isSelectOpen ? arrowUp : arrowDown"
                         (nzOpenChange)="onChangeSelectIconState()"
                         [nzLoading]="(timezones$.isLoading$ | async)" nzShowSearch>
                <nz-option *ngFor="let timezone of (timezones$.response$ | async)"
                           [nzLabel]="timezone.dropdownLabel" [nzValue]="timezone.id">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </ng-container>
      </nz-col>
      <nz-col nzLg="8" nzMd="12" nzSm="24" *ngIf="!updateOtherUserMode || (userInput && userInput.type !== 'API')">
        <ng-container formGroupName="currency">
          <nz-form-item>
            <nz-form-label nzRequired>{{ isExchangeUser ? '' : 'Additional Reporting Currency & ' }}Deals Currency</nz-form-label>
            <nz-form-control>
              <nz-select formControlName="id" id="update_user_currency_id" nzPlaceHolder="Select a reporting currency"
                         [nzSuffixIcon]="isSelectOpen ? arrowUp : arrowDown"
                         (nzOpenChange)="onChangeSelectIconState()"
                         [nzLoading]="(currencies$.isLoading$ | async)" nzShowSearch>
                <nz-option *ngFor="let currency of (currencies$.response$ | async) | sortBy : 'abbreviation' : 'asc' : ['USD'] : 'start'"
                           [nzLabel]="currency.abbreviation" [nzValue]="currency.id">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
          <nz-alert nzType="info"
                    nzMessage="Currency conversion data is based on historical rates which are updated daily at 7:00 AM EST. All billing and payments are in USD">
          </nz-alert>
        </ng-container>
      </nz-col>
      <ng-container *ngIf="isParentPublisherEdit && publisherAccountType === 'SELF_SERVE'">
        <nz-col nzLg="8" nzMd="12" nzSm="24">
            <nz-form-item>
              <nz-form-label>Default UI</nz-form-label>
              <nz-radio-group formControlName="defaultUi" id="update-user-default-ui" style="width: 100%;">
                <label nz-radio nzValue="CTRL">CTRL</label>
                <label nz-radio nzValue="SPEARAD">Spearad</label>
              </nz-radio-group>
            </nz-form-item>
        </nz-col>
      </ng-container>
    </div>
  </ng-container>
  </form>
  <ng-container *ngIf="user && !isExchangeUser">
    <div class="sectionTitle" *ngIf="!updateOtherUserMode || (userInput && userInput.type !== 'API')">
      <fa-icon icon="bell"></fa-icon>
      Alerts & Notifications
    </div>
    <nz-row nzGutter="8">
        <nz-alert nzType="info"
                  [nzMessage]=alertsManagementMsg>
        </nz-alert>
    </nz-row>
    <nz-row>
      <nz-col [nzLg]="{span: 24}" [nzXl]="{span: 16}" [nzXXl]="{span: 12}">
        <ng-container *ngIf="!updateOtherUserMode || (userInput && userInput.type !== 'API')">
              <app-alerts-management [user]="user" (alertsSubmitResult)="afterAlertsSubmit($event)"></app-alerts-management>
        </ng-container>
      </nz-col>
    </nz-row>
  </ng-container>

  <div nz-row nzJustify="end" class="margin-top-2" *ngIf="!updateOtherUserMode">
    <button nzType="link" nz-button id="update_user_cancelBtn" *ngIf="user && (user.type === 'INTERNAL' || portalType === 'partner')"
            class="font-icon-color-grey font-icon-hover-color-mid-dark bold-font margin-right-small"
            (click)="onCancel(); $event.preventDefault()">Cancel
    </button>
    <button class="primary-button" (click)="onSubmit()" [nzLoading]="(isJavaUserLoading$ | async)" nz-button nzSize="large" nzType="primary"
            *ngIf="!user?.deletedAt" id="update_user_saveBtn" [disabled]="(isJavaUserLoading$ | async) || form.invalid">Save
    </button>
  </div>
</ng-template>

<ng-template #showPassBtnTpl>
  <div class="display-flex align-items-center">
    <span class="smaller-font bold-font font-icon-color-darker margin-right-small background-white" *ngIf="isCopied_password" @fadeOut>Copied to clipboard</span>
    <fa-icon icon="copy" *ngIf="form.get('password').value" (click)="copyToClipboard('password')"
              class="font-icon-color-darker cursor-pointer margin-right-small" id="copyToClipboardBtn-pass"
              style="background: #fff;"></fa-icon>
    <fa-icon [icon]="!isPasswordVisible ? 'eye' : 'eye-slash'" (click)="isPasswordVisible = !isPasswordVisible"
              class="font-icon-color-darker cursor-pointer" id="togglePassVisibilityBtn-pass"></fa-icon>
  </div>
</ng-template>

<ng-template #alertsManagementMsg>
  The notifications set below will be sent to the signed in user only  <br>
  All alerts are sent at 8 AM EST
</ng-template>

<ng-template #showConfPassBtnTpl>
  <div class="display-flex align-items-center">
    <span class="smaller-font bold-font font-icon-color-darker margin-right-small background-white" *ngIf="isCopied_passwordConfirmation" @fadeOut>Copied to clipboard</span>
    <fa-icon icon="copy" *ngIf="form.get('passwordConfirmation').value" (click)="copyToClipboard('passwordConfirmation')"
              class="font-icon-color-darker cursor-pointer margin-right-small" id="copyToClipboardBtn-conf-pass"
              style="background: #fff;"></fa-icon>
    <fa-icon [icon]="!isConfPasswordVisible ? 'eye' : 'eye-slash'" (click)="isConfPasswordVisible = !isConfPasswordVisible"
              class="font-icon-color-darker cursor-pointer" id="togglePassVisibilityBtn-confPass"></fa-icon>
  </div>
</ng-template>


<ng-template #arrowUp>
  <fa-icon [icon]="'angle-down'" class="select-icon select-icon-open font-icon-color-darker"></fa-icon>
</ng-template>
<ng-template #arrowDown>
  <fa-icon [icon]="'angle-down'" class="select-icon select-icon-close font-icon-color-darker"></fa-icon>
</ng-template>

