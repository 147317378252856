import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({providedIn: 'root'})

export class EventService {
  private emitEventSource = new Subject<any>();
  eventEmitted = this.emitEventSource.asObservable();

  emitEvent(name: string, data?: any) {
    const emit = { name: name, data: data };
    this.emitEventSource.next(emit);
  }
}
