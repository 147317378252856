import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroupDirective, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { PublisherEdit } from '../../_models/models';
import { SharedStoreService } from '../../_services/shared-store.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-publisher-sellers',
  templateUrl: './publisher-sellers.component.html',
  styleUrls: ['./publisher-sellers.component.less'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    }
  ]
})
export class PublisherSellersComponent implements OnInit {
  form: UntypedFormGroup;
  warningMsg = 'At least one of the Sellers.json fields is blank.';

  @Input() publisher: PublisherEdit = {} as PublisherEdit;
  @Input() editMode = false;
  @Input() hideTitle: boolean;
  @Input() hideWarning = false;

  @Input() set externalMode(external: boolean) {
    if (external) {
      this.warningMsg += ' Contact your Nexxen Account Manager.';
    }
  }

  sellerTypes$ = of([
    {key: 'PUBLISHER', value: 'Publisher'},
    {key: 'INTERMEDIARY', value: 'Intermediary'},
    {key: 'BOTH', value: 'Both'}
  ]);


  constructor(
    private sharedStoreStore: SharedStoreService,
    private controlContainer: ControlContainer
  ) {
  }

  ngOnInit(): void {
    this.form = (this.controlContainer as FormGroupDirective).form;
  }


  onSellerTypeListChange(sellerTypes: String[]): void {

    let allPtsFormArray = this.form.controls.publisherTrafficSources as UntypedFormArray;
    for (let i = 0; i < allPtsFormArray?.value.length; i++) {

      if (sellerTypes.length === 0) {
        allPtsFormArray.at(i).get('sellerType').setValue(null);
      } else if (!sellerTypes.includes(allPtsFormArray.at(i).get('sellerType').value)) {
        if (sellerTypes.length === 1) {
          allPtsFormArray.at(i).get('sellerType').setValue(sellerTypes[0]);
        } else {
          allPtsFormArray.at(i).get('sellerType').setValue(null);
        }
      }
      allPtsFormArray.at(i).get('sellerType').updateValueAndValidity();
      allPtsFormArray.at(i).get('sellerType').markAsDirty();
    }
  }
}
