import { Injectable } from '@angular/core';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class TableService {
  allChecked = false;
  indeterminate = false;
  loading = true;
  sortName = null;
  sortValue = null;
  listOfSearchName = [];
  searchAddress: string;
  public tableData: Array<any> = [];
  public displayData: Array<any> = [];

  constructor() {
    this.displayData = [];
  }

  sort(sort: { key: string, value: string }): void {
    this.sortName = sort.key;
    this.sortValue = sort.value;
    this.search();
  }

  search(): void {
    /** filter data **/
    const filterFunc = item => (this.searchAddress ? item.address.indexOf(this.searchAddress) !== -1 : true) && (this.listOfSearchName.length ? this.listOfSearchName.some(name => item.name.indexOf(name) !== -1) : true);
    const data = this.tableData.filter(item => filterFunc(item));
    /** sort data **/
    if (this.sortName && this.sortValue) {
      this.displayData = data.sort(
        (a, b) => {
          const check_a = (_.isString(a[this.sortName])) ? a[this.sortName].toLowerCase() : a[this.sortName];
          const check_b = (_.isString(b[this.sortName])) ? b[this.sortName].toLowerCase() : b[this.sortName];
          if (this.sortValue === 'ascend') {
            return (check_a > check_b ? 1 : -1);
          } else {
            return (check_b > check_a ? 1 : -1);
          }
        }
      );
    } else {
      this.displayData = data;
    }
  }

  refreshStatus(): void {
    const validData = this.tableData.filter(value => !value.disabled);
    const allChecked = validData.length > 0 && validData.every(value => value.checked === true);
    const allUnChecked = validData.every(value => !value.checked);
    this.allChecked = allChecked;
    this.indeterminate = (!allChecked) && (!allUnChecked);
  }

  checkAll(value: boolean): void {
    this.tableData.forEach(data => {
      if (!data.disabled) {
        data.checked = value;
      }
    });
    this.refreshStatus();
  }


}
