import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { NzTooltipDirective } from 'ng-zorro-antd/tooltip';

@Directive({
  selector: '[appEllipsisAndTooltip]'
})
export class EllipsisAndTooltipDirective implements AfterViewInit {

  @Input('appEllipsisAndTooltip') active: boolean;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private nzTooltip: NzTooltipDirective
  ) {
  }

  private get hasOverflow(): boolean {
    const el: HTMLElement = this.el.nativeElement;
    return el.offsetWidth < el.scrollWidth;
  }

  ngAfterViewInit(): void {
    if (this.active) {
      this.renderer.addClass(this.el.nativeElement, 'ellipsisText');
      if (this.hasOverflow) {
        this.nzTooltip.trigger = 'hover';
      } else {
        this.nzTooltip.trigger = null;
      }
    } else {
      this.nzTooltip.trigger = null;
    }
  }
}
