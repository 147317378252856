
<nz-card class="symmetric display-flex align-items-center justify-content-center background-transparent z-index-1" [ngClass]="{'height-0': true}">
  <div class="display-flex align-items-center justify-content-center" [ngClass]="{'loadingPlacements': isLoading}">
    <ng-container *ngIf="isLoading">
      <nz-spin [nzSpinning]="isLoading" nzSimple nzSize="small"></nz-spin>
      <span class="padding-left-small small-font bold-font font-icon-color-darker"> Checking for placements</span>
    </ng-container>
    <ng-container *ngIf="!isLoading">
        <nz-alert nzType="info" [nzMessage]="noPlacementsTpl" class="margin-top-1"></nz-alert>
        <ng-template #noPlacementsTpl>
          <fa-icon icon="info" class="fa-lg font-icon-color-darker"></fa-icon>
          <span class="padding-left-small"> In order to create {{ ['a','e','i','o','u'].includes(createItem[0].toLowerCase()) ? 'an ' : 'a ' }} <span class="blue-bold-font">{{createItem}}</span>
            please create an <span class="blue-bold-font">Ad Placement</span> in <span class="blue-bold-font">Integration Details</span>
          </span>
        </ng-template>
    </ng-container>
  </div>
</nz-card>

