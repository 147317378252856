import {Injectable} from '@angular/core';
import {ApiService} from '../../../_services/api.service';
import {JapiQuery} from '../../../_models/models';
import _ from 'lodash';


@Injectable({providedIn: 'root'})

export class DomainBundlesService {

  constructor( private api: ApiService ) {}

  buildSearchPublishersQuery(search: string): JapiQuery {
    const operation = _.isNumber(search) ? 'EQUALS' : 'LIKE';
    const fieldName = _.isNumber(search) ? 'publisherId' : 'publisherName';

    return {
      filter: {
        filters: [
          {fieldName: fieldName, operation: operation, value: search},
          {fieldName: 'status', operation: 'EQUALS', value: 'active'}
        ],
        withTrashed: false
      },
      paging: {
        number: 1,
        size: 30
      },
    };
  }

  buildSearchPublishersByIdsQuery(publishersIds: string[]): JapiQuery {
    return {
      filter: {
        filters: [
          {fieldName: 'publisherId', operation: 'IN', value: publishersIds},
        ],
        withTrashed: false
      },
      paging: {
        number: 1,
        size: 30
      },
    };
  }
}
