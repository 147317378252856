import { Action } from '@ngrx/store';
import { CtrlNavigationContext, LastPath } from '../../shared/_models/models';

export const UPDATE_CURRENT_CONTEXT = '[Page Layout] Update current context';
export const UPDATE_LAST_URL = '[Page Layout] Update last url state';

export class UpdateCurrentContext implements Action {
  readonly type = UPDATE_CURRENT_CONTEXT;
  constructor(public payload: { currentContext: CtrlNavigationContext }) {}
}

export class UpdateLastPathAction implements Action {
  readonly type = UPDATE_LAST_URL;
  constructor(public payload: { lastPath: LastPath }) {}
}


export type LayoutActions =
  UpdateCurrentContext |
  UpdateLastPathAction;
