<div class="chart-wrapper" #wrapper [style.min-height]="height + 'px'">
  <nz-empty *ngIf="config === null || (!config.isLoading && (config.data?.length === 0)); else notEmpty"></nz-empty>
  <ng-template #notEmpty>
    <div class="chart">
      <ng-container *ngIf="config.isLoading; else chart">
        <nz-spin nzSimple [nzIndicator]="customSpinner" [style.min-height]="height + 'px'"></nz-spin>
        <ng-template #customSpinner><em nz-icon nzType="loading" style="font-size: 3em;"></em> </ng-template>
      </ng-container>
      <ng-template #chart>
        <ng-container *ngIf="config.data?.length !== 0">
          <ng-container [ngSwitch]="config.type">



            <!-- VERTICAL BAR CHART -->
            <ng-container *ngSwitchCase="'barVertical'">
              <ng-container *ngIf="!isBarChartAllValuesZero">
                <ngx-charts-bar-vertical
                  [view]="[width, height]"
                  [animations]="false"
                  [scheme]="colorScheme"
                  [results]="config.data"
                  [xAxis]="true"
                  [yAxis]="false"
                  [legend]="false"
                  [showXAxisLabel]="false"
                  [showYAxisLabel]="false"
                  [maxXAxisTickLength]="6"
                  [rotateXAxisTicks]="false"
                  [barPadding]="20">
                  <ng-template #tooltipTemplate let-model="model">
                    <ng-container *ngTemplateOutlet="tooltipTemplateContent; context: {model}"></ng-container>
                  </ng-template>
                  <ng-template #seriesTooltipTemplate let-model="model">
                    <ng-container *ngTemplateOutlet="seriesTooltipTemplateContent; context: {model}"></ng-container>
                  </ng-template>
                </ngx-charts-bar-vertical>
              </ng-container>
            </ng-container>



            <!-- HORIZONTAL BAR CHART -->
            <ng-container *ngSwitchCase="'barHorizontal'">
              <ng-container *ngIf="!isBarChartAllValuesZero">
                <ngx-charts-bar-horizontal
                  [view]="[width, height]"
                  [animations]="false"
                  [scheme]="colorScheme"
                  [results]="config.data"
                  [xAxis]="false"
                  [yAxis]="true"
                  [yAxisTicks]="config.yAxis?.ticks"
                  [yAxisTickFormatting]="yAxisFormat"
                  [legend]="false"
                  [showXAxisLabel]="false"
                  [showYAxisLabel]="false"
                  [maxXAxisTickLength]="16"
                  [rotateXAxisTicks]="false"
                  [showDataLabel]="true"
                  [barPadding]="8">
                  <ng-template #tooltipTemplate let-model="model">
                    <ng-container *ngTemplateOutlet="tooltipTemplateContent; context: {model}"></ng-container>
                  </ng-template>
                  <ng-template #seriesTooltipTemplate let-model="model">
                    <ng-container *ngTemplateOutlet="seriesTooltipTemplateContent; context: {model}"></ng-container>
                  </ng-template>
                </ngx-charts-bar-horizontal>
              </ng-container>
            </ng-container>



            <!-- LINE CHART -->
            <ng-container *ngSwitchCase="'line'">
              <ngx-charts-line-chart #lineChartEl
                [view]="[width, height]"
                [animations]="false"
                [customColors]="config.customColors"
                [yAxis]="true"
                [xAxis]="true"
                [xAxisTickFormatting]="xAxisFormat"
                [xAxisTicks]="config.xAxis.ticks"
                [yAxisTickFormatting]="yAxisFormat"
                [curve]="shape.curveLinear"
                [results]="config.data"
                [autoScale]="config.autoScale !== undefined ? config.autoScale : true"
                [timeline]="config.timeline || false"
                [roundDomains]="true"
                [ngClass]="(config.extraClass || '') + (' ' + onePointLineChartsIndexesClass) +
                  (' ' + endOfPartialLineChartIndexesClass)">
                <ng-template #tooltipTemplate let-model="model">
                  <ng-container *ngTemplateOutlet="tooltipTemplateContent; context: {model}"></ng-container>
                </ng-template>
                <ng-template #seriesTooltipTemplate let-model="model">
                  <ng-container *ngTemplateOutlet="seriesTooltipTemplateContent; context: {model}"></ng-container>
                </ng-template>
              </ngx-charts-line-chart>
            </ng-container>



            <!-- PIE/DONUT CHART-->
            <ng-container *ngSwitchCase="'pie'">
              <ngx-charts-pie-chart
                [view]="[width, height]"
                [scheme]="colorScheme"
                [results]="config.data"
                [gradient]="false"
                [legend]="false"
                [labels]="true"
                [doughnut]="true">
              </ngx-charts-pie-chart>
            </ng-container>



            <!-- GAUGE CHART -->
            <ng-container *ngSwitchCase="'gauge'">
              <ngx-charts-gauge
                [view]="[width, height]"
                [scheme]="colorScheme"
                [angleSpan]="355"
                [results]="config.data"
                [max]="gaugeMax"
                [showAxis]="false"
                [legend]="false">
              </ngx-charts-gauge>
            </ng-container>



            <!-- TABLE -->
            <ng-container *ngSwitchCase="'table'">
              <ng-container *ngIf="config.extraData?.table && config.extraData?.tableColumns">
                <app-table
                  [table]="config.extraData?.table"
                  [tableColumns]="config.extraData?.tableColumns"
                  [tableData]="config.data"
                  [isTableLoading]="config.isLoading"
                  [tableType]="'clientSide'"
                  [idProp]="'app-chart-table-'+config.key"
                  [totalRows]="config.data?.length"
                  [isExternal]="false"
                  [hasSizeChanger]="false"
                  [isPaginated]="false"
                  [hasFooter]="false">
                </app-table>
              </ng-container>
            </ng-container>



          </ng-container>
        </ng-container>
      </ng-template>
    </div>
  </ng-template>
</div>




<ng-template #tooltipTemplateContent let-model="model">
  <div class="tooltipItem" [id]="'single_tooltip_' + config.type + '_' + config.key + '_' + model.name">
    <div class="xTickLabel">{{ xAxisFormat(model.name) }} {{ getNameForChartTooltip(model.series) }}</div>
    <div class="singleSeriesValueCont">
      <div class="tooltipDataValue">{{ getTooltipText(model) }}</div>
    </div>
  </div>
</ng-template>

<ng-template #seriesTooltipTemplateContent let-model="model">
  <div class="tooltipItem" [id]="'series_tooltip_' + config.type + '_' + config.key + '_' + model[0].name">
    <div class="xTickLabel">{{ xAxisFormat(model[0].name) }}</div>
    <div class="seriesValuesCont">
      <ng-container *ngFor="let tooltipItem of model; let idx = index" class="tooltipItemDataLine">
        <ng-container *ngIf="showTooltipItem(tooltipItem, model, idx)">
          <div class="tooltipDataLabel margin-right-1">{{ getNameForChartTooltip(tooltipItem.series) }}</div>
          <div class="tooltipDataColor" [style.background-color]="tooltipItem.color"></div>
          <div class="tooltipDataValue">{{ getTooltipText(tooltipItem) }}</div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>
