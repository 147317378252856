import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CurrentUser } from '../../shared/_models/models';

type MainRoute = 'supply' | 'portal' | 'partner';

@Injectable({providedIn: 'root'})
export class AuthGuard  {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private nzNotificationService: NzNotificationService
  ) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;

    if (currentUser && !this.isMainRouteAccessible(currentUser, route)) {
      this.nzNotificationService.warning('Not allowed', 'You do not have the required permissions to access this page.');
      this.router.navigate(['/']);
      return false;
    }

    if (currentUser) {
      // if passed all the other conditions above
      return true;
    }
    // not logged in so redirect to login page with the return url
    const queryParams = (state.url && state.url !== '/') ? {returnUrl: state.url} : null;
    this.router.navigate(['/auth/login'], {queryParams});
    return false;
  }

  private isMainRouteAccessible(user: CurrentUser, route: ActivatedRouteSnapshot): boolean {
    const currentMainRoute = (route.routeConfig.path || route.url?.[0]?.path) as MainRoute;
    if (user?.type === 'INTERNAL') {
      return currentMainRoute === 'supply';
    } else if (user?.type === 'EXTERNAL' && user.partnerId) {
      return currentMainRoute === 'partner';
    } else if (user?.type === 'EXTERNAL') {
      return ['portal', 'spearad', 'account'].some(mainRoute => currentMainRoute === mainRoute);
    }
    return false;
  }
}
