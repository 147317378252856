<div class="error-wrapper">
  <div class="error-container">
    <img alt="not-found">
    <div>
      <h1>404 - Not Found</h1>
      <p>Sorry, the page you visited does not exist</p>
      <button class="primary-button" routerLink="/" nz-button nzType="primary" id="home-link">Back to Home</button>
    </div>
  </div>
</div>
