import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ControlContentAttribute } from '../../_models/models';

@Component({
  selector: 'app-content-controls',
  templateUrl: './content-controls.component.html',
  styleUrls: ['./content-controls.component.less'],
})

export class ContentControlsComponent implements OnInit {

  @Input() parentFormGroup: UntypedFormGroup;

  selectIcons: any = {};
  contentAttributes: Array<ControlContentAttribute> = [
    {displayName: 'Select All', value: 'all', isDisabledForRequest: false, isDisabledForReport: false},
    {displayName: 'Category', value: 'category', isDisabledForRequest: false, isDisabledForReport: false},
    {displayName: 'Channel', value: 'channel', isDisabledForRequest: false, isDisabledForReport: false},
    {displayName: 'Content Rating', value: 'contentrating', isDisabledForRequest: false, isDisabledForReport: false},
    {displayName: 'Episode', value: 'episode', isDisabledForRequest: false, isDisabledForReport: false},
    {displayName: 'Genre', value: 'genre', isDisabledForRequest: false, isDisabledForReport: false},
    {displayName: 'ID', value: 'id', isDisabledForRequest: false, isDisabledForReport: false},
    {displayName: 'Key Words', value: 'keywords', isDisabledForRequest: false, isDisabledForReport: false},
    {displayName: 'Language', value: 'language', isDisabledForRequest: false, isDisabledForReport: false},
    {displayName: 'Length', value: 'length', isDisabledForRequest: false, isDisabledForReport: false},
    {displayName: 'Live Stream', value: 'livestream', isDisabledForRequest: false, isDisabledForReport: false},
    {displayName: 'Network', value: 'network', isDisabledForRequest: false, isDisabledForReport: false},
    {displayName: 'Producer', value: 'producer', isDisabledForRequest: false, isDisabledForReport: false},
    {displayName: 'Production Quality', value: 'productionquality', isDisabledForRequest: false, isDisabledForReport: false},
    {displayName: 'Season', value: 'season', isDisabledForRequest: false, isDisabledForReport: false},
    {displayName: 'Series', value: 'series', isDisabledForRequest: false, isDisabledForReport: false},
    {displayName: 'Title', value: 'title', isDisabledForRequest: false, isDisabledForReport: false}
  ];
  private unsubscribe$ = new Subject<void>();

  constructor() {}

  ngOnInit(): void {
    this.defineContentControls(this.parentFormGroup?.controls.contentAttrRequestExclude.value, 'contentAttrRequestExclude', 'isDisabledForRequest');
    this.defineContentControls(this.parentFormGroup?.controls.contentAttrReportExclude.value, 'contentAttrReportExclude', 'isDisabledForReport');
    this.initFormListenChanges();
  }

  initFormListenChanges(): void {
    this.parentFormGroup?.controls.contentAttrRequestExclude.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.defineContentControls(value, 'contentAttrRequestExclude', 'isDisabledForRequest');
      });
    this.parentFormGroup?.controls.contentAttrReportExclude.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this.defineContentControls(value, 'contentAttrReportExclude', 'isDisabledForReport');
      });
  }

  onChangeSelectIconState(isOpen, id): void {
    this.selectIcons[id] = isOpen;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getTooltipText(): string {
    return this.parentFormGroup?.controls.contentAttrReportExclude.disabled ?
      'When entire content object (All) is excluded from bid requests, it will automatically be excluded from buyer reporting as well' : null;
  }

  private defineContentControls(controlValue: Array<string>, controlName: string, disablePropName: string): void {
    if (controlValue?.includes('all')) {
      this.contentAttributes = this.setContentDisabled(this.contentAttributes, disablePropName, true);
      this.parentFormGroup.get(controlName).setValue(['all'], {emitEvent: false});
    } else {
      this.contentAttributes = this.setContentDisabled(this.contentAttributes, disablePropName, false);
    }
  }

  private setContentDisabled = (list: Array<ControlContentAttribute>, prop: string, val: boolean): Array<ControlContentAttribute> => {
    list.forEach(item => {
      if (item.value !== 'all') {
        item[prop] = val;
      }
    });
    return [...list];
  }

}
