<div class="maintenance-container">
  <header class="display-flex justify-content-center">
    <company-logo-svg class="display-grid" style="width: 200px"></company-logo-svg>
  </header>
  <main>
    <div><img alt="forbidden"></div>
    <div>
      <h1>Under Maintenance</h1>
      <p>We're upgrading our system. Apologies for any inconvenience. We'll be back soon!</p>
    </div>
  </main>
</div>
