import {
  PublisherTrafficSourcesActions,
  FETCH_PUBLISHER_TRAFFIC_SOURCES_REQUEST,
  FETCH_PUBLISHER_TRAFFIC_SOURCES_SUCCESS,
  FETCH_PUBLISHER_TRAFFIC_SOURCES_FAILURE,
  UPDATE_PUBLISHER_TRAFFIC_SOURCES_REQUEST,
  UPDATE_PUBLISHER_TRAFFIC_SOURCES_SUCCESS,
  UPDATE_PUBLISHER_TRAFFIC_SOURCES_FAILURE, CLEAR_STORE_PUBLISHER_TRAFFIC_SOURCES
} from './publisherTrafficSources.actions';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import {PublisherTrafficSource} from '../../../../../shared/_models/models';

export interface PublisherTrafficSourceState {
  publisherTrafficSources: PublisherTrafficSource[];
  isLoading: boolean;
  error?: any;
}

const initialState: PublisherTrafficSourceState = {
  publisherTrafficSources: null,
  isLoading: false,
  error: null
};

export function publisherTrafficSourcesReducer(state = initialState, action: PublisherTrafficSourcesActions) {
  switch (action.type) {
    case FETCH_PUBLISHER_TRAFFIC_SOURCES_REQUEST:
      return {...state, isLoading: true};
    case FETCH_PUBLISHER_TRAFFIC_SOURCES_SUCCESS:
      return {
        ...state,
        publisherTrafficSources: action.payload.publisherTrafficSources,
        isLoading: false
      };
    case FETCH_PUBLISHER_TRAFFIC_SOURCES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      };
    case UPDATE_PUBLISHER_TRAFFIC_SOURCES_REQUEST:
      return {...state, isLoading: true};
    case UPDATE_PUBLISHER_TRAFFIC_SOURCES_SUCCESS:
      return {
        ...state,
        publisherTrafficSources: action.payload.publisherTrafficSources,
        isLoading: false
      };
    case UPDATE_PUBLISHER_TRAFFIC_SOURCES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      };
    case CLEAR_STORE_PUBLISHER_TRAFFIC_SOURCES:
      return {
        ...state,
        publisherTrafficSources: [],
        isLoading: false,
        error: null
      };
    default:
      return state;
  }
}

export const getPublisherTrafficSourcesState = createFeatureSelector<PublisherTrafficSourceState>('publisherTrafficSources');
export const getPublisherTrafficSources = createSelector(getPublisherTrafficSourcesState, (state: PublisherTrafficSourceState) => state.publisherTrafficSources);
export const getPublisherTrafficSourcesLoading = createSelector(getPublisherTrafficSourcesState, (state: PublisherTrafficSourceState) => state.isLoading);

