import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewContainerRef } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import * as fromShared from '../_store/shared.reducer';
import { FeatureToggles, FeatureTogglesKey } from '../_models/models';
import { SharedStoreService } from '../_services/shared-store.service';

@Directive({
  selector: '[appFeatureToggle]'
})
export class FeatureToggleDirective implements OnInit, OnDestroy {

  @Input() appFeatureToggleActive = true;
  @Input() appFeatureToggle: FeatureTogglesKey;
  // Activate else function like in *ngIf="condition; else elseTemplate
  @Input() appFeatureToggleElse: TemplateRef<any>;
  @Output() viewCreated = new EventEmitter<void>();

  private unsubscribe$ = new Subject<void>();
  private unleashFeatureToggles$: Observable<FeatureToggles>;
  private unleashFeatureToggles: FeatureToggles = {};

  constructor(
    private vcr: ViewContainerRef,
    private tpl: TemplateRef<any>,
    private sharedStoreService: SharedStoreService,
  ) {
  }

  static ngTemplateContextGuard(
    directive: FeatureToggleDirective,
    appFeatureToggle: unknown
  ): appFeatureToggle is FeatureTogglesKey {
    return true;
  }

  ngOnInit(): void {
    this.unleashFeatureToggles$ = this.sharedStoreService.getSharedAsset('featureToggles');
    this.unleashFeatureToggles$.pipe(
      filter(featureToggles => featureToggles !== null),
      filter(featureToggles => !Object.values(featureToggles).every(value => value === null)),
      takeUntil(this.unsubscribe$)
    ).subscribe(res => {
      if (res && this.unleashFeatureToggles[this.appFeatureToggle] !== res[this.appFeatureToggle]) {
        this.unleashFeatureToggles = {...res};
        this.vcr.clear();
        if (!this.appFeatureToggleActive || this.unleashFeatureToggles[this.appFeatureToggle]) {
          this.vcr.createEmbeddedView(this.tpl);
          this.viewCreated.emit();
        } else if (this.appFeatureToggleElse) {
          this.vcr.createEmbeddedView(this.appFeatureToggleElse);
        }
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
