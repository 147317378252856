import { Injectable } from '@angular/core';
import { ApiService } from '../../../shared/_services/api.service';
import { Observable } from 'rxjs';
import { CommonDataModel, DealExternalSync, JapiQuery, ResponseFromServer, SyncType } from '../../../shared/_models/models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DealExternalSyncService {

  constructor(private apiService: ApiService) {
  }

  getDealExternalSyncId(dealId: number): Observable<DealExternalSync> {
    return this.apiService.getDealExternalSyncId(dealId).pipe(
      map(dealExternalSync => {
        if (dealExternalSync?.syncType) {
          dealExternalSync.syncType = dealExternalSync.syncType.toLowerCase() as SyncType;
        }
        return dealExternalSync;
      })
    );
  }

  syncDealExternal(dealId: number): Observable<DealExternalSync> {
    return this.apiService.syncDealExternal(dealId);
  }

  retrieveDealExternalSyncStatus(dealId: number): Observable<DealExternalSync> {
    return this.apiService.retrieveDealExternalSyncStatus(dealId);
  }

  getDealExternalSyncLogs(dealId: number, query: JapiQuery = {}): Observable<CommonDataModel<DealExternalSync[]>> {
    return this.apiService.getDealExternalSyncLogs(dealId, query);
  }

  getDealExchangeExternalSyncId(dealId: number): Observable<ResponseFromServer<DealExternalSync>> {
    return this.apiService.getDealExchangeExternalSyncId(dealId);
  }

  syncDealExchangeExternal(dealId: number): Observable<ResponseFromServer<DealExternalSync>> {
    return this.apiService.syncDealExchangeExternal(dealId);
  }

  retrieveDealExchangeExternalSyncStatus(dealId: number): Observable<ResponseFromServer<DealExternalSync>> {
    return this.apiService.retrieveDealExchangeExternalSyncStatus(dealId);
  }

  getDealExchangeExternalSyncLogs(dealId: number, params: string): Observable<CommonDataModel<DealExternalSync[]>> {
    return this.apiService.getDealExchangeExternalSyncLogs(dealId, params);
  }
}
