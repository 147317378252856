import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Common } from '../_common/common.helper';

@Injectable()
export class FilenameInterceptor implements HttpInterceptor {

  constructor(
    private common: Common
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        let filename = '';

        if (request.headers.get('use-server-filename')) {
          const disposition = event.headers.get('content-disposition');
          if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '');
            }
          }
        }

        if (request.headers.get('download-file')) {
          this.common.downloadFile(event.body, filename);
        }
      }

      return event;
    }));
  }
}
