import {
  AudienceTargetingActions,
  CLEAR_STORE_AUDIENCE_TARGETING,
  FETCH_SEGMENT_LIST_REQUEST,
  FETCH_SEGMENT_LIST_SUCCESS,
  FETCH_SEGMENT_LIST_FAILURE,
  UPDATE_MODAL_FILTERS,
  FETCH_SEGMENT_PROVIDER_LIST_REQUEST,
  FETCH_SEGMENT_PROVIDER_LIST_SUCCESS,
  FETCH_SEGMENT_PROVIDER_LIST_FAILURE,
  FETCH_SEGMENT_SORT_BY_LIST_FAILURE,
  FETCH_SEGMENT_SORT_BY_LIST_REQUEST,
  FETCH_SEGMENT_SORT_BY_LIST_SUCCESS,
  FETCH_TOTAL_MAX_PRICE_REQUEST,
  FETCH_TOTAL_MAX_PRICE_SUCCESS,
  FETCH_TOTAL_MAX_PRICE_FAILURE,
} from './audience-targeting.actions';

import { createFeatureSelector, createSelector } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { AppFilter, AudienceTargetingSegment, FilterSelectOption } from '../../../shared/_models/models';
import { audienceTargetingModelFiltersConfig } from '../_services/audience-targeting.config';

export interface AudienceTargetingState {
  modalFilters: AppFilter[];
  segmentProviders: FilterSelectOption[];
  segmentSortByList: FilterSelectOption[];
  totalMaxPrice: number;
  totalElements: number;
  segments: AudienceTargetingSegment[];
  isLoading: boolean;
  error?: any;
}

const initialState: AudienceTargetingState = {
  modalFilters: cloneDeep(audienceTargetingModelFiltersConfig),
  segmentProviders: [],
  segmentSortByList: [],
  totalMaxPrice: null,
  totalElements: null,
  segments: null,
  isLoading: false,
  error: null
};

export function audienceTargetingReducer(state = initialState, action: AudienceTargetingActions) {
  switch (action.type) {
    case UPDATE_MODAL_FILTERS:
      return {...state, modalFilters: action.payload.modalFilters};
    case FETCH_SEGMENT_LIST_REQUEST:
      return {...state, isLoading: true};
    case FETCH_SEGMENT_LIST_SUCCESS:
      return {
        ...state,
        segments: action.payload.segments,
        totalElements: action.payload.totalElements,
        isLoading: false,
      };
    case FETCH_SEGMENT_PROVIDER_LIST_REQUEST:
      return {...state, isLoading: true};
    case FETCH_SEGMENT_PROVIDER_LIST_SUCCESS:
      return {
        ...state,
        segmentProviders: action.payload.segmentProviders,
        modalFilters: action.payload.updateAppFilters(
          state.modalFilters, 'audienceTargetingModelProvider', action.payload.segmentProviders
        ),
        isLoading: false,
      };
    case FETCH_SEGMENT_SORT_BY_LIST_REQUEST:
      return {...state, isLoading: true};
    case FETCH_SEGMENT_SORT_BY_LIST_SUCCESS:
      return {
        ...state,
        segmentSortByList: action.payload.segmentSortByList,
        modalFilters: action.payload.updateAppFilters(
          state.modalFilters, 'audienceTargetingModelSortBy', action.payload.segmentSortByList
        ),
        isLoading: false,
      };
    case FETCH_TOTAL_MAX_PRICE_REQUEST:
      return {...state, isLoading: true};
    case FETCH_TOTAL_MAX_PRICE_SUCCESS:
      return {
        ...state,
        totalMaxPrice: action.payload.totalMaxPrice,
        isLoading: false,
      };
    case CLEAR_STORE_AUDIENCE_TARGETING:
      const clearedState = {...initialState};
      return clearedState;
    case FETCH_TOTAL_MAX_PRICE_FAILURE:
    case FETCH_SEGMENT_PROVIDER_LIST_FAILURE:
    case FETCH_SEGMENT_SORT_BY_LIST_FAILURE:
    case FETCH_SEGMENT_LIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}

export const getAudienceTargetingState = createFeatureSelector<AudienceTargetingState>('audienceTargeting');
export const getModalFilters = createSelector(getAudienceTargetingState, (state: AudienceTargetingState) => state.modalFilters);
export const getSegments = createSelector(getAudienceTargetingState, (state: AudienceTargetingState) => state.segments);
export const getTotalMaxPrice = createSelector(getAudienceTargetingState, (state: AudienceTargetingState) => state.totalMaxPrice);
export const getTotalElements = createSelector(getAudienceTargetingState, (state: AudienceTargetingState) => state.totalElements);
export const getIsLoading = createSelector(getAudienceTargetingState, (state: AudienceTargetingState) => state.isLoading);

