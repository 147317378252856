<form nz-form [formGroup]="form">
  <div class="sectionTitle">
    <fa-icon icon="key"></fa-icon>
    Password
  </div>
  <div nz-row *ngIf="showChangePassword && form.get('verified')?.value" class="small-font margin-bottom-1">
    <nz-col nzLg="16" nzMd="24" nzSm="24">
      <nz-alert nzType="info" nzMessage="Please remember to copy the password before saving any changes"></nz-alert>
    </nz-col>
  </div>
  <div nz-row nzGutter="16">
    <ng-container *ngIf="showChangePassword">
      <nz-col nzLg="8" nzMd="12" nzSm="24">
        <nz-form-item>
          <nz-form-label [nzRequired]="updateOtherUserMode && (userInput && !userInput.id)">Password</nz-form-label>
          <nz-form-control
            [nzValidateStatus]="(form.get('password')?.invalid && form.get('password')?.touched) ? 'error' : null"
            [nzErrorTip]="passwordErrorTipTpl">
            <nz-input-group [nzSuffix]="showPassBtnTpl">
              <input #passwordInputEl formControlName="password" nz-input placeholder="Enter a password"
                     [type]="isPasswordVisible ? 'text' : 'password'"
                     autocomplete="new-password" name="password" id="update_user_password" style="padding-right: 5em;">
            </nz-input-group>
            <ng-template #passwordErrorTipTpl>
              <app-password-validator [form]="form" [type]="'Password'"></app-password-validator>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
      <nz-col nzLg="8" nzMd="12" nzSm="24">
        <nz-form-item>
          <nz-form-label [nzRequired]="updateOtherUserMode && (userInput && !userInput.id)">Confirm password
          </nz-form-label>
          <nz-form-control
            [nzValidateStatus]="((form?.hasError('notSamePassword') || form.get('passwordConfirmation')?.invalid) && form.get('passwordConfirmation')?.touched) ? 'error' : null"
            [nzErrorTip]="passwordConfirmErrorTipTpl">
            <nz-input-group [nzSuffix]="showConfPassBtnTpl">
              <input nz-input placeholder="Confirm your password"
                     [type]="isConfPasswordVisible ? 'text' : 'password'"
                     formControlName="passwordConfirmation" name="passwordConfirmation"
                     id="update_user_passwordConfirmation" style="padding-right: 5em;">
            </nz-input-group>
            <ng-template #passwordConfirmErrorTipTpl>
              <app-password-validator [form]="form" [type]="'PasswordConfirmation'"></app-password-validator>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </nz-col>
    </ng-container>
    <nz-col nzLg="8" nzMd="12" nzSm="24">
      <nz-form-item>
        <nz-form-control>
          <div class="display-flex">
            <button nz-button *ngIf="showChangePassword"
                    id="update_user_generatePasswordBtn" style="transition: none;"
                    class="small-font secondary-button match-form-item-height small-height small-padding"
                    [disabled]="form?.controls.password.disabled"
                    (click)="generatePassword(); $event.preventDefault()">
              Generate Password
            </button>
            <button nz-button *ngIf="isUpdating"
                    [nzType]="showChangePassword ? 'link' : null"
                    id="update_user_changePasswordBtn" style="transition: none; outline: none;"
                    class="small-font" [ngClass]="!showChangePassword ? 'secondary-button' : 'match-form-item-height'"
                    (click)="togglePasswordChange(); $event.preventDefault()">
              {{showChangePassword ? 'Cancel' : 'Change Password'}}
            </button>
          </div>
        </nz-form-control>
      </nz-form-item>
    </nz-col>
  </div>
</form>

<ng-template #showPassBtnTpl>
  <div class="display-flex align-items-center">
    <span class="smaller-font bold-font font-icon-color-darker margin-right-small background-white"
          *ngIf="isCopied_password" @fadeOut>Copied to clipboard</span>
    <fa-icon icon="copy" *ngIf="form.get('password').value" (click)="copyToClipboard('password')"
             class="font-icon-color-darker cursor-pointer margin-right-small" id="copyToClipboardBtn-pass"
             style="background: #fff;"></fa-icon>
    <fa-icon [icon]="!isPasswordVisible ? 'eye' : 'eye-slash'" (click)="isPasswordVisible = !isPasswordVisible"
             class="font-icon-color-darker cursor-pointer" id="togglePassVisibilityBtn-pass"></fa-icon>
  </div>
</ng-template>
<ng-template #showConfPassBtnTpl>
  <div class="display-flex align-items-center">
    <span class="smaller-font bold-font font-icon-color-darker margin-right-small background-white"
          *ngIf="isCopied_passwordConfirmation" @fadeOut>Copied to clipboard</span>
    <fa-icon icon="copy" *ngIf="form.get('passwordConfirmation').value"
             (click)="copyToClipboard('passwordConfirmation')"
             class="font-icon-color-darker cursor-pointer margin-right-small" id="copyToClipboardBtn-confPass"
             style="background: #fff;"></fa-icon>
    <fa-icon [icon]="!isConfPasswordVisible ? 'eye' : 'eye-slash'"
             (click)="isConfPasswordVisible = !isConfPasswordVisible"
             class="font-icon-color-darker cursor-pointer" id="togglePassVisibilityBtn-confPass"></fa-icon>
  </div>
</ng-template>
