import { Component, Input } from '@angular/core';
import { WarningBoxType } from '../../_models/models';

@Component({
  selector: 'app-warning-box',
  templateUrl: './warning-box.component.html',
  styleUrls: ['./warning-box.component.less']
})
export class WarningBoxComponent {
  @Input() type: WarningBoxType;
  @Input() boxTitle: string;
  @Input() extraDataTitle: string;
  @Input() boxContent: string;
  @Input() extraData: any;
  @Input() attrClass: string;


}
