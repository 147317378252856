import { Injectable } from '@angular/core';
import { PreloadingStrategy, RouteConfigLoadEnd, Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { StrategyRoute } from '../../app-routing.module';

export type RouteStrategyType = 'external_preload' | 'internal_preload' | 'lazy' | 'preload';

@Injectable({
  providedIn: 'root'
})
export class CustomPreloadingStrategyService implements PreloadingStrategy {

  currentRouteStrategyType: RouteStrategyType;

  constructor(router: Router) {
    router.events.pipe(
      filter(event => event instanceof RouteConfigLoadEnd)
    )
      .subscribe((r: RouteConfigLoadEnd) => {
        this.currentRouteStrategyType = r.route.data?.routeSectionType;
      });
  }

  preload(route: StrategyRoute, load: () => Observable<any>): Observable<any> {
    // console.log(route.data?.routeSectionType, this.currentRouteStrategyType, route.path);
    if (route.data?.routeSectionType === 'preload' ||
      (route.data?.routeSectionType !== 'lazy' && route.data?.routeSectionType === this.currentRouteStrategyType)) {
      return load();
    } else {
      return EMPTY;
    }
  }
}
