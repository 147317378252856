import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { SanitizerType } from '../_models/models';
import * as DOMPurify from 'dompurify';

@Pipe({
  name: 'sanitizer'
})
export class SanitizerPipe implements PipeTransform {

  constructor(private domSanitizer: DomSanitizer) {
  }

  public transform(value: number | string, type: SanitizerType): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    if (value) {
      switch (type) {
        case 'html':
        {
          const sanitizedHtml = DOMPurify.sanitize(value, { USE_PROFILES: { html: true } });
          return this.domSanitizer.bypassSecurityTrustHtml(sanitizedHtml);
        }
        case 'style':
        {
          const sanitizedStyle = DOMPurify.sanitize(value, { ADD_ATTR: ['style'] });
          return this.domSanitizer.bypassSecurityTrustStyle(sanitizedStyle);
        }
        case 'resourceUrl':
          return this.domSanitizer.bypassSecurityTrustResourceUrl(value as string);
        default:
          throw new Error(`Invalid safe type specified: ${type}`);
      }
    }
  }
}
