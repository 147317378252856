import { Component } from '@angular/core';
import { AuthenticationService } from '../../../_services';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.less']
})
export class ForbiddenComponent {

  constructor(
    public auth: AuthenticationService,
  ) {}

  logout() {
    this.auth.logout().subscribe();
  }

}
