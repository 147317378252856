import {AppFilter, AppTable, AppTableColumn} from '../../_models/models';


export const blockedAdvertisersTableConfig: AppTable = new AppTable('badvTable', 1, 10, 'badvName',
  'asc', true, true);

export const blockedAdvertisersTableColumns: AppTableColumn[] = [
  {id: 'id', prop: 'badvName', displayName: 'Blocked Advertisers', type: 'text', isSortEnabled: false},
  {
    id: 'badvActions', prop: 'actions', displayName: 'Actions', type: 'actions', isSortEnabled: false,
    actions: [
      {
        id: 'badvDelete', name: 'delete', auth: 'canDelete', displayName: 'Delete',
        desc: 'Are you sure you wish to delete this blocked advertiser?'
      },
    ]
  }
];

export const blockedAdvertisersFiltersConfig: AppFilter[] = [
  new AppFilter('blockedAdvertisersSearchNameFilter', 'SEARCH', '', 'Search', 'Search for blocked advertisers',
    'default', true, true,
    {nameProp: 'badvName'}, undefined, undefined,
    undefined, undefined, undefined, undefined, undefined,
    undefined, undefined, undefined, undefined, undefined,
    undefined),
];

export const blockedAdvertisersTableButtons = [
  {
    id: 'BlockedAdvertisersDownloadCsvButton', type: 'SECONDARY-BUTTON', title: 'Download CSV',
    hasSpinner: false, auth: 'canRead', action: 'download', isDisabled: false,
    customClass: 'small-font small-height small-padding'
  },
  {
    id: 'BlockedAdvertisersDeleteAllButton', type: 'DELETE-ALL-BUTTON', title: 'Delete All Blocked Advertisers', action: 'deleteAll',
    hasLoadingSpinner: false, auth: 'canDelete', desc: 'Are you sure you wish to delete all Blocked Advertisers?', isDisabled: false,
    customClass: 'small-font small-height small-padding'
  },
];
