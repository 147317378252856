import { Action } from '@ngrx/store';

export const FETCH_TRAFFIC_SOURCES_REQUEST = '[Traffic sources] Fetch traffic sources request';
export const FETCH_TRAFFIC_SOURCES_SUCCESS = '[Traffic sources] Fetch traffic sources success';
export const FETCH_TRAFFIC_SOURCES_FAILURE = '[Traffic sources] Fetch traffic sources failure';

export const FETCH_TRAFFIC_SOURCE_BY_ID_REQUEST = '[Traffic sources] Fetch traffic source by id request';
export const FETCH_TRAFFIC_SOURCE_BY_ID_SUCCESS = '[Traffic sources] Fetch traffic source by id success';
export const FETCH_TRAFFIC_SOURCE_BY_ID_FAILURE = '[Traffic sources] Fetch traffic source by id failure';
export const REMOVE_TRAFFIC_SOURCE_BY_ID_FROM_STATE = '[Traffic sources] REMOVE traffic source by id from state';

export const CREATE_TRAFFIC_SOURCE_REQUEST = '[Traffic sources] Create traffic source request';
export const CREATE_TRAFFIC_SOURCE_SUCCESS = '[Traffic sources] Create traffic source success';
export const CREATE_TRAFFIC_SOURCE_FAILURE = '[Traffic sources] Create traffic source failure';

export const EDIT_TRAFFIC_SOURCE_REQUEST = '[Traffic sources] Update traffic source request';
export const EDIT_TRAFFIC_SOURCE_SUCCESS = '[Traffic sources] Update traffic source success';
export const EDIT_TRAFFIC_SOURCE_FAILURE = '[Traffic sources] Update traffic source failure';

export const DELETE_TRAFFIC_SOURCE_REQUEST = '[Traffic sources] Delete traffic source request';
export const DELETE_TRAFFIC_SOURCE_SUCCESS = '[Traffic sources] Delete traffic source success';
export const DELETE_TRAFFIC_SOURCE_FAILURE = '[Traffic sources] Delete traffic source failure';

export const FETCH_SSPS_REQUEST = '[Traffic sources] Fetch ssps request';
export const FETCH_SSPS_SUCCESS = '[Traffic sources] Fetch ssps success';
export const FETCH_SSPS_FAILURE = '[Traffic sources] Fetch ssps failure';

export const SET_TRAFFIC_SOURCES_FILTERS = '[Traffic sources] Set filters';
export const EDIT_TRAFFIC_SOURCES_FILTERS = '[Traffic sources] Update filters';

export class FetchTrafficSourcesRequestAction implements Action {
  readonly type = FETCH_TRAFFIC_SOURCES_REQUEST;
}
export class FetchTrafficSourcesSuccessAction implements Action {
  readonly type = FETCH_TRAFFIC_SOURCES_SUCCESS;
  constructor(public payload: { trafficSources: any[] }) {
  }
}
export class FetchTrafficSourcesFailureAction implements Action {
  readonly type = FETCH_TRAFFIC_SOURCES_FAILURE;
  constructor(public payload: { error: string }) {
  }
}

export class FetchTrafficSourceByIdRequestAction implements Action {
  readonly type = FETCH_TRAFFIC_SOURCE_BY_ID_REQUEST;
}
export class FetchTrafficSourceByIdSuccessAction implements Action {
  readonly type = FETCH_TRAFFIC_SOURCE_BY_ID_SUCCESS;
  constructor(public payload: { editedTrafficSource: any }) {
  }
}
export class FetchTrafficSourceByIdFailureAction implements Action {
  readonly type = FETCH_TRAFFIC_SOURCE_BY_ID_FAILURE;
  constructor(public payload: { error: string }) {
  }
}
export class RemoveTrafficSourceByIdFromState implements Action {
  readonly type = REMOVE_TRAFFIC_SOURCE_BY_ID_FROM_STATE;
}

export class CreateTrafficSourceRequestAction implements Action {
  readonly type = CREATE_TRAFFIC_SOURCE_REQUEST;
}
export class CreateTrafficSourceSuccessAction implements Action {
  readonly type = CREATE_TRAFFIC_SOURCE_SUCCESS;
  constructor(public payload: { editedTrafficSource: any }) {
  }
}
export class CreateTrafficSourceFailureAction implements Action {
  readonly type = CREATE_TRAFFIC_SOURCE_FAILURE;
  constructor(public payload: { error: string }) {
  }
}

export class EditTrafficSourceRequestAction implements Action {
  readonly type = EDIT_TRAFFIC_SOURCE_REQUEST;
}
export class EditTrafficSourceSuccessAction implements Action {
  readonly type = EDIT_TRAFFIC_SOURCE_SUCCESS;
  constructor(public payload: { editedTrafficSource: any }) {
  }
}
export class EditTrafficSourceFailureAction implements Action {
  readonly type = EDIT_TRAFFIC_SOURCE_FAILURE;
  constructor(public payload: { error: string }) {
  }
}

export class DeleteTrafficSourceRequestAction implements Action {
  readonly type = DELETE_TRAFFIC_SOURCE_REQUEST;
}
export class DeleteTrafficSourceSuccessAction implements Action {
  readonly type = DELETE_TRAFFIC_SOURCE_SUCCESS;
  constructor(public payload: { deleteTrafficSourceId: number | string }) {
  }
}
export class DeleteTrafficSourceFailureAction implements Action {
  readonly type = DELETE_TRAFFIC_SOURCE_FAILURE;
  constructor(public payload: { error: string }) {
  }
}


export class FetchSspsRequestAction implements Action {
  readonly type = FETCH_SSPS_REQUEST;
}
export class FetchSspsSuccessAction implements Action {
  readonly type = FETCH_SSPS_SUCCESS;
  constructor(public payload: { ssps: any[] }) {
  }
}
export class FetchSspsFailureAction implements Action {
  readonly type = FETCH_SSPS_FAILURE;
  constructor(public payload: { error: string }) {
  }
}

export type TrafficSourcesActions =
  FetchTrafficSourcesRequestAction |
  FetchTrafficSourcesSuccessAction |
  FetchTrafficSourcesFailureAction |
  FetchTrafficSourceByIdRequestAction |
  FetchTrafficSourceByIdSuccessAction |
  FetchTrafficSourceByIdFailureAction |
  RemoveTrafficSourceByIdFromState |
  CreateTrafficSourceRequestAction |
  CreateTrafficSourceSuccessAction |
  CreateTrafficSourceFailureAction |
  EditTrafficSourceRequestAction |
  EditTrafficSourceSuccessAction |
  EditTrafficSourceFailureAction |
  DeleteTrafficSourceRequestAction |
  DeleteTrafficSourceSuccessAction |
  DeleteTrafficSourceFailureAction |
  FetchSspsRequestAction |
  FetchSspsSuccessAction |
  FetchSspsFailureAction;
