import {
  AppFilter,
  AppTable,
  AppTableColumn,
  BasicModal,
  ContentLivestream,
  InventoryPackage,
  DropdownButtonsConfig,
  ResponseFromServer
} from '../../../shared/_models/models';

export const inventoryPackagesTableConfig: AppTable = new AppTable('inventoryPackagesTable', 1, 10, 'createdAt', 'DESC');

export const inventoryPackagesPermissionsConfig = {
  read: 'supply.publisher.package.read',
  create: 'supply.publisher.package.create',
  update: 'supply.publisher.package.update',
  delete: 'supply.publisher.package.delete'
};

export const inventoryPackagesPermissionsConfigForExternal = {
  read: 'publisher.deal.show',
  create: 'publisher.deal.show',
  update: 'publisher.deal.show',
  delete: 'publisher.deal.show'
};

export const inventoryPackagesFiltersConfig: AppFilter[] = [
  new AppFilter('inventoryPackagesSearchFilter', 'SEARCH', '', 'Package ID/Name', 'Search by package id/name',
    'default', true, true, {nameProp: 'description', idProp: 'packageId'}),
  new AppFilter('inventoryPackagesPublishersFilter', 'SELECT', 'publisherId', 'Publisher', 'Filter by publishers id/name',
    'multiple', true, false, null,
    [], []),
];

export const inventoryPackagesTableButtons = [
  {id: 'inventoryPackagesCreateButton', type: 'PRIMARY-BUTTON', title: 'Create Package', hasLoadingSpinner: false, auth: 'canCreate'}
];

export const inventoryPackagesTableColumns: AppTableColumn[] = [
  {id: 'inventoryPackagesPackageId', prop: 'packageId', displayName: 'Inventory Package ID', type: 'text', isSortEnabled: true},
  {id: 'inventoryPackagesPackageName', prop: 'description', displayName: 'Inventory Package Name', type: 'text', isSortEnabled: true},
  {id: 'inventoryPackagesCreationDate', prop: 'createdAt', displayName: 'Created At (EST)', type: 'date', isSortEnabled: true},
  {id: 'inventoryPackagesDeals', prop: 'publisherDeals', displayName: 'Associated Deals', type: 'moreTag',
    isSortEnabled: false,
    // TODO: check associated deals column is working properly
    moreTag: {prop: 'pubDealId', displayNameProp: 'description', popoverTitle: 'More deals', linked: true },
    valueFormatter: (dealId: string): string[] => ['..', 'deals', dealId, 'edit'],
  },
  {
    id: 'inventoryPackagesActions', prop: 'actions', displayName: 'Actions', type: 'actions',
    actions: [
      {id: 'inventoryPackagesEdit', name: 'edit', auth: 'canUpdate', displayName: 'Edit', hrefLink: '/edit', hrefIdProp: 'packageId'},
      // {id: 'inventoryPackagesRunForecast', name: 'runForecast', auth: 'canRead', displayName: 'Run Forecast'},
      {id: 'inventoryPackageSelectForDeal', name: 'startDeal', auth: 'canUpdate', displayName: 'Select for new deal'},
      {id: 'inventoryPackagesDelete', name: 'new-delete', auth: 'canDelete', displayName: 'Delete'},
    ]
  }
];

export const inventoryPackagesFormSubmitButtons: DropdownButtonsConfig = {
  main: {id: 'savePackage', label: 'Save Package', buttonType: 'primary-button'},
  options: [
    {id: 'savePackageAndRunForecast', label: 'Save and Run Forecast'},
    {id: 'savePackageAndSelectNewDeal', label: 'Save and Use for a New Deal'},
  ],
};

export const inventoryPackagesImpressionTypes = [
  {title: 'Banner', value: 'BANNER', key: 'BANNER'},
  {title: 'Native', value: 'NATIVE', key: 'NATIVE'},
  {title: 'Video', value: 'VIDEO', key: 'VIDEO'},
  {title: 'Audio', value: 'AUDIO', key: 'AUDIO'},
];

export const packageConfigTypes = [
  {title: 'Standard', value: 'STANDARD', key: 'STANDARD'},
  {title: 'BVOD Standard', value: 'BVOD', key: 'BVOD'},
  {title: 'BVOD 1st Party Data', value: 'BVOD_FIRST_PARTY_DATA', key: 'BVOD_FIRST_PARTY_DATA'},
];
export const inventoryPackageDeleteModal: BasicModal = {
  buttons: [
    {type: 'CANCEL', text: 'Cancel'},
    {type: 'DELETE', text: 'Yes, Delete It'}]
};

export const inventoryPackagesViewabilityPercents = ['None', '50%', '60%', '70%', '75%'];

export const packagesMock: ResponseFromServer<InventoryPackage[]> = {
  paginated: false,
  data: [{
    packageId: 1,
    placements: [],
    excludedPlacements: [],
    selectAllPlacements: false,
    includeFuturePlacements: false,
    status: 'ACTIVE',
    description: 'desc',
    packageConfigType: 'STANDARD',
    notes: 'notes',
    filterCountry: null,
    filterCountryList: null,
    filterZipCode: null,
    filterZipCodeList: null,
    filterDma: null,
    filterDmaList: null,
    filterRegion: null,
    filterRegionList: null,
    filterAdsize: null,
    filterAdsizeList: null,
    filterVideosize: null,
    filterVideosizeList: null,
    filterImpType: null,
    filterGenre: null,
    filterGenreList: null,
    filterContentRating: null,
    filterContentRatingList: null,
    filterContentLanguage: null,
    filterContentLanguageList: null,
    filterDeviceType: '{"ctv": 1, "settopbox": 1, "connectedtv": 1}',
    filterSite: null,
    filterApp: null,
    filterPreroll: false,
    filterMidroll: false,
    filterPostroll: false,
    filterViewabilityPercent: 10,
    filterCtrPercent: 10,
    filterVcrPercent: 10,
    debugEnd: null,
    filterContentLivestream: ContentLivestream.LIVE,
    domainDealPackages: [],
  } as InventoryPackage,
  {
    packageId: 2,
    placements: [],
    excludedPlacements: [],
    selectAllPlacements: false,
    includeFuturePlacements: false,
    status: 'INACTIVE',
    packageConfigType: 'STANDARD',
    description: 'desc',
    notes: 'notes',
    filterCountry: null,
    filterCountryList: null,
    filterZipCode: null,
    filterZipCodeList: null,
    filterDma: null,
    filterDmaList: null,
    filterRegion: null,
    filterRegionList: null,
    filterAdsize: null,
    filterAdsizeList: null,
    filterVideosize: null,
    filterVideosizeList: null,
    filterImpType: null,
    filterGenre: null,
    filterGenreList: null,
    filterContentRating: null,
    filterContentRatingList: null,
    filterContentLanguage: null,
    filterContentLanguageList: null,
    filterDeviceType: '{"mobile": 1}',
    filterSite: null,
    filterApp: null,
    filterPreroll: false,
    filterMidroll: false,
    filterPostroll: false,
    filterViewabilityPercent: 10,
    filterCtrPercent: 10,
    filterVcrPercent: 10,
    debugEnd: null,
    filterContentLivestream: ContentLivestream.LIVE,
    domainDealPackages: [],
  } as InventoryPackage]
};

