import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { skip, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { cloneDeep, sortBy } from 'lodash';

import {
  AppFilter,
  AppTable,
  AppTableColumn,
  Audit,
  CommonDataModel,
  JapiQuery,
  CtrlUser, ResponseFromServer, Country
} from '../../_models/models';
import { ApiService } from '../../_services/api.service';
import { SharedService } from '../../_services/shared.service';
import {
  auditEndpoints,
  auditFiltersConfig,
  auditTableButtons,
  AuditTranslatableEntities,
  publisherAuditTableColumns,
  publisherAuditTableConfig,
  publisherFieldsToUiMapping
} from './audit.config';
import { NzTreeNode } from 'ng-zorro-antd/tree';
import { SharedStoreService } from '../../_services/shared-store.service';
import { sharedAssetsConfigs } from '../../_services/shared-store.config';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.less']
})
export class AuditComponent implements OnInit, OnDestroy {

  @Input() auditType: 'PUBLISHER' | 'DEAL';
  @Input() publisherId: number;
  @Input() portalType: string;
  @Input() auditTranslatableEntities: AuditTranslatableEntities;

  audit$: Observable<CommonDataModel<Audit[]>>;
  audit = new CommonDataModel<Audit[]>();
  auditFilters: AppFilter[] = cloneDeep(auditFiltersConfig);
  auditTableColumns: AppTableColumn[];
  auditTable: AppTable;
  auditTableButtons = auditTableButtons;

  TRANSLATABLE_ENTITIES_KEYS: string[] = ['bcat', 'btype', 'battr', 'dspAccountExcludeList', 'cat', 'country'];
  GEO_FLOORS_ALL_COUNTRIES = '**';

  private unsubscribe$ = new Subject<void>();
  private publisherFieldsToUiMapping = publisherFieldsToUiMapping;

  constructor(private apiService: ApiService,
    private sharedService: SharedService,
    private sharedStoreService: SharedStoreService) {
  }

  ngOnInit(): void {
    // should be the same for all models, but if diff required - add switch case per entity
    this.auditTableColumns = cloneDeep(publisherAuditTableColumns);
    this.auditTable = {...publisherAuditTableConfig};

    this.sharedStoreService.getSharedAsset(sharedAssetsConfigs.countries.key)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: ResponseFromServer<Country[]>) => this.auditTranslatableEntities.countries = res.data);

    // request data by entity type
    this.loadAudit();
    this.adjustFiltersByPortal();
    this.loadUsers('PUBLISHER');
    if (this.portalType === 'INTERNAL') {
      this.loadUsers('INTERNAL');
    }
  }

  public adjustFiltersByPortal(): void {
    if (this.portalType !== 'INTERNAL') {
      const findIndex = this.auditFilters.findIndex(f => f.id === 'auditInternalUser');
      if (findIndex > -1) {
        this.auditFilters[findIndex].isHidden = true;
      }
    }
  }

  public onFiltersChange(event: { filters: AppFilter[]; filterId: string }, isResetFilters = false) {
    let newFilters: AppFilter[];
    let currentFilter: AppFilter;

    if (isResetFilters) {
      newFilters = this.sharedService.resetFilterSelectedValues(event.filters);
      newFilters = this.sharedService.updateFilter(newFilters, 'auditPublisherUser',
        'isDisabled', false);
      newFilters = this.sharedService.updateFilter(newFilters, 'auditInternalUser',
        'isDisabled', false);
    } else {
      const filterIndex = this.sharedService.getFilterItemIndex(event.filters, event.filterId);
      currentFilter = event.filters[filterIndex];
      newFilters = event.filters;
      if (event.filters[filterIndex].isRequired) {
        const selectedValues = this.sharedService.getFilterSelectedValues(newFilters, event.filterId);
        newFilters = this.sharedService.updateFilter(newFilters, event.filterId,
          'isValid', selectedValues && selectedValues.length > 0);
        newFilters = this.sharedService.updateFilter(newFilters, event.filterId,
          'errorTooltip',
          'Required filter');
      }

      // special treatment for publisher users and internal users - if one has selections, the other one should be disabled
      if (event.filterId === 'auditPublisherUser' && currentFilter.selectedValues.length > 0) {
        newFilters = this.sharedService.clearSelectedValues(newFilters, 'auditInternalUser');
        newFilters = this.sharedService.updateFilter(newFilters, 'auditInternalUser',
          'isDisabled', true);
      }

      if (event.filterId === 'auditPublisherUser' && currentFilter.selectedValues.length === 0) {
        newFilters = this.sharedService.updateFilter(newFilters, 'auditInternalUser',
          'isDisabled', false);
      }

      if (event.filterId === 'auditInternalUser' && currentFilter.selectedValues.length > 0) {
        newFilters = this.sharedService.clearSelectedValues(newFilters, 'auditPublisherUser');
        newFilters = this.sharedService.updateFilter(newFilters, 'auditPublisherUser',
          'isDisabled', true);
      }

      if (event.filterId === 'auditInternalUser' && currentFilter.selectedValues.length === 0) {
        newFilters = this.sharedService.updateFilter(newFilters, 'auditPublisherUser',
          'isDisabled', false);
      }
    }

    this.auditFilters = cloneDeep(newFilters);
    this.auditTable.pageIndex = 1;
    if (!this.auditTable.sortBy) {
      this.auditTable.sortBy = 'timestamp';
      this.auditTable.sortDirection = 'DESC';
    }
    this.loadAudit();
  }

  loadAudit(): void {
    this.audit.isLoading = true;
    const query = this.sharedService.buildTableQuery(this.auditFilters, this.auditTable);
    const endpoint = auditEndpoints[this.auditType] + this.publisherId + '/audit';
    const datesRange = this.sharedService.getFilterSelectedValues(this.auditFilters, 'auditDatesRange');
    const fromDate = this.sharedService.convertDateToNumberYYYYMMDD(datesRange[0], true);
    const toDate = this.sharedService.convertDateToNumberYYYYMMDD(datesRange[1], true);
    this.audit$ = this.apiService.getAuditData(query, endpoint, fromDate, toDate).pipe(takeUntil(this.unsubscribe$));
    this.audit$.pipe(skip(1)).subscribe(res => this.audit = this.adjustResponse(res));
  }

  private adjustResponse(auditOriginal: CommonDataModel<Audit[]>): CommonDataModel<Audit[]> {
    let adjustedVal;
    const adjustedAuditData: Audit[] = auditOriginal.data?.reduce((result: Audit[], val: Audit) => {
      adjustedVal = this.adjustUiValues(val);
      result.push(adjustedVal);
      return result;
    }, []);
    return {...auditOriginal, data: adjustedAuditData};
  }

  private adjustUiValues(val: Audit): Audit {
    if (!this.auditTranslatableEntities.iabCategoriesFlattened) {
      this.auditTranslatableEntities.iabCategoriesFlattened = this.translateIabCategories(this.auditTranslatableEntities.iabCategories);
    }
    // audit adjustments
    const newVal = this.auditType === 'PUBLISHER' ? this.adjustPublisherAuditFields(val) : {...val};

    if (Array.isArray(newVal.previousValue)) {
      newVal.previousValue = newVal.previousValue.join(', ');
    }
    if (Array.isArray(newVal.newValue)) {
      newVal.newValue = newVal.newValue.join(', ');
    }
    if (newVal.previousValue === null && newVal.newValue === '') {
      newVal.previousValue = 'null';
      newVal.newValue = 'empty string';
    }
    return newVal;
  }

  private adjustPublisherAuditFields(val: Audit): Audit {
    const fields = ['previousValue', 'newValue'];
    if (val.fieldName.toLowerCase().includes('country')) {
      // cover the cases for various country name fields like "countryAlpha2 (Audio)"
      val.fieldName = 'country';
    }
    const configObj: any = this.publisherFieldsToUiMapping[val.fieldName];
    if (!configObj) {
      return { ...val };
    }
    if (this.TRANSLATABLE_ENTITIES_KEYS.includes(val.fieldName)) {
      fields.forEach(field => this.translateField(val, field, configObj));
    }
    val.fieldName = configObj.displayName;
    return { ...val };
  }
  private translateArrayField(val: unknown, field: string, configObj: any): string{
    if (val[field].length === 0) {
      return 'null';
    }
    return this.translateName(configObj, val[field]);
  }

  private translateName(configObj: any, ids: string[]): string {
    const matchList = this.auditTranslatableEntities[configObj.matchListName] || [];
    const resolvedNames = this.getNamesByIds(ids, matchList, configObj.matchProp, configObj.nameProp);
    return resolvedNames.join(',');
  }

  private translateStringField(val: string, configObj: any): string {
    if (val === this.GEO_FLOORS_ALL_COUNTRIES) {
      return 'All Countries';
    }
    if (val === 'null') {
      return 'null';
    }
    const splitIds = val.split(',');
    return this.translateName(configObj, splitIds);
  }

  private translateField(val: unknown, field: string, configObj: any): void {
    if (Array.isArray(val[field])) {
      val[field] = this.translateArrayField(val, field, configObj);
    } else if (val[field]) {
      val[field] = this.translateStringField(val[field], configObj);
    } else {
      val[field] = 'null';
    }
  }

  private getNamesByIds(ids: string[], matchList: unknown[], matchProp: string, nameProp: string): string[] {
    let findIndex: number;
    return ids.reduce((res: string[], val: string) => {
      // should be == due to string ids passed from backend
      findIndex = matchList.findIndex(v => v[matchProp] == val);
      if (findIndex > -1) {
        res.push(matchList[findIndex][nameProp]);
      }
      return res;
    }, []);
  }

  private translateIabCategories(iabCategoriesTree: NzTreeNode[]) {
    return iabCategoriesTree.reduce((res: Array<{ key: string; title: string }>, val) => {
      if (val.children) {
        val.children.forEach(c => res.push({key: c.key, title: c.title}));
      }
      res.push({key: val.key, title: val.title});
      return res;
    }, []);
  }

  private loadUsers(usersType: string): void {
    const filters: JapiQuery = {
      filter: {
        filters: [{
          fieldName: usersType === 'INTERNAL' ? 'type' : 'publisherId',
          operation: 'EQUALS',
          value: usersType === 'INTERNAL' ? 'INTERNAL' : this.publisherId,
        }]
      }
    };
    this.apiService.getUsers(filters).pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      let sortedUsers: CtrlUser[] = [];
      if (res.data && res.data.length) {
        sortedUsers = sortBy(res.data, [
          (a: CtrlUser) => a.type === 'API' ? 1 : 0,
          (a: CtrlUser) => `${a.firstName.toLowerCase()} ${a.lastName.toLowerCase()}`,
        ]);
        sortedUsers = sortedUsers.reduce((result, val, i) => {
          if (result.findIndex(f => f.value === val) === -1) {
            result.push({id: val.id, displayName: val.firstName + ' ' + val.lastName, value: val.id});
          }
          return result;
        }, []);
      }
      this.auditFilters = this.sharedService.updateFilterPossibleValues(this.auditFilters,
        usersType === 'INTERNAL' ? 'auditInternalUser' : 'auditPublisherUser', sortedUsers);
    });
  }

  onTableSortChange(table: AppTable) {
    this.auditTable.sortBy = table.sortBy;
    this.auditTable.sortDirection = table.sortDirection.toUpperCase();
    this.auditTable.pageIndex = 1;
    this.auditTable.pageSize = table.pageSize;
    if (!this.auditTable.sortBy) {
      this.auditTable.sortBy = 'timestamp';
      this.auditTable.sortDirection = 'DESC';
    }
    this.loadAudit();
  }

  onTablePageChange(table: AppTable) {
    this.auditTable.pageIndex = table.pageIndex;
    this.loadAudit();
  }

  onButtonClick(buttonId: string) {
    if (buttonId === 'auditResetFiltersButton') {
      this.onFiltersChange({filters: this.auditFilters, filterId: 'n/a'}, true);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
