<nz-row>
  <nz-col nzSm="24" nzMd="24" nzLg="24">
    <nz-form-item>
      <nz-form-label>
        Contract File
        <ng-container *ngIf="isRequired">
          <span nz-tooltip nzTooltipTitle="Publisher did not accept the Terms of Service. Contract file will be required for finance purposes">
            <fa-icon icon="exclamation-circle" class="fa-md" style="color: #FF0000; padding-left: 4px"></fa-icon>
          </span>
        </ng-container>
      </nz-form-label>
      <div class="margin-bottom-small small-font" *ngFor="let contractFile of uploadedContractFiles">
        <ng-template #indicatorTemplate><em nz-icon nzType="loading" style="font-size: 14px;"></em></ng-template>
        <span nz-text nzType="secondary">
          Contract file <nz-spin *ngIf="contractFile.contractFileLoading" style="display:inline-block;" nzSimple
                                 [nzIndicator]="indicatorTemplate"></nz-spin>
          &nbsp;<a class="underlined-link-button semibold-font"
                   (click)="downloadContractFile(contractFile.contractFileId)">{{contractFile.contractFileName}}</a> was uploaded at {{contractFile.contractFileCreatedAt | date: 'MM/dd/yyyy'}}
        </span>
      </div>
      <div *ngIf="contractFile.length === 0 && this.publisherData.payment?.publisherFinancialDocument?.length === 0" class="margin-bottom-small small-font">
        <span nz-text nzType="secondary">No contract file</span>
      </div>
      <div>
        <nz-form-control style="text-align: end;">
          <nz-upload
            class="uploadComponent"
            [(nzFileList)]="contractFile"
            [nzBeforeUpload]="beforeUploadContracts"
            [ngClass]="{itemsPendingToUpload: contractFile.length}"
            (nzChange)="onContractFileChange($event)"
            [nzShowUploadList]="!contractHasBeenUploaded">
            <button nz-button class="secondary-button" type="button" id="upload_contract_file_btn">
              <span>Upload New Contract</span>
            </button>
          </nz-upload>
        </nz-form-control>
      </div>
    </nz-form-item>
  </nz-col>
</nz-row>
