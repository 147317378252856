import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export interface ObsWithStatusResult<T> {
  isLoading?: boolean;
  response?: T;
  // error?: string;
}

// const defaultError = 'Something went wrong';

@Pipe({
  name: 'isLoading',
})
export class IsLoadingPipe implements PipeTransform {
  transform<T = any>(obs$: Observable<T>, isLoadingCondition: Function): Observable<ObsWithStatusResult<T>> {
    return obs$.pipe(
      map((res: any) => {
        return {
          isLoading: isLoadingCondition(res),
          response: res,
          // error: value.type === 'error' ? defaultError : '',
        };
      }),
      startWith({ isLoading: true }),
      // catchError(error => of({ isLoading: false, error: typeof error === 'string' ? error : defaultError }))
    );
  }
}
