import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, Observer, of, Subscription } from 'rxjs';
import { NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { NzNotificationService } from 'ng-zorro-antd/notification';
@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.less']
})
export class UploadFileComponent {
  @Input() uploadText = 'Upload';
  @Input() hideRemoveBtn = false;
  @Input() uploadFileInstruction = 'Accepts PNG and JPG files, max 2MB';
  @Input() set updateUrlImg(value: string) {
    if (!this.imgSource && value && value.startsWith('http')) {
      this.imgSource = value;
    }
  }
  @Output() fileUpload: EventEmitter<NzUploadXHRArgs> = new EventEmitter<NzUploadXHRArgs>();

  imgSource?: string;
  loading = false;

  constructor(private nzNotificationService: NzNotificationService) {}

  beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]): Observable<boolean> =>
    new Observable((observer: Observer<boolean>) => {
      const isJpgOrPng = file.type === 'image/jpg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.errorNotification('File uploads are only supported for JPG or PNG files');
        observer.complete();
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.errorNotification('Please ensure that the image size is less than 2MB');
        observer.complete();
        return;
      }
      observer.next(isJpgOrPng && isLt2M);
      observer.complete();
    });

  handleChange(info: { file?: NzUploadFile }): void {
    if (info.file?.status === 'uploading') {
      this.loading = true;
      this.getBase64(info.file.originFileObj, (img: string) => {
        this.loading = false;
        this.imgSource = img;
      });
    } else {
      this.errorNotification('');
      this.loading = false;
    }
  }

  customRequest = (item: NzUploadXHRArgs): Subscription => {
    this.fileUpload.emit(item);
    return of('').subscribe();
  };

  removeImg(): void {
    this.imgSource = null;
    this.fileUpload.emit(null);
  }
  private errorNotification(msg: string): void {
    this.nzNotificationService.error('Upload file failed', msg);
  }

  private getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result.toString()));
    reader.readAsDataURL(img);
  }
}
