import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { FormHelper } from '../../_common';
import { CommonDataModel, JapiQuery, JapiTableFilter, Permission, ResponseFromServer } from '../_models/models';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';
import { Observable } from 'rxjs';
import { HttpHelperService } from './http-helper.service';
import { map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})

export class PermissionService {

  constructor(
    private http: HttpClient,
    private httpHelper: HttpHelperService,
    private fh: FormHelper,
    private httpHelperService: HttpHelperService
  ) {
  }

  getPermissions(filters: JapiQuery): Observable<ResponseFromServer<Permission[]>> {
    return this.http.post<ResponseFromServer<Permission[]>>(`${environment.javaApiUrl}/permission/get`, filters);
  }

  publisherPermissions(): Observable<ResponseFromServer<Permission[]>> {
    const filters: JapiQuery = {filter: {filters: [{fieldName: 'name', operation: 'LIKE', value: 'publisher.'}]}};
    return this.getPermissions(filters);
  }

  getPermissionsTree(): Observable<CommonDataModel<NzTreeNodeOptions[]>> {
    return this.httpHelperService.expandPost<Permission[]>(`${environment.javaApiUrl}/permission/get`, {})
      .pipe(map(
        res => res.status === 'Success' ? this.convertPermissionsToTree(res) : res));
  }

  private convertPermissionsToTree(permissions: CommonDataModel<Permission[]>) {
    const newPermissionTree = {...permissions, data: []};
    let currentTree;
    permissions.data.forEach(p => {
      currentTree = newPermissionTree.data;
      p.name.split('.').forEach((section, index, array) => {
        const subTree = currentTree.find(currentSubTree => currentSubTree.key === section);
        if (subTree) {
          currentTree = subTree.children;
        } else {
          if (index === array.length - 1) {
            currentTree.push({
              title: p.description,
              key: p.name,
              id: p.id,
              isLeaf: true,
            });
          } else {
            const children = [];
            currentTree.push({
              title: section.charAt(0).toUpperCase() + section.slice(1).toLowerCase(),
              key: section,
              children
            });
            currentTree = children;
          }
        }
      });
    });
    return newPermissionTree;
  }
}
