import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Injectable({ providedIn: 'root' })

export class SupplyHomeService {

  constructor(
    private http: HttpClient
  ) {}

  public getJavaWidgetData() {
    const url = `${environment.javaInsightsUrl}/dashboard/widget`;
    return this.http.get<any>(url).pipe();
  }

  public getJavaPublisherData(type: string) {
    let url = `${environment.javaInsightsUrl}/dashboard/`;
    url += type;
    return this.http.get<any>(url).pipe();
  }

  public getJavaGraphData(type: string, rangeType: string, intervalType: string) {
    let url = `${environment.javaInsightsUrl}/dashboard/graph`;
    url += type;
    url += `?dateRange=${rangeType}&dateInterval=${intervalType}`;
    return this.http.get<any>(url).pipe();
  }
}
