<div *ngIf="portalType === 'supply'" class="sub-header margin-bottom-1">Floors</div>
<ng-container [formGroup]="floorsForm" (keydown.enter)="$event.preventDefault()">
  <ng-container formArrayName="floors">
    <nz-table nzTemplateMode nzSimple nzSize="small" class="static-table borderless-table floors-table">
      <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col" style="padding-left: 0;">Impression Type</th>
        <th scope="col">Device</th>
        <th scope="col">Country</th>
        <th scope="col" style="display: none;">Override</th>
        <th scope="col">Default Floor</th>
        <th scope="col" *ngIf="portalType === 'supply'">Nexxen Curated Floor</th>
        <th scope="col" *ngIf="portalType === 'supply'">Direct Demand Floor</th>
        <th scope="col" *ngIf="portalType === 'supply'">
          <div>
            <nz-form-label class="whitespace-normal" nzRequired>Floor Adjustment
            </nz-form-label>
            <span nz-tooltip nzTooltipTitle="Enter the proper Floor Adjustment %, to ensure you can hit your Margin goal">
                        <fa-icon class="fa-md font-icon-color-grey-cool font-icon-hover-color-primary-main padding-bottom-small"
                                 icon="question-circle"></fa-icon>
                      </span>
          </div>
        </th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <ng-container *ngFor="let floor of floorsArray.controls; let i = index">
        <ng-container [formGroupName]="i">
          <tr
            *ngIf="!floor.get('isHidden')?.value"
            [ngClass]="{'has-errors': (
              floor?.errors ||
              floor?.get('bidFloor')?.errors ||
              floor?.get('floorAdjustment')?.errors
            )}"
          >
            <td>
              <div [ngStyle]="floor.get('isGeoFloor')?.value && {'visibility': 'hidden'}"
                   style="text-align: center;">
                <nz-switch
                  id="{{floor.get('isGeoFloor')?.value ? 'geo-switch-' + i  : floor.get('impType')?.value + '-parent-switch'}}"
                  nzSize="small"
                  [ngModel]="floor.get('isEnabled').value"
                  (ngModelChange)="updateFloorStatus($event, i)"
                  [ngModelOptions]="{standalone: true}">
                </nz-switch>
              </div>
            </td>
            <td style="width: 10rem;">
              <div [ngStyle]="floor.get('isGeoFloor')?.value && {'display': 'none'}">
                <input style="width:100%; color: grey; font-size: 14px"
                       nz-input
                       [value]="floor?.get('impType').value | lowercase | titlecase"
                       disabled/>
              </div>
            </td>

            <td>
              <nz-select nzPlaceHolder="Select device type" formControlName="deviceType"
                         style="width: 100%;"
                         id="{{floor.get('impType')?.value + '-device-' + i}}"
                         nzShowArrow="false"
                         [nzSuffixIcon]="selectIcons['more_floors_imp_device_' + i] ? arrowUp : arrowDown"
                         (nzOpenChange)="onChangeSelectIconState($event, 'more_floors_imp_device_' + i)"
                         [nzDisabled]="!floor.get('isGeoFloor')?.value"
                         [ngStyle]="!floor.get('isGeoFloor')?.value && {'color': 'grey'}">
                <nz-option nzLabel="All devices" [nzValue]="allDevices"></nz-option>
                <nz-option *ngFor="let option of deviceTypes"
                           [nzLabel]="option.displayName"
                           [nzValue]="option">
                </nz-option>
              </nz-select>
              <div class="error-txt" *ngIf="floor?.errors">Unique combination is required
                <fa-icon icon="question-circle" class="fa-sm font-icon-color-grey-cool font-icon-hover-color-primary-main cursor-pointer"
                         nz-tooltip
                         nzTooltipTitle="Combination of country and device should be unique per impression type"></fa-icon>
              </div>
            </td>

            <td>
              <nz-select nzPlaceHolder="Select country" formControlName="country"
                         nzShowSearch
                         id="{{floor.get('impType')?.value + '-country-' + i}}"
                         style="width: 100%;"
                         nzShowArrow="false"
                         [nzSuffixIcon]="selectIcons['more_floors_country_id_' + i] ? arrowUp : arrowDown"
                         (nzOpenChange)="onChangeSelectIconState($event, 'more_floors_country_id_' + i)"
                         [nzDisabled]="!floor.get('isGeoFloor')?.value"
                         [ngStyle]="!floor.get('isGeoFloor')?.value && {'color': 'grey'}">

                <nz-option nzLabel="All countries" [nzValue]="allCountries.alpha2"></nz-option>
                <nz-option *ngFor="let option of countries"
                           [nzLabel]="option.name"
                           [nzValue]="option.alpha2">
                </nz-option>
              </nz-select>
            </td>

            <td style="max-width: 10rem;">
              <nz-form-control nz-tooltip
                               [nzTooltipTitle]="externalUserWithFixedCpm ? 'Floor is based on fixed CPM' :
                               getFloorTooltip(floor?.get('impType').value, floor?.get('isGeoFloor').value, 'bidFloor')">
                <nz-input-group nzAddOnAfter="$">
                  <nz-input-number style="width: 100%; border-top-right-radius: 0; border-bottom-right-radius: 0;"
                                   id="{{floor.get('impType')?.value + '-bid-floor-' + i}}"
                                   [nzStep]="0.001"
                                   [nzDisabled]="!floor.get('isEnabled')?.value || externalUserWithFixedCpm"
                                   formControlName="bidFloor">
                  </nz-input-number>
                </nz-input-group>
              </nz-form-control>
              <div class="error-txt">
                <div *ngIf="floor?.get('bidFloor').hasError('bidFloorGreaterThanCpm')">
                  Floor must be higher than the fixed cost
                </div>
                <div *ngIf="floor?.get('bidFloor').hasError('rangeNotValid')">Bid floor range is 0 to 99.999</div>
              </div>
            </td>

            <td *ngIf="portalType === 'supply'">
              <nz-form-control>
                <nz-input-group nzAddOnAfter="$">
                  <nz-input-number style="width: 100%; border-top-right-radius: 0; border-bottom-right-radius: 0;"
                    [id]="'pts_'+ i +'_'+floor.get('impType').value+'_unruly_curated_deal_floor'" [nzMax]="99.999" [nzMin]="0" [nzStep]="0.001" formControlName="curatedDealFloor">
                  </nz-input-number>
                </nz-input-group>
              </nz-form-control>
            </td>

            <td *ngIf="portalType === 'supply'">
              <nz-form-control>
                <nz-input-group nzAddOnAfter="$">
                  <nz-input-number style="width: 100%; border-top-right-radius: 0; border-bottom-right-radius: 0;"
                    [id]="'pts_'+ i +'_'+floor.get('impType').value+'_direct_demand_deal_floor'" [nzMax]="99.999" [nzMin]="0" [nzStep]="0.001" formControlName="internalDspFloor">
                  </nz-input-number>
                </nz-input-group>
              </nz-form-control>
            </td>

            <td *ngIf="portalType == 'supply'" style="max-width: 10rem;">
              <nz-form-control nz-tooltip
                               nzTooltipTitle="{{getFloorTooltip(floor?.get('impType').value, floor.get('isGeoFloor')?.value, 'floorAdjustment')}}">
                <nz-input-group [nzAddOnAfter]="'%'">
                  <nz-input-number style="width: 100%; border-top-right-radius: 0; border-bottom-right-radius: 0;"
                                   id="{{floor.get('impType')?.value + '-floor-adjustment-' + i}}"
                                   [nzDisabled]="floor.get('isGeoFloor')?.value || !floor.get('isEnabled')?.value"
                                   [nzStep]="0.001"
                                   [nzMax]="9999.99"
                                   formControlName="floorAdjustment">
                  </nz-input-number>
                </nz-input-group>
              </nz-form-control>
              <div class="error-txt" *ngIf="floor?.get('floorAdjustment').errors">Adjustment range is 0 to 9,999.99</div>
            </td>
            <td class="font-icon-color-darker">
              <fa-icon icon="plus-circle" class="fa-lg margin-right-small cursor-pointer"
                       id="{{floor.get('impType')?.value + '-add-geo-' + i}}"
                       [ngStyle]="!floor.get('isEnabled')?.value && {'opacity': 0.5, 'filter': 'grayscale(1)', 'cursor': 'not-allowed'}"
                       (click)="addGeoFloor(i)">
              </fa-icon>
              <fa-icon icon="minus-circle" class="fa-lg font-icon-color-darker cursor-pointer"
                       [ngStyle]="!floor.get('isGeoFloor')?.value && {'opacity': 0.5, 'filter': 'grayscale(1)', 'cursor': 'not-allowed'}"
                       id="{{floor.get('impType')?.value + '-remove-geo-' + i}}"
                       (click)="removeGeoFloor(i)">
              </fa-icon>
            </td>

          </tr>
        </ng-container>
      </ng-container>
      </tbody>
    </nz-table>
  </ng-container>
</ng-container>

<ng-template #arrowUp>
  <fa-icon [icon]="'angle-down'" class="select-icon select-icon-open"></fa-icon>
</ng-template>
<ng-template #arrowDown>
  <fa-icon [icon]="'angle-down'" class="select-icon select-icon-close"></fa-icon>
</ng-template>
