import { Component, Input } from '@angular/core';
import { PortalType } from '../../_models/models';

@Component({
  selector: 'app-placements-required',
  templateUrl: './placements-required.component.html',
  styleUrls: ['./placements-required.component.less']
})
export class PlacementsRequiredComponent {
  @Input() createItem: string;
  @Input() isLoading: boolean;
  @Input() portalType: PortalType;
  @Input() hasPlacements: boolean;
}
