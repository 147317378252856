import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, Validators, AbstractControl, ValidatorFn, ValidationErrors, UntypedFormControl } from '@angular/forms';

import _ from 'lodash';

import { SharedService } from '../../../../shared/_services/shared.service';
import { Commission, CommissionToSubmit, Floor } from '../../../../shared/_models/models';
import { FLOOR_TYPES } from '../../../../shared/_common/enums';
import { FormHelper } from '../../../../shared/_common/form.helper';
import { debounceTime, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-placement-floors',
  templateUrl: './placement-floors.component.html',
  styleUrls: ['./placement-floors.component.less'],
})
export class PlacementFloorsComponent implements OnInit, OnChanges, OnDestroy {

  @Input() commissionsStart: Commission = {} as Commission;
  @Input() pubTsCommission: Commission;
  @Input() portalType;
  @Input() placementMode: 'CREATE' | 'DUPLICATE' | 'EDIT';

  // @Input() geoFloors;
  @Input() pubTsFloors;

  @Input() placementId;
  @Input() placementFloors;
  @Input() pubTsCot;
  @Input() placementCot;

  private _commissionChangeValue: Commission;
  private unsubscribe$ = new Subject<void>();

  @Input() set commissionsChange(val: CommissionToSubmit) {
    if (val) {
      this._commissionChangeValue = val.placementCommissions[0];
      if (this._commissionChangeValue === null && val.isCommissionFormValid === false) {
        const defaultValueCommissionFormNotValid = {} as Commission;
        defaultValueCommissionFormNotValid.overridePubTsCommission = true;
        this._commissionChangeValue = defaultValueCommissionFormNotValid;
      }
      this.formHelper.markFormGroupDirtyAndUpdateValidity(this.floorsArray);
    }
  }
  @Input() countries;
  @Output() updatedFloors: EventEmitter<any> = new EventEmitter();

  externalUserWithFixedCpm: boolean;
  allCountries = {nameProp: 'All countries', alpha2: 'any'};
  allDevices = {displayName: 'All devices', value: 'any'};
  deviceTypes = [
    {displayName: 'CTV', value: 'CTV'},
    {displayName: 'Desktop', value: 'DESKTOP'},
    {displayName: 'Mobile', value: 'MOBILE'}
  ];


  origGeoFloorsAdjusted: Floor[] = []; // to save floors as is and then support hide/unhide easily
  geoFloorsToDelete: Floor[] = [];

  emptySupplyPlacementFloor: Floor = {
    bidFloor: null,
    createdAt: null,
    floorAdjustment: null,
    floorAdjustmentType: 'PERCENT',
    id: null,
    impType: null,
    placementId: null,
    publisherTrafficSourceId: null,
    updatedAt: null,
    country: null,
    deviceType: null,
    curatedDealFloor: null,
    internalDspFloor: null,
  };

  floorsForm: UntypedFormGroup;
  floorsArray: UntypedFormArray;
  selectIcons: any = {};

  constructor(
    private formBuilder: UntypedFormBuilder,
    private sharedService: SharedService,
    private formHelper: FormHelper
  ) {
  }

  ngOnInit(): void {
    this.initForm();
    this.initFormListenChanges();
    // == in case floors untouched, we still need to output data to parent to have it available on submit
    this.sendOutputsToParent();
    this.floorsArray.controls.forEach(this.disableRowForInactiveStatus);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.commissionsStart?.currentValue) {
      this.commissionsStart.overridePubTsCommission = true;
    }
    if (changes.pubTsCommission && this.pubTsCommission && !this.commissionsStart) {
      this.formHelper.markFormGroupDirtyAndUpdateValidity(this.floorsArray);
    }
  }

  disableRowForInactiveStatus = (floorControl: UntypedFormControl): void => {
    const curatedDealFloor = floorControl.get('curatedDealFloor');
    const internalDspFloor = floorControl.get('internalDspFloor');
    floorControl.valueChanges.pipe(
      map(val => val.isEnabled),
      debounceTime(300),
      distinctUntilChanged(),
      startWith(floorControl.value.isEnabled as boolean),
      takeUntil(this.unsubscribe$)
    ).subscribe(isEnable => {
      if (isEnable) {
        curatedDealFloor.enable({emitEvent: false});
        internalDspFloor.enable({emitEvent: false});
      } else {
        curatedDealFloor.disable({emitEvent: false});
        internalDspFloor.disable({emitEvent: false});
      }
    });
  }

  triggerValidators: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const parentControl = control.parent?.controls as { [p: string]: AbstractControl };
    if (parentControl) {
      parentControl.bidFloor.updateValueAndValidity({onlySelf: true});
      parentControl.floorAdjustment.updateValueAndValidity({onlySelf: true});
    }
    return null;
  }

  bidFloorGreaterThanCpmValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    let currentCommission = this._commissionChangeValue ||
      this.commissionsStart || {overridePubTsCommission: false};
    if (!(currentCommission && currentCommission.overridePubTsCommission === true)) {
      currentCommission = this.pubTsCommission;
    }
    const cpmType = currentCommission?.commissionType;
    if (this.portalType === 'portal') {
      const cot = this.placementCot ? this.placementCot : this.pubTsCot;
      this.externalUserWithFixedCpm = cot === 'fx';
      return null;
    }
    if (cpmType === 'FIXED') {
      const commission = currentCommission.commission;
      const bidFloor = control.value;
      const parentControl = control.parent?.controls as { [p: string]: AbstractControl };
      if (bidFloor < commission && parentControl?.isEnabled.value) {
        return {bidFloorGreaterThanCpm: true};
      }
    }
    return null;
  }

  floorAdjustmentRangeValidator = (control: AbstractControl): ValidationErrors | null => {
    const parentControl = control.parent as UntypedFormGroup;
    if (parentControl?.get('isEnabled').value && (control.value == null ||  control.value === '' || control.value < 0 ||
      control.value > 9999.99)) {
      return {'rangeNotValid': true};
    } else {
      return null;
    }
  }

  rangeValidator = (control: AbstractControl): ValidationErrors | null => {
    return control.value >= 0 && control.value <= 99.999 ? null : {'rangeNotValid': true};
  }

  uniqueCombinationValidator(): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors => {
      if (this.floorsForm && this.floorsForm.controls) {
        const allFloors = this.floorsForm.controls['floors'] as UntypedFormArray;

        let currentFloor, currentCombination, parentFloor;
        const combinations = {};
        for (let i = 0; i < allFloors.value.length; i++) {
          currentFloor = allFloors.at(i).value;
          currentCombination = currentFloor.impType + '-' + currentFloor.country + '-' + currentFloor.deviceType?.value;
          combinations[currentCombination] = (combinations[currentCombination] || 0) + 1;
        }
        for (let i = 0; i < allFloors.value.length; i++) {
          currentFloor = allFloors.at(i).value;
          if (!currentFloor.isGeoFloor) {
            parentFloor = {...currentFloor};
          }
          currentCombination = currentFloor.impType + '-' + currentFloor.country + '-' + currentFloor.deviceType?.value;
          if (combinations[currentCombination] > 1 && currentFloor.isGeoFloor && parentFloor.isEnabled) {
            allFloors.at(i).setErrors({notUnique: true}, {emitEvent: false});
          } else {
            allFloors.at(i).setErrors(null);
          }
        }
      }
      return;
    };
  }

  public initForm(): void {
    // == init the form
    this.floorsArray = this.formBuilder.array([], {validators: this.uniqueCombinationValidator()});
    this.floorsForm = this.formBuilder.group({
      floors: this.floorsArray
    });
    this.patchValuesToFloors();
  }

  public initFormListenChanges(): void {
    this.floorsForm.valueChanges
      .subscribe(value => {
        this.sendOutputsToParent();
      });
  }

  public patchValuesToFloors(): void {
    // == patch supplyPlacementFloors ->
    // if floor exists it will be added, otherwise an empty floor will be rendered with disabled toggle
    let floorToAdd: Floor;
    let supplyPlacementFloorIndex = -1;
    // let arrLength = 0;
    let indexInForm = 0;
    FLOOR_TYPES.forEach(floorType => {
      supplyPlacementFloorIndex = this.placementFloors ? this.placementFloors.findIndex((floor: Floor) => floor.impType === floorType) : -1;
      if (supplyPlacementFloorIndex > -1) {
        floorToAdd = {...this.placementFloors[supplyPlacementFloorIndex]};
        floorToAdd.isEnabled = true;
      } else {
        floorToAdd = {...this.emptySupplyPlacementFloor, impType: floorType};
        floorToAdd.isEnabled = false;
      }
      (this.floorsForm.controls['floors'] as UntypedFormArray).insert(indexInForm, this.createNextFloor(floorToAdd, false));
      indexInForm++;

      if (floorToAdd.geoFloors && floorToAdd.geoFloors.length > 0) {
        let geoFloor = null;
        floorToAdd.geoFloors.forEach(gf => {
          geoFloor = this.adjustGeoFloorStructure(floorToAdd, gf);
          this.origGeoFloorsAdjusted.push(geoFloor);
          (this.floorsForm.controls['floors'] as UntypedFormArray).insert(indexInForm, this.createNextFloor(geoFloor, true));
          indexInForm++;
        });
      }
    });
  }

  public updateFloorStatus(toHide: boolean, index: number): void {
    (<UntypedFormGroup>this.floorsForm.get('floors')).controls[index].get('isEnabled').setValue(toHide);
    this.floorsForm.markAsDirty();
    this.floorsForm.updateValueAndValidity({emitEvent: true});

    this.sendOutputsToParent();

    const placementFloorImpType = (<UntypedFormGroup>this.floorsForm.get('floors')).controls[index].get('impType').value;
    this.setIsHiddenGeoFloors(!toHide, placementFloorImpType);
    if (!toHide) {
      // check if has geo floors from this imp type
      const allFloors = this.floorsForm.getRawValue().floors;
      const hasGeoChildren = allFloors.filter(floor => {
        return floor.isGeoFloor && floor.impType === placementFloorImpType;
      }).length;
      if (hasGeoChildren) {
        this.sharedService.showNotification('info', 'Notice',
          'Please note that saving disabled default floor will cause deletion of its variable floors');
      }
    }
  }

  sendOutputsToParent() {

    // if not valid - skip all the logic
    if (!this.floorsForm.valid) {
      this.updatedFloors.emit({isFloorsFormValid: this.floorsForm.valid});
      return;
    }

    const allFloors = _.cloneDeep(this.floorsForm.controls.floors.value);

    // == do not submit floor adjustments for external
    if (this.portalType === 'portal') {
      allFloors.forEach(f => {
        // floor adjustment values are required when creating a new floor
        if (this.placementMode === 'EDIT' && f.id) {
          delete f.floorAdjustment;
          delete f.floorAdjustmentType;
        } else {
          f.floorAdjustment = 0;
          f.floorAdjustmentType = 'PERCENT';
        }
      });
    }

    // == ensure there are no create geo floors with exact same combination as delete geo floors based on original value
    // in other words, if create geo floor has same combination as to delete geo floor, need to remove it from delete and from create,
    // and move it to update list

    // another use case here is when user changes existing geo floor combination,
    // and then adds new geo floor with original geoFloor combination
    // the server would not allow adding new geoFloor with existing combination
    // the solution is to switch the combinations
    // example:
    // update combination from: floorId-any-AF to floorId-any-AL
    // new combination = floorId-any-AF -> may fail due to async timing

    // another use case: there 2 geoFloors, duplicate first, now delete first,
    // now set country of original second to be same as original first
    // then set the new geoFloor country to same as original second

    // enabled floors id list will serve us to decide if geoFloor needs to be updated at all
    const floorsToEmit = {
      isFloorsFormValid: this.floorsForm.valid,
      floors: this.adjustFloorsForPayload(allFloors),
    };

    this.updatedFloors.emit(floorsToEmit);
  }

  adjustFloorsForPayload(floors: Floor[]) {
    const adjustedFloors: Floor[] = [];
    floors.forEach((floor, index) => {
      if (this.placementMode === 'DUPLICATE') {
        floor.id = null;
      }
      if (floor.isEnabled && !floor.isGeoFloor && !floor.geoFloors) {
        floor.geoFloors = [];
      }
      if (floor.isGeoFloor) {
        const parentFloorIndex = this.findParentFloorIndex(adjustedFloors);
        if (!adjustedFloors[parentFloorIndex].geoFloors) {
          adjustedFloors[parentFloorIndex].geoFloors = [];
        }
        // now make sure that same "new" geoFloor is not in the origFloor list
        // (when someone removes geo floor, then adds the same geo floor again in the ui)
        const indexInOrigGeoFloors = this.findIndexInOrigGeoFloors(floor);
        if (indexInOrigGeoFloors > -1) {
          floor.id = this.placementMode === 'DUPLICATE' ? null : this.origGeoFloorsAdjusted[indexInOrigGeoFloors].id;
        }
        adjustedFloors[parentFloorIndex].geoFloors.push(this.transformToPayloadGeoFloor(floor));
      } else {
        adjustedFloors.push(floor);
      }
    });
    return adjustedFloors;
  }

  setIsHiddenGeoFloors(isHidden: boolean, parentImpType: string) {
    let isGeo;
    _.forEach((<UntypedFormGroup>this.floorsForm.get('floors')).controls, (floor) => {
      isGeo = floor.get('isGeoFloor').value;
      if (isGeo && parentImpType === floor.get('impType').value) {
        floor.get('isHidden').setValue(isHidden);
      }
    });
  }

  addGeoFloor(copyIndex: number) {
    if (!this.floorsForm.getRawValue().floors[copyIndex].isEnabled) {
      return;
    }
    const allFloors = this.floorsForm.controls['floors'] as UntypedFormArray;
    if (allFloors.length > 199) {
      this.sharedService.showNotification('info', 'Maximum reached', 'Up to 200 floors are allowed');
      return;
    }
    const newFloor: Floor = {...this.floorsForm.controls['floors'].value[copyIndex]};

    // if geoFloor copied from parent floor, take parent id to geoFloor, otherwise preserve supplyPlacementFloorId
    // const supplyPlacementFloorId = newFloor.supplyPlacementFloorId ? newFloor.supplyPlacementFloorId : newFloor.id;
    // newFloor.supplyPlacementFloorId = supplyPlacementFloorId;
    newFloor.placementId = this.placementId;
    newFloor.id = null;

    (this.floorsForm.controls['floors'] as UntypedFormArray).insert(copyIndex + 1, this.createNextFloor(newFloor, true));
  }

  removeGeoFloor(removeIndex: number) {
    // build the list of geo floors to be deleted.
    // two possible cases: delete real geo floors that are in DB - or - delete (hide) added geo floors then remove them before save in UI
    // so, add to delete list only real existing in DB geo floor, otherwise just hide from view

    // also note that if parent placementFloor should be deleted,
    // java backend will remove geoFloors automatically, so do not add them to the delete list

    const deleteFloor = this.floorsForm.getRawValue().floors[removeIndex];
    const allFloors = _.cloneDeep(this.floorsForm.controls.floors.value);
    const placementFloorIndex = allFloors.findIndex(f => f.id == deleteFloor.supplyPlacementFloorId);
    const isPlacementFloorEnabled = allFloors[placementFloorIndex].isEnabled;
    if (deleteFloor.isGeoFloor) {
      if (deleteFloor.id && isPlacementFloorEnabled) {
        // put in the delete the original floor to avoid conflict of combination device-country-type
        const origFloor = {...this.origGeoFloorsAdjusted.filter(oriFloor => oriFloor.id === deleteFloor.id)[0]};
        this.geoFloorsToDelete.push(origFloor);
      }
      (this.floorsForm.controls['floors'] as UntypedFormArray).removeAt(removeIndex);
    }
  }

  getFloorTooltip(impType: string, isGeo: boolean, value_type: string) {
    const placementFloors = this.floorsForm.getRawValue().floors.filter(floor => !floor.isGeoFloor);
    const placementFloor = placementFloors.filter(floor => floor.impType === impType)[0];
    const pubTsFloorIndex = this.pubTsFloors ? this.pubTsFloors.findIndex(x => x.impType === impType) : -1;
    const pubTsFloor = pubTsFloorIndex > -1 ? this.pubTsFloors[pubTsFloorIndex] : null;
    let tooltip;
    let floorType = '';
    let unitType = '';
    let pubTsFloorType = '';
    if (placementFloor && impType) {
      tooltip = this.portalType === 'supply' ? 'Parent value: ' : 'Default value: ';
      if (!isGeo) {
        if (value_type === 'floorAdjustment') {
          unitType = (pubTsFloor && pubTsFloor.floorAdjustmentType) ?
            (pubTsFloor.floorAdjustmentType === 'PERCENT' ? '%' : '$') : '';
        }
        if (value_type === 'floorAdjustmentType') {
          if (pubTsFloor) {
            pubTsFloorType = pubTsFloor['floorAdjustmentType'] === 'FIXED' ? 'Fixed-CPM' : 'Percent';
          }
        }
        tooltip = tooltip + (pubTsFloor ?
          (pubTsFloorType ? pubTsFloorType : pubTsFloor[value_type] + unitType) : 'not set');
      } else {
        if (value_type === 'floorAdjustmentType') {
          floorType = placementFloor.floorAdjustmentType ?
            (placementFloor.floorAdjustmentType === 'FIXED' ? 'Fixed-CPM' : 'Percent') : '';
        }
        if (value_type === 'floorAdjustment') {
          unitType = placementFloor.floorAdjustmentType ?
            (placementFloor.floorAdjustmentType === 'PERCENT' ? '%' : '$') : '';
        }
        tooltip = tooltip + (floorType ? floorType : placementFloor[value_type]) + unitType;
      }
    }
    return tooltip ? tooltip : null;
  }

  private adjustGeoFloorStructure(placementFloor: Floor, geoFloor: any) {
    const deviceType = this.deviceTypes.filter(device => device.value === geoFloor.deviceType)[0];
    const countryIndex = this.countries.findIndex(c => c.alpha2 === geoFloor.countryAlpha2);
    let country;
    if (countryIndex > -1) {
      country = this.countries[countryIndex].alpha2;
    } else {
      country = this.allCountries.alpha2;
    }
    return {
      id: geoFloor.id,
      supplyPlacementFloorId: geoFloor.supplyPlacementFloorId,
      placementId: this.placementId,
      impType: placementFloor.impType,
      country: country,
      deviceType: deviceType ? deviceType : this.allDevices,
      bidFloor: geoFloor.bidFloor,
      floorAdjustment: '-',
      floorAdjustmentType: '-',
      curatedDealFloor: geoFloor.curatedDealFloor,
      internalDspFloor: geoFloor.internalDspFloor,
      isEnabled: true,
      isGeoFloor: true
    };
  }

  private createNextFloor(floor: Floor, isGeoFloor: boolean): UntypedFormGroup {
    let nextFloor = this.formBuilder.group({
      id: floor.id ? floor.id : null,
      supplyPlacementFloorId: [floor.supplyPlacementFloorId ? floor.supplyPlacementFloorId : null],
      placementId: [this.placementId],
      isEnabled: [floor.isEnabled, this.triggerValidators],
      impType: [{value: floor.impType, disabled: false}],
      country: [floor.country ? floor.country : this.allCountries.alpha2],
      deviceType: [floor.deviceType ? floor.deviceType : this.allDevices],
      bidFloor: [(+floor.bidFloor).toFixed(3), [Validators.required, this.rangeValidator, this.bidFloorGreaterThanCpmValidator]],
      curatedDealFloor: [floor.curatedDealFloor],
      internalDspFloor: [floor.internalDspFloor],
      isGeoFloor: [isGeoFloor],
      isHidden: [false]
    });

    // == for placement floor we need floor adjustment and type with validations
    // otherwise, set default value without validations
    if (!isGeoFloor) {
      nextFloor = this.formBuilder.group({
        ...nextFloor.controls,
        floorAdjustment: [this.portalType === 'supply' ? floor.floorAdjustment : 0 , [this.floorAdjustmentRangeValidator]],
        floorAdjustmentType: [this.portalType === 'supply' ? floor.floorAdjustmentType : 'PERCENT' , Validators.required],
      });
    } else {
      nextFloor = this.formBuilder.group({
        ...nextFloor.controls,
        floorAdjustment: ['-'],
        floorAdjustmentType: ['-'],
      });
    }
    return nextFloor;
  }

  private findParentFloorIndex(payloadFloors: Floor[]): number {
    for (let i = payloadFloors.length - 1; i > -1; i--) {
      if (payloadFloors[i].impType) {
        return i;
      }
    }
    return -1;
  }

  private findIndexInOrigGeoFloors(geoFloorToCheck: Floor): number {
    for (let i = 0; i < this.origGeoFloorsAdjusted.length; i++) {
      if (this.origGeoFloorsAdjusted[i].country === geoFloorToCheck.country &&
        this.origGeoFloorsAdjusted[i].deviceType.value === geoFloorToCheck.deviceType.value &&
        this.origGeoFloorsAdjusted[i].impType === geoFloorToCheck.impType) {
        return i;
      }
    }
    return -1;
  }


  transformToPayloadGeoFloor(geoFloor: Floor) {
    return {
      id: geoFloor.id ? geoFloor.id : null,
      placementId: geoFloor.placementId ? geoFloor.placementId : null,
      supplyPlacementFloorId: geoFloor.supplyPlacementFloorId,
      deviceType: geoFloor.deviceType ? (geoFloor.deviceType.value === 'any' ? 'ANY' : geoFloor.deviceType.value) : 'ANY',
      countryAlpha2: geoFloor.country === 'any' ? '**' : geoFloor.country,
      bidFloor: geoFloor.bidFloor,
      impType: geoFloor.impType,
      curatedDealFloor: geoFloor.curatedDealFloor,
      internalDspFloor: geoFloor.internalDspFloor,
    };
  }

  onChangeSelectIconState(isOpen, id) {
    this.selectIcons[id] = isOpen;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
