import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class DrawerService {

  private _visible = false;
  private _title = '';
  public childrenVisible: Array<boolean> = [];
  public childrenTitle: Array<string> = [];
  public closable = false;
  public isFullWidth = false;

  constructor(
    private router: Router,
  ) {
    this.closable = false;
  }

  // TODO: Optional child on function add child set/get?

  public close(route?: string): void {
    this.visible = false;
    this.isFullWidth = false;
    if (route) {
      this.router.navigate([route]);
    }
  }

  public open(title?: string, closable?: boolean, isFullWidth?: boolean): void {
    this.visible = true;
    if (closable) {
      this.closable = closable;
    } else {
      this.closable = false;
    }
    if (title) {
      this.title = title;
    }
    if (isFullWidth) {
      this.isFullWidth = isFullWidth;
    }
  }

  public toggle(): void {
    this.visible = !this.visible;
  }

  set visible(value: boolean) {
    this._visible = value;
  }

  get visible(): boolean {
    return this._visible;
  }

  set title(value: string) {
    this._title = value;
  }

  get title(): string {
    return this._title;
  }

}
