import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-commission-fixed-cpm-warning-text',
  templateUrl: './commission-fixed-cpm-warning-text.component.html'
})
export class CommissionFixedCpmWarningTextComponent {
  @Input() commissionsValueWithWarning: number;
  @Input() trafficSourcesWithWarning: { [trafficSource: string]: number };
}
