<div nz-row nzGutter="40" nzJustify="space-around" nzAlign="middle" class="error-container">

  <div nz-col nzSpan="12"><img alt="forbidden"></div>
  <div nz-col nzSpan="12">
    <h1>403 - Forbidden</h1>
    <p>Sorry, you don't have permission to access the page you visited</p>
    <button class="primary-button" routerLink="/" nz-button nzType="primary" style="margin-right: 16px;" id="home-link">Back to Home</button>
    <button class="secondary-button" (click)="logout()" nz-button id="logout-link">Logout</button>
  </div>

</div>
