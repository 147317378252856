import { Injectable } from '@angular/core';
import { RoleJava } from '../../../../shared/_models/role';
import { ApiService } from '../../../../shared/_services/api.service';
import { CommonDataModel, JapiQuery } from '../../../../shared/_models/models';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class RoleService {

  constructor(
    private apiService: ApiService,
  ) {
  }

  getAllRoles(_filters: JapiQuery = {}): Observable<CommonDataModel<RoleJava[]>> {
    return this.apiService.getRoles(_filters);
  }

  getActiveRoles(): Observable<CommonDataModel<RoleJava[]>> {
    const filters: JapiQuery = {
      filter: {
        filters: [
          {fieldName: 'deletedAt', operation: 'IS_NULL', value: ''}
        ]
      }
    };
    return this.apiService.getRoles(filters);
  }

  getRoleById(id: number): Observable<CommonDataModel<RoleJava[]>> {
    const filters: JapiQuery = {
      filter: {
        filters: [
          {fieldName: 'id', operation: 'EQUALS', value: id},
        ],
        withTrashed: true
      }
    };
    return this.apiService.getRoles(filters);
  }
  create(role: RoleJava): Observable<CommonDataModel<RoleJava>> {
    return this.apiService.createRole(role);
  }

  update(role: RoleJava, id: number): Observable<CommonDataModel<RoleJava>> {
    return this.apiService.updateAndRestoreRole(role, id);
  }

  delete(id: number): any {
    return this.apiService.deleteRole(id);
  }

}
