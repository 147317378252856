import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() { }

  public connectErrorsToForm(form: UntypedFormGroup, validationErrors: any, group_key: string = '') {

    _.each(form.controls, function(value, key) {
      if (value instanceof UntypedFormGroup) {
        const new_group_key = (group_key == '') ? key + '.' : group_key + '.' + key + '.';
        this.connectErrorsToForm(value, validationErrors, new_group_key);
      } else {
        if (validationErrors[group_key + key] && validationErrors[group_key + key][0]) {
          const error = { message: validationErrors[group_key + key][0] };
          value.markAsTouched();
          value.setErrors(error);
        }
      }
    }.bind(this));

  }

}
