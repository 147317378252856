import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { version } from '../../../environments/version';

@Injectable({
  providedIn: 'root'
})

export class VersionService {

  private _apiVersionSubject: BehaviorSubject<any>;
  public _apiVersion: Observable<any>;

  private _uiVersionSubject: BehaviorSubject<any>;
  public _uiVersion: Observable<any>;

  constructor() {
    this._apiVersionSubject = new BehaviorSubject<any>('unknown');
    this._apiVersion = this._apiVersionSubject.asObservable();

    this._uiVersionSubject = new BehaviorSubject<any>('unknown');
    this._uiVersion = this._uiVersionSubject.asObservable();

    this.uiVersion = this.readUiVersion();
  }

  public get apiVersion(): any {
    return this._apiVersionSubject.value;
  }

  public set apiVersion($value) {
    this._apiVersionSubject.next($value);
  }

  public get uiVersion(): any {
    return this._uiVersionSubject.value;
  }

  public set uiVersion($value) {
    this._uiVersionSubject.next($value);
  }

  private readUiVersion(): any {
    return version.major + '.' + version.minor;
  }

}
