import { Injectable } from '@angular/core';
import { DecimalPipe, DatePipe, CurrencyPipe, PercentPipe } from '@angular/common';
import { MapFromKeyValuePipe } from '../_pipes/mapFromKeyValue.pipe';
import { SimplePercentPipe } from '../_pipes/simple_percent.pipe';

@Injectable()

export class PipeManager {

  private _pipes = {
    'number': new DecimalPipe('en-US'),
    'date': new DatePipe('en-US'),
    'currency': new CurrencyPipe('en-US'),
    'percent': new PercentPipe('en-US'),
    'simplePercent': new SimplePercentPipe(),
    'key': new MapFromKeyValuePipe(),
  };

  public PipeForKey(key: string) {
    return this._pipes[key];
  }

}
