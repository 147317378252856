import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import _ from 'lodash';
import { IabAttribute, IabBat, DspAccount } from '../../../../shared/_models/models';

@Component({
  selector: 'app-blocked-lists',
  templateUrl: './blocked-lists.component.html',
  styleUrls: ['./blocked-lists.component.less']
})
export class BlockedListsComponent implements OnInit, OnDestroy {

  @Input() blockedListsData;
  @Input() portalType;
  @Input() iabCategories;
  @Input() iabBannerAdTypes: IabBat[];
  @Input() iabCreativeAttributes: IabAttribute[];
  @Input() dspAccounts: DspAccount[];
  @Output() updatedBlockedLists: EventEmitter<any> = new EventEmitter();

  private unsubscribe$ = new Subject<void>();
  blockedListsForm: UntypedFormGroup;
  selectIcons: any = {};

  constructor(
    private formBuilder: UntypedFormBuilder,
  ) {
  }

  public ngOnInit(): void {
    this.initForm();
    this.initFormListenChanges();
  }

  initForm(): void {
    this.blockedListsForm = this.formBuilder.group({
      bcat: [null],
      btype: [null],
      battr: [null],
      tldBadv: [null],
      dspAccountExcludeList: [null],
    });
    this.patchValuesToBlockedLists();
  }

  initFormListenChanges(): void {
    this.blockedListsForm.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((_val) => {
        this.sendOutputsToParent();
      });
  }

  patchValuesToBlockedLists() {
    if (this.blockedListsData) {
      this.blockedListsForm.patchValue(this.blockedListsData);
    }
  }

  sendOutputsToParent(): void {
    const blockedLists = _.cloneDeep(this.blockedListsForm.value);
    const blockedListToEmit = {
      isBlockedListsFormValid: this.blockedListsForm.valid,
      blockedListsData: blockedLists
    };
    this.updatedBlockedLists.emit(blockedListToEmit);
  }

  onChangeSelectIconState(isOpen: boolean, id: string): void {
    this.selectIcons[id] = isOpen;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
