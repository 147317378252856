import { Action } from '@ngrx/store';
import { AppFilter, AppTable, PublisherEdit } from '../../../../shared/_models/models';

export const UPDATE_PUBLISHERS_FILTERS = '[Publishers] Update publishers filters';
export const UPDATE_PUBLISHERS_TABLE = '[Publishers] Update publishers table';
export const FETCH_PUBLISHERS_REQUEST = '[Publishers] Fetch publishers request';
export const FETCH_PUBLISHERS_SUCCESS = '[Publishers] Fetch publishers success';
export const FETCH_PUBLISHERS_FAILURE = '[Publishers] Fetch publishers failure';

export const FETCH_PUBLISHERS_FOR_FILTER_REQUEST = '[Publishers] Fetch publishers for filter request';
export const FETCH_PUBLISHERS_FOR_FILTER_SUCCESS = '[Publishers] Fetch publishers for filter success';
export const FETCH_PUBLISHERS_FOR_FILTER_FAILURE = '[Publishers] Fetch publishers for filter failure';

export const FETCH_PUBLISHERS_OPS_OWNERS_REQUEST = '[Publishers] Fetch publishers operations owners request';
export const FETCH_PUBLISHERS_OPS_OWNERS_SUCCESS = '[Publishers] Fetch publishers operations owners success';
export const FETCH_PUBLISHERS_OPS_OWNERS_FAILURE = '[Publishers] Fetch publishers operations owners failure';

export const FETCH_PUBLISHER_BY_ID_REQUEST = '[Publishers] Fetch publisher by id';
export const FETCH_PUBLISHER_BY_ID_SUCCESS = '[Publishers] Fetch publisher by id success';
export const FETCH_PUBLISHER_BY_ID_FAILURE = '[Publishers] Fetch publisher by id failure';

export const UPDATE_PUBLISHER_TO_EDIT = '[Publishers] Update publisher to edit';

export class UpdateFiltersAction implements Action {
    readonly type = UPDATE_PUBLISHERS_FILTERS;

    constructor(public payload: { publishersFilters: AppFilter[] }) {
    }
}

export class UpdatePublishersTableAction implements Action {
    readonly type = UPDATE_PUBLISHERS_TABLE;

    constructor(public payload: { publishersTable: AppTable }) {
    }
}

export class FetchPublishersRequestAction implements Action {
    readonly type = FETCH_PUBLISHERS_REQUEST;
}

export class FetchPublishersSuccessAction implements Action {
    readonly type = FETCH_PUBLISHERS_SUCCESS;

    constructor(public payload: { publishers: any }) {
    }
}

export class FetchPublishersFailureAction implements Action {
    readonly type = FETCH_PUBLISHERS_FAILURE;

    constructor(public payload: { error: string }) {
    }
}

export class FetchPublishersForFilterRequestAction implements Action {
    readonly type = FETCH_PUBLISHERS_FOR_FILTER_REQUEST;
}

export class FetchPublishersForFilterSuccessAction implements Action {
    readonly type = FETCH_PUBLISHERS_FOR_FILTER_SUCCESS;

    constructor(public payload: { publishersForFilter: any }) {
    }
}

export class FetchPublishersForFilterFailureAction implements Action {
    readonly type = FETCH_PUBLISHERS_FOR_FILTER_FAILURE;

    constructor(public payload: { error: string }) {
    }
}

export class FetchPublishersOpsOwnersRequestAction implements Action {
    readonly type = FETCH_PUBLISHERS_OPS_OWNERS_REQUEST;
}

export class FetchPublishersOpsOwnersSuccessAction implements Action {
    readonly type = FETCH_PUBLISHERS_OPS_OWNERS_SUCCESS;

    constructor(public payload: { operationsOwners: any }) {
    }
}

export class FetchPublishersOpsOwnersFailureAction implements Action {
    readonly type = FETCH_PUBLISHERS_OPS_OWNERS_FAILURE;

    constructor(public payload: { error: string }) {
    }
}

export class UpdatePublisherToEditAction implements Action {
  readonly type = UPDATE_PUBLISHER_TO_EDIT;
  constructor(public payload: { publisher: PublisherEdit }) {
  }
}

//NOT IN USE tll full php-to-java-publisher refactoring
export class FetchPublisherByIdRequestAction implements Action {
  readonly type = FETCH_PUBLISHER_BY_ID_REQUEST;
}

export class FetchPublisherByIdSuccessAction implements Action {
  readonly type = FETCH_PUBLISHER_BY_ID_SUCCESS;

  constructor(public payload: { publisher: any }) {
  }
}

export class FetchPublisherByIdFailureAction implements Action {
  readonly type = FETCH_PUBLISHER_BY_ID_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export type PublishersActions =
    UpdateFiltersAction |
    UpdatePublishersTableAction |
    FetchPublishersRequestAction |
    FetchPublishersSuccessAction |
    FetchPublishersFailureAction |
    FetchPublishersForFilterRequestAction |
    FetchPublishersForFilterSuccessAction |
    FetchPublishersForFilterFailureAction |
    FetchPublishersOpsOwnersRequestAction |
    FetchPublishersOpsOwnersSuccessAction |
    FetchPublishersOpsOwnersFailureAction |
    UpdatePublisherToEditAction |
    FetchPublisherByIdRequestAction |
    FetchPublisherByIdSuccessAction |
    FetchPublisherByIdFailureAction;
