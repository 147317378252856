
<nz-button-group #buttonGroupEl>
  <button nz-button nzType="primary"
          class="medium-padding"
          [ngClass]="buttons?.main.buttonType"
          [nzLoading]="isLoading"
          (click)="onButtonClicked(buttons?.main.id)"
          [id]="'dropdown-button-main-'+buttons?.main.id">
    {{buttons?.main.label}}
  </button>
  <button *ngIf="buttons?.options?.length > 0 && !hideOptions" class="small-padding" [ngClass]="buttons?.main.buttonType"
          nzPlacement="bottomRight"
          nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu"
          nz-tooltip="More options" nzTooltipPlacement="topRight"
          (nzVisibleChange)="onDropdownToggle($event, buttonGroupEl)">
    <fa-icon icon="chevron-down" [rotate]="isDropdownVisible ? 180 : null" class="smaller-font"></fa-icon>
  </button>
</nz-button-group>

<nz-dropdown-menu #menu="nzDropdownMenu">
  <ul nz-menu class="dropdown-ul" [style.min-width]="dropdownMinWidth + 'px'">
    <ng-container *ngFor="let option of buttons?.options">
      <li nz-menu-item (click)="onButtonClicked(option.id)" [id]="'dropdown-button-option-'+option.id">
        <a class="link-button small-font font-icon-color-grey font-icon-hover-color-black">{{option.label}}</a>
      </li>
    </ng-container>
  </ul>
</nz-dropdown-menu>

