import { AppTable, AppTableColumn, ModalButton, ThinPublisher, ClusivityStatus } from '../_models/models';

export type PublisherDescription = Pick<ThinPublisher, 'publisherId' | 'publisherName'>;

export interface AlertsTableRow {
  name: string;
  description: string;
  notificationName: string;
  ruleId: number;
  status: ClusivityStatus;
  publishers?: PublisherDescription[];
}

export interface EditAlertRow extends AlertsTableRow {
  subscriptionId: number;
  publisher: PublisherDescription;
}
export type SentinelRuleGroup = 'deals_internal' | 'deals_external';
export const INTERNAL_ALERTS_SENTINEL_GROUP: SentinelRuleGroup = 'deals_internal';
export const EXTERNAL_ALERTS_SENTINEL_GROUP: SentinelRuleGroup = 'deals_external';

export type AlertWithStatus = Pick<AlertsTableRow, 'ruleId' | 'status'>;


export const alertsTable = new AppTable('alertsTable', 1, 10, 'description', 'asc', false, false, 'no-border');
export const externalAlertsTableColumns: AppTableColumn[] = [
  {id: 'alertName', prop: 'name', displayName: 'Name', type: 'text-with-tooltip',
    valueFormatter: (row: AlertsTableRow) => row.description},
  {id: 'emailAlert', prop: 'type', displayName: 'Email Me', type: 'toggle', actionId: 'alertToggleAction'},
];
export const internalAlertsTableColumns: AppTableColumn[] = [
  {
    id: 'alertName',
    prop: 'name',
    displayName: 'Name',
    type: 'text-with-tooltip',
    valueFormatter: (row: AlertsTableRow) => row.description,
    colWidth: 'max-content'
  },
  {
    id: 'emailAlert',
    prop: 'publishers',
    displayName: 'Email Notification',
    type: 'moreTag',
    actionId: 'alertToggleAction',
    moreTag: {
      displayNameProp: 'publisherName',
      popoverTitle: 'More publishers',
      noDataMessage: 'No publishers selected'
    },
    colWidth: 'max-content'
  },
  {
    id: 'editAlert', prop: 'actions', displayName: '', type: 'actions', actionId: 'alertEditAction',
    actions: [
      {id: 'alertEditAction', name: 'edit', auth: 'canUpdate', displayName: 'Edit'}
    ],
    colWidth: 'max-content'
  }
];

export const editAlertsTableColumns: AppTableColumn[] = [
  {id: 'publisherId', prop: 'publisher.publisherId', displayName: 'ID', type: 'text'},
  {id: 'publisherName', prop: 'publisher.publisherName', displayName: 'Publisher', type: 'text'},
  {id: 'emailAlert', prop: 'type', displayName: 'Email Me', type: 'toggle-alert', actionId: 'alertToggleAction'},
  {
    id: 'alertActions', prop: 'actions', displayName: '', type: 'actions',
    actions: [
      {
        id: 'alertDeleteAction',
        name: 'delete',
        auth: 'canDelete',
        displayName: 'Delete',
        desc: 'Are you sure you wish to delete this publisher\'s alert?'
      }
    ]
  }
];



export const addAlertToPublisherModalButtons: ModalButton[] = [
  {type: 'CANCEL', text: 'Cancel'},
  {type: 'APPROVE', text: 'Save'}
];

export const authPermissions = {
  canRead: true,
  canCreate: true,
  canUpdate: true,
  canDelete: true,
};


