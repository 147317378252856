import { Directive, HostListener, ElementRef} from '@angular/core';

@Directive({
  selector: '[appFocusInvalid]'
})
export class FocusInvalidDirective {


  constructor(private el: ElementRef) { }

  @HostListener('submit', ['$event.target'])
public  onSubmit(event) {
    const invalidElements = this.el.nativeElement.querySelectorAll('input.ng-invalid,textarea.ng-invalid,nz-select.ng-invalid,nz-checkbox-group.ng-invalid');
    if (invalidElements.length > 0) {
      invalidElements[0].focus();
    }
  }
}
