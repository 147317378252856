import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import dayjs from 'dayjs';
import { CachedResponse } from '../_models/models';


@Injectable({
  providedIn: 'root'
})
export class RequestCacheService {

  private cache = new Map<string, [number, Observable<any>]>();

  private CACHE_TTL = 100; // seconds

  constructor() {}

  public cachedRequest(key: string, req$: Observable<any>, expiresAt: number = null): Observable<CachedResponse<any>> {
    const cachedItem = this.cache.get(key);
    let isFromCache = true;
    if (!cachedItem || cachedItem[0] < dayjs().unix()) {
      const expires = this.getExpirationTime(expiresAt);
      this.cache.set(key, [expires, req$.pipe(
        catchError(err => {
          this.clearCacheItem(key);
          return throwError(err);
        }),
        shareReplay(1),
      )]);
      isFromCache = false;
    }
    return this.cache.get(key)[1].pipe(map(res => ({isFromCache, response: res})));
  }


  clearCacheItem(key: string) {
    setTimeout(() => {
      this.cache.delete(key);
    }, 0);
  }


  getExpirationTime(expiresAt: number = null): number {
    if (expiresAt !== null) {
      return expiresAt === 0 ? dayjs().unix() : expiresAt;
    }
    return dayjs().unix() + this.CACHE_TTL;
  }


  public clearCache() {
    this.cache = new Map<string, [number, Observable<any>]>();
  }


}


