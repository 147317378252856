import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Country, JapiQuery, ResponseFromServer, State } from '../../_models/models';
import { ApiService } from '../api.service';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class CountryService {

  private countriesResponse: ResponseFromServer<Country[]>;

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) {
  }

  getCountries(): Observable<ResponseFromServer<Country[]>> {
    if (this.countriesResponse) {
      return of(this.countriesResponse);
    }
    return this.apiService.getCountries().pipe(tap(countriesResponse => {
      if (countriesResponse?.data) {
        this.countriesResponse = countriesResponse;
      }
    }));
  }

  getStates(country_code: string): Observable<ResponseFromServer<State[]>> {
    const filter: JapiQuery = {filter: {filters: [{fieldName: 'country', operation: 'IN', value: country_code}]}};
    return this.apiService.getRegions(filter).pipe(map(regions => {
      return regions.data.reduce((acc, r) => {
        if (r?.region && r.region !== '?') {
          r.region = r.region.toUpperCase();
          acc.data.push(r);
        }
        return acc;
      }, {...regions, data: []} as ResponseFromServer<State[]>);
    }));
  }
}
