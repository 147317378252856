import { Pipe, PipeTransform } from '@angular/core';
import { round } from 'lodash';

@Pipe({
  name: 'widgetformat',
})
export class WidgetFormatPipe implements PipeTransform {

  transform(value: string, percents: boolean = true, fullDecimals: boolean = false): string {
    let val = parseFloat(value);
    if (val > 1) {
      if (val >= 1000000000000) {
        val = val / 1000000000000;
        value = this.lodashRound(val, 2, fullDecimals) + 'T';
      } else if (val >= 1000000000) {
        val = val / 1000000000;
        value = this.lodashRound(val, 2, fullDecimals) + 'B';
      } else if (val >= 1000000) {
        val = val / 1000000;
        value = this.lodashRound(val, 2, fullDecimals) + 'M';
      } else if (val >= 1000) {
        val = val / 1000;
        value = this.lodashRound(val, 2, fullDecimals) + 'K';
      } else if (val < 1000) {
        value = this.lodashRound(val, 2, fullDecimals) + '';
      }
    } else if (val <= 1 && percents == true) {
      val = val * 100;
      value = this.lodashRound(val, 3, fullDecimals) + '%';
    }
    return value;
  }

  lodashRound(val: number, precision: number, fullDecimals?: boolean): string {
    const rounded: number = round(val, precision);
    return (fullDecimals && !Number.isInteger(val)) ? rounded.toFixed(precision) : rounded.toString();
  }

}
