import { Pipe, PipeTransform } from '@angular/core';
import { SortOption } from '../_models/models';

type Position = 'start' | 'end';

@Pipe({
  name: 'sortBy'
})
export class SortByPipe implements PipeTransform {
  // T represents the type of the object in the array [{User}, {User}]
  // K is the property name in the object T (user.id , user.name)
  // we need this <T extends object, K extends keyof T> allows compiler to autocomplete/arise errors
  transform<T extends object, K extends keyof T>(
    arrayOfObj: T[], orderByFieldName: K, sortOption: SortOption = 'asc', exceptions: T[K][] = [], exceptionsPositions: Position = 'end'
  ): T[] {
    if (arrayOfObj?.length > 0) {
      const exceptionsObjects = [];
      let nonExceptionObjects;
      if (exceptions.length > 0) {
        // forEach to remain the original order of the exceptions
        exceptions.forEach(ex => {
          const exp = arrayOfObj.find(obj => obj[orderByFieldName] === ex);
          if (exp) {
            exceptionsObjects.push(exp);
          }
        });
        nonExceptionObjects = arrayOfObj.filter(obj => !exceptions.some(ex => ex === obj[orderByFieldName]));
      } else {
        nonExceptionObjects = arrayOfObj;
      }
      nonExceptionObjects.sort((a, b) => {
        if (a[orderByFieldName] < b[orderByFieldName]) {
          return sortOption === 'asc' ? -1 : 1;
        } else if (a[orderByFieldName] > b[orderByFieldName]) {
          return sortOption === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      });
      if (exceptionsObjects.length > 0) {
        arrayOfObj = exceptionsPositions === 'start' ?
          [...exceptionsObjects, ...nonExceptionObjects] : [...nonExceptionObjects, ...exceptionsObjects];
      }
    }
    return arrayOfObj;
  }
}
