import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appShowIfTextLeaked]'
})
export class ShowIfTextLeakedDirective implements AfterViewInit {

  constructor(private el: ElementRef, private renderer: Renderer2) {
  }

  ngAfterViewInit(): void {
    const parentEl = this.el.nativeElement.parentElement;
    const boxHeight = parentEl?.offsetHeight;
    const contentHeight = parentEl?.firstElementChild?.offsetHeight;
    if (boxHeight - contentHeight >= 0) {
      this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
    }
  }
}
