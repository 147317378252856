import { Action } from '@ngrx/store';
import { AppFilter, AudienceTargetingSegment, FilterSelectOption } from '../../../shared/_models/models';

export const UPDATE_MODAL_FILTERS = '[AudienceTargeting] Update modal filters';

export const FETCH_SEGMENT_LIST_REQUEST = '[AudienceTargeting] Fetch segment list request';
export const FETCH_SEGMENT_LIST_SUCCESS = '[AudienceTargeting] Fetch segment list success';
export const FETCH_SEGMENT_LIST_FAILURE = '[AudienceTargeting] Fetch segment list failure';

export const FETCH_SEGMENT_PROVIDER_LIST_REQUEST = '[AudienceTargeting] Fetch segment providers list request';
export const FETCH_SEGMENT_PROVIDER_LIST_SUCCESS = '[AudienceTargeting] Fetch segment providers list success';
export const FETCH_SEGMENT_PROVIDER_LIST_FAILURE = '[AudienceTargeting] Fetch segment providers list failure';

export const FETCH_SEGMENT_SORT_BY_LIST_REQUEST = '[AudienceTargeting] Fetch segment sort by list request';
export const FETCH_SEGMENT_SORT_BY_LIST_SUCCESS = '[AudienceTargeting] Fetch segment sort by list success';
export const FETCH_SEGMENT_SORT_BY_LIST_FAILURE = '[AudienceTargeting] Fetch segment sort by list failure';

export const FETCH_TOTAL_MAX_PRICE_REQUEST = '[AudienceTargeting] Fetch total max price request';
export const FETCH_TOTAL_MAX_PRICE_SUCCESS = '[AudienceTargeting] Fetch total max price success';
export const FETCH_TOTAL_MAX_PRICE_FAILURE = '[AudienceTargeting] Fetch total max price failure';

export const CLEAR_STORE_AUDIENCE_TARGETING = '[AudienceTargeting] Remove audience targeting from store';


export class UpdateModalFilters implements Action {
  readonly type = UPDATE_MODAL_FILTERS;

  constructor(public payload: { modalFilters: AppFilter[] }) {
  }
}

export class FetchSegmentListRequestAction implements Action {
  readonly type = FETCH_SEGMENT_LIST_REQUEST;
}

export class FetchSegmentListSuccessAction implements Action {
  readonly type = FETCH_SEGMENT_LIST_SUCCESS;

  constructor(public payload: { segments: AudienceTargetingSegment[]; totalElements: number }) {
  }
}

export class FetchSegmentListFailureAction implements Action {
  readonly type = FETCH_SEGMENT_LIST_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class FetchSegmentProviderListRequestAction implements Action {
  readonly type = FETCH_SEGMENT_PROVIDER_LIST_REQUEST;
}

export class FetchSegmentProviderListSuccessAction implements Action {
  readonly type = FETCH_SEGMENT_PROVIDER_LIST_SUCCESS;

  constructor(public payload: { segmentProviders: FilterSelectOption[]; updateAppFilters }) {
  }
}

export class FetchSegmentProviderListFailureAction implements Action {
  readonly type = FETCH_SEGMENT_PROVIDER_LIST_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class FetchSegmentSortByListRequestAction implements Action {
  readonly type = FETCH_SEGMENT_SORT_BY_LIST_REQUEST;
}

export class FetchSegmentSortByListSuccessAction implements Action {
  readonly type = FETCH_SEGMENT_SORT_BY_LIST_SUCCESS;

  constructor(public payload: { segmentSortByList: FilterSelectOption[]; updateAppFilters }) {
  }
}

export class FetchSegmentSortByListFailureAction implements Action {
  readonly type = FETCH_SEGMENT_SORT_BY_LIST_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class FetchTotalMaxPriceRequestAction implements Action {
  readonly type = FETCH_TOTAL_MAX_PRICE_REQUEST;
}

export class FetchTotalMaxPriceSuccessAction implements Action {
  readonly type = FETCH_TOTAL_MAX_PRICE_SUCCESS;

  constructor(public payload: { totalMaxPrice: number }) {
  }
}

export class FetchTotalMaxPriceFailureAction implements Action {
  readonly type = FETCH_TOTAL_MAX_PRICE_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class ClearFromStoreAudienceTargetingAction implements Action {
  readonly type = CLEAR_STORE_AUDIENCE_TARGETING;
}


export type AudienceTargetingActions =
  UpdateModalFilters |
  FetchSegmentListRequestAction |
  FetchSegmentListSuccessAction |
  FetchSegmentListFailureAction |
  FetchSegmentProviderListRequestAction |
  FetchSegmentProviderListSuccessAction |
  FetchSegmentProviderListFailureAction |
  FetchSegmentSortByListRequestAction |
  FetchSegmentSortByListSuccessAction |
  FetchSegmentSortByListFailureAction |
  FetchTotalMaxPriceRequestAction |
  FetchTotalMaxPriceSuccessAction |
  FetchTotalMaxPriceFailureAction |
  ClearFromStoreAudienceTargetingAction;

