import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';

import { AuthenticationService } from '../../auth/_services/authentication.service';
import { environment } from '../../../environments/environment';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { KeycloakToken } from '../_models/models';

export const nonAuthUrls = [
  'users/email',
  'users/password/forgot',
  'users/password/reset',
  'captcha',
  'signup',
  'termsofservice/latest',
  'dataProcessingAddendum/latest',
  'location/country/get',
  'location/region/get',
  'version.json',
];

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  private MINIMUM_ALLOWED_TOKEN_LIFE = 2 * 60;
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private auth: AuthenticationService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const currentUser = this.auth.currentUserValue;
    let token: string;

    if (currentUser?.access_token) {
      token = currentUser.access_token;
    }

    if (token) {
      request = this.setBearerToken(request, token);
    }

    // if not login/signup or captcha and no token => abort
    if ((request.url.toString().includes('ctrl-japi') || request.url.toString().includes('ctrl-api')) &&
      !currentUser && !nonAuthUrls.some(url => request.url.toString().includes(url))) {
      console.error('Request blocked');
      return EMPTY;
    }

    if (
      (request.url.startsWith(`${environment.javaApiUrl}`) || request.url.startsWith(`${environment.javaInsightsUrl}`)
        || request.url.startsWith(`${environment.exchangeDealsUrl}`))
      && currentUser?.access_token && this.isTokenExpired(currentUser.expiresAt)
    ) {
      return this.handleRefreshToken(request, next);
    }

    return next.handle(request);
  }


  private setBearerToken(request: HttpRequest<any>, token: string): HttpRequest<any>{
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private isTokenExpired(expiresAt: number): boolean {
    return (expiresAt - (Date.now() / 1000)) <= this.MINIMUM_ALLOWED_TOKEN_LIFE;
  }

  private handleRefreshToken(request: HttpRequest<any>, next: HttpHandler) {
    // Any request that is executed while the refresh token process happens will wait for the new token
    // through the returned refreshTokenSubject below.
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const refreshToken = this.auth.currentUserValue.refresh_token;
      if (refreshToken) {
        return this.auth.refreshAuth(refreshToken).pipe(
          switchMap((token: KeycloakToken) => {
            // Update the new token in AuthService
            this.auth.onUpdateAccessToken(token);
            // Send the new token to any other request waiting for it
            this.refreshTokenSubject.next(token.access_token);
            // Re-handle the first unauthorized request
            return next.handle(this.setBearerToken(request, token.access_token));
          }),
          catchError((err) => {
            this.auth.doLogout(true);
            return throwError(err);
          }),
          finalize(() => {
            this.isRefreshing = false;
          }),
        );
      }
    }
    // Other requests will wait and re-handle themselves when the new token arrives
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.setBearerToken(request, token)))
    );
  }

}
