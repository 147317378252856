import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromLayout from './layout/_store/layout.reducer';
import * as fromShared from './shared/_store/shared.reducer';
import * as fromInventoryPackages from './features/inventory-packages/_store/inventory-packages.reducer';
import * as fromAudienceTargeting from './features/inventory-packages/_store/audience-targeting.reducer';
import * as fromPlacements from './features/placements/_store/placements.reducer';
import * as fromPublisherTrafficSources from './features/supply/publishers/publisher-configurations/_store/publisherTrafficSources.reducer';

export interface State {
  layout: fromLayout.State;
  shared: fromShared.SharedState;
  inventoryPackages: fromInventoryPackages.InventoryPackagesState;
  audienceTargeting: fromAudienceTargeting.AudienceTargetingState;
  placements: fromPlacements.PlacementsState;
  publisherTrafficSources: fromPublisherTrafficSources.PublisherTrafficSourceState;
}

export const reducers: ActionReducerMap<State> = {
  layout: fromLayout.layoutReducer,
  shared: fromShared.sharedReducer,
  inventoryPackages: fromInventoryPackages.inventoryPackagesReducer,
  audienceTargeting: fromAudienceTargeting.audienceTargetingReducer,
  placements: fromPlacements.placementsReducer,
  publisherTrafficSources: fromPublisherTrafficSources.publisherTrafficSourcesReducer,
};

// used as meta reducer in app-module store import config.
// works like interceptor, so every action passes through it
export function clearState(reducer) {
  return function (state, action) {
    return reducer(state, action);
  };
}


export const getInventoryPackagesState = createFeatureSelector<fromInventoryPackages.InventoryPackagesState>('inventoryPackages');
export const getInventoryPackagesLoading = createSelector(getInventoryPackagesState, fromInventoryPackages.getInventoryPackagesLoading);
export const getInventoryPackages = createSelector(getInventoryPackagesState, fromInventoryPackages.getInventoryPackages);
export const getInventoryPackagesFilters = createSelector(getInventoryPackagesState, fromInventoryPackages.getInventoryPackagesFilters);
export const getInventoryPackagesTable = createSelector(getInventoryPackagesState, fromInventoryPackages.getInventoryPackagesTable);
export const getInventoryPackagesTotal = createSelector(getInventoryPackagesState, fromInventoryPackages.getInventoryPackagesTotal);
export const getInventoryPackageByIdLoading =
  createSelector(getInventoryPackagesState, fromInventoryPackages.getInventoryPackageByIdLoading);
export const getInventoryPackageById = createSelector(getInventoryPackagesState, fromInventoryPackages.getInventoryPackageById);
export const getCountries = createSelector(getInventoryPackagesState, fromInventoryPackages.getCountries);
export const getCountriesLoading = createSelector(getInventoryPackagesState, fromInventoryPackages.getCountriesLoading);
export const getRegions = createSelector(getInventoryPackagesState, fromInventoryPackages.getRegions);
export const getMetros = createSelector(getInventoryPackagesState, fromInventoryPackages.getMetros);
export const getPlacements = createSelector(getInventoryPackagesState, fromInventoryPackages.getPlacements);
export const getPlacementsLoading = createSelector(getInventoryPackagesState, fromInventoryPackages.getPlacementsLoading);
export const getPublishers = createSelector(getInventoryPackagesState, fromInventoryPackages.getPublishers);
export const getPublishersLoading = createSelector(getInventoryPackagesState, fromInventoryPackages.getPublishersLoading);
export const getDeviceTypes = createSelector(getInventoryPackagesState, fromInventoryPackages.getDeviceTypes);
export const getContentAttributes = createSelector(getInventoryPackagesState, fromInventoryPackages.getContentAttributes);
