import { Component, Input, OnInit } from '@angular/core';
import { DealExternalSyncService } from '../../../features/deals/_services/deal-external-sync.service';
import { EMPTY, Observable } from 'rxjs';
import { AppFilter, AppTable, AppTableColumn, CommonDataModel, DealExternalSync } from '../../_models/models';
import {
  dealExternalSyncFiltersConfig,
  dealExternalSyncTable,
  dealExternalSyncTableColumns,
  dealExternalSyncMarketplaceTableColumns,
  dealExternalSyncFiltersConfigBase
} from '../../../features/deals/_services/deal-external-sync.config';
import { SharedService } from '../../_services/shared.service';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { RequestQueryBuilder } from '@libs/crud-request-nestjs';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-deal-external-sync',
  templateUrl: './deal-external-sync.component.html',
  styleUrls: ['./deal-external-sync.component.less']
})
export class DealExternalSyncComponent implements OnInit {

  @Input() dealId: number;
  @Input() dealName: string;
  @Input() mainSyncDeal: DealExternalSync;
  @Input() mode: 'dealExchange' | 'deal' = 'deal';
  dealExtSyncsData$: Observable<CommonDataModel<DealExternalSync[]>>;
  dealExtSyncsFilters: AppFilter[];
  dealExtSyncsTableColumns: AppTableColumn[];
  dealExtSyncsTable: AppTable;
  isLoading: boolean;

  constructor(
    private dealExternalSyncService: DealExternalSyncService,
    private clipboardService: ClipboardService,
    private sharedService: SharedService
  ) {
  }

  ngOnInit(): void {
    this.dealExtSyncsFilters = this.mainSyncDeal?.syncType === 'marketplace' ? dealExternalSyncFiltersConfigBase : dealExternalSyncFiltersConfig;
    this.dealExtSyncsTableColumns = this.mainSyncDeal?.syncType === 'marketplace' ?
      dealExternalSyncMarketplaceTableColumns :
      dealExternalSyncTableColumns;
    this.dealExtSyncsTable = dealExternalSyncTable;
    this.loadData();
  }

  loadData(): void {
    const query = this.sharedService.buildTableQuery(this.dealExtSyncsFilters, this.dealExtSyncsTable);
    if (this.mode === 'deal') {
      this.dealExtSyncsData$ = this.dealExternalSyncService.getDealExternalSyncLogs(this.dealId, query);
    } else if (this.mode === 'dealExchange') {
      const queryParam = this.sharedService.mapJapiQueryToCreateQueryParams(query);
      const queryParamString = RequestQueryBuilder.create(queryParam).query();
      this.dealExtSyncsData$ = this.dealExternalSyncService.getDealExchangeExternalSyncLogs(this.dealId, queryParamString);
    }
  }

  onTableSortChange(appTable: AppTable): void {
    appTable.sortDirection = appTable.sortDirection.toUpperCase();
    appTable.pageIndex = 1;
    this.dealExtSyncsTable = appTable;
    this.loadData();
  }

  onTablePageChange(appTable: AppTable): void {
    this.dealExtSyncsTable = appTable;
    this.loadData();
  }

  onFiltersChange(event: { filters: AppFilter[]; filterId: string }): void {
    if (['dealExternalSyncRange', 'action'].some(filterName => event.filterId === filterName)) {
      this.dealExtSyncsFilters = event.filters;
      this.loadData();
    }
  }

  retrieveStatus(): void {
    this.isLoading = true;
    let retrieve$: Observable<DealExternalSync>;
    if (this.mode === 'deal') {
      retrieve$ = this.dealExternalSyncService.retrieveDealExternalSyncStatus(this.dealId);
    } else if (this.mode === 'dealExchange') {
      retrieve$ = this.retrieveExchangeDealById(this.dealId);
    }
    retrieve$.pipe(
      tap(res => {
        if (res?.synced) {
          this.sharedService.onHttpCompletionResolve(res.synced, 'Success', 'Deal retrieved status succeed',
            '', '');
        } else {
          this.sharedService.onHttpCompletionResolve(res.synced, 'Failure', 'Deal retrieved status failed',
            '', '');
        }
      }),
      finalize(() => this.isLoading = false),
      catchError(err => {
        this.sharedService.onHttpCompletionResolve(false, 'Failure', err.error?.message || 'Deal retrieved status failed',
          '', '');
        return EMPTY;
      })
    ).subscribe(() => {
      this.loadData();
    });
  }

  copyToClipboard(dv3MarketplaceUri: string) {
    this.clipboardService.copy(dv3MarketplaceUri);
  }

  private retrieveExchangeDealById(dealId: number): Observable<DealExternalSync> {
    return this.dealExternalSyncService.retrieveDealExchangeExternalSyncStatus(dealId).pipe(
      map(res => res?.data)
    );
  }
}
