import { Action } from '@ngrx/store';
import {
  AppFilter,
  AppTable,
  ContentGenre,
  Placement,
  ResponseFromServer,
  IabBat,
  IabAttribute,
  DspAccount,
} from '../../../shared/_models/models';

export const UPDATE_PLACEMENTS_FILTERS = '[Placements] Update placements filters';
export const UPDATE_PLACEMENTS_TABLE = '[Placements] Update placements table';

export const FETCH_PLACEMENTS_REQUEST = '[Placements] Fetch placements request';
export const FETCH_PLACEMENTS_SUCCESS = '[Placements] Fetch placements success';
export const FETCH_PLACEMENTS_FAILURE = '[Placements] Fetch placements failure';

export const FETCH_PLACEMENT_BY_ID_REQUEST = '[Placements] Fetch placement by id request';
export const FETCH_PLACEMENT_BY_ID_SUCCESS = '[Placements] Fetch placement by id success';
export const FETCH_PLACEMENT_BY_ID_FAILURE = '[Placements] Fetch placement by id failure';

export const EDIT_PLACEMENT_REQUEST = '[Placements] Edit placement request';
export const EDIT_PLACEMENT_SUCCESS = '[Placements] Edit placement success';
export const EDIT_PLACEMENT_FAILURE = '[Placements] Edit placement failure';

export const CREATE_PLACEMENT_REQUEST = '[Placements] Create placement request';
export const CREATE_PLACEMENT_SUCCESS = '[Placements] Create placement success';
export const CREATE_PLACEMENT_FAILURE = '[Placements] Create placement failure';

export const FETCH_IAB_CATEGORIES_REQUEST = '[Placements] Fetch iab categories request';
export const FETCH_IAB_CATEGORIES_SUCCESS = '[Placements] Fetch iab categories success';
export const FETCH_IAB_CATEGORIES_FAILURE = '[Placements] Fetch iab categories failure';

export const FETCH_IAB_BANNER_AD_TYPES_REQUEST = '[Placements] Fetch iab banner ad types request';
export const FETCH_IAB_BANNER_AD_TYPES_SUCCESS = '[Placements] Fetch iab banner ad types success';
export const FETCH_IAB_BANNER_AD_TYPES_FAILURE = '[Placements] Fetch iab banner ad types failure';

export const FETCH_IAB_CREATIVE_ATTRIBUTES_REQUEST = '[Placements] Fetch iab creative attributes request';
export const FETCH_IAB_CREATIVE_ATTRIBUTES_SUCCESS = '[Placements] Fetch iab creative attributes success';
export const FETCH_IAB_CREATIVE_ATTRIBUTES_FAILURE = '[Placements] Fetch iab creative attributes failure';

export const FETCH_DSP_ACCOUNTS_REQUEST = '[Placements] Fetch dsp accounts request';
export const FETCH_DSP_ACCOUNTS_SUCCESS = '[Placements] Fetch dsp accounts success';
export const FETCH_DSP_ACCOUNTS_FAILURE = '[Placements] Fetch dsp accounts failure';

export const FETCH_PUB_TS_REQUEST = '[Placements] Fetch publisher traffic source request';
export const FETCH_PUB_TS_SUCCESS = '[Placements] Fetch publisher traffic source success';
export const FETCH_PUB_TS_FAILURE = '[Placements] Fetch publisher traffic source failure';

export const FETCH_CONTENT_GENRE_REQUEST = '[Placements] Content genre fetch request';
export const FETCH_CONTENT_GENRE_SUCCESS = '[Placements] Content genre fetch success';
export const FETCH_CONTENT_GENRE_FAILURE = '[Placements] Content genre fetch failure';

export const SET_DOMAIN_BUNDLES_DUPLICATE_STATUS = '[Placements] Set domain bundles duplicate status';

export const RESET_PLACEMENT_STATE = '[Placements] Remove placement from state';

export class UpdateFiltersAction implements Action {
  readonly type = UPDATE_PLACEMENTS_FILTERS;

  constructor(public payload: { placementsFilters: AppFilter[] }) {
  }
}

export class UpdatePlacementsTableAction implements Action {
  readonly type = UPDATE_PLACEMENTS_TABLE;

  constructor(public payload: { placementsTable: AppTable }) {
  }
}

export class FetchPlacementsRequestAction implements Action {
  readonly type = FETCH_PLACEMENTS_REQUEST;
}

export class FetchPlacementsSuccessAction implements Action {
  readonly type = FETCH_PLACEMENTS_SUCCESS;

  constructor(public payload: { placements: any }) {
  }
}

export class FetchPlacementsFailureAction implements Action {
  readonly type = FETCH_PLACEMENTS_FAILURE;

  constructor(public payload: { error: string }) {
  }
}


export class FetchPlacementByIdRequestAction implements Action {
  readonly type = FETCH_PLACEMENT_BY_ID_REQUEST;
}

export class FetchPlacementByIdSuccessAction implements Action {
  readonly type = FETCH_PLACEMENT_BY_ID_SUCCESS;

  constructor(public payload: { currentPlacement: Placement }) {
  }
}

export class FetchPlacementByIdFailureAction implements Action {
  readonly type = FETCH_PLACEMENT_BY_ID_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class EditPlacementRequestAction implements Action {
  readonly type = EDIT_PLACEMENT_REQUEST;
}

export class EditPlacementSuccessAction implements Action {
  readonly type = EDIT_PLACEMENT_SUCCESS;

  constructor(public payload: { currentPlacement: Placement }) {
  }
}

export class EditPlacementFailureAction implements Action {
  readonly type = EDIT_PLACEMENT_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class CreatePlacementRequestAction implements Action {
  readonly type = CREATE_PLACEMENT_REQUEST;
}

export class CreatePlacementSuccessAction implements Action {
  readonly type = CREATE_PLACEMENT_SUCCESS;

  constructor(public payload: { currentPlacement: Placement }) {
  }
}

export class CreatePlacementFailureAction implements Action {
  readonly type = CREATE_PLACEMENT_FAILURE;

  constructor(public payload: { error: string }) {
  }
}


export class FetchContentGenresRequestAction implements Action {
  readonly type = FETCH_CONTENT_GENRE_REQUEST;
}

export class FetchContentGenreSuccessAction implements Action {
  readonly type = FETCH_CONTENT_GENRE_SUCCESS;

  constructor(public payload: { contentGenres: ContentGenre[] }) {
  }
}

export class FetchContentGenreFailureAction implements Action {
  readonly type = FETCH_CONTENT_GENRE_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class FetchIabCategoriesRequestAction implements Action {
  readonly type = FETCH_IAB_CATEGORIES_REQUEST;
}

export class FetchIabCategoriesSuccessAction implements Action {
  readonly type = FETCH_IAB_CATEGORIES_SUCCESS;

  constructor(public payload: { iabCategories: any }) {
  }
}

export class FetchIabCategoriesFailureAction implements Action {
  readonly type = FETCH_IAB_CATEGORIES_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class FetchIabBannerAdTypesRequestAction implements Action {
  readonly type = FETCH_IAB_BANNER_AD_TYPES_REQUEST;
}

export class FetchIabBannerAdTypesSuccessAction implements Action {
  readonly type = FETCH_IAB_BANNER_AD_TYPES_SUCCESS;

  constructor(public payload: { iabBannerAdTypes: ResponseFromServer<IabBat[]> }) {
  }
}

export class FetchIabBannerAdTypesFailureAction implements Action {
  readonly type = FETCH_IAB_BANNER_AD_TYPES_FAILURE;

  constructor(public payload: { error: string }) {
  }
}


export class FetchIabCreativeAttributesRequestAction implements Action {
  readonly type = FETCH_IAB_CREATIVE_ATTRIBUTES_REQUEST;
}

export class FetchIabCreativeAttributesSuccessAction implements Action {
  readonly type = FETCH_IAB_CREATIVE_ATTRIBUTES_SUCCESS;

  constructor(public payload: { iabCreativeAttributes: ResponseFromServer<IabAttribute[]> }) {
  }
}

export class FetchIabCreativeAttributesFailureAction implements Action {
  readonly type = FETCH_IAB_CREATIVE_ATTRIBUTES_FAILURE;

  constructor(public payload: { error: string }) {
  }
}


export class FetchDspAccountsRequestAction implements Action {
  readonly type = FETCH_DSP_ACCOUNTS_REQUEST;
}

export class FetchDspAccountsSuccessAction implements Action {
  readonly type = FETCH_DSP_ACCOUNTS_SUCCESS;

  constructor(public payload: { dspAccounts: ResponseFromServer<DspAccount[]> }) {
  }
}

export class FetchDspAccountsFailureAction implements Action {
  readonly type = FETCH_DSP_ACCOUNTS_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class FetchPubTsByIdRequestAction implements Action {
  readonly type = FETCH_PUB_TS_REQUEST;
}

export class FetchPubTsByIdSuccessAction implements Action {
  readonly type = FETCH_PUB_TS_SUCCESS;

  constructor(public payload: { pubTs: any }) {
  }
}

export class FetchPubTsByIdFailureAction implements Action {
  readonly type = FETCH_PUB_TS_FAILURE;

  constructor(public payload: { error: string }) {
  }
}

export class SetDomainBundlesDuplicateStatus implements Action {
  readonly type = SET_DOMAIN_BUNDLES_DUPLICATE_STATUS;
  constructor(public payload: { isDomainBundlesDuplicateFinished: boolean }) {
  }
}

export class ResetPlacementState implements Action {
  readonly type = RESET_PLACEMENT_STATE;
}

export type PlacementsActions =
  UpdateFiltersAction |
  UpdatePlacementsTableAction |
  FetchPlacementsRequestAction |
  FetchPlacementsSuccessAction |
  FetchPlacementsFailureAction |
  FetchPlacementByIdRequestAction |
  FetchPlacementByIdSuccessAction |
  FetchPlacementByIdFailureAction |
  EditPlacementRequestAction |
  EditPlacementSuccessAction |
  EditPlacementFailureAction |
  CreatePlacementRequestAction |
  CreatePlacementSuccessAction |
  CreatePlacementFailureAction |
  FetchIabCategoriesRequestAction |
  FetchIabCategoriesSuccessAction |
  FetchIabCategoriesFailureAction |
  FetchIabBannerAdTypesRequestAction |
  FetchIabBannerAdTypesSuccessAction |
  FetchIabBannerAdTypesFailureAction |
  FetchIabCreativeAttributesRequestAction |
  FetchIabCreativeAttributesSuccessAction |
  FetchIabCreativeAttributesFailureAction |
  FetchDspAccountsRequestAction |
  FetchDspAccountsSuccessAction |
  FetchDspAccountsFailureAction |
  FetchPubTsByIdRequestAction |
  FetchPubTsByIdSuccessAction |
  FetchPubTsByIdFailureAction |
  FetchContentGenresRequestAction |
  FetchContentGenreSuccessAction |
  FetchContentGenreFailureAction |
  SetDomainBundlesDuplicateStatus |
  ResetPlacementState;
