import {
  LayoutActions,
  UPDATE_CURRENT_CONTEXT,
  UPDATE_LAST_URL,
} from './layout.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CtrlNavigationContext, LastPath } from '../../shared/_models/models';

export interface State {
  currentContext: CtrlNavigationContext;
  lastPath: LastPath;
}

const initialState: State = {
  currentContext: null,
  lastPath: {
    ctrl: null,
    spearad: null,
  },
};

export function layoutReducer(state = initialState, action: LayoutActions) {
  switch (action.type) {
    case UPDATE_CURRENT_CONTEXT:
      return <State>{
        ...state,
        currentContext: action.payload.currentContext,
      };
    case UPDATE_LAST_URL:
      return <State>{
        ...state,
        lastPath: {
          ...state.lastPath,
          ...action.payload.lastPath,
        }
      };
    default: {
      return state;
    }
  }
}

export const getLayoutState = createFeatureSelector<State>('layout');
export const getCurrentContext = createSelector(getLayoutState, (state: State) => state.currentContext);
export const getLayoutLastPath = createSelector(getLayoutState, (state: State) => state.lastPath);
