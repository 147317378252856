import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, style, transition, animate } from '@angular/animations';
import { Store } from '@ngrx/store';
import * as fromShared from '../../_store/shared.reducer';
import * as SharedActions from '../../_store/shared.actions';
import { ApiService } from '../../_services/api.service';
import {
  Observable,
  BehaviorSubject,
  Subject
} from 'rxjs';
import { debounceTime, skip, takeUntil } from 'rxjs/operators';
import { JapiQuery } from '../../_models/models';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.less'],
  animations: [
    trigger('toggleDropdown', [
      transition(':enter', [
        style({ opacity: 0, maxHeight: 0 }),
        animate('0.3s 0.5s ease', style({ opacity: 1, maxHeight: '*' }))
      ]),
      transition(':leave', [
        style({ opacity: 1, maxHeight: '*' }),
        animate('0.3s 0.5s ease', style({ opacity: 0, maxHeight: 0 }))
      ]),
    ]),
  ],
})
export class SharedGlobalSearchComponent implements OnInit, OnDestroy {

  @Input() layout;
  @Input() width;
  @Input() dropdownTransform = 'unset';
  @Input() alwaysOpen = false;
  private _isOpen: boolean = false;
  @Input()
  set isOpen(isOpen) {
    this._isOpen = isOpen;
    if (isOpen) {
      setTimeout(() => {
        this.nonNgZorroSearchInput.nativeElement.focus();
      }, 500);
    }
  }
  get isOpen() {
    return this._isOpen;
  }

  @Output('clickOutside') clickOutside: EventEmitter<Object> = new EventEmitter();

  @ViewChild('nonNgZorroSearchInput') nonNgZorroSearchInput: ElementRef;

  isDropDownOpen = false;
  isSearchValid = true;
  searchVal = null;
  pageSize = 50;
  pageNumber = 0;
  totalResults = 0;

  RESTRICTED_CHARACTERS = ['*', '?', '[', ']', '\\', '`', '^', '%', '{', '}', '|'];

  private unsubscribe$ = new Subject<void>();
  globalSearchOptions$: Observable<any>;
  isLoadingGlobalSearch$: Observable<boolean>;
  isLoadingGlobalSearch: boolean = false;
  searchValChange$ = new BehaviorSubject('');

  constructor(
    private store: Store<fromShared.SharedState>,
    private apiService: ApiService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.searchValChange$.pipe(skip(1), takeUntil(this.unsubscribe$), debounceTime(700))
      .subscribe(searchVal => {
        // this.isSearchValid = true;
        if (searchVal && searchVal.length > 2) {
          this.pageNumber = 0;
          this.totalResults = 0;
          this.getGlobalSearchOptions(searchVal, true);
        }
      });

    this.initStoreSubscriptions();
  }


  initStoreSubscriptions() {
    this.isLoadingGlobalSearch$ = this.store.select(fromShared.getSharedIsLoadingGlobalSearch);
    this.isLoadingGlobalSearch$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      this.isLoadingGlobalSearch = res;
    });
    this.globalSearchOptions$ = this.store.select(fromShared.getSharedGlobalSearchOptions);
    this.globalSearchOptions$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
    });
  }


  onSearch(val: string) {
    if (!this.isStringValid(val)) {
      this.store.dispatch(new SharedActions.RemoveGlobalSearchResultsFromStore());
      this.isSearchValid = false;
      return;
    }
    this.isSearchValid = true;
    if (val === '') {
      return;
    }
    this.searchValChange$.next(val);
  }


  isStringValid(str) {
    const regexp = new RegExp(`[${this.RESTRICTED_CHARACTERS.join('\\')}]`, 'g');
    return regexp.test(str) ? false : true;
  }


  getGlobalSearchOptions(searchVal, emptyOptions = false) {
    if (emptyOptions) {
      this.store.dispatch(new SharedActions.RemoveGlobalSearchResultsFromStore());
    }
    this.store.dispatch(new SharedActions.FetchGlobalSearchRequestAction());
    this.apiService.getGlobalSearch(searchVal.trim().toLowerCase().substr(0, 50), this.pageSize, this.pageNumber)
      .subscribe(
        (res: any) => {
          this.totalResults = res.totalElements;
          this.store.dispatch(new SharedActions.FetchGlobalSearchSuccessAction({globalSearchOptions: res.data}));
        },
        (err: any) => {
          this.store.dispatch(new SharedActions.FetchGlobalSearchFailureAction({error: err}));
        },
      );
  }


  onClickOutside(event) {
    if (!this.isOpen || this.alwaysOpen) { return; }
    if (event.value) {
      this.clickOutside.emit(true);
    }
  }


  onClear() {
    this.store.dispatch(new SharedActions.RemoveGlobalSearchResultsFromStore());
    setTimeout(() => {
      this.searchVal = '';
      this.nonNgZorroSearchInput.nativeElement.focus();
      this.onSearch(this.searchVal);
    }, 0);
  }


  onOpenChange(event) {
    if (event === false) {
      setTimeout(() => { this.isDropDownOpen = event; }, 100);
    } else {
      this.isDropDownOpen = event;
    }
  }


  onScroll(event) {
    const el = event.target;
    if ((el.scrollHeight - el.scrollTop - 10) <= el.clientHeight && !this.isLoadingGlobalSearch) {
      this.onScrollEnd();
    }
  }


  onScrollEnd() {
    if ( Math.ceil(this.totalResults / this.pageSize) > (this.pageNumber + 1)) {
      this.pageNumber++;
      this.getGlobalSearchOptions(this.searchVal);
    }
  }


  onSelectOption(event) {
    this.getRouteUrlBeforeNavigate(event);
  }


  navigateToPlacementById(placementId, publisherId) {
    const queryPlacements: JapiQuery = {
      filter: {
        id: +placementId
      },
      include: ['pubTs']
    };
    this.apiService.getPlacements(queryPlacements).subscribe(
      (res: any) => {
        const pubTs = res.data[0].pubTs;
        let path;
        if (this.layout === 'internal') {
          path = `/supply/publishers/${publisherId}/update/${pubTs.pubTsId}/placements/${placementId}/update`;
        } else if (this.layout === 'external') {
          path = `/portal/integration-details/${pubTs.pubTsId}/placements/${placementId}/update`;
        }
        this.navigateTo(path);
      },
      err => {

      }
    );
  }


  getRouteUrlBeforeNavigate(option) {
    if (this.layout === 'internal') {
      switch (option.type) {
        case 'fusion_deal':
          this.navigateTo(`/supply/deals/${option.idstr}/edit`);
          break;
        case 'fusion_deal_package':
          this.navigateTo(`/supply/inventory-packages/${option.idstr}/edit`);
          break;
        case 'fusion_publisher':
          this.navigateTo(`/supply/publishers/${option.idstr}/update`);
          break;
        case 'fusion_placement':
          this.navigateToPlacementById(option.idstr, option.publisherId);
          break;
        case 'fusion_exchange_deal':
          this.navigateTo(`/supply/exchange-deals/${option.idstr}/edit`);
          break;
        case 'fusion_publisher_seller_id':
          this.navigateTo(`/supply/publishers/${option.idstr}/update`);
          break;
        default:
          break;
      }
    } else if (this.layout === 'external') {
      switch (option.type) {
        case 'fusion_deal':
          this.navigateTo(`/portal/deals/${option.idstr}/edit`);
          break;
        case 'fusion_deal_package':
          this.navigateTo(`/portal/inventory-packages/${option.idstr}/edit`);
          break;
        case 'fusion_placement':
          this.navigateToPlacementById(option.idstr, option.publisherId);
          break;
        default:
          break;
      }
    }
  }


  navigateTo(path) {
    const origRouteReuseStrategy = this.router.routeReuseStrategy.shouldReuseRoute;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate([path]);
    setTimeout(() => {
      this.router.routeReuseStrategy.shouldReuseRoute = origRouteReuseStrategy;
    }, 1000);
  }


  getTypeLabel(type) {
    switch (type) {
      case 'fusion_deal':
        return 'Deal';
      case 'fusion_deal_package':
        return 'Inventory Package';
      case 'fusion_publisher':
      case 'fusion_publisher_seller_id':
        return 'Publisher';
      case 'fusion_placement':
        return 'Placement';
      case 'fusion_exchange_deal':
        return 'Exchange Deal';
      default:
        return 'no_type';
    }
  }


  getTypeColor(type) {
    switch (type) {
      case 'fusion_deal':
        return '#FF216B';
      case 'fusion_deal_package':
        return '#FFAA00';
      case 'fusion_publisher':
      case 'fusion_publisher_seller_id':
        return '#00BAB3';
      case 'fusion_placement':
        return '#1DC99A';
      case 'fusion_exchange_deal':
        return '#1d9bc9';
      default:
        return '#DBE1E5';
    }
  }


  showPublisherData(option) {
    if (this.layout === 'external') { return false; }
    if (option.publisherId && option.type !== 'fusion_publisher' && option.type !== 'fusion_publisher_seller_id') { return true; }
    return false;
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.store.dispatch(new SharedActions.RemoveGlobalSearchResultsFromStore());
  }


}
