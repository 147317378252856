import { Action } from '@ngrx/store';

export const FETCH_AD_SERVER_TECH_REQUEST = '[Ad server tech] Fetch ad server tech request';
export const FETCH_AD_SERVER_TECH_SUCCESS = '[Ad server tech] Fetch ad server tech success';
export const FETCH_AD_SERVER_TECH_FAILURE = '[Ad server tech] Fetch ad server tech failure';

export const FETCH_AD_SERVER_TECH_BY_ID_REQUEST = '[Ad server tech] Fetch ad server tech by id request';
export const FETCH_AD_SERVER_TECH_BY_ID_SUCCESS = '[Ad server tech] Fetch ad server tech by id success';
export const FETCH_AD_SERVER_TECH_BY_ID_FAILURE = '[Ad server tech] Fetch ad server tech by id failure';
export const REMOVE_AD_SERVER_TECH_BY_ID_FROM_STATE = '[Ad server tech] REMOVE ad server tech by id from state';

export const CREATE_AD_SERVER_TECH_REQUEST = '[Ad server tech] Create ad server tech request';
export const CREATE_AD_SERVER_TECH_SUCCESS = '[Ad server tech] Create ad server tech success';
export const CREATE_AD_SERVER_TECH_FAILURE = '[Ad server tech] Create ad server tech failure';

export const EDIT_AD_SERVER_TECH_REQUEST = '[Ad server tech] Update ad server tech request';
export const EDIT_AD_SERVER_TECH_SUCCESS = '[Ad server tech] Update ad server tech success';
export const EDIT_AD_SERVER_TECH_FAILURE = '[Ad server tech] Update ad server tech failure';

export const DELETE_AD_SERVER_TECH_REQUEST = '[Ad server tech] Delete ad server tech request';
export const DELETE_AD_SERVER_TECH_SUCCESS = '[Ad server tech] Delete ad server tech success';
export const DELETE_AD_SERVER_TECH_FAILURE = '[Ad server tech] Delete ad server tech failure';

export const FETCH_TAG_PARAMS_REQUEST = '[Tag params] Fetch tag params request';
export const FETCH_TAG_PARAMS_SUCCESS = '[Tag params] Fetch tag params success';
export const FETCH_TAG_PARAMS_FAILURE = '[Tag params] Fetch tag params failure';

export class FetchAdServerTechRequestAction implements Action {
  readonly type = FETCH_AD_SERVER_TECH_REQUEST;
}
export class FetchAdServerTechSuccessAction implements Action {
  readonly type = FETCH_AD_SERVER_TECH_SUCCESS;
  constructor(public payload: { adServerTech: any[] }) {
  }
}
export class FetchAdServerTechFailureAction implements Action {
  readonly type = FETCH_AD_SERVER_TECH_FAILURE;
  constructor(public payload: { error: string }) {
  }
}

export class FetchAdServerTechByIdRequestAction implements Action {
  readonly type = FETCH_AD_SERVER_TECH_BY_ID_REQUEST;
}
export class FetchAdServerTechByIdSuccessAction implements Action {
  readonly type = FETCH_AD_SERVER_TECH_BY_ID_SUCCESS;
  constructor(public payload: { editedAdServerTech: any }) {
  }
}
export class FetchAdServerTechByIdFailureAction implements Action {
  readonly type = FETCH_AD_SERVER_TECH_BY_ID_FAILURE;
  constructor(public payload: { error: string }) {
  }
}
export class RemoveAdServerTechByIdFromState implements Action {
  readonly type = REMOVE_AD_SERVER_TECH_BY_ID_FROM_STATE;
}

export class CreateAdServerTechRequestAction implements Action {
  readonly type = CREATE_AD_SERVER_TECH_REQUEST;
}
export class CreateAdServerTechSuccessAction implements Action {
  readonly type = CREATE_AD_SERVER_TECH_SUCCESS;
  constructor(public payload: { editedAdServerTech: any }) {
  }
}
export class CreateAdServerTechFailureAction implements Action {
  readonly type = CREATE_AD_SERVER_TECH_FAILURE;
  constructor(public payload: { error: string }) {
  }
}

export class EditAdServerTechRequestAction implements Action {
  readonly type = EDIT_AD_SERVER_TECH_REQUEST;
}
export class EditAdServerTechSuccessAction implements Action {
  readonly type = EDIT_AD_SERVER_TECH_SUCCESS;
  constructor(public payload: { editedAdServerTech: any }) {
  }
}
export class EditAdServerTechFailureAction implements Action {
  readonly type = EDIT_AD_SERVER_TECH_FAILURE;
  constructor(public payload: { error: string }) {
  }
}

export class DeleteAdServerTechRequestAction implements Action {
  readonly type = DELETE_AD_SERVER_TECH_REQUEST;
}
export class DeleteAdServerTechSuccessAction implements Action {
  readonly type = DELETE_AD_SERVER_TECH_SUCCESS;
  constructor(public payload: { deleteAdServerTechId: number | string }) {
  }
}
export class DeleteAdServerTechFailureAction implements Action {
  readonly type = DELETE_AD_SERVER_TECH_FAILURE;
  constructor(public payload: { error: string }) {
  }
}

export class FetchTagParamsRequestAction implements Action {
  readonly type = FETCH_TAG_PARAMS_REQUEST;
  }
export class FetchTagParamsSuccessAction implements Action {
  readonly type = FETCH_TAG_PARAMS_SUCCESS;
  constructor(public payload: { tagParams: string }) {
  }
}
export class FetchTagParamsFailureAction implements Action {
  readonly type = FETCH_TAG_PARAMS_FAILURE;
  constructor(public payload: { error: string }) {
  }
}
export type AdServerTechActions =
  FetchAdServerTechRequestAction |
  FetchAdServerTechSuccessAction |
  FetchAdServerTechFailureAction |
  FetchAdServerTechByIdRequestAction |
  FetchAdServerTechByIdSuccessAction |
  FetchAdServerTechByIdFailureAction |
  RemoveAdServerTechByIdFromState |
  CreateAdServerTechRequestAction |
  CreateAdServerTechSuccessAction |
  CreateAdServerTechFailureAction |
  EditAdServerTechRequestAction |
  EditAdServerTechSuccessAction |
  EditAdServerTechFailureAction |
  DeleteAdServerTechRequestAction |
  DeleteAdServerTechSuccessAction |
  DeleteAdServerTechFailureAction |
  FetchTagParamsRequestAction |
  FetchTagParamsSuccessAction |
  FetchTagParamsFailureAction;

