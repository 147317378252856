import { Pipe, PipeTransform } from '@angular/core';

import { SharedService } from '../_services/shared.service';

@Pipe({
  name: 'formatText'
})
export class FormatTextPipe implements PipeTransform {

  constructor(private sharedService: SharedService) {
  }

  transform(value: any, args?: any): any {
    if ((!value && value?.toString().toLowerCase() !== 'false') || value?.length === 0 ||
      (typeof (value + 0) === 'number' &&
        value?.toString().toLowerCase() !== 'true' && value?.toString().toLowerCase() !== 'false')) {
      return value;
    }

    let newValue = Array.isArray(value) ? value.join(', ').toString() : value.toString();
    if (args.capitalize) {
      newValue = newValue.toLowerCase();
    }
    if (args.replaceNull) {
      newValue = newValue.toLowerCase() === '0' ? '-' : newValue;
    }
    if (args.booleanString) {
      newValue = newValue == 0 ? 'False' : 'True';
    }
    if (args.firstCapital) {
      const tmp = newValue.toLowerCase();
      newValue = tmp.charAt(0).toUpperCase() + tmp.slice(1);
    }
    if (args.removeUnderscore) {
      newValue = newValue.replace(/_/g, ' ');
    }
    if (args.truncateText && newValue.length > args.truncateText) {
      newValue = newValue.substring(0, args.truncateText) + '...';
    }

    if (args.camelCaseToWords) {
      // regular case - no ( or )
      if (newValue.indexOf('(') < 0) {
        newValue = this.sharedService.camelCaseToWords(newValue);
      }
      // special case - starts with " (" - do not camelCaseToWords
      if (newValue.substring(0, 1) === '(') {
        newValue = newValue.substring(1, newValue.length - 1);
      }
      // special case - starts with " (" - do not camelCaseToWords
      if (newValue.substring(0, 2) === ' (') {
        newValue = newValue.substring(2, newValue.length - 1);
      }
      // last case - camelCaseToWords only until first (
      newValue = this.sharedService.camelCaseToWords(newValue.substring(0, newValue.indexOf('('))) + ' ' +
            newValue.substring(newValue.indexOf('('));
    }

    if (args.enumToTitleCase) {
      // if all letters are capital
      if ((!Array.isArray(value) && value.toString() === value.toString().toUpperCase()) ||
        (value.toString() === 'true' || value.toString() === 'false')) {

        const first = value.toString().substr(0, 1).toUpperCase();
        newValue = newValue.replace(/_/, ' ');
        newValue = first.toUpperCase() + newValue.substr(1).toLowerCase();
      }
    }

    return newValue;
  }
}
