import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { fromEvent, Subject } from 'rxjs';

import * as _ from 'lodash';

import { AppFilter, AuthPermissions, SortOption, ReportFilterButtonActions } from '../../_models/models';
import { SharedService } from '../../_services/shared.service';
import { debounceTime, takeUntil, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-filters',
  templateUrl: './app-filters.component.html',
  styleUrls: ['./app-filters.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppFiltersComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('filtersContainerEl', {static: true}) filtersContainerEl: ElementRef;
  @Input() buttons: any[] = [];
  @Input() isDataLoading = false;
  @Input() isDownloading = false;
  @Input() authPermissions: AuthPermissions;
  @Input() formValidatorFn: ValidatorFn;

  @Output() filtersChanged: EventEmitter<{ filters: AppFilter[]; filterId: string }> = new EventEmitter();
  @Output() filterSearched: EventEmitter<any> = new EventEmitter();
  @Output() buttonClicked: EventEmitter<any> = new EventEmitter();
  filterButtonsColStart: any = 'auto';
  filtersControls: UntypedFormArray;
  updateFilterDebounce = _.debounce((filterId: string) => {
    this.filtersChanged.emit({filters: this.filters, filterId: filterId});
  }, 300, {});

  searchFilterDebounce = _.debounce((searchValue: string, filterId: string) => {
    this.filterSearched.emit({searchValue: searchValue, filterId: filterId});
  }, 1000, {});

  // debounce on button click is needed to allow state to update filters before
  clickButtonDebounce = _.debounce((buttonId: string) => {
    this.buttonClicked.emit(buttonId);
  }, 400, {});
  // input set-get approach, because simple changes won't capture previous filters
  // the idea is to manually check what changes should be applied to control form
  private unsubscribe$ = new Subject<void>();
  private _previousFilters: AppFilter[];
  private _currentFilters: AppFilter[];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private sharedService: SharedService,
    private cdr: ChangeDetectorRef,
  ) {
  }
  get filters() {
    return this._currentFilters;
  }
  @Input()
  set filters(filters) {
    if (this._previousFilters) {
      this.updateFiltersControls(this._previousFilters, filters);
    }
    this._previousFilters = _.cloneDeep(filters);
    this._currentFilters = _.cloneDeep(filters);
    setTimeout(() => {
      this.calcGridColumns();
    }, 100);
  }

  ngOnInit() {
    this.filtersControls = new UntypedFormArray([]);
    this.filtersControls = this.populateFiltersToControls(this.filters);
    this.filtersControls?.addValidators(this.formValidatorFn);
  }

  ngAfterViewInit() {
    const resize$ = fromEvent(window, 'resize').pipe(
      startWith(''),
      debounceTime(300),
      takeUntil(this.unsubscribe$)
    );
    resize$.subscribe(() => {
      this.calcGridColumns();
    });
  }

  trackByFn(index, filter) {
    return index;
  }

  populateFiltersToControls(filters: AppFilter[]) {
    const updatedFilters = new UntypedFormArray([]);
    let selectedValues;
    filters.forEach((filter: AppFilter) => {
      if (filter.type === 'SELECT' || filter.type === 'SELECT_WITH_SWITCH') {
        selectedValues = filter.selectedValues ? filter.selectedValues.map(val => val.displayName) : [];
      } else {
        selectedValues = _.cloneDeep(filter.selectedValues);
      }
      updatedFilters.push(this.formBuilder.control(selectedValues));
    });
    return updatedFilters;
  }

  updateFiltersControls(previousFilters: AppFilter[], currentFilters: AppFilter[]) {
    currentFilters.forEach((currentFilter: AppFilter, index: number) => {

      if (!_.isEqual(currentFilter.selectedValues, previousFilters[index].selectedValues)) {
        let selectedValues;
        if (currentFilter.type === 'SELECT' || currentFilter.type === 'SELECT_WITH_SWITCH') {
          selectedValues = currentFilter.selectedValues ? currentFilter.selectedValues.map(val => val.displayName) : [];
        } else {
          selectedValues = _.cloneDeep(currentFilter.selectedValues);
        }
        this.filtersControls.at(index).setValue(selectedValues, {emitEvent: false});
      }

      // workaround bug that happened only on this filter
      if (currentFilter.id === 'publisherReportTimezone' &&
        this.filtersControls.at(index).value[0] !== currentFilter.selectedValues[0].displayName) {
        this.filtersControls.at(index).setValue(currentFilter.selectedValues[0].displayName, {emitEvent: false});
      }

      if (currentFilter.isValid && this.filtersControls.at(index).hasError('err')) {
        this.filtersControls.at(index).setErrors(null);
      }
      if (!currentFilter.isValid && !this.filtersControls.at(index).hasError('err')) {
        this.filtersControls.at(index).setErrors({err: true});
      }

      if (currentFilter.isDisabled && !previousFilters[index].isDisabled) {
        this.filtersControls.at(index).disable({emitEvent: false});
      }
      if (!currentFilter.isDisabled && previousFilters[index].isDisabled) {
        this.filtersControls.at(index).enable({emitEvent: false});
      }
      if (currentFilter.isRequired && !previousFilters[index].isRequired) {
        this.filtersControls.at(index).setValidators([Validators.required]);
        this.filtersControls.at(index).markAsTouched();
        this.filtersControls.at(index).updateValueAndValidity();
      }
      if (!currentFilter.isRequired && previousFilters[index].isRequired) {
        this.filtersControls.at(index).setValidators(null);
        this.filtersControls.at(index).updateValueAndValidity();
      }
    });
  }

  onFilterChange(selectedValues, controlIndex: number) {
    this.filters[controlIndex].selectedValues = Array.isArray(selectedValues) ? [...selectedValues] : selectedValues;
    this.updateFilterDebounce(this.filters[controlIndex].id);
  }

  onFilterSearch(searchValue, controlIndex: number) {
    this.searchFilterDebounce(searchValue, this.filters[controlIndex].id);
  }

  onButtonClick(buttonId: string) {
    if (buttonId.includes('ApplyFiltersButton') && !this.filtersControls.valid) {
      this.sharedService.showNotification('error', 'Invalid filters', 'Please ensure filters are valid');
      return;
    }
    this.clickButtonDebounce(buttonId);
  }

  onDatePickerChange(datePickerState: any) {
    this.buttons.forEach(btn => {
      if (btn.id.includes('ReportApplyFiltersButton') || btn.id.includes('ReportDownloadCsvButton')) {
        btn.isDisabled = datePickerState.isDatePickerOpen;
      }
    });
  }

  calcGridColumns() {
    // calculating the remaining empty columns in the last row (filter-buttons will receive the remaining space)
    const gridEl = this.filtersContainerEl.nativeElement;
    // need to add this check otherwise unit tests throw undefined
    if (gridEl == null) {
      return;
    }
    const gridLeft = Math.ceil(gridEl.getBoundingClientRect().left);
    if (gridLeft === 0) {
      return;
    }
    const gridCols = getComputedStyle(gridEl).gridTemplateColumns.split(' ').map(a => +a.substring(0, a.length - 2)).filter(a => a);
    const gridColWidth = Math.floor(gridCols[0]);
    let gridGap: any = getComputedStyle(gridEl).columnGap;
    gridGap = +gridGap.substr(0, gridGap.length - 2);
    const visibleFiltersCount = this.filters.filter(filter => !filter['isHidden']).length;

    // add check for tests to pass
    let lastFilterLeft = 0;
    if (gridEl.children[visibleFiltersCount - 1] != null) {
      lastFilterLeft = Math.ceil(gridEl.children[visibleFiltersCount - 1].getBoundingClientRect().left);
    }
    let lastFilterPosition = 0;
    gridCols.forEach(() => {
      if ((gridColWidth + gridGap) * lastFilterPosition <= (lastFilterLeft - gridLeft)) {
        lastFilterPosition++;
      }
    });
    // if the remaining empty columns in the last row is 0 or smaller than the number of filter buttons
    // we tell filter-buttons to start at column 1 (giving them a whole new row)
    if ((gridCols && lastFilterPosition === gridCols.length) ||
      (this.buttons && gridCols && this.buttons.length > (gridCols.length - lastFilterPosition)) ||
      (gridCols && gridCols[gridCols.length - 1] < gridColWidth)) {
      this.filterButtonsColStart = 1;
    } else {
      this.filterButtonsColStart = lastFilterPosition + 1;
    }

    if (this.cdr && !this.cdr['destroyed']) {
      this.cdr.markForCheck();
    }
  }

  sortByChanged(i: number, value: SortOption): void {
    this._currentFilters[i].sortType = value;
    this.updateFilterDebounce(this.filters[i].id);
  }

  ngOnDestroy() {
    this.cdr.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
