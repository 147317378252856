import { Action } from '@ngrx/store';

export const FETCH_GLOBAL_SEARCH_REQUEST = '[Shared] Get global search request';
export const FETCH_GLOBAL_SEARCH_SUCCESS = '[Shared] Get global search success';
export const FETCH_GLOBAL_SEARCH_FAILURE = '[Shared] Get global search failure';
export const REMOVE_GLOBAL_SEARCH_RESULTS_FROM_STORE = '[Shared] Remove global search results from store';




export class FetchGlobalSearchRequestAction implements Action {
  readonly type = FETCH_GLOBAL_SEARCH_REQUEST;
}
export class FetchGlobalSearchSuccessAction implements Action {
  readonly type = FETCH_GLOBAL_SEARCH_SUCCESS;
  constructor(public payload: { globalSearchOptions: any }) {}
}
export class FetchGlobalSearchFailureAction implements Action {
  readonly type = FETCH_GLOBAL_SEARCH_FAILURE;
  constructor(public payload: { error: string }) {}
}
export class RemoveGlobalSearchResultsFromStore implements Action {
  readonly type = REMOVE_GLOBAL_SEARCH_RESULTS_FROM_STORE;
}



export type SharedActions =
  FetchGlobalSearchRequestAction |
  FetchGlobalSearchSuccessAction |
  FetchGlobalSearchFailureAction |
  RemoveGlobalSearchResultsFromStore;

