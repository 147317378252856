import { AfterViewInit, ComponentFactoryResolver, Directive, Input, ViewContainerRef } from '@angular/core';
import { CustomComponentConfig, CustomComponent} from '../_models/models';

@Directive({
  selector: '[customComponentHost]'
})
export class CustomComponentHostDirective implements AfterViewInit {

  private _componentConfig: CustomComponentConfig;
  @Input()
  set componentConfig(componentConfig) {
    if (componentConfig) {
      this._componentConfig = componentConfig;
      this.loadCustomComponent();
    }
  }
  get componentConfig() {
    return this._componentConfig;
  }

  constructor(
    public viewContainerRef: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
  ) {}

  ngAfterViewInit(): void {
    this.loadCustomComponent();
  }

  loadCustomComponent() {
    if (this.componentConfig?.component) {
      this.viewContainerRef.clear();
      let componentRef = this.viewContainerRef.createComponent<CustomComponent>(this.componentConfig.component);
      componentRef.instance.data = this.componentConfig.data;
      componentRef.changeDetectorRef.detectChanges();
    }
  }

}
