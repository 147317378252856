import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { IabAttribute, IabBat, ResponseFromServer } from '../../_models/models';
import {SharedStoreService} from '../shared-store.service';

@Injectable({ providedIn: 'root' })

export class SupplyIABService {

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private sharedStoreService: SharedStoreService
  ) {}

  getBannerAdTypes(): Observable<ResponseFromServer<IabBat[]>> {
    return this.apiService.getIabBannerAdTypes();
  }

  getCreativeAttributes(): Observable<ResponseFromServer<IabAttribute[]>> {
    return this.apiService.getIabCreativeAttributes();
  }

}
