import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ModalButton, ModalActionType } from '../../_models/models';

@Component({
  selector: 'app-modal',
  templateUrl: './app-modal.component.html',
  styleUrls: ['./app-modal.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class AppModalComponent {

  @Input() modalWidth = 550;
  @Input() isModalVisible = false;
  @Input() modalButtons: ModalButton[];
  @Input() disableApproveButton = false;
  @Output() modalEvent: EventEmitter<ModalActionType> = new EventEmitter();

  onModalAction(actionType: ModalActionType): void {
    this.modalEvent.emit(actionType);
  }
}
