<div class="card-wrapper">
  <nz-card>
    <div class="sectionTitle justify-content-space-between">
      <div class="display-flex capitalize-text align-items-center">
        <fa-icon icon="bell"></fa-icon>
        <nz-spin [nzSpinning]="isLoading">
          {{currentAlert.name}}
        </nz-spin>
      </div>
      <button nz-button nzSize="large" nzType="primary" class="primary-button" id="add-publisher-btn" (click)="toggleModalView()">Add Publisher</button>
    </div>
    <app-table [tableData]="alertsTableData"
               [tableColumns]="alertsTableColumns"
               [table]="publishersAlertsTable"
               [authPermissions]="authUser"
               (alertRowAction)="onAlertRowAction($event)"
               [isTableLoading]="isLoading"
    >
    </app-table>
    <app-modal
      [isModalVisible]="isModalVisible"
      [modalButtons]="modalButtons"
      [modalWidth]="400"
      (modalEvent)="onModalEvent($event)"
    >
      <app-add-publisher-to-alert [addPublisherForm]="addPublisherForm"
                                  [user]="user"
                                  (informOnSubmit)="isSuccessModalSubmit($event)">
      </app-add-publisher-to-alert>
    </app-modal>
  </nz-card>
</div>


