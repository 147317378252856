<ng-container *appFeatureToggle="'PaymentHistory'" >
  <ng-container  *ngIf="validHistoryView">
    <div class="sectionTitle">
      <fa-icon icon="history"></fa-icon>
      Payment History
    </div>
    <nz-spin nzSize="large" [nzSpinning]="isLoading">
          <app-filters
            [filters]="billingHistoryFilters"
            [buttons]="billingHistoryTableButtons"
            [authPermissions]="authPermissions"
            [isDataLoading]="isLoading"
            (filtersChanged)="onFiltersChange($event)"
            (buttonClicked)="onRetrieveClick()">
          </app-filters>
          <ng-container *ngIf="billingHistory">
            <app-table
              [isExternal] = "isExternal"
              [isTableLoading]="isLoading"
              [table]="billingHistoryTable"
              [tableColumns]="billingHistoryTableColumns"
              [tableData]="billingHistory"
              [authPermissions]="authPermissions"
              [totalRows]="billingHistory?.length"
              [isPaginated]="true"
              [hasSizeChanger]="true"
              [tableType]="'clientSide'"
              [idProp]="'billPaymentId'"
              (billingHistoryAction)="onRowAction($event)">
            </app-table>
            <app-modal
              [isModalVisible]="isModalVisible"
              [modalButtons]="modalButtons"
              [modalWidth]="400"
              (modalEvent)="onModalEvent($event)">
              <app-bill-payment-info *ngIf="billPaymentToShow" [billPayment]="billPaymentToShow"></app-bill-payment-info>
            </app-modal>
          </ng-container>
    </nz-spin>
  </ng-container>
</ng-container>
